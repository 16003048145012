import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

class Page extends React.Component {
  render() {
    return (
      <div>
        <Helmet title={this.props.title} link={this.props.link} meta={this.props.meta} />
        { this.props.children }
      </div>
    );
  };
}

Page.propTypes = {
  title: PropTypes.string,
  link: PropTypes.array,
  meta: PropTypes.array
};

export default hot(module)(Page);
