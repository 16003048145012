import { combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import * as types from 'types';

const projects = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_LANDSCAPE_SUCCESS:
      return keyBy(action.data.map((thisProject) => {
        const tempProject = thisProject;
        tempProject.latitude = parseFloat(tempProject.latitude);
        tempProject.longitude = parseFloat(tempProject.longitude);
        return tempProject;
      }), 'id');
    default:
      return state;
  }
};

const organizations = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_LANDSCAPE_SUCCESS:
      const orgs = {};
      action.data.forEach((thisProject) => {
        const orgId = String(thisProject.organization_id);
        if (!Object.keys(orgs).includes(orgId)) {
          orgs[orgId] = {};
          orgs[orgId].id = thisProject.Organization.id;
          orgs[orgId].name = thisProject.Organization.name;
          orgs[orgId].website_url = thisProject.Organization.website_url;
          orgs[orgId].logo_url = thisProject.Organization.logo_url;
          orgs[orgId].slug = thisProject.Organization.slug;
          orgs[orgId].projects = [];
        }
        orgs[orgId].projects.push(thisProject.id);
      });
      return orgs;
    default:
      return state;
  }
};

const recent = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_LANDSCAPE_RECENT_SUCCESS:
      return action.data;
    case types.GET_LANDSCAPE_RECENT_UPDATE_SUCCESS:
      return isEmpty(action.data) ? [] : concat(state, action.data);
    case types.CLEAR_LANDSCAPE_RECENT:
      return [];
    case types.EXIT_LANDSCAPE_RECENT:
      return [];
    default:
      return state;
  }
};

const recentRunning = (
  state = false,
  action
) => {
  switch (action.type) {
    case types.LANDSCAPE_RECENT_RUNNING:
      return true;
    case types.EXIT_LANDSCAPE_RECENT:
      return false;
    default:
      return state;
  }
};

const landscapeReducer = combineReducers({
  projects,
  organizations,
  recent,
  recentRunning,
});

export default landscapeReducer;
