import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import intersection from 'lodash/intersection';
import classNames from '../utils/classNames.js';
import styles from 'css/components/livechart';
import ReactHighcharts from 'react-highcharts';
import highchartsExporting from 'highcharts-exporting';
import moment from 'moment-timezone';
import downloadIcon from '../images/Download-Icon.png';

const cx = classNames.bind(styles);

class LiveChart extends Component {
  constructor(props) {
    super(props);

    this.getSeriesName = this.getSeriesName.bind(this);
    this.getSeriesColor = this.getSeriesColor.bind(this);
    this.constructSeries = this.constructSeries.bind(this);
    this.timeRangeGenerator = this.timeRangeGenerator.bind(this);
    this.exportFilenameGenerator = this.exportFilenameGenerator.bind(this);

    this.state = {
      series: this.constructSeries(props),
      currentObservations: [],
      liveChartInterval: null,
      chartLoaded: false,
    };
  }

  componentDidMount() {
    const { recentOrdered } = this.props;

    this.setState({
      chartLoaded: true,
    });

    // HighCharts Compatibility
    if (!('liveChartTimeRangeGenerator' in window)) {
      window.liveChartTimeRangeGenerator = this.timeRangeGenerator;
      window.liveChartExportFilenameGenerator = this.exportFilenameGenerator;

      highchartsExporting(ReactHighcharts.Highcharts);
    }

    ReactHighcharts.Highcharts.setOptions({
      global: {
          useUTC: false
      }
    });
  }

  constructSeries(nextProps) {
    const buildSeries = {};
    const series = [];

    nextProps.recentOrdered.forEach((thisObs) => {
      if (!(thisObs.species_id in buildSeries)) {
        buildSeries[thisObs.species_id] = [];
      }
      buildSeries[thisObs.species_id].push([moment.tz(thisObs.recorded_at, nextProps.projectData.timezone).valueOf(), thisObs.count]);
    });

    const updateSpecies = Object.keys(buildSeries);

    updateSpecies.forEach((speciesSeries) => {
      const speciesName = nextProps.taxonomy[speciesSeries].common_name;
      series.push({
        name: speciesName,
        id: this.getSeriesName(speciesSeries),
        color: this.getSeriesColor(speciesName),
        data: buildSeries[speciesSeries],
        animation: false,
      });
    });

    return series;
  }

  componentWillReceiveProps(nextProps) {
    let series = this.state.series;

    const updatedObservations = Object.keys(nextProps.recentIds);
    if (intersection(this.state.currentObservations, updatedObservations).length < updatedObservations.length) {
      series = this.constructSeries(nextProps);
    }

    this.setState({
      series,
      currentObservations: updatedObservations,
    });
  }

  getSeriesName(speciesId) {
    return 'species-series-' + speciesId.toString();
  }

  getSeriesColor(species) {
    const colorScheme = ['#BE1E2D', '#BE1E3A', '#BF1E48', '#C01E56', '#C11E65', '#C21E73', '#C31E81', '#C41E90', '#C51E9F', '#C61EAE', '#C71EBD', '#C31EC7', '#B51EC8', '#A71EC9', '#991ECA', '#8B1ECB', '#7D1ECC', '#6E1ECD', '#601ECE', '#511ECF', '#421ED0', '#331ED1', '#241ED1', '#1E27D2', '#1E36D3', '#1E46D4', '#1E55D5', '#1E65D6', '#1E75D7', '#1E86D8', '#1E96D9', '#1EA7DA', '#1EB7DA', '#1EC8DB', '#1ED9DC', '#1EDDD0', '#1DDEC0', '#1DDFB1', '#1DE0A1', '#1DE191', '#1DE281', '#1DE370', '#1DE460', '#1DE44F', '#1DE53E', '#1DE62D', '#1EE71D', '#2FE81D', '#41E91D', '#52EA1D', '#64EB1D', '#76EC1D', '#88ED1D', '#9AED1D', '#ACEE1D', '#BFEF1D', '#D1F01D', '#E4F11D', '#F2ED1D', '#F3DB1D', '#F4CA1D', '#F5B81D', '#F6A61D', '#F7951D'];
    let speciesCode = 0;

    for (let thisChar = 0; thisChar < species.length; thisChar++) {
      speciesCode += species.charCodeAt(thisChar) * 31;
    }

    speciesCode %= colorScheme.length;

    return colorScheme[speciesCode];
  }

  timeRangeGenerator(clean) {
    const currentDay = moment();
    const chartStart = moment().subtract(2, 'hours');
    const chartEnd = moment();

    if (clean) {
      return currentDay.format('ll') + ' ' + chartStart.format('kkmm') + ' to ' + chartEnd.format('kkmm');
    } else {
      return currentDay.format('LL') + ' from ' + chartStart.format('LT') + ' to ' + chartEnd.format('LT');
    }
  }

  exportFilenameGenerator() {
    const { projectData } = this.props;

    return 'Live Chart - ' + projectData.name + ' - ' + this.timeRangeGenerator(true);
  }

  render() {
    const { recentOrdered, recentIds, projectData, taxonomy } = this.props;

    let configOnly = false;

    const chartConfig = {
      chart: {
        type: 'scatter',
        zoomType: 'xy'
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'url(' + downloadIcon + ')',
            width: 22,
            height: 22,
            x: -2,
            y: -2,
            symbolX: 20,
            symbolY: 20,
            symbolFill: null,
            menuItems: [{
                text: 'Download PNG',
                onclick: function () {
                  this.exportChart({
                    type: 'image/png',
                    filename: window.liveChartExportFilenameGenerator(),
                    subtitle: {
                      text: window.liveChartTimeRangeGenerator(false),
                    },
                  });
                }
              }, {
                text: 'Download JPEG',
                onclick: function () {
                    this.exportChart({
                      type: 'image/jpeg',
                      filename: window.liveChartExportFilenameGenerator(),
                      subtitle: {
                        text: window.liveChartTimeRangeGenerator(false),
                      },
                    });
                }
              }, {
                text: 'Download PDF',
                onclick: function () {
                  this.exportChart({
                      type: 'application/pdf',
                      filename: window.liveChartExportFilenameGenerator(),
                      subtitle: {
                        text: window.liveChartTimeRangeGenerator(false),
                      },
                    });
                },
              separator: false
            }],
            theme: {
              fill: null,
              states: {
                hover: {
                  fill: '#FFFFFF',
                },
                select: {
                  fill: '#FFFFFF'
                }
              }
            }
          }
        },
        chartOptions: {
          title: {
            text: projectData.name,
          },
          credits: {
            enabled: true,
            text: '© ' + moment().format('YYYY') + ' ' + projectData.organization.name + ' - Chart generated by Dunkadoo.org'
          }
        },
        sourceWidth: 1000,
        sourceHeight: 600,
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'datetime',
        tickPixelInterval: 75,
        startOnTick: false,
        endOnTick: false,
        dateTimeLabelFormats: {
          hour: '%l:%M %p',
          minute: '%l:%M %p',
          second: '%l:%M %p',
        }
      },
      yAxis: {
        title: {
          text: null
        },
        tickInterval: 1,
        plotLines: [
          {
            value: 0,
            width: 1,
            color: '#808080'
          }
        ]
      },
      legend: {
        enabled: true
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.series.name + '</b><br/>' + ReactHighcharts.Highcharts.dateFormat('%l:%M %p', this.x) + '<br/>Count: ' + this.y;
        }
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          }
        }
      }
    };

    chartConfig.series = this.state.series;

    chartConfig.xAxis.min = moment().subtract(2, 'hours').valueOf();
    chartConfig.xAxis.max = moment().add(1, 'minute').valueOf();

    return (
      <div className={cx('outer-container')}>
        <h3 className={cx('section-head')}>Live Updates</h3>
        <div className={cx('graph', 'container')}>
          {recentOrdered.length > 0 ? (
            <ReactHighcharts config={chartConfig} isPureConfig={configOnly} ref={(ref) => { this.liveChart = ref; }} />
          ) : (
            <div className={cx('outer-container')}>
              <h3
              className={cx('section-subhead')}>
                No Reports in the Last Two Hours
              </h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

LiveChart.propTypes = {
  recentOrdered: PropTypes.array.isRequired,
  recentIds: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  taxonomy: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    recentOrdered: state.project.recentObservations.ordered,
    recentIds: state.project.recentObservations.identified,
    projectData: state.project.projectData,
    taxonomy: state.taxonomy.taxonomy,
  };
}

export default connect(mapStateToProps, { })(LiveChart);
