import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import classNames from '../utils/classNames.js';
import Dimensions from '../utils/local_modules/react-dimensions';
import { Table, Column, ColumnGroup, Cell } from 'fixed-data-table-2';
import '../../node_modules/fixed-data-table-2/dist/fixed-data-table.css';
import styles from 'css/components/hourlycounts';
import moment from 'moment-timezone';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';

const cx = classNames.bind(styles);

function hourRange(startHour, endHour) {
  const hourRange = [moment(startHour)];
  let current = moment(startHour);
  const end = moment(endHour);
  while (!current.isSame(end)) {
    current.add(1, 'hour');
    hourRange.push(current.clone());
  }
  return hourRange;
}

class HourlyCounts extends Component {
  constructor(props) {
    super(props);

    this.dateChange = this.dateChange.bind(this);

    let availableDays = Object.keys(props.hourlyCountData);

    availableDays = availableDays.map((thisDay) => {
      return moment.tz(thisDay, props.projectData.timezone);
    });

    availableDays.sort((a, b) => {
      return a.valueOf() - b.valueOf();
    });

    this.state = {
      date: availableDays[availableDays.length - 1].format('YYYY-MM-DD'),
      noDataToDisplay: false,
    };
  }

  dateChange(event) {
    const target = event.target;

    this.setState({
      date: target.value
    }, () => {
      ReactGA.event({
        category: 'Public: HourlyCounts',
        action: 'dateChange',
        label: this.props.projectData.name,
      });
    });
  }

  render() {
    const { containerHeight, containerWidth, projectData, taxonomy, speciesDays, dateList, hourlyCountData } = this.props;

    let availableDays = Object.keys(hourlyCountData);

    availableDays = availableDays.map((thisDay) => {
      return moment.tz(thisDay, projectData.timezone);
    });

    availableDays.sort((a, b) => {
      return a.valueOf() - b.valueOf();
    });

    const hourObservations = hourlyCountData[this.state.date];

    let availableHours = hourObservations.map((thisObs) => {
      return thisObs.localHour;
    });

    availableHours = uniq(availableHours);

    availableHours = availableHours.map((thisHour) => {
      return moment.tz(thisHour, projectData.timezone);
    });

    availableHours.sort((a, b) => {
      return a.valueOf() - b.valueOf();
    });


    let availableSpecies = hourObservations.map((thisObs) => {
      return thisObs.species_id;
    });

    availableSpecies = uniq(availableSpecies);

    availableSpecies.sort((a, b) => {
      return taxonomy[a].order - taxonomy[b].order;
    });

    const speciesColumn = availableSpecies.map((thisSpecies) => {
      return taxonomy[thisSpecies].common_name;
    });

    const collectSpeciesData = groupBy(hourObservations, (thisObs) => {
      return thisObs.species_id;
    });

    const columnTemplate = hourRange(availableHours[0], availableHours[availableHours.length - 1]);
    const columnIndex = columnTemplate.map((thisHour) => { return thisHour.format(); });

    const hourData = {};

    hourData.sum = range(0, availableSpecies.length, 0);

    columnIndex.forEach((thisHour) => {
      hourData[thisHour] = range(0, availableSpecies.length, "0");
    });

    hourObservations.forEach((thisObs) => {
      const thisHour = thisObs.localHour;
      const thisSpecies = availableSpecies.indexOf(thisObs.species_id);
      hourData[thisHour][thisSpecies] = thisObs.sum;

      hourData.sum[thisSpecies] += parseInt(thisObs.sum, 10);
    });

    hourData.species = speciesColumn;

    return (
      <div className={cx('hourly-container')}>
        <h3 className={cx('section-head')}>Hourly Data</h3>
        <div>
          <form
            className={cx('date-updater')}
            onSubmit={(event) => {
              event.preventDefault();
            }} >
            <label htmlFor={'date'}>
              Select Day:
            </label>
            <select
              id={'date'}
              name="date"
              onChange={this.dateChange}
              value={this.state.date} >
              {availableDays.map((thisDay) => {
                return (
                  <option
                    key={thisDay.valueOf()}
                    value={thisDay.format('YYYY-MM-DD')}>
                    {thisDay.format('MMMM D')}
                  </option>
                );
              })}
            </select>
          </form>
        </div>
        <Table
          rowHeight={50}
          headerHeight={50}
          groupHeaderHeight={30}
          rowsCount={availableSpecies.length}
          width={containerWidth}
          maxHeight={containerHeight}
          {...this.props} >
          <ColumnGroup
            fixed >
            <Column
              columnKey="species"
              fixed
              header={<Cell>Species</Cell>}
              cell={({rowIndex, ...props}) => (
                    <Cell {...props}>
                      {hourData.species[rowIndex]}
                    </Cell>
                  )}
              flexGrow={1}
              width={130}
              minWidth={130} />
          </ColumnGroup>
          <ColumnGroup
            align={containerWidth > 600 ? 'center' : 'left'}
            header={<Cell>Hour</Cell>} >
            {columnTemplate.map((thisHour) => {
              return (
                <Column
                  key={thisHour.valueOf()}
                  columnKey={thisHour.format()}
                  header={<Cell>{thisHour.format('h A')}</Cell>}
                  cell={({rowIndex, ...props}) => (
                    <Cell {...props}>
                      {hourData[thisHour.format()][rowIndex]}
                    </Cell>
                  )}
                  align="center"
                  flexGrow={1}
                  width={32}
                  minWidth={32} />
              );
            })}
            <Column
              key={'sum'}
              columnKey={'sum'}
              header={<Cell>Total</Cell>}
              cell={({rowIndex, ...props}) => (
                <Cell style={{paddingRight: 10}} {...props}>
                  {String(hourData['sum'][rowIndex])}
                </Cell>
              )}
              align="center"
              flexGrow={1}
              width={55}
              minWidth={55} />
          </ColumnGroup>
        </Table>
      </div>
    );
  }
}

HourlyCounts.propTypes = {
  projectData: PropTypes.object.isRequired,
  taxonomy: PropTypes.object.isRequired,
  hourlyCountData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    taxonomy: state.taxonomy.taxonomy,
    hourlyCountData: state.project.hourlyCounts,
  };
}

export default connect(mapStateToProps, { })(
  Dimensions({
    getHeight: function(element) {
      return Math.min(window.innerHeight, 600);
    },
    getWidth: function(element) {
      return Math.min(window.innerWidth - 50, 800);
    }
})(HourlyCounts));
