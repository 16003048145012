import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { fetchProjectData, fetchExploreData, fetchEmbedData } from './fetch-data';
import { App, Embed, Home, About, Features, Pricing, Privacy, Terms, Cookies, Acknowledgements, LoginOrRegister, Dashboard, ProjectList, Project, EmbedProject } from './pages';

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default (store) => {
  const requireAuth = (nextState, replace, callback) => {
    const { user: { authenticated }} = store.getState();
    if (!authenticated) {
      replace({
        pathname: '/login',
        state: { nextPathname: nextState.location.pathname }
      });
    }
    callback();
  };

  const redirectAuth = (nextState, replace, callback) => {
    const { user: { authenticated }} = store.getState();
    if (authenticated) {
      replace({
        pathname: '/dashboard'
      });
    }
    callback();
  };

  return (
    <Route>
      <Route path="/" component={App} >
        <IndexRoute component={Home} fetchHome={null} />
      </Route>
      <Route path="/login" component={App} >
        <IndexRoute component={LoginOrRegister} onEnter={redirectAuth} />
      </Route>
      <Route path="/dashboard" component={App} >
        <IndexRoute component={Dashboard} onEnter={requireAuth} />
      </Route>
      <Route path="/about" component={App} >
        <IndexRoute component={About} />
      </Route>
      <Route path="/features" component={App} >
        <IndexRoute component={Features} />
      </Route>
      <Route path="/pricing" component={App} >
        <IndexRoute component={Pricing} />
      </Route>
      <Route path="/privacy" component={App} >
        <IndexRoute component={Privacy} />
      </Route>
      <Route path="/terms" component={App} >
        <IndexRoute component={Terms} />
      </Route>
      <Route path="/cookies" component={App} >
        <IndexRoute component={Cookies} />
      </Route>
      <Route path="/acknowledgements" component={App} >
        <IndexRoute component={Acknowledgements} />
      </Route>
      <Route path="/project" component={App} >
        <IndexRoute component={ProjectList} />
      </Route>
      <Route path="/project/:slug" component={App} >
        <IndexRoute component={Project} fetchProject={fetchProjectData} />
      </Route>
      <Route path="/explore" component={App} >
        <IndexRoute component={ProjectList} />
      </Route>
      <Route path="/explore/:organizationSlug/:projectSlug" component={App} >
        <IndexRoute component={Project} fetchExplore={fetchExploreData} />
      </Route>
      <Route path="/embed/:organizationSlug/:projectSlug" component={Embed}>
        <IndexRoute component={EmbedProject} fetchEmbed={fetchEmbedData} />
      </Route>
    </Route>
  );
};
