import { polyfill } from 'es6-promise';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import request from 'axios';
import moment from 'moment-timezone';
import isString from 'lodash/isString';
import find from 'lodash/find';
import classNames from '../utils/classNames.js';
import { createOrganization, createProject, createTaxonomy, getProjectTemplates, getTaxonomyTemplates } from '../actions/admin';
import { createHawkCount } from '../actions/integrations';
import * as types from '../types';
import styles from '../css/components/addactivities';

polyfill();

const cx = classNames.bind(styles);

const unacceptableSlugNames = new RegExp(/[^a-zA-Z0-9\-]/i);

class Adder extends Component {
  constructor(props) {
    super(props);

    this.uploadOrganizationInfo = this.uploadOrganizationInfo.bind(this);
    this.uploadProjectInfo = this.uploadProjectInfo.bind(this);
    this.uploadTaxonomyInfo = this.uploadTaxonomyInfo.bind(this);
    this.uploadHawkCount = this.uploadHawkCount.bind(this);
    this.infoChange = this.infoChange.bind(this);
    this.nameWithSlugChange = this.nameWithSlugChange.bind(this);
    this.slugChange = this.slugChange.bind(this);

    this.state = {
      errorMessage: [],
      informSlugValidation: false,
      organization_name: '',
      organization_slug: '',
      project_organization_id: props.adminOrgs[0],
      project_taxonomy_id: props.orgProjects[props.adminOrgs[0]].TaxonomyList[0],
      project_name: '',
      project_slug: '',
      project_template: 'blank',
      taxonomy_organization_id: props.adminOrgs[0],
      taxonomy_name: '',
      taxonomy_template: 'blank',
      superadmin_taxonomy: '',
      hawkcount_project_id: props.orgProjects[props.adminOrgs[0]],
      hawkcount_site_id: '',
      hawkcount_timezone: 'Etc/GMT+5'
    };
  }

  componentWillMount() {
    if (((this.props.editing === types.ACTIVITY_ADD_PROJECT) || (this.props.editing === types.ACTIVITY_ADD_INTEGRATION_HAWKCOUNT)) && (this.props.projectTemplates.length === 0)) {
      this.props.getProjectTemplates();
    } else if (([types.ACTIVITY_ADD_TAXONOMY, types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY].indexOf(this.props.editing) > -1) && (this.props.taxonomyTemplates.length === 0)) {
      this.props.getTaxonomyTemplates();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (((nextProps.editing === types.ACTIVITY_ADD_PROJECT) || (nextProps.editing === types.ACTIVITY_ADD_INTEGRATION_HAWKCOUNT)) && (nextProps.projectTemplates.length === 0)) {
      nextProps.getProjectTemplates();
    } else if (([types.ACTIVITY_ADD_TAXONOMY, types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY].indexOf(nextProps.editing) > -1) && (nextProps.taxonomyTemplates.length === 0)) {
      nextProps.getTaxonomyTemplates();
    }
  }

  uploadOrganizationInfo(event) {
    const { createOrganization } = this.props;

    event.preventDefault();
    event.stopPropagation();

    let readyToAdd = true;
    const buildErrorMessage = [];

    const buildOrganization = {
    };

    request.post('/ui/v1/organization/slug/', { slug: this.state.organization_slug }).then((slugResult) => {
      try {
        if (isString(this.state.organization_name) && this.state.organization_name.length > 0) {
          buildOrganization.organization_name = this.state.organization_name;
        } else {
          throw new Error('Missing name for your new organization.');
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Missing a name for your new organization.</li>);
      }

      try {
        if (isString(this.state.organization_slug) && this.state.organization_slug.length > 0) {
            console.log(slugResult);
            if (!slugResult.data.taken) {
              buildOrganization.organization_slug = this.state.organization_slug;
            } else {
              readyToAdd = false;
              buildErrorMessage.push(<li>The link you selected is already taken. Please select a different link.</li>);
            }
        } else {
          throw new Error('Missing link for your new organization.');
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Missing a link for your new organization.</li>);
      }

      if (readyToAdd) {
        createOrganization(buildOrganization);

        ReactGA.event({
          category: 'Dashboard: Adder',
          action: 'Create Organization',
          label: buildOrganization.organization_name,
        });
      } else {
        window.scrollTo(0, 0);
        this.setState({
          errorMessage: buildErrorMessage,
        });
      }
    }).catch(() => {
      this.setState({
        errorMessage: [<li>Couldn&apos;t connect to server.</li>],
      });
    });
  }

  uploadProjectInfo(event) {
    const { createProject } = this.props;

    event.preventDefault();
    event.stopPropagation();

    let readyToAdd = true;
    const buildErrorMessage = [];

    const buildProject = {
    };

    request.post('/ui/v1/project/slug/', { slug: this.state.project_slug, organization_id: this.state.project_organization_id }).then((slugResult) => {
      try {
        if (isNaN(this.state.project_organization_id)) {
          throw new Error('Organization Selection Failed');
        } else {
          buildProject.organization_id = this.state.project_organization_id;
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Organization Selection Failed.</li>);
      }

      try {
        if (isString(this.state.project_name) && this.state.project_name.length > 0) {
          buildProject.project_name = this.state.project_name;
        } else {
          throw new Error('Missing name for your new project.');
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Missing a name for your new project.</li>);
      }

      try {
        if (isString(this.state.project_slug) && this.state.project_slug.length > 0) {
          if (!slugResult.data.taken) {
            buildProject.project_slug = this.state.project_slug;
          } else {
            readyToAdd = false;
            buildErrorMessage.push(<li>The link you selected is already taken. Please select a different link.</li>);
          }
        } else {
          throw new Error('Missing link for your new project.');
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Missing a link for your new project.</li>);
      }

      buildProject.taxonomy_id = this.state.project_taxonomy_id;
      buildProject.project_template = this.state.project_template;
      buildProject.timezone = moment.tz.guess();

      if (readyToAdd) {
        createProject(buildProject);

        ReactGA.event({
          category: 'Dashboard: Adder',
          action: 'Create Project',
          label: buildProject.project_name,
        });
      } else {
        window.scrollTo(0, 0);
        this.setState({
          errorMessage: buildErrorMessage,
        });
      }
    }).catch(() => {
      this.setState({
        errorMessage: [<li>Couldn&apos;t connect to server.</li>],
      });
    });
  }

  uploadTaxonomyInfo(event) {
    const { createTaxonomy, editing } = this.props;

    event.preventDefault();
    event.stopPropagation();

    let readyToAdd = true;
    const buildErrorMessage = [];

    const buildTaxonomy = {
    };

    request.post('/ui/v1/taxonomy/name/', { name: this.state.taxonomy_name, organization_id: this.state.taxonomy_organization_id }).then((nameResult) => {
      try {
        if (isNaN(this.state.taxonomy_organization_id)) {
          throw new Error('Organization Selection Failed');
        } else {
          buildTaxonomy.organization_id = this.state.taxonomy_organization_id;
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Organization Selection Failed.</li>);
      }

      try {
        if (isString(this.state.taxonomy_name) && this.state.taxonomy_name.length > 0) {
          if (!nameResult.data.taken) {
            buildTaxonomy.taxonomy_name = this.state.taxonomy_name;
          } else {
            readyToAdd = false;
            buildErrorMessage.push(<li>The name you selected is already taken. Please select a different name.</li>);
          }
        } else {
          throw new Error('Duplicate for your new taxonomy.');
        }
      } catch (e) {
        readyToAdd = false;
        buildErrorMessage.push(<li>Missing a name for your new taxonomy.</li>);
      }

      if (editing === types.ACTIVITY_ADD_TAXONOMY) {
        buildTaxonomy.taxonomy_template = this.state.taxonomy_template;
      } else if (editing === types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY) {
        buildTaxonomy.Species = this.state.superadmin_taxonomy;
      }

      if (readyToAdd) {
        createTaxonomy(buildTaxonomy);

        ReactGA.event({
          category: 'Dashboard: Adder',
          action: 'Create Taxonomy',
          label: buildTaxonomy.taxonomy_name,
        });
      } else {
        window.scrollTo(0, 0);
        this.setState({
          errorMessage: buildErrorMessage,
        });
      }
    }).catch(() => {
      this.setState({
        errorMessage: [<li>Couldn&apos;t connect to server.</li>],
      });
    });
  }

  uploadHawkCount(event) {
    const { createHawkCount, projectTemplates, orgProjects, adminSchemas } = this.props;

    event.preventDefault();
    event.stopPropagation();

    let readyToAdd = true;
    const buildErrorMessage = [];

    const buildProject = {
    };

    try {
      if (isNaN(this.state.hawkcount_project_id)) {
        throw new Error('Missing Project ID');
      } else {
        buildProject.hawkcount_project_id = parseInt(this.state.hawkcount_project_id, 10);
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Missing Project ID</li>);
    }

    try {
      if (isNaN(this.state.hawkcount_site_id)) {
        throw new Error('Missing HawkCount Site ID');
      } else {
        buildProject.hawkcount_site_id = parseInt(this.state.hawkcount_site_id, 10);
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Missing HawkCount Site ID</li>);
    }

    try {
      if (['Etc/GMT+5', 'Etc/GMT+6', 'Etc/GMT+7', 'Etc/GMT+8', 'Etc/GMT+9'].indexOf(this.state.hawkcount_timezone) === -1) {
        throw new Error('Missing HawkCount Timezone');
      } else {
        buildProject.timezone = this.state.hawkcount_timezone;
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Missing HawkCount Timezone</li>);
    }

    buildProject.organization_id = find(projectTemplates, ['id', buildProject.hawkcount_project_id]).Organization.id;
    buildProject.organization_slug = orgProjects[buildProject.organization_id].slug;
    buildProject.project_slug = adminSchemas[buildProject.hawkcount_project_id].slug;

    if (readyToAdd) {
      createHawkCount(buildProject);

      ReactGA.event({
        category: 'Dashboard: Adder',
        action: 'Create HawkCount Integration',
        label: buildProject.project_name,
      });
    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: buildErrorMessage,
      });
    }
  }

  infoChange(event) {
    const { orgProjects } = this.props;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'project_organization_id') {
      this.setState({
        project_taxonomy_id: orgProjects[value].TaxonomyList[0],
      });
    }

    this.setState({
      [name]: value
    });
  }

  nameWithSlugChange(event) {
    const { editing } = this.props;

    const target = event.target;
    const value = target.value;
    const name = target.name;

    // https://gist.github.com/mathewbyrne/1280286
    const slugged = value.toString().toLowerCase().trim()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-and-')         // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -

      if (editing === types.ACTIVITY_ADD_ORGANIZATION) {
      this.setState({
        organization_name: value,
        organization_slug: slugged,
      });
    } else if (editing === types.ACTIVITY_ADD_PROJECT) {
      this.setState({
        project_name: value,
        project_slug: slugged,
      });
    }
  }

  slugChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let invalidEntry = false;

    let slugValue = this.state[name];
    if (!unacceptableSlugNames.test(value)) {
      slugValue = value.toLowerCase();
    } else {
      invalidEntry = true;
    }

    this.setState({
      [name]: slugValue,
      informSlugValidation: invalidEntry,
    });
  }

  render() {
    const { editing, orgProjects, adminOrgs, projectTemplates, taxonomyTemplates } = this.props;

    return (
      <div
        className={cx('container')} >
        {(this.state.errorMessage.length > 0) &&
          <div
            className={cx('issues-container')} >
            <h2><span className={cx('issue-highlight')}>Issues</span></h2>
            <ul>
              {this.state.errorMessage}
            </ul>
          </div>
        }
        {editing === types.ACTIVITY_ADD_ORGANIZATION &&
          <div
            className={cx('new-asset-wrapper')} >
            <h2
              className={cx('review-header')} >
              Create a New Organization
            </h2>
            <div
              className={cx('add-activity-form-container')} >
              <form
                className={cx('info-form')}
                onSubmit={this.uploadOrganizationInfo} >
                <label
                  htmlFor="organization_name"
                  className={cx('prompt')} >
                  Organization Name
                </label>
                <div
                  className={cx('context-help')} >
                  Choose a name for your new organization. This name will be visible at the top of all of your project pages.
                </div>
                <input
                  id={'organization_name'}
                  name="organization_name"
                  type="text"
                  onChange={this.nameWithSlugChange}
                  value={this.state.organization_name} />
                <label
                  htmlFor="organization_slug"
                  className={cx('prompt')} >
                  Link Base for Public Pages
                </label>
                <div
                  className={cx('context-help')} >
                  Your public pages will include a formatted version of your Organization name and Project names. This will appear in all of the links that you share with your newsletter and on social media, so keep it short and memorable. This link may only include lowercase letters, numbers, and hyphens.
                  <br />
                  <span className={cx('emphasis')}>Example:</span> If your Organization is named &quot;National Society of Environmental Research,&quot; you may want to use &quot;nser&quot; or &quot;environmental-society&quot; to keep links manageable.
                  <br />
                  <span className={cx('emphasis')}>Note:</span> This cannot be changed after the Organization is created.
                </div>
                <div
                  className={cx('slug-display')} >
                  https://dunkadoo.org/explore/{this.state.organization_slug}
                </div>
                {this.state.informSlugValidation &&
                  <div
                    className={cx('inform-field-validation')} >
                    Only lower-cased letters, numbers, and hyphens can be used in links.
                  </div>
                }
                <input
                  id={'organization_slug'}
                  name="organization_slug"
                  type="text"
                  onChange={this.slugChange}
                  value={this.state.organization_slug} />
                <input
                  className={cx('add-activity-submit-button')}
                  type="submit"
                  value="Create Organization" />
              </form>
            </div>
          </div>
        }
        {((editing === types.ACTIVITY_ADD_PROJECT) && (projectTemplates.length > 0)) &&
          <div
            className={cx('new-asset-wrapper')} >
            <h2
              className={cx('review-header')} >
              Create a New Project
            </h2>
            <div
              className={cx('add-activity-form-container')} >
              <form
                className={cx('info-form')}
                onSubmit={this.uploadProjectInfo} >
                <label
                  htmlFor={'project_organization_id'}
                  className={cx('prompt')} >
                  Organization
                </label>
                <div
                  className={cx('context-help')} >
                  Every Project belongs to an Organization. Organization Administrators will be able to make changes to this Project.
                </div>
                <select
                  id={'project_organization_id'}
                  name="project_organization_id"
                  onChange={this.infoChange}
                  value={this.state.project_organization_id} >
                  {adminOrgs.sort((a, b) => {
                    const nameA = orgProjects[a].name.toLowerCase();
                    const nameB = orgProjects[b].name.toLowerCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((thisOrgId) => {
                    return (
                      <option
                        key={thisOrgId}
                        value={thisOrgId} >
                        {orgProjects[thisOrgId].name}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor={'project_taxonomy_id'}
                  className={cx('prompt')} >
                  Taxonomy
                </label>
                <div
                  className={cx('context-help')} >
                  Every Project is based on a Taxonomy, and all data collected is related to the species in this Taxonomy. If you plan to make changes to the Taxonomy for this Project, consider creating a new Taxonomy dedicated to this Project.
                  <br />
                  <span className={cx('emphasis')}>Note:</span> The Taxonomy cannot be changed after the Project is created.
                </div>
                {orgProjects[this.state.project_organization_id].TaxonomyList.length === 0 &&
                  <div
                    className={cx('inform-field-validation')} >
                    You must add a taxonomy to this organization before you can add a project.
                  </div>
                }
                {orgProjects[this.state.project_organization_id].TaxonomyList.length > 0 &&
                  <select
                    id={'project_taxonomy_id'}
                    name="project_taxonomy_id"
                    onChange={this.infoChange}
                    value={this.state.project_taxonomy_id} >
                    {orgProjects[this.state.project_organization_id].TaxonomyList.sort((a, b) => {
                        const nameA = orgProjects[this.state.project_organization_id].TaxonomyLookup[a].name.toLowerCase();
                        const nameB = orgProjects[this.state.project_organization_id].TaxonomyLookup[b].name.toLowerCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      }).map((thisTaxonomyId) => {
                      return (
                        <option
                          key={thisTaxonomyId}
                          value={thisTaxonomyId} >
                          {orgProjects[this.state.project_organization_id].TaxonomyLookup[thisTaxonomyId].name}
                        </option>
                      );
                    })}
                  </select>
                }
                <label
                  htmlFor="project_name"
                  className={cx('prompt')} >
                  Project Name
                </label>
                <div
                  className={cx('context-help')} >
                  Choose a name for your new Project. This name will be visible on the online dashboard, on the mobile app, and at the top of your public Project page.
                </div>
                <input
                  id={'project_name'}
                  name="project_name"
                  type="text"
                  onChange={this.nameWithSlugChange}
                  value={this.state.project_name} />
                <label
                  htmlFor="project_slug"
                  className={cx('prompt')} >
                  Link for Public Pages
                </label>
                <div
                  className={cx('context-help')} >
                  Your public pages will include a formatted version of your Project names. This will appear in all of the links that you share with your newsletter and on social media, so keep it short and memorable. This link may only include lowercase letters, numbers, and hyphens.
                  <br />
                  <span className={cx('emphasis')}>Example:</span> If your Project is known as the &quot;Third Annual National Park BioBlitz,&quot; you may want to use &quot;bioblitz-{new Date().getFullYear()}&quot; to keep links manageable.
                  <br />
                  <span className={cx('emphasis')}>Note:</span> This cannot be changed after the Project is created.
                </div>
                <div
                  className={cx('slug-display')} >
                  https://dunkadoo.org/explore/{orgProjects[this.state.project_organization_id].slug}/{this.state.project_slug}
                </div>
                {this.state.informSlugValidation &&
                  <div
                    className={cx('inform-field-validation')} >
                    Only lower-cased letters, numbers, and hyphens can be used in links.
                  </div>
                }
                <input
                  id={'project_slug'}
                  name="project_slug"
                  type="text"
                  onChange={this.slugChange}
                  value={this.state.project_slug} />
                <label
                  htmlFor={'project_template'}
                  className={cx('prompt')} >
                  Template
                </label>
                <div
                  className={cx('context-help')} >
                  To make building new Projects easier, you can select an existing Project or one of our templates.
                </div>
                <label
                  htmlFor={'project_selection_blank'} >
                  <input
                    key="blank"
                    id={'project_selection_blank'}
                    name="project_template"
                    type="radio"
                    value="blank"
                    onChange={this.infoChange}
                    checked={this.state.project_template === 'blank'}
                    className={cx('project-template-radio')} />
                  <div
                    className={cx('project-template-info-container')} >
                    <div
                      className={cx('project-template-info-name')} >
                      Blank
                    </div>
                    <div
                      className={cx('project-template-info-description')} >
                      Start with an empty project.
                    </div>
                  </div>
                </label>
                {projectTemplates.map((thisProject) => {
                  return (
                    <label
                      key={thisProject.id}
                      htmlFor={'project_selection_' + thisProject.id} >
                      <input
                        id={'project_selection_' + thisProject.id}
                        name="project_template"
                        type="radio"
                        value={thisProject.id}
                        onChange={this.infoChange}
                        checked={this.state.project_template === String(thisProject.id)}
                        className={cx('project-template-radio')} />
                      <div
                        className={cx('project-template-info-container')} >
                        <div
                          className={cx('project-template-info-organization')} >
                          {thisProject.Organization.name}
                        </div>
                        <div
                          className={cx('project-template-info-name')} >
                          {thisProject.name}
                        </div>
                        <div
                          className={cx('project-template-info-description')} >
                          {thisProject.description}
                        </div>
                      </div>
                    </label>
                  );
                })}
                <input
                  className={cx('add-activity-submit-button')}
                  type="submit"
                  value="Create Project" />
              </form>
            </div>
          </div>
        }
        {(([types.ACTIVITY_ADD_TAXONOMY, types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY].indexOf(editing) > -1) && (taxonomyTemplates.length > 0)) &&
          <div
            className={cx('new-asset-wrapper')} >
            <h2
              className={cx('review-header')} >
              Create a New Taxonomy
            </h2>
            <div
              className={cx('add-activity-form-container')} >
              <form
                className={cx('info-form')}
                onSubmit={this.uploadTaxonomyInfo} >
                <label
                  htmlFor={'taxonomy_organization_id'}
                  className={cx('prompt')} >
                  Organization
                </label>
                <div
                  className={cx('context-help')} >
                  Every Taxonomy belongs to an Organization. Organization Administrators will be able to make changes to this Taxonomy.
                </div>
                <select
                  id={'taxonomy_organization_id'}
                  name="taxonomy_organization_id"
                  onChange={this.infoChange}
                  value={this.state.taxonomy_organization_id} >
                  {adminOrgs.sort((a, b) => {
                    const nameA = orgProjects[a].name.toLowerCase();
                    const nameB = orgProjects[b].name.toLowerCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map((thisOrgId) => {
                    return (
                      <option
                        key={thisOrgId}
                        value={thisOrgId} >
                        {orgProjects[thisOrgId].name}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor="taxonomy_name"
                  className={cx('prompt')} >
                  Taxonomy Name
                </label>
                <div
                  className={cx('context-help')} >
                  Choose a name for the Taxonomy that will be easy to differentiate in the future.
                </div>
                <input
                  id={'taxonomy_name'}
                  name="taxonomy_name"
                  type="text"
                  onChange={this.infoChange}
                  value={this.state.taxonomy_name} />
                {(editing === types.ACTIVITY_ADD_TAXONOMY) &&
                  <div>
                    <label
                      htmlFor={'project_template'}
                      className={cx('prompt')} >
                      Starter Taxonomy
                    </label>
                    <div
                      className={cx('context-help')} >
                      To make building new Taxonomies easier, you can select an existing Taxonomy or one of our templates. If you select the Blank Taxonomy, you will add each species individually. If you start with one of the other options, you can make changes to the Species records once the Taxonomy has been created. If you have an existing taxonomy that you would like to programmatically import, please contact support at <a href="mailto:support@dunkadoo.org?Subject=Taxonomy%20Import">support@dunkadoo.org</a>.
                    </div>
                    <label
                      htmlFor={'project_selection_blank'} >
                      <input
                        key="blank"
                        id={'project_selection_blank'}
                        name="taxonomy_template"
                        type="radio"
                        value="blank"
                        onChange={this.infoChange}
                        checked={this.state.taxonomy_template === 'blank'}
                        className={cx('project-template-radio')} />
                      <div
                        className={cx('project-template-info-container')} >
                        <div
                          className={cx('project-template-info-name')} >
                          Blank
                        </div>
                        <div
                          className={cx('project-template-info-description')} >
                          Add all species individually.
                        </div>
                      </div>
                    </label>
                    {taxonomyTemplates.map((thisTaxonomy) => {
                      return (
                        <label
                          key={thisTaxonomy.id}
                          htmlFor={'taxonomy_selection_' + thisTaxonomy.id} >
                          <input
                            id={'taxonomy_selection_' + thisTaxonomy.id}
                            name="taxonomy_template"
                            type="radio"
                            value={thisTaxonomy.id}
                            onChange={this.infoChange}
                            checked={this.state.taxonomy_template === String(thisTaxonomy.id)}
                            className={cx('project-template-radio')} />
                          <div
                            className={cx('project-template-info-container')} >
                            <div
                              className={cx('project-template-info-organization')} >
                              {thisTaxonomy.Organization.name}
                            </div>
                            <div
                              className={cx('project-template-info-name')} >
                              {thisTaxonomy.name}
                            </div>
                            <div
                              className={cx('project-template-info-description')} >
                              {thisTaxonomy.description}
                            </div>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                }
                {(editing === types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY) &&
                  <div>
                    <label
                      htmlFor={'project_template'}
                      className={cx('prompt')} >
                      Species Array (Include Outer Brackets)
                    </label>
                    <div
                      className={cx('context-help')} >
                      Paste the Species Array
                      <br />
                      <pre>
{`[
    {
        "code": "HITI",
        "attribute": "",
        "common_name": "Highland Tinamou",
        "order": 33,
        "scientific_name": "Nothocercus bonapartei",
        "species_schema": ""
    }, {
        "common_name" : "Broad-winged Hawk",
        "scientific_name" : "Buteo platypterus",
        "code" : "BW",
        "attribute" : [
          "Light Adult",
          "Light Juvenile",
          "Dark Adult",
          "Dark Juvenile"
        ],
        "species_schema" : {
            "kettle" : {
                "type" : "radio",
                "order" : 1,
                "prompt" : "In Kettle?",
                "options" : [{
                        "text" : "Yes"
                    }, {
                        "text" : "No",
                        "defaultSelected" : true
                    }
                ]
            }
        },
        "order" : 2966
    }
]`}
                      </pre>
                    </div>
                    <textarea
                      id={'superadmin_taxonomy'}
                      name="superadmin_taxonomy"
                      className={cx('superadmin-textarea')}
                      onChange={this.infoChange}
                      value={this.state.superadmin_taxonomy} />
                  </div>
                }
                <input
                  className={cx('add-activity-submit-button')}
                  type="submit"
                  value="Create Taxonomy" />
              </form>
            </div>
          </div>
        }
        {((editing === types.ACTIVITY_ADD_INTEGRATION_HAWKCOUNT) && (projectTemplates.length > 0)) &&
          <div
            className={cx('new-asset-wrapper')} >
            <h2
              className={cx('review-header')} >
              Add a New HawkCount Integration
            </h2>
            <div
              className={cx('add-activity-form-container')} >
              <form
                className={cx('info-form')}
                onSubmit={this.uploadHawkCount} >
                <label
                  htmlFor={'hawkcount_project_id'}
                  className={cx('prompt')} >
                  Project to Connect
                </label>
                <div
                  className={cx('context-help')} >
                  Select the Project to Add the Integration to
                  <br />
                </div>
                {projectTemplates.map((thisProject) => {
                  return (
                    <label
                      key={thisProject.id}
                      htmlFor={'hawkcount_project_selection_' + thisProject.id} >
                      <input
                        id={'hawkcount_project_selection_' + thisProject.id}
                        name="hawkcount_project_id"
                        type="radio"
                        value={thisProject.id}
                        onChange={this.infoChange}
                        checked={this.state.hawkcount_project_id === String(thisProject.id)}
                        className={cx('project-template-radio')} />
                      <div
                        className={cx('project-template-info-container')} >
                        <div
                          className={cx('project-template-info-organization')} >
                          {thisProject.Organization.name}
                        </div>
                        <div
                          className={cx('project-template-info-name')} >
                          {thisProject.name}
                        </div>
                      </div>
                    </label>
                  );
                })}
                <label
                  htmlFor="project_name"
                  className={cx('prompt')} >
                  HawkCount Site ID (Need to Have Access Granted First)
                </label>
                <div
                  className={cx('context-help')} >
                  HawkCount Site Number from HawkCount.org
                </div>
                <input
                  id={'hawkcount_site_id'}
                  name="hawkcount_site_id"
                  type="text"
                  onChange={this.infoChange}
                  value={this.state.hawkcount_site_id} />
                <select
                  id={'hawkcount_timezone'}
                  name="hawkcount_timezone"
                  onChange={this.infoChange}
                  value={this.state.hawkcount_timezone} >
                  <option
                    value="Etc/GMT+5" >
                    EST Etc/GMT+5
                  </option>
                  <option
                    value="Etc/GMT+6" >
                    CST Etc/GMT+6
                  </option>
                  <option
                    value="Etc/GMT+7" >
                    MST Etc/GMT+7
                  </option>
                  <option
                    value="Etc/GMT+8" >
                    PST Etc/GMT+8
                  </option>
                  <option
                    value="Etc/GMT+9" >
                    AKST Etc/GMT+9
                  </option>
                </select>
                <input
                  className={cx('add-activity-submit-button')}
                  type="submit"
                  value="Create Integration" />
              </form>
            </div>
          </div>
        }
      </div>
    );
  }
}

Adder.propTypes = {
  createOrganization: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  createTaxonomy: PropTypes.func.isRequired,
  createHawkCount: PropTypes.func.isRequired,
  getProjectTemplates: PropTypes.func.isRequired,
  getTaxonomyTemplates: PropTypes.func.isRequired,
  editing: PropTypes.string.isRequired,
  orgProjects: PropTypes.object.isRequired,
  adminSchemas: PropTypes.object.isRequired,
  adminOrgs: PropTypes.array.isRequired,
  projectTemplates: PropTypes.array,
  taxonomyTemplates: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    editing: state.admin.editing,
    orgProjects: state.admin.orgProjects,
    adminSchemas: state.admin.adminSchemas,
    adminOrgs: state.admin.user.adminOrgs,
    projectTemplates: state.admin.projectTemplates,
    taxonomyTemplates: state.admin.taxonomyTemplates,
  };
}

export default connect(mapStateToProps, { createOrganization, createProject, createTaxonomy, createHawkCount, getProjectTemplates, getTaxonomyTemplates })(Adder);
