import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/obsmarker';

const cx = classNames.bind(styles);

class ObsMarker extends Component {
  constructor(props) {
    super(props);

    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);

    this.state = {
      manuallyFeatured: false,
    };
  }

  mouseEnter() {
    const { hoveredObservations, observations } = this.props;

    hoveredObservations(observations);

    this.setState({
      manuallyFeatured: true,
    });
  }

  mouseLeave() {
    const { unhoveredObservations } = this.props;

    unhoveredObservations();

    this.setState({
      manuallyFeatured: false,
    });
  }

  render() {
    const { hoveredObservations, observations, taxonomy, $hover, tightenZoom } = this.props;

    return (
      <div
        className={cx('container', {'container-featured': $hover || this.state.manuallyFeatured})}
        onClick={() => {
          hoveredObservations(observations);
          tightenZoom(observations);
        }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave} >
        <div className={cx('count')}>
          {observations.length}
        </div>
      </div>
    );
  }
}

ObsMarker.propTypes = {
  tightenZoom: PropTypes.func,
  hoveredObservations: PropTypes.func,
  unhoveredObservations: PropTypes.func,
  observations: PropTypes.array,
  taxonomy: PropTypes.object,
  mobile: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    taxonomy: state.taxonomy.taxonomy,
  };
}

export default connect(mapStateToProps, { })(ObsMarker);
