import React, { Component } from 'react';
import PropTypes from 'prop-types';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import moment from 'moment-timezone';
import classNames from '../utils/classNames.js';
import { updateMessages, receivedMessages } from '../actions/messages';
import styles from '../css/components/message';

const cx = classNames.bind(styles);

// Need to also change in reducer and css
const displayDuration = 6000;

class Message extends Component {
  constructor(props) {
    super(props);

    this.cleanupMessages = this.cleanupMessages.bind(this);
    this.dismissMessage = this.dismissMessage.bind(this);

    this.state = {
      messageQueue: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { receivedMessages } = this.props;

    if (nextProps.messages.length > 0) {
      window.setTimeout(this.cleanupMessages, displayDuration * 2);

      this.setState({
        messageQueue: [...this.state.messageQueue, ...nextProps.messages],
      }, () => {
        receivedMessages(nextProps.messages.map(m => m.uuid));
      });
    }
  }

  cleanupMessages() {
    const messageQueue = this.state.messageQueue.filter((thisMessage) => {
      return moment().isSameOrBefore(thisMessage.expireAt);
    });

    this.setState({
      messageQueue,
    });
  }

  dismissMessage(messageId) {
    const messageQueue = cloneDeep(this.state.messageQueue);

    remove(messageQueue, (m) => {
      return m.uuid === messageId;
    });

    this.setState({
      messageQueue,
    });
  }

  render() {
    return (
      <div
        className={cx('message-container')} >
        {this.state.messageQueue.map((thisMessage) => {
          return (
            <div
              key={thisMessage.uuid}
              className={cx('message', {
                success: thisMessage.type === 'SUCCESS',
                failure: thisMessage.type === 'FAILURE',
              })}
              onClick={() => {
                this.dismissMessage(thisMessage.uuid);
              }}>
              {thisMessage.text}
            </div>
          );
        })}
      </div>

    );
  }
}

Message.propTypes = {
  updateMessages: PropTypes.func.isRequired,
  receivedMessages: PropTypes.func.isRequired,
  messages: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    messages: state.message.messages,
  };
}

export default connect(mapStateToProps, { updateMessages, receivedMessages })(Message);
