import React from 'react';
import Page from '../pages/Page';
import AppContainer from '../containers/App';
import { title, meta, link } from './assets';

class App extends React.Component {
  render() {
    return (
      <Page title={title} meta={meta} link={link}>
        <AppContainer {...this.props} />
      </Page>
    );
  }
}

export default App;
