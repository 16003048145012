export { default as App } from 'pages/App';
export { default as Embed } from 'pages/Embed';
export { default as Home } from 'pages/Home';
export { default as About } from 'pages/About';
export { default as Features } from 'pages/Features';
export { default as Privacy } from 'pages/Privacy';
export { default as Acknowledgements } from 'pages/Acknowledgements';
export { default as Cookies } from 'pages/Cookies';
export { default as Terms } from 'pages/Terms';
export { default as Pricing } from 'pages/Pricing';
export { default as LoginOrRegister } from 'pages/LoginOrRegister';
export { default as Dashboard } from 'pages/Dashboard';
export { default as ProjectList } from 'pages/ProjectList';
export { default as Project } from 'pages/Project';
export { default as EmbedProject } from 'pages/EmbedProject';
