import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/notableupdates';

const moment = require('moment-timezone');

const cx = classNames.bind(styles);

class NotableUpdates extends Component {
  render() {
    const { recentNotable, taxonomy } = this.props;

    const orderedUpdates = recentNotable.map((thisObs) => {
      const updateObs = thisObs;
      updateObs.time = moment(thisObs.recorded_at);
      return updateObs;
    });

    orderedUpdates.sort((a, b) => {
      return b.time.valueOf() - a.time.valueOf();
    });

    const outputUpdates = orderedUpdates.map((thisObs) => {
      const momentTime = moment.tz(thisObs.recorded_at, moment.tz.guess());
      const formatTime = momentTime.format('LT');
      const formatDay = momentTime.format('dddd, MMMM Do');

      return (
        <div
          className={cx('row')}
          key={thisObs.uuid} >
          <div
            className={cx('sighting')} >
            <p className={cx('species')} >{taxonomy[thisObs.species_id].common_name}</p>
            <p className={cx('detail')} >{formatTime}<span className={cx('day-separator')}>{formatDay}</span></p>
          </div>
        </div>
      );
    });

    return (
      <div className={cx('container')}>
        <h3 className={cx('section-head')}>Notable Sightings</h3>
        <div className={cx('table')}>
          {outputUpdates}
        </div>
      </div>
    );
  }
}

NotableUpdates.propTypes = {
  recentNotable: PropTypes.array.isRequired,
  taxonomy: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    recentNotable: state.project.recentNotable,
    taxonomy: state.taxonomy.taxonomy,
  };
}

export default connect(mapStateToProps, { })(NotableUpdates);
