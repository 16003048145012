import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/boidmarker';

const cx = classNames.bind(styles);

const easeDenominator = 100;

class BoidMarker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animationName: '',
    };
  }

  componentDidMount() {
    const { uuid, individual, angle, distance } = this.props;

    // Dynamic Keyframes by http://codepen.io/mosson/pen/zGENPx
    const styleSheet = document.styleSheets[document.styleSheets.length - 1];

    const animationName = 'animation' + uuid + String(individual);

    const keyframes =
    `@keyframes ${animationName} {
        0% {
          transform:rotate(${angle}rad) translate(0px);
          opacity: 1;
        }
        100% {
          transform:rotate(${angle}rad) translate(${distance}px);
          opacity: 0;
        }
    }`;

    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

    const webkitKeyframes =
    `@-webkit-keyframes ${animationName} {
        0% {
          -webkit-transform:rotate(${angle}rad) translate(0px);
          opacity: 1;
        }
        100% {
          -webkit-transform:rotate(${angle}rad) translate(${distance}px);
          opacity: 0;
        }
    }`;

    styleSheet.insertRule(webkitKeyframes, styleSheet.cssRules.length);

    this.setState({
      animationName,
    });
  }

  render() {
    const { color, angle, $hover } = this.props;

    if (this.state.animationName !== '') {
      return (
        <div
          className={cx('boid-container')}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '10px 20px 10px 0',
            borderColor: 'transparent transparent ' + color + ' transparent',
            marginTop: -15,
            marginLeft: -10,
            animationName: this.state.animationName,
            animationTimingFunction: 'linear',
            animationDuration: '10s',
            animationDelay: '0.0s',
            animationIterationCount: 1,
            animationDirection: 'normal',
            animationFillMode: 'forwards',
            zIndex: 9,
          }} />
      );
    }

    return false;
  }
}

BoidMarker.propTypes = {
  uuid: PropTypes.string,
  color: PropTypes.string,
  angle: PropTypes.number,
  distance: PropTypes.number,
};

export default BoidMarker;
