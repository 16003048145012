import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import classNames from '../utils/classNames.js';
import { updateOrgInfo } from '../actions/admin';
import styles from '../css/components/schema';

const cx = classNames.bind(styles);

const fieldNames = ['id', 'name', 'description', 'data_notice', 'website_url', 'donate_cta', 'donate_url', 'sponsor_description'];

class OrgManager extends Component {
  constructor(props) {
    super(props);

    this.orgInfoChange = this.orgInfoChange.bind(this);
    this.sendOrgManager = this.sendOrgManager.bind(this);
    this.injestOrgObject = this.injestOrgObject.bind(this);
    this.egressOrgObject = this.egressOrgObject.bind(this);

    this.state = {
      org: this.injestOrgObject(props.orgSchema),
      errorMessage: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.workingOrg !== nextProps.workingOrg) {
      this.setState({
        org: this.injestOrgObject(nextProps.orgSchema),
      });
    }
  }

  injestOrgObject(orgObject) {
    const updateOrgObject = {};

    fieldNames.forEach((fieldName) => {
      // Clone fields and don't touch unneeded fields
      updateOrgObject[fieldName] = orgObject[fieldName];

      // Replace Line Breaks
      if (typeof updateOrgObject[fieldName] === 'string') {
        if (/(\\r\\n|\\n|\\r)/gm.test(updateOrgObject[fieldName])) {
          updateOrgObject[fieldName] = updateOrgObject[fieldName].replace(/(\\r\\n|\\n|\\r)/gm, '\r\n');
        }
      }

      // Replace null with empty string
      if (updateOrgObject[fieldName] === null) {
        updateOrgObject[fieldName] = '';
      }
    });

    return updateOrgObject;
  }

  egressOrgObject(orgObject) {
    const updateOrgObject = {};
    let readyToSubmit = true;
    const buildErrorMessage = [];

    fieldNames.forEach((fieldName) => {
      // Clone fields and don't touch unneeded fields
      updateOrgObject[fieldName] = orgObject[fieldName];

      // Replace Line Breaks
      if (typeof updateOrgObject[fieldName] === 'string') {
        if (/(\\r\\n|\\n|\\r)/gm.test(updateOrgObject[fieldName])) {
          updateOrgObject[fieldName] = updateOrgObject[fieldName].replace(/(\\r\\n|\\n|\\r)/gm, '\r\n');
        }
      }

      // Replace null with empty string
      if (updateOrgObject[fieldName] === '') {
        updateOrgObject[fieldName] = null;
      }
    });

    try {
      updateOrgObject.id = this.state.org.id;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Save Failed</p>);
    }

    try {
      updateOrgObject.name = this.state.org.name;
      if ((!updateOrgObject.name) || (updateOrgObject.name.length === 0)) {
        throw new Error('Please Choose a Name for Your Organization');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Please Choose a Name for Your Organization</p>);
    }

    if (readyToSubmit) {
      this.setState({
        errorMessage: '',
      });

      return updateOrgObject;
    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: buildErrorMessage,
      });
      return false;
    }
  }

  orgInfoChange(event) {
    const { orgSchema } = this.props;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const updateOrg = orgSchema;
    updateOrg[name] = value;

    this.setState({
      org: updateOrg
    });
  }

  sendOrgManager(event) {
    const { updateOrgInfo } = this.props;
    event.preventDefault();
    updateOrgInfo(this.egressOrgObject(this.state.org));
  }

  render() {
    const { org } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2>{this.state.org.name}</h2>
        <div
          className={cx('context-help')} >
          Use Organizations to put your team&apos;s research in one place. These collections of Projects and Taxonomies share a set of administrators. You can create multiple Organizations.
        </div>
        <p
          className={cx('form-errors')} >
          {this.state.errorMessage}
        </p>
        <form
          className={cx('info-form')}
          onSubmit={this.sendOrgManager} >
          <label
            htmlFor={'name'}
            className={cx('prompt')} >
            Name
          </label>
          <div
            className={cx('context-help')} >
            Enter the name of your Organization. This name will be visible at the top of all of your Project pages.
          </div>
          <input
            id={'name'}
            name="name"
            type="text"
            onChange={this.orgInfoChange}
            value={this.state.org.name} />
          <br />
          <label
            htmlFor={'website_url'}
            className={cx('prompt')} >
            Organization Website URL
          </label>
          <div
            className={cx('context-help')} >
            If your Organization has a website, enter the web address here starting with &quot;http://&quot;. Your name and logo at the top of your public pages will link to your website.
          </div>
          <input
            id={'website_url'}
            name="website_url"
            type="text"
            onChange={this.orgInfoChange}
            value={this.state.org.website_url} />
          <br />
          <label
            htmlFor={'description'}
            className={cx('prompt')} >
            Organization Description
          </label>
          <div
            className={cx('context-help')} >
            Enter a brief description of your Organization. This will appear at the bottom of your public pages, so use a conversational tone that will help the public to understand your research.
          </div>
          <Textarea
            id={'description'}
            name="description"
            onChange={this.orgInfoChange}
            value={this.state.org.description} />
          <br />
          <label
            htmlFor={'data_notice'}
            className={cx('prompt')} >
            Data Notice
          </label>
          <div
            className={cx('context-help')} >
            Your organization&apos;s data policy will appear on the bottom of your public pages. You can use this space to inform viewers how they may use your data or contact you to get permission to use the data.
          </div>
          <Textarea
            id={'data_notice'}
            name="data_notice"
            onChange={this.orgInfoChange}
            value={this.state.org.data_notice} />
          <br />
          <label
            htmlFor={'sponsor_description'}
            className={cx('prompt')} >
            Sponsor Information
          </label>
          <div
            className={cx('context-help')} >
            If your Organization has a sponsorship for your research, you can include information about them here. This description will appear at the bottom of your public pages.
          </div>
          <Textarea
            id={'sponsor_description'}
            name="sponsor_description"
            onChange={this.orgInfoChange}
            value={this.state.org.sponsor_description} />
          <br />
          <input type="submit" value="Save Organization" />
        </form>
      </div>
    );
  }
}

OrgManager.propTypes = {
  orgSchema: PropTypes.object.isRequired,
  workingOrg: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    orgSchema: state.admin.organizations[state.admin.workingOrg],
    workingOrg: state.admin.workingOrg,
  };
}

export default connect(mapStateToProps, { updateOrgInfo })(OrgManager);
