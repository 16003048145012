/* eslint consistent-return: 0, no-else-return: 0*/
import { polyfill } from 'es6-promise';
import request from 'axios';
import md5 from 'spark-md5';
import * as types from '../types';

polyfill();

export function makeTaxonomyRequest(method, taxonomyId, data, action = '') {
  return request[method]('/ui/v1/taxonomy/' + taxonomyId + (action !== '' ? '/' + action : action), data);
}

export function getTaxonomySuccess(data) {
  return { type: types.REQUEST_TAXONOMY_SUCCESS, data };
}

export function getTaxonomyFailure(data) {
  return {
    type: types.REQUEST_TAXONOMY_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function getTaxonomy(taxonomyId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeTaxonomyRequest('get', taxonomyId)
      .then((updatedAdminSites) => dispatch(getTaxonomySuccess(updatedAdminSites.data)))
      .catch(() => dispatch(getTaxonomyFailure({ taxonomyId, error: 'Unable to Connect to the Internet' })));
  };
}
