import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ReactDOM from 'react-dom';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import SchemaTextArray from '../components/SchemaTextArray';
import SchemaEditor from '../components/SchemaEditor';
import Modal from 'react-modal';
import classNames from '../utils/classNames.js';
import { updateTaxonomy, updateAdminEditing } from '../actions/admin';
import { getTaxonomy } from '../actions/taxonomy';
import * as types from '../types';
import styles from '../css/components/taxonomymanager';
import ReactDataGrid from '../utils/local_modules/react-data-grid/dist/react-data-grid.js';
import { Toolbar, Data as RDGData } from '../utils/local_modules/react-data-grid-addons/dist/react-data-grid-addons.js';
import '../utils/local_modules/react-data-grid/dist/react-data-grid.min.css';
import '../utils/local_modules/react-data-grid-addons/dist/react-data-grid-addons.min.css';

Modal.setAppElement('body');

const {
  Selectors
} = RDGData;

const inBrowser = typeof window !== 'undefined';

const cx = classNames.bind(styles);

const taxColumns = [
  {
    key: 'order',
    name: 'Order',
    editable: true,
    sortable: true,
    resizable: true,
    filterable: true,
  },
  {
    key: 'common_name',
    name: 'Common Name',
    width: 250,
    editable: true,
    sortable: true,
    resizable: true,
    filterable: true,
  },
  {
    key: 'scientific_name',
    name: 'Scientific Name',
    width: 250,
    editable: true,
    sortable: true,
    resizable: true,
    filterable: true,
  },
  {
    key: 'code',
    name: 'Species Code',
    width: 120,
    editable: true,
    sortable: true,
    resizable: true,
    filterable: true,
  },
  {
    key: 'attribute_display',
    name: 'Attributes',
    width: 200,
    resizable: true,
    filterable: true,
  },
  {
    key: 'species_schema_display',
    name: 'Species Questions',
    width: 200,
    resizable: true,
  }
];

const speciesFields = [
  {
    field: 'order',
    name: 'Order',
    array: false,
    float: true,
    schema: false,
  },
  {
    field: 'common_name',
    name: 'Common Name',
    array: false,
    float: false,
    schema: false,
  },
  {
    field: 'scientific_name',
    name: 'Scientific Name',
    array: false,
    float: false,
    schema: false,
  },
  {
    field: 'code',
    name: 'Species Code',
    array: false,
    float: false,
    schema: false,
  },
  {
    field: 'attribute',
    name: 'Attributes',
    array: true,
    float: false,
    schema: false,
  },
  {
    field: 'species_schema',
    name: 'Species Questions',
    array: false,
    float: false,
    schema: true,
  }
];

class TaxonomyManager extends Component {
  constructor(props) {
    super(props);

    this.ingressTaxonomy = this.ingressTaxonomy.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.rowsCount = this.rowsCount.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.onGridChange = this.onGridChange.bind(this);
    this.customEditor = this.customEditor.bind(this);
    this.editAttribute = this.editAttribute.bind(this);
    this.saveAttributes = this.saveAttributes.bind(this);
    this.updateSpeciesSchema = this.updateSpeciesSchema.bind(this);
    this.handleGridRowsUpdated = this.handleGridRowsUpdated.bind(this);
    this.generateDelta = this.generateDelta.bind(this);
    this.discardSpeciesChanges = this.discardSpeciesChanges.bind(this);
    this.saveTaxonomy = this.saveTaxonomy.bind(this);
    this.closeModals = this.closeModals.bind(this);

    props.getTaxonomy(props.workingTaxonomy);

    const importTaxonomy = this.ingressTaxonomy(props.taxonomyIndex);

    this.state = {
      stage: 'edit',
      rows: importTaxonomy,
      originalRows: cloneDeep(importTaxonomy),
      filters: {},
      sortColumn: null,
      sortDirection: null,
      taxonomyLoaded: false,
      componentLoaded: false,
      initialSort: false,
      isMobile: false,
      attributeEditorOpen: false,
      attributeEditorRow: {},
      attributeEditorAttributes: [],
      speciesSchemaEditorOpen: false,
      speciesSchemaEditorRow: {},
      speciesSchemaEditorSchema: {},
      speciesSchemaEditorUsedVariables: [],
      changesMade: false,
      delta: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.workingTaxonomy !== nextProps.workingTaxonomy) {
      nextProps.getTaxonomy(nextProps.workingTaxonomy);
      this.setState({
        stage: 'edit',
        delta: [],
      });
    }

    if (nextProps.taxonomyEmpty) {
      nextProps.updateAdminEditing(types.ACTIVITY_TAXONOMY_ADD_SPECIES);
    } else if (this.props.taxonomyIndex.length !== nextProps.taxonomyIndex.length) {
      const importTaxonomy = this.ingressTaxonomy(nextProps.taxonomyIndex);

      this.setState({
        rows: importTaxonomy,
        originalRows: importTaxonomy,
        taxonomyLoaded: this.state.componentLoaded,
        stage: 'edit',
        delta: [],
        changesMade: false,
      });
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch),
    });

    if (this.props.taxonomyIndex.length > 0) {
      this.setState({
        taxonomyLoaded: true,
        componentLoaded: true,
      });
    } else {
      this.setState({
        componentLoaded: true,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      taxonomyLoaded: false,
      componentLoaded: false,
    });
  }

  ingressTaxonomy(taxonomy) {
    let importTaxonomy = taxonomy.map((thisSpecies) => {
      const updateSpecies = clone(thisSpecies);
      updateSpecies.order = parseFloat(updateSpecies.order);
      updateSpecies.common_name = updateSpecies.common_name || '';
      updateSpecies.scientific_name = updateSpecies.scientific_name || '';
      updateSpecies.attribute_display = updateSpecies.attribute ? updateSpecies.attribute.join(', ') : '';
      if (updateSpecies.species_schema) {
        const questionCount = Object.keys(updateSpecies.species_schema).length;
        if (questionCount > 1) {
          updateSpecies.species_schema_display = String(questionCount) + ' Questions';
        } else if (questionCount === 1) {
          updateSpecies.species_schema_display = String(questionCount) + ' Question';
        } else {
          updateSpecies.species_schema_display = '';
        }
      } else {
        updateSpecies.species_schema_display = '';
        updateSpecies.species_schema = {};
      }
      return updateSpecies;
    });

    importTaxonomy = sortBy(importTaxonomy, ['order']);

    return importTaxonomy;
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    if (!this.state.initialSort) {
      
    }
    return this.getRows().length;
  }

  rowGetter(rowIdx) {
    return Selectors.getRows(this.state)[rowIdx];
  }

  rowsCount() {
    return Selectors.getRows(this.state).length;
  }

  handleGridSort(sortColumn, sortDirection) {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
      }
    };

    const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);

    this.setState({
      rows,
      sortColumn,
      sortDirection
    }, () => {
      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'Taxonomy Manager: Sort Rows: ' + sortColumn,
        label: this.props.taxonomySchema.name,
      });
    });
  }

  handleFilterChange(filter) {
    let newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }

    this.setState({
      filters: newFilters
    }, () => {
      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'Taxonomy Manager: Filter Rows: ' + filter.column.key,
        label: this.props.taxonomySchema.name,
      });
    });
  }

  onClearFilters() {
    this.setState({ filters: {} });
  }

  onClearFilters() {
    this.setState({
      filters: {}
    });
  }

  onGridChange(event) {
    // Don't do anything
  }

  customEditor({ rowIdx, idx }) {
    // Attribute Editor
    if (idx === 4) {
      const currentRow = cloneDeep(this.rowGetter(rowIdx));
      const currentAttributes = currentRow.attribute ? cloneDeep(currentRow.attribute) : [];

      this.setState({
        attributeEditorOpen: true,
        attributeEditorRow: currentRow,
        attributeEditorAttributes: currentAttributes,
      }, () => {
        ReactGA.event({
          category: 'Dashboard: Taxonomy',
          action: 'Taxonomy Manager: Attribute Editor',
          label: this.props.taxonomySchema.name,
        });
      });
    } else if (idx === 5) {
      const currentRow = cloneDeep(this.rowGetter(rowIdx));

      this.setState({
        speciesSchemaEditorOpen: true,
        speciesSchemaEditorRow: currentRow,
        speciesSchemaEditorSchema: currentRow.species_schema,
        speciesSchemaEditorUsedVariables: currentRow.used_variables,
      }, () => {
        ReactGA.event({
          category: 'Dashboard: Taxonomy',
          action: 'Taxonomy Manager: Open Schema Editor',
          label: this.props.taxonomySchema.name,
        });
      });
    }
  }

  editAttribute(updateAttributes) {
    this.setState({
      attributeEditorAttributes: updateAttributes,
    });
  }

  saveAttributes(event) {
    event.preventDefault();
    event.stopPropagation();

    const updatedAttributes = this.state.attributeEditorAttributes;

    // Handle it ineffeciently to account for uncertain state within ReactDataGrid
    const updatedRows = this.state.rows.map((thisSpecies) => {
      const updatedSpecies = thisSpecies;
      if (thisSpecies.id === this.state.attributeEditorRow.id) {
        updatedSpecies.attribute = updatedAttributes;
        updatedSpecies.attribute_display = updatedAttributes.join(', ');
      }
      return updatedSpecies;
    });

    this.setState({
      rows: updatedRows,
      attributeEditorOpen: false,
      attributeEditorRow: {},
      attributeEditorAttributes: [],
      changesMade: true,
    }, () => {
      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'Taxonomy Manager: Save Attributes',
        label: this.props.taxonomySchema.name,
      });
    });
  }

  updateSpeciesSchema(updatedSchema, updatedUsedVariables) {
    // Handle it ineffeciently to account for uncertain state within ReactDataGrid
    const updatedRows = this.state.rows.map((thisSpecies) => {
      const updatedSpecies = thisSpecies;
      if (thisSpecies.id === this.state.speciesSchemaEditorRow.id) {
        updatedSpecies.species_schema = updatedSchema;
        const questionCount = Object.keys(updatedSchema).length;
        if (questionCount > 1) {
          updatedSpecies.species_schema_display = String(questionCount) + ' Questions';
        } else if (questionCount === 1) {
          updatedSpecies.species_schema_display = String(questionCount) + ' Question';
        } else {
          updatedSpecies.species_schema_display = '';
        }
        updatedSpecies.used_variables = updatedUsedVariables;
      }
      return updatedSpecies;
    });

    this.setState({
      rows: updatedRows,
      speciesSchemaEditorOpen: false,
      speciesSchemaEditorRow: {},
      speciesSchemaEditorSchema: {},
      speciesSchemaEditorUsedVariables: [],
      changesMade: true,
    }, () => {
      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'Taxonomy Manager: Save Schema Editor',
        label: this.props.taxonomySchema.name,
      });
    });
  }

  // https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid/src/ReactDataGrid.js
  handleGridRowsUpdated({cellKey, fromRow, toRow, fromRowId, toRowId, rowIds, updated, action, fromRowData}) {
    const rows = this.state.rows.slice();

    rowIds.forEach((thisRowId) => {
      const currentRowPosition = findIndex(rows, (thisSpecies) => { return thisSpecies.id === thisRowId; });
      const rowToUpdate = rows[currentRowPosition];
      const updatedRow = update(rowToUpdate, {$merge: updated});
      rows[currentRowPosition] = updatedRow;
    });

    this.setState({
      rows,
      changesMade: true,
    }, () => {
      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'Taxonomy Manager: Species Table Edited',
        label: this.props.taxonomySchema.name,
      });
    });
  }

  generateDelta() {
    const { taxonomyLookup } = this.props;

    const changes = [];

    this.state.rows.forEach((thisRow) => {
      const originalSpecies = taxonomyLookup[thisRow.id];
      const speciesDif = {
        id: thisRow.id,
        update: cloneDeep(thisRow),
        delta: {},
      };

      if (parseFloat(thisRow.order).toFixed(6) !== parseFloat(originalSpecies.order).toFixed(6)) {
        speciesDif.delta.order = thisRow.order;
      }

      if (thisRow.common_name !== originalSpecies.common_name) {
        speciesDif.delta.common_name = thisRow.common_name;
      }

      if (thisRow.scientific_name !== originalSpecies.scientific_name) {
        speciesDif.delta.scientific_name = thisRow.scientific_name;
      }

      if (thisRow.code !== originalSpecies.code) {
        speciesDif.delta.code = thisRow.code;
      }

      if (thisRow.code !== originalSpecies.code) {
        speciesDif.delta.code = thisRow.code;
      }

      if (isEmpty(thisRow.species_schema) && isEmpty(originalSpecies.species_schema)) {
        ;
      } else if (!isEqual(thisRow.species_schema, originalSpecies.species_schema)) {
        speciesDif.delta.species_schema = thisRow.species_schema;
        speciesDif.delta.used_variables = thisRow.used_variables;
      }

      const attributeDelta = [
        ...differenceWith(thisRow.attribute, originalSpecies.attribute, isEqual),
        ...differenceWith(originalSpecies.attribute, thisRow.attribute, isEqual)
      ];

      if (attributeDelta.length > 0) {
        speciesDif.delta.attribute = thisRow.attribute;
      }

      if (Object.keys(speciesDif.delta).length > 0) {
        changes.push(speciesDif);
      }
    });

    this.setState({
      stage: 'review',
      delta: changes,
    }, () => {
      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'Taxonomy Manager: Review Changes',
        label: this.props.taxonomySchema.name,
      });
    });
  }

  discardSpeciesChanges(discardSpecies) {
    const { taxonomyIndex } = this.props;

    const updateList = cloneDeep(this.state.delta).filter((thisRecord) => {
      return thisRecord.id !== discardSpecies.id;
    });

    if (updateList.length > 0) {
      this.setState({
        delta: updateList,
      }, () => {
        ReactGA.event({
          category: 'Dashboard: Taxonomy',
          action: 'Taxonomy Manager: Discard Species Changes - One',
          label: this.props.taxonomySchema.name,
        });
      });
    } else {
      this.setState({
        rows: this.ingressTaxonomy(taxonomyIndex),
        stage: 'edit',
        delta: [],
        changesMade: false,
      }, () => {
        ReactGA.event({
          category: 'Dashboard: Taxonomy',
          action: 'Taxonomy Manager: Discard Species Changes - All',
          label: this.props.taxonomySchema.name,
        });
      });
    }
  }

  saveTaxonomy() {
    const { updateTaxonomy, workingTaxonomy } = this.props;

    const outputDeltas = this.state.delta.map((thisUpdate) => {
      return {
        id: thisUpdate.id,
        ...thisUpdate.delta
      };
    });

    updateTaxonomy(workingTaxonomy, outputDeltas);

    ReactGA.event({
      category: 'Dashboard: Taxonomy',
      action: 'Taxonomy Manager: Save Taxonomy',
      label: this.props.taxonomySchema.name,
    });
  }

  closeModals(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      attributeEditorOpen: false,
      attributeEditorRow: {},
      attributeEditorAttributes: [],
      speciesSchemaEditorOpen: false,
      speciesSchemaEditorRow: {},
      speciesSchemaEditorSchema: {},
      speciesSchemaEditorUsedVariables: [],
    });
  }

  render() {
    const { taxonomySchema, taxonomyLookup, contextHelp } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2>{taxonomySchema.name}</h2>
        {(this.state.taxonomyLoaded && (this.state.stage === 'edit')) &&
          <div>
            <h2
              className={cx('review-header')} >
              Edit Taxonomy
            </h2>
            <div
              className={cx('context-help')} >
              This page is a powerful tool for managing the species in your taxonomy.
              <br /><br />
              <span className={cx('emphasis')}>Data Fields</span>
              <ul>
                <li><span className={cx('emphasis')}>Order:</span> Species are sorted in this table, in visualizations, and in the mobile app by their Order value. You can use numbers with up to three decimal places.</li>
                <li><span className={cx('emphasis')}>Common Name:</span> Every species in the taxonomy must have a common name. This is a searchable field in the mobile app.</li>
                <li><span className={cx('emphasis')}>Scientific Name:</span> Use this field for scientific names and author citations. (Optional)</li>
                <li><span className={cx('emphasis')}>Species Code:</span> Short codes are used to make data entry faster. This is a searchable field in the mobile app. (Optional)</li>
                <li><span className={cx('emphasis')}>Attributes:</span> Some taxonomies include characteristics such as morph, sex, or age that are readily observed and collected with each sighting. In the mobile app, these can be displayed on the species buttons for faster data entry. Enter one attribute on each line. (Optional)</li>
                <li><span className={cx('emphasis')}>Species Questions:</span> Prompt the user to collect additional data if the species is observed. This is a powerful feature that allows you to customize what data is collected for every species in the taxonomy. (Optional)</li>
              </ul>
              <span className={cx('emphasis')}>Interacting</span>
              <ul>
                <li><span className={cx('emphasis')}>Edit:</span> Double click within a cell to change the value. Press Enter to keep your change or Esc to cancel.</li>
                <li><span className={cx('emphasis')}>Search:</span> Click &quot;Search Species&quot; in the upper-right corner to filter the species rows. Type your search in the boxes at the top of each row.</li>
                <li><span className={cx('emphasis')}>Sort:</span> Click on column headers to sort. Click again to change the direction.</li>
              </ul>
              <span className={cx('emphasis')}>Saving Changes</span>
              <br />
              After making your changes in the table, click &quot;Review Changes&quot; at the bottom. If the changes look correct, press &quot;Save All Changes.&quot;. If you notice a mistake, you can click &quot;Discard Changes for This Species&quot; to prevent changes from being made for that species.
            </div>
            <ReactDataGrid
              columns={taxColumns}
              sortColumn="order"
              rowGetter={this.rowGetter}
              rowsCount={this.rowsCount()}
              enableCellSelect
              enableRowSelect={false}
              enableDragAndDrop={false}
              onChange={this.onGridChange}
              onGridSort={this.handleGridSort}
              onAddFilter={this.handleFilterChange}
              onClearFilters={this.onClearFilters}
              onCellSelected={this.customEditor}
              onGridRowsUpdated={this.handleGridRowsUpdated}
              toolbar={<Toolbar enableFilter filterRowsButtonText="Search Species" />}
              minHeight={this.state.isMobile ? 300 : 700}
              ref={(thisGrid) => { this.taxonomyGrid = thisGrid; }} />
            {this.state.changesMade &&
              <div
                className={cx('finish-stage-button-container')} >
                <button
                  className={cx('review-action-button')}
                  onClick={this.generateDelta} >
                  Review Changes
                </button>
              </div>
            }
            </div>
        }
        {(this.state.stage === 'review') &&
          <div>
            <h2
              className={cx('review-header')} >
              Review Your Changes
            </h2>
            <div
              className={cx('review-table-container')} >
              {this.state.delta.map((thisChange) => {
                const changes = Object.keys(thisChange.delta);
                return (
                  <div
                    key={thisChange.id}
                    className={cx('review-table-row')} >
                    <div
                      className={cx('review-table-comparison')} >
                      <div
                        className={cx('review-table-data-wrapper', 'review-table-before')} >
                        <h3
                          className={cx('review-table-header')} >
                          Original
                        </h3>
                        {speciesFields.map((thisField) => {
                          return (
                            <div
                              key={thisField.field}
                              className={cx('species-field', {'species-field-highlight': changes.indexOf(thisField.field) > -1})} >
                              <div
                                className={cx('species-field-name')} >
                                {thisField.name}:
                              </div>
                              {(thisField.array && ((taxonomyLookup[thisChange.id][thisField.field]) && (taxonomyLookup[thisChange.id][thisField.field].length > 0))) &&
                                <div
                                  className={cx('species-field-array')} >
                                  {taxonomyLookup[thisChange.id][thisField.field].map((thisEntry) => {
                                    return (
                                      <div
                                        key={thisEntry}
                                        className={cx('species-field-array-element')} >
                                        {thisEntry}
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                              {thisField.float &&
                                <div>
                                  {parseFloat(taxonomyLookup[thisChange.id][thisField.field])}
                                </div>
                              }
                              {thisField.schema &&
                                <div
                                  className={cx('species-field-array')} >
                                  {Object.keys(taxonomyLookup[thisChange.id][thisField.field] || {}).map((thisQuestion) => {
                                    return (
                                      <div
                                        key={thisQuestion}
                                        className={cx('species-field-array-element')} >
                                        {thisQuestion}
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                              {!(thisField.array || thisField.float || thisField.schema) &&
                                <div>
                                  {taxonomyLookup[thisChange.id][thisField.field]}
                                </div>
                              }
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className={cx('review-table-data-wrapper', 'review-table-after')} >
                        <h3
                          className={cx('review-table-header')} >
                          Edited
                        </h3>
                        {speciesFields.map((thisField) => {
                          return (
                            <div
                              key={thisField.field}
                              className={cx('species-field', {'species-field-highlight': changes.indexOf(thisField.field) > -1})} >
                              <div
                                className={cx('species-field-name')} >
                                {thisField.name}:
                              </div>
                              {(thisField.array && ((thisChange.update[thisField.field]) && (thisChange.update[thisField.field].length > 0))) &&
                                <div
                                  className={cx('species-field-array')} >
                                  {thisChange.update[thisField.field].map((thisEntry) => {
                                    return (
                                      <div
                                        key={thisEntry}
                                        className={cx('species-field-array-element')} >
                                        {thisEntry}
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                              {thisField.float &&
                                <div>
                                  {thisChange.update[thisField.field]}
                                </div>
                              }
                              {thisField.schema &&
                                <div
                                  className={cx('species-field-array')} >
                                  {Object.keys(thisChange.update[thisField.field] || {}).map((thisQuestion) => {
                                    return (
                                      <div
                                        key={thisQuestion}
                                        className={cx('species-field-array-element')} >
                                        {thisQuestion}
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                              {!(thisField.array || thisField.float || thisField.schema) &&
                                <div>
                                  {thisChange.update[thisField.field]}
                                </div>
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <button
                      className={cx('review-action-button')}
                      onClick={() => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.discardSpeciesChanges(thisChange);
                      }} >
                      Discard Changes for This Species
                    </button>
                  </div>
                );
              })}
            </div>
            <div
              className={cx('finish-stage-button-container')} >
              <button
                className={cx('review-action-button')}
                onClick={this.saveTaxonomy} >
                Save All Changes
              </button>
            </div>
          </div>
        }
        <Modal
          // style={{ overlay: {}, content: {} }}
          isOpen={this.state.attributeEditorOpen}
          contentLabel="Attribute Editor"
          portalClassName={cx('attribute-editor-modal', {'context-help-enabled': contextHelp, 'context-help-disabled': !contextHelp})}
          overlayClassName={cx('attribute-editor-overlay')}
          className={cx('attribute-editor-modal-contents')}
          bodyOpenClassName={cx('editor-modal-open')}
          ariaHideApp
          shouldCloseOnOverlayClick
          onRequestClose={this.closeModals}
          role="dialog">
          <h2
            className={cx('attribute-editor-header')} >
            Edit Attributes
          </h2>
          <div
            className={cx('attribute-edit-form')} >
            <form
              className={cx('info-form')}
              onSubmit={this.saveAttributes} >
              <input
                className={cx('attribute-submit-button')}
                type="submit"
                value="Save Attributes" />
              <SchemaTextArray
                textArray={this.state.attributeEditorAttributes}
                editArray={this.editAttribute} />
              <div
                className={cx('attribute-submit-button-spacer')} />
            </form>
          </div>
          <button
            className={cx('close-modal-button')}
            onClick={this.closeModals} >
              &#10006;
          </button>
        </Modal>
        <Modal
          // style={{ overlay: {}, content: {} }}
          isOpen={this.state.speciesSchemaEditorOpen}
          contentLabel="Species Questions Editor"
          portalClassName={cx('species-schema-editor-modal')}
          overlayClassName={cx('attribute-editor-overlay')}
          className={cx('species-schema-editor-modal-contents')}
          bodyOpenClassName={cx('editor-modal-open')}
          ariaHideApp
          shouldCloseOnOverlayClick
          onRequestClose={this.closeModals}
          role="dialog">
          <h2
            className={cx('attribute-editor-header')} >
            Edit Species Questions
          </h2>
          {this.state.speciesSchemaEditorOpen &&
            <SchemaEditor
              schema={this.state.speciesSchemaEditorSchema}
              updateSchema={this.updateSpeciesSchema}
              assetId={this.state.speciesSchemaEditorRow.id}
              usedVariables={this.state.speciesSchemaEditorUsedVariables}
              reportType={types.REPORT_TYPE_OBSERVATIONS} />
          }
          <button
            className={cx('close-modal-button')}
            onClick={this.closeModals} >
              &#10006;
          </button>
        </Modal>
      </div>
    );
  }
}

TaxonomyManager.propTypes = {
  getTaxonomy: PropTypes.func.isRequired,
  updateTaxonomy: PropTypes.func.isRequired,
  updateAdminEditing: PropTypes.func.isRequired,
  workingTaxonomy: PropTypes.number,
  taxonomyIndex: PropTypes.array,
  taxonomyLookup: PropTypes.object,
  taxonomyEmpty: PropTypes.bool,
  taxonomySchema: PropTypes.object,
  workingOrg: PropTypes.number,
  contextHelp: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    workingTaxonomy: state.admin.workingTaxonomy,
    taxonomyIndex: state.admin.activeTaxonomy.index,
    taxonomyLookup: state.admin.activeTaxonomy.lookup,
    taxonomyEmpty: state.admin.activeTaxonomy.empty,
    taxonomySchema: state.admin.organizations[state.admin.workingOrg].TaxonomyLookup[state.admin.workingTaxonomy],
    workingOrg: state.admin.workingOrg,
    contextHelp: state.admin.contextHelp,
  };
}

export default connect(mapStateToProps, { getTaxonomy, updateTaxonomy, updateAdminEditing })(TaxonomyManager);
