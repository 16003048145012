import React, { Component } from 'react';
import Page from '../pages/Page';
import PrivacyContainer from '../containers/Privacy';

class Privacy extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink()
    };
  }

  pageTitle() {
    return 'Dunkadoo Privacy Policy';
  }

  pageMeta() {
    return [
      { name: 'description', content: 'Dunkadoo Privacy Policy' }
    ];
  }

  pageLink() {
    return [];
  }

  render() {
    return (
      <Page {...this.getMetaData()}>
        <PrivacyContainer {...this.props} />
      </Page>
    );
  }
}

export default Privacy;
