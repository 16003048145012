import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import isFinite from 'lodash/isFinite';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import Modal from 'react-modal';
import SchemaTextArray from '../components/SchemaTextArray';
import SchemaEditor from '../components/SchemaEditor';
import classNames from '../utils/classNames.js';
import { addSpecies } from '../actions/admin';
import * as types from '../types';
import styles from '../css/components/taxonomymanager';

Modal.setAppElement('body');

const cx = classNames.bind(styles);

const speciesFields = [
  {
    field: 'order',
    name: 'Order',
    array: false,
    float: true,
  },
  {
    field: 'common_name',
    name: 'Common Name',
    array: false,
    float: false,
  },
  {
    field: 'scientific_name',
    name: 'Scientific Name',
    array: false,
    float: false,
  },
  {
    field: 'code',
    name: 'Species Code',
    array: false,
    float: false,
  },
  {
    field: 'attribute',
    name: 'Attributes',
    array: true,
    float: false,
  },
];

class TaxonomyAddSpecies extends Component {
  constructor(props) {
    super(props);

    this.uploadSpecies = this.uploadSpecies.bind(this);
    this.editAttribute = this.editAttribute.bind(this);
    this.updateSchema = this.updateSchema.bind(this);
    this.openSpeciesSchemaModal = this.openSpeciesSchemaModal.bind(this);
    this.closeModals = this.closeModals.bind(this);

    this.state = {
      attribute: [],
      errorMessage: [],
      speciesSchemaEditorOpen: false,
      schema: {},
      usedVariables: [],
    };
  }

  uploadSpecies(event) {
    const { addSpecies, workingTaxonomy } = this.props;

    event.preventDefault();
    event.stopPropagation();

    let readyToAdd = true;
    const buildErrorMessage = [];

    const buildSpecies = {
      taxonomy_id: workingTaxonomy,
    };

    try {
      const order = parseFloat(this.speciesOrder.value);
      if (isFinite(order) && (order > 0)) {
        buildSpecies.order = order;
      } else {
        throw new Error('Order must be a number greater than zero.');
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Order must be a number greater than zero.</li>);
    }

    try {
      if (!isEmpty(this.speciesCommonName.value) && (this.speciesCommonName.value.length > 0)) {
        buildSpecies.common_name = this.speciesCommonName.value;
      } else {
        throw new Error('Must input a Common Name.');
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Must input a Common Name.</li>);
    }

    try {
      if (isString(this.speciesScientificName.value)) {
        buildSpecies.scientific_name = this.speciesScientificName.value;
      } else {
        throw new Error('Scientific name must be alphanumeric.');
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Scientific name must be alphanumeric.</li>);
    }

    try {
      if (Array.isArray(this.state.attribute)) {
        buildSpecies.attribute = this.state.attribute;
      } else {
        throw new Error('Species Attributes Error');
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Species Attributes Error</li>);
    }

    try {
      if (isString(this.speciesCode.value)) {
        buildSpecies.code = this.speciesCode.value;
      } else {
        throw new Error('Species code must be alphanumeric.');
      }
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Species code must be alphanumeric.</li>);
    }

    try {
      buildSpecies.species_schema = this.state.schema;
      buildSpecies.used_variables = this.state.usedVariables;
    } catch (e) {
      readyToAdd = false;
      buildErrorMessage.push(<li>Error with Species Questions</li>);
    }

    if (readyToAdd) {
      // Reset Fields
      this.speciesOrder.value = '';
      this.speciesCommonName.value = '';
      this.speciesScientificName.value = '';
      this.speciesCode.value = '';
      this.setState({
        attribute: [],
        schema: {},
        usedVariables: [],
        errorMessage: false,
      });

      addSpecies(workingTaxonomy, buildSpecies);

      ReactGA.event({
        category: 'Dashboard: Taxonomy',
        action: 'addSpecies',
        label: this.props.taxonomySchema.name,
      });
    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: buildErrorMessage,
      }, () => {
        ReactGA.exception({
          description: 'Taxonomy Manager: Failed to Save ' + this.props.taxonomySchema.name,
          fatal: false,
        });
      });
    }
  }

  editAttribute(updateAttributes) {
    this.setState({
      attribute: updateAttributes,
    });
  }

  updateSchema(updatedSchema, updatedUsedVariables) {
    this.setState({
      speciesSchemaEditorOpen: false,
      schema: updatedSchema,
      usedVariables: updatedUsedVariables,
    });
  }

  openSpeciesSchemaModal(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      speciesSchemaEditorOpen: true,
    });
  }

  closeModals(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      speciesSchemaEditorOpen: false,
      schema: {},
      usedVariables: [],
    });
  }

  render() {
    const { taxonomySchema, workingTaxonomy, contextHelp } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2>{taxonomySchema.name}</h2>
        <h2
          className={cx('review-header')} >
          New Species
        </h2>
        <div
          className={cx('context-help')} >
          Use this form to add species to your taxonomy.
        </div>
        {(this.state.errorMessage.length > 0) &&
          <div
            className={cx('issues-container')} >
            <h2><span className={cx('issue-highlight')}>Issues</span></h2>
            <ul>
              {this.state.errorMessage}
            </ul>
          </div>
        }
        <div
          className={cx('new-species-form-container')} >
          <form
            className={cx('info-form')}
            onSubmit={this.uploadSpecies} >
            <div
              className={cx('new-species-submit-container')} >
              <input
                className={cx('new-species-submit-button')}
                type="submit"
                value="Add New Species" />
            </div>
            <div
              className={cx('new-species-field')} >
              <div
                className={cx('new-species-field-name')} >
                Order*
              </div>
              <div
                className={cx('context-help')} >
                Species are sorted in the taxonomy, in visualizations, and in the mobile app by their Order value. You can use numbers with up to three decimal places.
              </div>
              <input
                ref={(ref) => { this.speciesOrder = ref; }}
                type="number"
                inputMode="numeric"
                placeholder="1.0"
                step="0.00001"
                min="0" />
            </div>
            <div
              className={cx('new-species-field')} >
              <div
                className={cx('new-species-field-name')} >
                Common Name*
              </div>
              <div
                className={cx('context-help')} >
                Every species in the taxonomy must have a common name. This is a searchable field in the mobile app.
              </div>
              <input
                ref={(ref) => { this.speciesCommonName = ref; }}
                type="text" />
            </div>
            <div
              className={cx('new-species-field')} >
              <div
                className={cx('new-species-field-name')} >
                Scientific Name
              </div>
              <div
                className={cx('context-help')} >
                Use this field for scientific names and author citations. (Optional)
              </div>
              <input
                ref={(ref) => { this.speciesScientificName = ref; }}
                type="text" />
            </div>
            <div
              className={cx('new-species-field')} >
              <div
                className={cx('new-species-field-name')} >
                Short Code
              </div>
              <div
                className={cx('context-help')} >
                Short codes are used to make data entry faster. This is a searchable field in the mobile app. (Optional)
              </div>
              <input
                ref={(ref) => { this.speciesCode = ref; }}
                type="text" />
            </div>
            <div
              className={cx('new-species-field')} >
              <div
                className={cx('new-species-field-name')} >
                Attributes
              </div>
              <div
                className={cx('context-help')} >
                Some taxonomies include characteristics such as morph, sex, or age that are readily observed and collected with each sighting. In the mobile app, these can be displayed on the species buttons for faster data entry. Enter one attribute on each line. (Optional)
              </div>
              <SchemaTextArray
                textArray={this.state.attribute}
                editArray={this.editAttribute} />
            </div>
            <div
              className={cx('new-species-field')} >
              <div
                className={cx('new-species-field-name')} >
                Species Questions
              </div>
              <div
                className={cx('context-help')} >
                Prompt the user to collect additional data if the species is observed. This is a powerful feature that allows you to customize what data is collected for every species in the taxonomy. (Optional)
              </div>
              <div
                className={cx('new-species-schema-cta')} >
                {!isEmpty(this.state.schema) && Object.keys(this.state.schema).map((thisField) => {
                  return (
                    <div
                      key={thisField}
                      className={cx('new-species-schema-field')} >
                      {thisField}
                    </div>
                  );
                })}
                {isEmpty(this.state.schema) &&
                  <div
                    className={cx('new-species-schema-field')} >
                    None
                  </div>
                }
                <button
                  onClick={this.openSpeciesSchemaModal} >
                  Click to {isEmpty(this.state.schema) ? 'Add' : 'Edit'} Species Questions
                </button>
              </div>
            </div>
            <div
              className={cx('new-species-submit-button-spacer')} />
          </form>
        </div>
        <Modal
          // style={{ overlay: {}, content: {} }}
          isOpen={this.state.speciesSchemaEditorOpen}
          contentLabel="Species Questions Editor"
          portalClassName={cx('species-schema-editor-modal', {'context-help-enabled': contextHelp, 'context-help-disabled': !contextHelp})}
          overlayClassName={cx('attribute-editor-overlay')}
          className={cx('species-schema-editor-modal-contents')}
          bodyOpenClassName={cx('editor-modal-open')}
          ariaHideApp
          shouldCloseOnOverlayClick
          onRequestClose={this.closeModals}
          role="dialog">
          <h2
            className={cx('attribute-editor-header')} >
            Edit Species Questions
          </h2>
          {this.state.speciesSchemaEditorOpen &&
            <SchemaEditor
              schema={this.state.schema}
              updateSchema={this.updateSchema}
              assetId={workingTaxonomy}
              usedVariables={this.state.usedVariables}
              reportType={types.REPORT_TYPE_OBSERVATIONS} />
          }
          <button
            className={cx('close-modal-button')}
            onClick={this.closeModals} >
              &#10006;
          </button>
        </Modal>
      </div>
    );
  }
}

TaxonomyAddSpecies.propTypes = {
  addSpecies: PropTypes.func.isRequired,
  workingTaxonomy: PropTypes.number,
  taxonomySchema: PropTypes.object,
  contextHelp: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    workingTaxonomy: state.admin.workingTaxonomy,
    taxonomySchema: state.admin.organizations[state.admin.workingOrg].TaxonomyLookup[state.admin.workingTaxonomy],
    contextHelp: state.admin.contextHelp,
  };
}

export default connect(mapStateToProps, { addSpecies })(TaxonomyAddSpecies);
