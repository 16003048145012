import { combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import update from 'immutability-helper';
import * as types from 'types';
import moment from 'moment-timezone';

const projectData = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
      return action.data.projectMeta || {};
    case types.CLEAR_PROJECT_DATA:
      return {};
    default:
      return state;
  }
};

const dayTally = (
  state = {
    species: 0,
    count: 0,
  },
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
    case types.GET_RECENT_SUCCESS:
      if ('dayTally' in action.data) {
        return action.data.dayTally;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return {
        species: 0,
        count: 0,
      };
    default:
      return state;
  }
};

const seasonTally = (
  state = {
    species: 0,
    count: 0,
  },
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
    case types.GET_RECENT_SUCCESS:
      if ('seasonTally' in action.data) {
        return action.data.seasonTally;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return {
        species: 0,
        count: 0,
      };
    default:
      return state;
  }
};

const dateList = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
      if ('dateList' in action.data) {
        return action.data.dateList;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return [];
    default:
      return state;
  }
};

const hourlyCounts = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS: {
      if ('hourlyCounts' in action.data) {
        const groupedHourlyCounts = groupBy(action.data.hourlyCounts, (thisTime) => {
          return thisTime.localHour.slice(0, 10);
        });
        return groupedHourlyCounts;
      }
      return state;
    }
    case types.CLEAR_PROJECT_DATA:
      return [];
    default:
      return state;
  }
};

const recentNotable = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
    case types.GET_RECENT_SUCCESS:
      if ('recentNotable' in action.data) {
        return action.data.recentNotable;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return [];
    default:
      return state;
  }
};

const recentObservations = (
  state = {
    ordered: [],
    identified: {}
  },
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
    case types.GET_RECENT_SUCCESS:
      if ('recentObservations' in action.data) {
        return {
          ordered: action.data.recentObservations,
          identified: keyBy(action.data.recentObservations, 'uuid'),
        };
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return {
        ordered: [],
        identified: {}
      };
    default:
      return state;
  }
};

const recentCoordinates = (
  state = {
    ordered: [],
    identified: {}
  },
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
    case types.GET_RECENT_SUCCESS:
      if ('recentCoordinates' in action.data) {
        return {
          ordered: action.data.recentCoordinates,
          identified: keyBy(action.data.recentCoordinates, 'uuid'),
        };
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return {
        ordered: [],
        identified: {}
      };
    default:
      return state;
  }
};

const speciesDays = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
      if ('speciesDays' in action.data) {
        return action.data.speciesDays;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return {};
    default:
      return state;
  }
};

const dailyTotal = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
      if ('dailyTotal' in action.data) {
        return action.data.dailyTotal;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return [];
    default:
      return state;
  }
};

const forecastData = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
      if ('forecastData' in action.data && action.data.forecastData) {
        return action.data.forecastData;
      }
      return state;
    case types.CLEAR_PROJECT_DATA:
      return {};
    default:
      return state;
  }
};

const projectReducer = combineReducers({
  projectData,
  dayTally,
  seasonTally,
  dateList,
  hourlyCounts,
  recentNotable,
  recentObservations,
  recentCoordinates,
  speciesDays,
  dailyTotal,
  forecastData,
});

export default projectReducer;
