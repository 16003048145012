import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from '../utils/classNames.js';
import Dimensions from '../utils/local_modules/react-dimensions';
import GoogleMapReact from 'google-map-react';
import { fitBounds, ptInBounds, withStateSelector } from 'google-map-react/utils';
import SimpleMarker from 'components/SimpleMarker';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import { navigateProject } from '../actions/project';
import styles from 'css/components/projectmap';

const cx = classNames.bind(styles);

class CoordMap extends Component {
  constructor(props) {
    super(props);

    this.sendCoords = this.sendCoords.bind(this);

    this.state = {
      center: null,
      zoom: null,
    };
  }

  sendCoords(event) {
    const { updateCoords } = this.props;

    updateCoords({
      latitude: event.lat.toFixed(6),
      longitude: event.lng.toFixed(6),
    });
  }

  render() {
    const { containerHeight, containerWidth, name, latitude, longitude, centerLatitude, centerLongitude, baseZoom, googleAPIKey } = this.props;

    const bounds = {
      ne: {
        lat: 49.384358,
        lng: -66.885444
      },
      sw: {
        lat: 5.000000,
        lng: -124.848974
      }
    };

    const size = {
      width: containerWidth,
      height: containerHeight,
    };

    let {center, zoom} = fitBounds(bounds, size);
    zoom -= 1;

    if (!isEmpty(centerLatitude) && !isEmpty(centerLongitude)) {
      center = {
        lat: parseFloat(centerLatitude),
        lng: parseFloat(centerLongitude),
      };
    }

    if (!isNaN(baseZoom)) {
      zoom = baseZoom;
    }

    const mapSetup = {
      key: googleAPIKey,
      language: 'en'
    };

    const mapOptions = {
      mapTypeId: 'satellite'
    };

    const mapStyling = {
      display: 'block',
      position: 'relative',
      margin: 0,
      padding: 0,
      flex: 1,
      height: 250
    };

    return (
      <GoogleMapReact
        bootstrapURLKeys={mapSetup}
        center={center}
        zoom={zoom}
        style={mapStyling}
        options={mapOptions}
        resetBoundsOnResize
        onClick={this.sendCoords} >
        {(!isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude))) &&
          <SimpleMarker
            describes={name}
            lat={parseFloat(latitude)}
            lng={parseFloat(longitude)}
            name={name}
            tightenZoom={this.tightenZoom}
            selectedMarker={this.onChildClick} />
        }
      </GoogleMapReact>
    );
  }
}

CoordMap.defaultProps = {
  latitude: '',
  longitude: '',
  centerLatitude: '',
  centerLongitude: '',
  baseZoom: NaN,
};

CoordMap.propTypes = {
  updateCoords: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  centerLatitude: PropTypes.string,
  centerLongitude: PropTypes.string,
  baseZoom: PropTypes.number,
  googleAPIKey: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    googleAPIKey: state.environment.GOOGLE_APIKEY,
  };
}

export default connect(mapStateToProps, { navigateProject })(
  Dimensions({
    getHeight: function(element) {
      return Math.max(element.clientHeight, 200);
    },
    getWidth: function(element) {
      return Math.max(element.clientWidth - 20, 200);
    },
    elementResize: true
})(CoordMap));
