import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Page from '../pages/Page';
import EmbedProjectContainer from '../containers/EmbedProject';

class EmbedProject extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink()
    };
  }

  pageTitle() {
    const { name } = this.props;

    return name + ' - Dunkadoo';
  }

  pageMeta() {
    const { description } = this.props;

    return [
      { name: 'description', content: description }
    ];
  }

  pageLink() {
    return [];
  }

  render() {
    return (
      <Page {...this.getMetaData()}>
        <EmbedProjectContainer {...this.props} />
      </Page>
    );
  }
}

EmbedProject.defaultProps = {
  name: 'Dunkadoo Project',
  description: 'Visualize Project Data',
};

EmbedProject.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    name: state.project.projectData.name,
    description: state.project.projectData.description,
  };
}

export default connect(mapStateToProps, { })(EmbedProject);
