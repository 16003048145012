import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/tallies';

const moment = require('moment-timezone');

const cx = classNames.bind(styles);

class Tallies extends Component {
  render() {
    const { range, dayTallySpecies, dayTallyCount, seasonTallySpecies, seasonTallyCount } = this.props;

    let header;
    let species;
    let count;

    if (range === 'day') {
      header = 'Day Summary';
      species = dayTallySpecies;
      count = dayTallyCount;
    } else if (range === 'season') {
      header = 'Season Summary';
      species = seasonTallySpecies;
      count = seasonTallyCount;
    }

    return (
      <div className={cx('container')}>
        <h3 className={cx('section-head')}>{header}</h3>
        <div className={cx('tallies')}>
          <div
            className={cx('species')} >
            <div
              className={cx('stat-header')} >
              Species Count
            </div>
            <div
              className={cx('stat')} >
              {species.toLocaleString()}
            </div>
          </div>
          <div
            className={cx('count')} >
            <div
              className={cx('stat-header')} >
              Total Count
            </div>
            <div
              className={cx('stat')} >
              {count.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Tallies.propTypes = {
  range: PropTypes.string.isRequired,
  dayTallySpecies: PropTypes.number,
  dayTallyCount: PropTypes.number,
  seasonTallySpecies: PropTypes.number,
  seasonTallyCount: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    dayTallySpecies: state.project.dayTally.species,
    dayTallyCount: state.project.dayTally.count,
    seasonTallySpecies: state.project.seasonTally.species,
    seasonTallyCount: state.project.seasonTally.count,
  };
}

export default connect(mapStateToProps, { })(Tallies);
