import React from 'react';
import classNames from '../utils/classNames.js';
import styles from 'css/components/about';
import dunkadooLogo from '../images/dunkadoo-640.png';
import counterAndPaper from '../images/Counter-and-Paper.png';
import dunkadooTablet from '../images/Dunkadoo-Tablet.png';
import vizOne from '../images/visualization-example-1.png';
import vizTwo from '../images/visualization-example-2.png';
import goodman from '../images/Goodman-Carol.jpg';
import conard from '../images/Conard-Russell.jpg';

const cx = classNames.bind(styles);

const About = () => {
  return (
    <div className={cx('about')}>
      <h2 className={cx('header')}>Dunkadoo</h2>
      <div className={cx('description')}>
        <div
          className={cx('illustration', 'full')} >
          <img
            src={vizOne}
            alt="Live Data and Weather Chart" />
        </div>
        <p>Dunkadoo builds tools to help scientists study our natural world and make science more accessible to the public.</p>
        <p>We founded Dunkadoo as a non-profit when we noticed that professional counters at migration sites were still using paper to record the birds they count. After a long day, they went back to a computer and transferred all that data by hand. It seemed so 20th century! Thus, our first tools help counters enter data on a device and display it on the web instantly. Even better, we provide a display for each site that’s fun for the public to explore. Our goal: make it possible to digitally see waves of migration as they’re happening.</p>
      </div>
      <h2 className={cx('header')}>What&apos;s With the Name?</h2>
      <div className={cx('description')}>
        <div
          className={cx('illustration', 'right', 'quarter')} >
          <img
            src={dunkadooLogo}
            alt="Our Logo Features an American Bittern" />
          <div
            className={cx('caption')} >
            <p>Our Logo Features an American Bittern</p>
          </div>
        </div>
        <p>The name is intentionally different, and we love it.</p>
        <p>Conservation organizations are typically an alphabet soup of acronyms and descriptive names, whereas software projects tend to have whimsical names and a collaborative spirit. Dunkadoo is first and foremost a software project founded to provide enterprise-class software for environmental causes.</p>
        <p>We wanted something easily remembered and spelled, something that one birder could recommend to another on the boardwalk without having to write it down.</p>
        <p>Hundreds of possible names later, we landed on a few historical books of old-fashioned bird names. When we came across Dunkadoo, an old New England name for the American Bittern, we knew we had a winner. If you’re wondering, “Dunk-a-doo” is the sound the bird makes from its secretive place in the marsh.</p>
      </div>
      <h2 className={cx('header')}>The Founders</h2>
      <div className={cx('description')}>
        <p>Russell Conard and Carol Goodman are two passionate environmentalists who also happen to be software developers. With experience ranging from marsh surveys for Bird Studies Canada to nest surveys in the Everglades, these two have worked with and done environmental fieldwork with seasoned biologists from coast to coast. As both are avid birders, many of Dunkadoo’s first projects are dedicated to bird research.</p>
        <p>Russell and Carol both understood that conservation organizations had a pressing need for digital field data collection and founded Dunkadoo in early 2016 to address those needs. Both felt a commitment to help the scientists doing fieldwork and to make their projects more accessible for the public.</p>
        <p>Some apps existed for citizen science use, but none could handle the rigorous and constantly changing demands of environmental researchers. The Dunkadoo app was built from scratch based on years of field testing, consultation with scientists and fieldwork professionals, and feedback from partner organizations. Some of the best field biologists in the country have helped to create and test the app. </p>
        <div
          className={cx('team-block')} >
          <div
            className={cx('illustration', 'left', 'quarter', 'rounded')} >
            <img
              src={conard}
              alt="Russell Conard" />
          </div>
          <h3
            className={cx('team-member-name')} >
            Russell Conard
          </h3>
          <h4
            className={cx('team-member-role')} >
            President and Lead Developer
          </h4>
          <div
            className={cx('obfuscated-email')} >
            <a
              href="http://www.google.com/recaptcha/mailhide/d?k=015LguzBJigi0rpyuJRqLoig==&amp;c=8ClfHzl8s99FRD38lMDO_MgqB5zsgiNNwdfNrV5vPRI="
              onClick="window.open('http://www.google.com/recaptcha/mailhide/d?k\x3d015LguzBJigi0rpyuJRqLoig\x3d\x3d\x26c\x3d8ClfHzl8s99FRD38lMDO_MgqB5zsgiNNwdfNrV5vPRI\x3d', '', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=500,height=300'); return false;"
              title="Reveal this e-mail address"
              className={cx('obfuscated-email')} >r...</a>@dunkadoo.org
          </div>
          <p>Russell serves as the president and lead developer of Dunkadoo. He got his start building software for environmental data ten years ago in the Purdue Climate Change Research Center, founded a startup to build software for fieldwork five years ago, and spends countless hours working with field biologists to improve the software he builds to meet their needs.</p>
        </div>
        <div
          className={cx('team-block')} >
          <div
            className={cx('illustration', 'left', 'quarter', 'rounded')} >
            <img
              src={goodman}
              alt="Carol Goodman" />
          </div>
          <h3
            className={cx('team-member-name')} >
            Carol Goodman
          </h3>
          <h4
            className={cx('team-member-role')} >
            Chairman and Site Coordinator
          </h4>
          <div
            className={cx('obfuscated-email')} >
            <a
              href="http://www.google.com/recaptcha/mailhide/d?k=01d5N7fcCJ8gjvYQUXxGp4yg==&amp;c=ifkT2gG1_ogI1imFpTUBm8XaUNgQ8UpW3vjAVfxxp4U="
              onClick="window.open('http://www.google.com/recaptcha/mailhide/d?k\x3d01d5N7fcCJ8gjvYQUXxGp4yg\x3d\x3d\x26c\x3difkT2gG1_ogI1imFpTUBm8XaUNgQ8UpW3vjAVfxxp4U\x3d', '', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=500,height=300'); return false;"
              title="Reveal this e-mail address" >c...</a>@dunkadoo.org
          </div>
          <p>Carol, currently chairman of Dunkadoo, spent 20+ years building software for the auto industry, and thought she was retired when she signed on to do a small project for Russell’s startup business 4 years ago. During the peak of the field season, Carol is on the phone supporting our partner organizations from dawn to dusk 7 days a week. So much for the quiet life!</p>
        </div>
      </div>
    </div>
  );
};

export default About;
