import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { findDOMNode } from 'react-dom';
// import { DragSource, DropTarget } from 'react-dnd';
// import { useDrag } from 'react-dnd';
import { popSpeciesButtons, popFocusButtons } from '../actions/admin';
import * as types from 'types';
import classNames from '../utils/classNames.js';
import styles from 'css/components/speciesbuttons';

const cx = classNames.bind(styles);

const style = {
  display: 'block',
  width: '80%',
  minWidth: '260px',
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
  margin: '6px auto 6px',
};

class SpeciesButton extends Component {
  constructor(props) {
    super(props);

    this.removeSpecies = this.removeSpecies.bind(this);
  }

  removeSpecies(speciesId) {
    const { buttonGroup, popSpeciesButtons, popFocusButtons } = this.props;

    if (buttonGroup === 'species_list') {
      popSpeciesButtons(speciesId);
    } else {
      popFocusButtons(speciesId);
    }
  }

  render() {
    const { text, isDragging, id } = this.props;
    const opacity = isDragging ? 0 : 1;

    return (
      <div
        style={{
          ...style,
          opacity
        }} >
        {text}
        <button
          className={cx('delete-species-button')}
          onClick={(event) => {
            event.preventDefault();
            this.removeSpecies(id);
            }
          } >
          X
        </button>
      </div>
    );
  }
}

SpeciesButton.propTypes = {
  buttonGroup: PropTypes.string.isRequired,
  popSpeciesButtons: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, { popSpeciesButtons, popFocusButtons })(SpeciesButton);
