import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import ReactGA from 'react-ga';
import classNames from '../utils/classNames.js';
import styles from 'css/components/schema';

const cx = classNames.bind(styles);

class ProjectManager extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { projectActivity, projectId } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2>{projectActivity.name}</h2>
        <div
          className={cx('context-help')} >
          <span className={cx('emphasis')}>Download Your Data</span>
          <ul>
            <li><span className={cx('emphasis')}>CSV:</span> This format is compatible with spreadsheet and database applications.</li>
            <li><span className={cx('emphasis')}>JSON:</span> JavaScript Object Notation is a data format used to preserve the relationships within the data.</li>
          </ul>
        </div>
        {projectActivity.observation_count > 0 &&
          <div
            className={cx('download-wrapper')} >
            <div
              className={cx('count')} >
              {projectActivity.observation_count}
            </div>
            <div
              className={cx('report-type')} >
              {projectActivity.observation_count === 1 ? (
                <span>Observation</span>
              ) : (
                <span>Observations</span>
              )}
            </div>
            <div
              className={cx('download-link-block')} >
              <Link
                to={`/ui/v1/project/data/${projectId}/download/?format=csv&type=observation`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  ReactGA.event({
                    category: 'Dashboard: Project',
                    action: 'Download: Observations - CSV',
                    label: projectActivity.name,
                  });
                }}
                className={cx('download-link')} >
                Download CSV
              </Link>
              <Link
                to={`/ui/v1/project/data/${projectId}/download/?format=json&type=observation`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  ReactGA.event({
                    category: 'Dashboard: Project',
                    action: 'Download: Observations - JSON',
                    label: projectActivity.name,
                  });
                }}
                className={cx('download-link')} >
                Download JSON
              </Link>
            </div>
          </div>
        }
        {projectActivity.metadata_count > 0 &&
          <div
            className={cx('download-wrapper')} >
            <div
              className={cx('count')} >
              {projectActivity.metadata_count}
            </div>
            <div
              className={cx('report-type')} >
              Metadata
            </div>
            <div
              className={cx('download-link-block')} >
              <Link
                to={`/ui/v1/project/data/${projectId}/download/?format=csv&type=metadata`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  ReactGA.event({
                    category: 'Dashboard: Project',
                    action: 'Download: Metadata - CSV',
                    label: projectActivity.name,
                  });
                }}
                className={cx('download-link')} >
                Download CSV
              </Link>
              <Link
                to={`/ui/v1/project/data/${projectId}/download/?format=json&type=metadata`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  ReactGA.event({
                    category: 'Dashboard: Project',
                    action: 'Download: Metadata - JSON',
                    label: projectActivity.name,
                  });
                }}
                className={cx('download-link')} >
                Download JSON
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}

ProjectManager.propTypes = {
  projectActivity: PropTypes.object,
  projectId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    projectActivity: state.admin.projectActivity[state.admin.workingProject],
    projectId: state.admin.workingProject,
  };
}

export default connect(mapStateToProps, { })(ProjectManager);
