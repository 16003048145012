import { polyfill } from 'es6-promise';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import moment from 'moment-timezone';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isSafeInteger from 'lodash/isSafeInteger';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import remove from 'lodash/remove';
import groupBy from 'lodash/groupBy';
import classNames from '../utils/classNames.js';
import { updateWorkingTaxonomy } from '../actions/admin';
import { gatherHawkCount, sendHawkCountCrossref, sendHawkCountConditions, sendHawkCountActive, sendHawkCountForceSync } from '../actions/integrations';
import * as types from '../types';
import styles from '../css/components/integrations';
import stylesSchema from '../css/components/schema';

polyfill();

const cx = classNames.bind(styles);
const cy = classNames.bind(stylesSchema);

const questionTypesLookup = {
  I: 'number/string',
  P: 'number/percentage',
  D: 'radio',
};

class IntegrationHawkCount extends Component {
  constructor(props) {
    super(props);

    this.ingressHCSpecies = this.ingressHCSpecies.bind(this);
    this.ingressHCFields = this.ingressHCFields.bind(this);
    this.markCompetingRefs = this.markCompetingRefs.bind(this);
    this.selectSpecies = this.selectSpecies.bind(this);
    this.selectAttribute = this.selectAttribute.bind(this);
    this.updateConditions = this.updateConditions.bind(this);
    this.updateLookupMap = this.updateLookupMap.bind(this);
    this.updateSyncTime = this.updateSyncTime.bind(this);
    this.uploadCrossref = this.uploadCrossref.bind(this);
    this.uploadConditions = this.uploadConditions.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.forceSync = this.forceSync.bind(this);

    this.state = {
      hawkcountSpecies: {},
      conditionFields: {},
      conditionLookup: {},
      resyncStart: moment().startOf('day').format(),
      resyncStartValid: true,
      resyncEnd: moment().add(1, 'day').startOf('day').format(),
      resyncEndValid: true,
    };
  }

  componentWillMount() {
    if (this.props.workingProject in this.props.hawkcountLandscape) {
      if (this.props.workingTaxonomy !== this.props.adminSchema.taxonomy_id) {
        this.props.updateWorkingTaxonomy(this.props.adminSchema.taxonomy_id);
        this.props.gatherHawkCount(this.props.workingProject);
      } else if ((this.props.taxonomyIndex.length === 0) || (this.props.hawkcountSite.project_id !== this.props.workingProject)) {
        this.props.gatherHawkCount(this.props.workingProject);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.workingProject in nextProps.hawkcountLandscape) {
      if (nextProps.workingTaxonomy !== nextProps.adminSchema.taxonomy_id) {
        nextProps.updateWorkingTaxonomy(nextProps.adminSchema.taxonomy_id);
        nextProps.gatherHawkCount(nextProps.workingProject);
      } else if ((nextProps.taxonomyIndex.length === 0) || (nextProps.hawkcountSite.project_id !== nextProps.workingProject)) {
        nextProps.gatherHawkCount(nextProps.workingProject);
      } else if ((nextProps.taxonomyIndex.length > 0) && (nextProps.hawkcountSpeciesSpecies.length > 0)) {
        this.ingressHCSpecies(nextProps.taxonomyLookup, nextProps.hawkcountSpecies, nextProps.hawkcountSpeciesSpecies, nextProps.adminSchema.taxonomy_id);
        this.ingressHCFields(nextProps.hawkcountConditionFields, nextProps.hawkcountConditionLookup);
      }
    }
  }

  ingressHCSpecies(dunkadooTaxonomyLookup, hawkcountTaxonomyIndex, hawkcountCrossref, dunkadooTaxonomyId) {
    const constructTaxonomy = hawkcountTaxonomyIndex.map((thisSpecies) => {
      const existingCrossref = find(hawkcountCrossref, {hawkcount_species_id: thisSpecies.id});
      const existingCrossrefs = hawkcountCrossref.filter((o) => { return o.hawkcount_species_id === thisSpecies.id; });
      const dunkadooSpecies = existingCrossrefs.map((thisRef) => {
        let species_attribute = null;
        let species_attribute_input = '';
        let valid = isSafeInteger(thisRef.species_id);

        if (thisRef) {
          if (isEmpty(dunkadooTaxonomyLookup[thisRef.species_id].attribute)) {
            if (!isEmpty(thisRef.species_attribute)) {
              valid = false;
            }
          } else if (dunkadooTaxonomyLookup[thisRef.species_id].attribute.indexOf(thisRef.species_attribute) > -1) {
            species_attribute = thisRef.species_attribute;
            species_attribute_input = thisRef.species_attribute;
            valid = true;
          } else if (isEmpty(thisRef.species_attribute)) {
            species_attribute = null;
            species_attribute_input = '';
            valid = true;
          } else {
            valid = false;
          }
        }

        return {
          species_name: thisRef ? dunkadooTaxonomyLookup[thisRef.species_id].common_name : '',
          species_id: thisRef ? thisRef.species_id : null,
          species_attribute,
          species_attribute_input,
          valid,
          conflict: false,
        };
      });

      dunkadooSpecies.push({
        species_name: '',
        species_id: null,
        species_attribute: null,
        species_attribute_input: '',
        valid: true,
        conflict: false,
      });

      return update(thisSpecies, {
        $merge: {
          taxonomy_id: dunkadooTaxonomyId,
          dunkadooSpecies
        }
      });
    });

    this.setState({
      hawkcountSpecies: keyBy(constructTaxonomy, 'id'),
    });
  }

  ingressHCFields(conditionFieldsInput, conditionLookupInput) {
    const groupedLookup = groupBy(conditionLookupInput, (o) => { return o.field; });

    const conditionFields = conditionFieldsInput.map((thisField) => {
      return update(thisField, {
        question_type_display: {
          $set: thisField.question_type,
        },
        lookup_data: {
          $set: groupedLookup[thisField.hawkcount_field] || [],
        }
      });
    });

    this.setState({
      conditionFields: keyBy(conditionFields, 'hawkcount_field'),
    });
  }

  markCompetingRefs() {
    function speciesIdAttribute(inputSpeciesId, inputAttribute) {
      return String(inputSpeciesId) + '_' + String(inputAttribute);
    }

    const allMatches = [];
    Object.keys(this.state.hawkcountSpecies).forEach((thisHCSpeciesId) => {
      this.state.hawkcountSpecies[thisHCSpeciesId].dunkadooSpecies.forEach((thisDunkadooSpecies) => {
        if (thisDunkadooSpecies.species_id) {
          allMatches.push(speciesIdAttribute(thisDunkadooSpecies.species_id, thisDunkadooSpecies.species_attribute));
        }
      });
    });

    const validatedRefs = cloneDeep(this.state.hawkcountSpecies);

    Object.keys(this.state.hawkcountSpecies).forEach((thisHCSpeciesId) => {
      validatedRefs[thisHCSpeciesId].dunkadooSpecies = this.state.hawkcountSpecies[thisHCSpeciesId].dunkadooSpecies.map((thisDunkadooSpecies) => {
        return update(thisDunkadooSpecies, {
          conflict: {
            $set: allMatches.filter((speciesHash) => { return speciesHash === speciesIdAttribute(thisDunkadooSpecies.species_id, thisDunkadooSpecies.species_attribute); }).length > 1,
          }
        });
      });
    });

    this.setState({
      hawkcountSpecies: validatedRefs,
    });
  }

  selectSpecies(event, hcSpeciesId, refIndex) {
    const { taxonomyIndex } = this.props;

    const target = event.target;
    let value = target.value;
    const name = target.name;

    const speciesMatch = find(taxonomyIndex, {common_name: value});

    if (isEmpty(value)) {
      // Doesn't match a species - update value and mark invalid
      this.setState({
        hawkcountSpecies: update(this.state.hawkcountSpecies, {
          [hcSpeciesId]: {
            dunkadooSpecies: {
              [refIndex]: {
                species_name: {
                  $set: '',
                },
                species_id: {
                  $set: null,
                },
                species_attribute: {
                  $set: null,
                },
                species_attribute_input: {
                  $set: '',
                },
                valid: {
                  $set: true,
                }
              }
            }
          }
        })
      }, () => {
        this.markCompetingRefs();
      });
    } else if (isEmpty(speciesMatch)) {
      // Doesn't match a species - update value and mark invalid
      this.setState({
        hawkcountSpecies: update(this.state.hawkcountSpecies, {
          [hcSpeciesId]: {
            dunkadooSpecies: {
              [refIndex]: {
                species_name: {
                  $set: value,
                },
                species_id: {
                  $set: null,
                },
                species_attribute: {
                  $set: null,
                },
                species_attribute_input: {
                  $set: '',
                },
                valid: {
                  $set: false,
                }
              }
            }
          }
        })
      });
    } else {
      // Matches a species - use species data from taxonomy
      this.setState({
        hawkcountSpecies: update(this.state.hawkcountSpecies, {
          [hcSpeciesId]: {
            dunkadooSpecies: {
              [refIndex]: {
                species_name: {
                  $set: value,
                },
                species_id: {
                  $set: speciesMatch.id,
                },
                species_attribute: {
                  $set: null,
                },
                species_attribute_input: {
                  $set: '',
                },
                valid: {
                  $set: true,
                }
              }
            }
          }
        })
      }, () => {
        this.markCompetingRefs();
      });
    }
  }

  selectAttribute(event, hcSpeciesId, refIndex) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      hawkcountSpecies: update(this.state.hawkcountSpecies, {
        [hcSpeciesId]: {
          dunkadooSpecies: {
            [refIndex]: {
              species_attribute: {
                $set: value === '' ? null : value,
              },
              species_attribute_input: {
                $set: value,
              }
            }
          }
        }
      })
    }, () => {
      this.markCompetingRefs();
    });
  }

  updateConditions(hcField, setting, event) {
    const { adminSchema } = this.props;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (setting === 'project_field') {
      this.setState({
        conditionFields: update(this.state.conditionFields, {
          [hcField]: {
            [setting]: {
              $set: value,
            },
            lookup: {
              $set: ['radio', 'list'].indexOf(adminSchema.metadata_schema[value].type) > -1,
            }
          }
        })
      });
    } else {
      this.setState({
        conditionFields: update(this.state.conditionFields, {
          [hcField]: {
            [setting]: {
              $set: value,
            }
          }
        })
      });
    }
  }

  updateLookupMap(hcField, projectField, input, output) {
    const currentLookup = cloneDeep(this.state.conditionFields[hcField].lookup_data);
    const checkExistingInput = find(currentLookup, {input});
    const checkExistingOutput = find(currentLookup, {output});

    if (checkExistingInput) {
      remove(currentLookup, (o) => { return o.input === input; });
    }

    if (checkExistingOutput) {
      remove(currentLookup, (o) => { return o.output === output; });
    }

    currentLookup.push({input, output});

    this.setState({
      conditionFields: update(this.state.conditionFields, {
        [hcField]: {
          lookup_data: {
            $set: currentLookup,
          }
        }
      })
    });
  }

  updateSyncTime(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: target.value,
      [name + 'Valid']: moment(target.value).isValid(),
    });
  }

  uploadCrossref() {
    const { sendHawkCountCrossref, hawkcountSpeciesSpecies, workingProject } = this.props;

    let crossref = [];

    Object.keys(this.state.hawkcountSpecies).forEach((thisHawkCountSpeciesID) => {
      const thisSpecies = this.state.hawkcountSpecies[thisHawkCountSpeciesID];

      thisSpecies.dunkadooSpecies.forEach((thisSpeciesRef) => {
        const currentCrossref = find(hawkcountSpeciesSpecies, {
          species_id: thisSpeciesRef.species_id,
          species_attribute: thisSpeciesRef.species_attribute,
        });

        crossref.push({
          id: currentCrossref ? currentCrossref.id : null,
          hawkcount_site_id: thisSpecies.hawkcount_site_id,
          taxonomy_id: thisSpecies.taxonomy_id,
          species_id: thisSpeciesRef.species_id,
          species_attribute: thisSpeciesRef.species_attribute,
          hawkcount_species_id: thisSpecies.id,
        });
      });
    });

    crossref = crossref.filter((o) => {
      return !isNull(o.id);
    });

    sendHawkCountCrossref(workingProject, {crossref});
  }

  uploadConditions() {
    const { sendHawkCountConditions, workingProject } = this.props;

    sendHawkCountConditions(workingProject, {conditions: this.state.conditionFields});
  }

  toggleActive(event) {
    const { sendHawkCountActive, hawkcountSite } = this.props;

    event.preventDefault();

    sendHawkCountActive(hawkcountSite.id);
  }

  forceSync(event) {
    const { sendHawkCountForceSync, hawkcountSite, workingProject } = this.props;
    const { resyncStart, resyncEnd } = this.state;

    event.preventDefault();

    sendHawkCountForceSync(workingProject, {resyncStart, resyncEnd});
  }

  render() {
    const { hawkcountSite, hawkcountSpecies, adminSchema, taxonomyLookup, taxonomyIndex } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2
          className={cx('review-header')} >
          HawkCount Integration Configuration
        </h2>
        {!(this.props.workingProject in this.props.hawkcountLandscape) &&
          <h3>No Integration Configured for This Project</h3>
        }
        {(this.props.workingProject in this.props.hawkcountLandscape) &&
          <div>
            <div
              className={cx('project-comparison')} >
              <div>
                <h3>
                  Dunkadoo
                </h3>
                <h4>
                  Name
                </h4>
                <p>
                  {adminSchema.name}
                </p>
                <h4>
                  Timezone
                </h4>
                <p>
                  {adminSchema.timezone}
                </p>
                <h4>
                  ID
                </h4>
                <p>
                  {adminSchema.id}
                </p>
              </div>
              <div>
                <h3>
                  HawkCount
                </h3>
                <h4>
                  Name
                </h4>
                <p>
                  {hawkcountSite.name}
                </p>
                <h4>
                  Timezone
                </h4>
                <p>
                  {hawkcountSite.timezone}
                </p>
                <h4>
                  ID
                </h4>
                <p>
                  {hawkcountSite.site_id}
                </p>
              </div>
            </div>
            <h3>
              Connection Status
            </h3>
            <ul>
              <li>
                Disable before making changes.
              </li>
              <li>
                Save work before enabling.
              </li>
              <li>
                Changes to already underway projects may have unpredictable results and will not correct past submissions to HawkCount. Only changes to the data will trigger changes to HawkCount.
              </li>
            </ul>
            <div
              className={cy('public-show-toggle', 'centered-control')}
              onClick={this.toggleActive} >
              <input
                id="active"
                type="checkbox"
                name="active"
                className={cy('public-show-toggle-checkbox')}
                checked={this.props.hawkcountSite.active} />
              <label
                className={cy('public-show-toggle-label')}
                htmlFor="public" >
                <span
                  className={cy('public-show-toggle-inner')} />
                <span
                  className={cy('public-show-toggle-switch')} />
              </label>
            </div>
            <div>
              <h3>
                Species
              </h3>
              <ul>
                <li>
                  Each species-attribute combination from the Dunkadoo taxonomy may only be selected once.
                </li>
                <li>
                  Leave a species name field empty if that species shouldn&apos;t sync.
                </li>
                <li>
                  If more than one Dunkadoo species/attribute combination need to sync to the same HawkCount species, enter the first match then press &apos;Save Crossref.&apos; Once the crossref has saved, an additional box will appear to add additional matches.
                </li>
                <li>
                  Depending on the site, a taxonomy may or may not contain an attribute labeled &quot;Unknown&quot; or &quot;All&quot; for each species with a corresponding attribute in HawkCount. This presents problems when counters input observations with a mixture of &quot;Unknown&quot; and without an attribute. To manage this, be sure to include both.
                </li>
                <li>
                  A red checkmark will indicate that a selection is not valid. If you don&apos;t want to sync a species, you can safely ignore this.
                </li>
              </ul>
              {Object.keys(this.state.hawkcountSpecies).map((thisHCSpeciesId) => {
                const thisHCSpecies = this.state.hawkcountSpecies[thisHCSpeciesId];
                return (
                  <div
                    key={thisHCSpeciesId}
                    className={cx('species-row-container')} >
                    <h4>
                      {thisHCSpecies.name} - {thisHCSpecies.adesc} ({thisHCSpecies.attrib})
                    </h4>
                    {this.state.hawkcountSpecies[thisHCSpeciesId].dunkadooSpecies.map((thisRef, thisRefId) => {
                      return (
                        <div
                          key={thisRefId}
                          className={cx('species-row-selections')} >
                          <input
                            list="project_taxonomy"
                            name={'crossref_species_' + String(thisHCSpeciesId)}
                            value={thisRef.species_name}
                            onChange={(event) => {
                              this.selectSpecies(event, thisHCSpeciesId, thisRefId);
                            }} />
                          <select
                            name={'crossref_attribute_' + String(thisHCSpeciesId)}
                            id={'crossref_attribute_' + String(thisHCSpeciesId)}
                            value={thisRef.species_attribute_input}
                            onChange={(event) => {
                              this.selectAttribute(event, thisHCSpeciesId, thisRefId);
                            }} >
                            <option key="empty" value="" />
                            {(thisRef.species_id && (taxonomyLookup[thisRef.species_id].attribute && taxonomyLookup[thisRef.species_id].attribute.length > 0)) && taxonomyLookup[thisRef.species_id].attribute.map((thisAttribute) => {
                              return (
                                <option
                                  key={thisAttribute}
                                  value={thisAttribute} >
                                  {thisAttribute}
                                </option>
                              );
                            })}
                          </select>
                          {thisRef.valid ? (
                            <div
                              className={cx('valid-species')} >
                              &#10004;
                            </div>
                          ) : (
                            <div
                              className={cx('invalid-species')} >
                              &#10008;
                            </div>
                          )}
                          {thisRef.conflict &&
                            <div
                              className={cx('invalid-species')} >
                              Conflict
                            </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <datalist id="project_taxonomy">
                {taxonomyIndex.map((thisSpecies) => {
                  return (
                    <option key={thisSpecies.id} value={thisSpecies.common_name} />
                  );
                })}
              </datalist>
              <button
                onClick={this.uploadCrossref} >
                Save Crossref
              </button>
            </div>
            <div>
              <h3>
                Conditions
              </h3>
              <ul>
                <li>
                  Enable questions to make them sync.
                </li>
                <li>
                  Changes to already underway projects may have unpredictable results and will not correct past submissions to HawkCount. Only changes to the data will trigger changes to HawkCount.
                </li>
                <li>
                  <strong>Dunkadoo &#8594; HawkCount</strong>
                  <ul>
                    <li>
                      <strong>number/text &#8594; number/string/percentage:</strong> Sends value.
                    </li>
                    <li>
                      <strong>number/text &#8594; radio:</strong> Sends value with no validation. (May produce unexpected results.)
                    </li>
                    <li>
                      <strong>radio &#8594; radio (With Translate):</strong> Select matching values. The values in parentheses are the actual values that get sent to HawkCount.
                    </li>
                    <li>
                      <strong>radio &#8594; radio (Without Translate):</strong> Sends the value entered in Dunkadoo. If responses in Dunkadoo match expected responses in HawkCount, unselect &quot;Translate Options Questions&quot;. (e.g. NNW &#8594; NNW)
                    </li>
                    <li>
                      <strong>radio &#8594; number/string/percentage:</strong> Type the corresponding value for each response.
                    </li>
                    <li>
                      <strong>checkbox &#8594; radio/number/string/percentage:</strong> Sends a comma delimited join of the list of selections.
                    </li>
                  </ul>
                </li>
              </ul>
              {Object.keys(this.state.conditionFields).sort().map((thisConditionFieldName) => {
                const thisConditionField = this.state.conditionFields[thisConditionFieldName];
                return (
                  <div
                    key={thisConditionFieldName}
                    className={cx('species-row-container')} >
                    <h4>
                      {thisConditionField.data.label} ({thisConditionFieldName})
                    </h4>
                    <div
                      className={cx('question-comparison')} >
                      <div>
                        <div
                          className={cx('question-comparison-header')} >
                          Dunkadoo
                        </div>
                        <div
                          className={cx('question-comparison-header')} >
                          HawkCount
                        </div>
                      </div>
                      <div>
                        <div>
                          {thisConditionField.project_field ? adminSchema.metadata_schema[thisConditionField.project_field].type : ''}
                        </div>
                        <div>
                          {questionTypesLookup[thisConditionField.question_type]}
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx('configure-field-container')} >
                      <label
                        htmlFor={'condition_enabled_' + String(thisConditionFieldName)}
                        className={cx('prompt')} >
                        Enabled
                      </label>
                      <input
                        id={'condition_enabled_' + String(thisConditionFieldName)}
                        className={cx('input-center')}
                        name={'condition_enabled_' + String(thisConditionFieldName)}
                        type="checkbox"
                        checked={thisConditionField.enabled}
                        onChange={event => (
                          this.updateConditions(thisConditionFieldName, 'enabled', event)
                        )} />
                    </div>
                    <div
                      className={cx('configure-field-container')} >
                      <label
                        htmlFor={'condition_lookup_' + String(thisConditionFieldName)}
                        className={cx('prompt')} >
                        Dunkadoo Project Field
                      </label>
                      <select
                        id={'condition_lookup_' + String(thisConditionFieldName)}
                        className={cx('input-center')}
                        name={'condition_lookup_' + String(thisConditionFieldName)}
                        value={thisConditionField.project_field || ''}
                        onChange={event => (
                          this.updateConditions(thisConditionFieldName, 'project_field', event)
                        )} >
                        <option key="empty" value="" />
                        {Object.keys(adminSchema.metadata_schema).sort().map((thisProjectQuestionField) => {
                          return (
                            <option
                              key={thisProjectQuestionField}
                              value={thisProjectQuestionField} >
                              {adminSchema.metadata_schema[thisProjectQuestionField].prompt.slice(0, Math.min(adminSchema.metadata_schema[thisProjectQuestionField].prompt.length, 60))} ({thisProjectQuestionField})
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {(thisConditionField.project_field && ['radio', 'list'].indexOf(adminSchema.metadata_schema[thisConditionField.project_field].type) > -1) &&
                      <div
                        className={cx('configure-field-container')} >
                        <label
                          htmlFor={'condition_lookup_' + String(thisConditionFieldName)}
                          className={cx('prompt')} >
                          Translate Options Question
                        </label>
                        <input
                          id={'condition_lookup_' + String(thisConditionFieldName)}
                          className={cx('input-center')}
                          name={'condition_lookup_' + String(thisConditionFieldName)}
                          type="checkbox"
                          checked={thisConditionField.lookup}
                          onChange={event => (
                            this.updateConditions(thisConditionFieldName, 'lookup', event)
                          )} />
                      </div>
                    }
                    {((thisConditionField.lookup && thisConditionField.question_type === 'D') && (thisConditionField.options && (thisConditionField.project_field && ['radio', 'list'].indexOf(adminSchema.metadata_schema[thisConditionField.project_field].type) > -1))) &&
                      <div
                        className={cx('present-options')} >
                        {thisConditionField.options.map((thisOption) => {
                          const findInput = find(thisConditionField.lookup_data, {output: thisOption.value});
                          const selectedInput = findInput ? findInput.input : '';

                          return (
                            <div
                              key={thisOption.value} >
                              <select
                                id={'condition_map_' + String(thisConditionFieldName)}
                                className={cx('input-center')}
                                name={'condition_map_' + String(thisConditionFieldName)}
                                value={selectedInput}
                                onChange={event => (
                                  this.updateLookupMap(thisConditionFieldName, thisConditionField.project_field, event.target.value, thisOption.value)
                                )} >
                                <option key="empty" value="" />
                                {adminSchema.metadata_schema[thisConditionField.project_field].options.map((thisSelection) => {
                                  return (
                                    <option
                                      key={thisSelection.text}
                                      value={thisSelection.text} >
                                      {thisSelection.text}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                htmlFor={'condition_map_' + String(thisConditionFieldName)}
                                className={cx('prompt')} >
                                {thisOption.desc} ({thisOption.value})
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    }
                    {((thisConditionField.lookup && thisConditionField.question_type !== 'D') && thisConditionField.project_field) &&
                      <div
                        className={cx('present-options')} >
                        {adminSchema.metadata_schema[thisConditionField.project_field].options.map((thisProjectOption) => {
                          const findOutput = find(thisConditionField.lookup_data, {input: thisProjectOption.text});
                          const selectedOutput = findOutput ? findOutput.output : '';

                          return (
                            <div
                              key={thisProjectOption.text} >
                              <input
                                id={'condition_map_' + String(thisConditionFieldName)}
                                className={cx('input-center')}
                                name={'condition_map_' + String(thisConditionFieldName)}
                                value={selectedOutput}
                                onChange={event => (
                                  this.updateLookupMap(thisConditionFieldName, thisConditionField.project_field, thisProjectOption.text, event.target.value)
                                )} />
                              <label
                                htmlFor={'condition_map_' + String(thisConditionFieldName)}
                                className={cx('prompt')} >
                                {thisProjectOption.text}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    }
                  </div>
                );
              })}
              <button
                onClick={this.uploadConditions} >
                Save Conditions
              </button>
            </div>
            <div
              className={cx('resync-controls')} >
              <h3>
                Force Resync
              </h3>
              <ul>
                <li>
                  This tool fixes a very specific problem - changes were made to the above configuration that are not reflected in data that was already sent. Clearing these records and resubmitting the data will overwrite the incorrect data with values generated using the above configuration.
                </li>
                <li>
                  Disable connection first. You can safely enable the connection again after hitting &quot;Delete Sync Records&quot; below and getting a confirmation message.
                </li>
                <li>
                  This will remove Dunkadoo&apos;s records that HawkCount submissions were made for the project between the start and end times specified below. This causes the sync system to think that Observations and Metadata have been newly created.
                </li>
                <li>
                  When data gets resent, it will overwrite the data already submitted for that period.
                  <ul>
                    <li>
                      Observations or Metadata submitted to an hour that now only exists on HawkCount will not be overwritten. To make this data go away, it is better to edit that data without forcing a resync. Because the records will still be present in Dunkadoo, the system will know to send changes for those periods.
                    </li>
                    <li>
                      If manual changes have been made in HawkCount after the original submission was made, they will be overwritten.
                    </li>
                  </ul>
                </li>
                <li>
                  Times are stored in the database in UTC. Though any timezone may be used in the times below, be conscious of the project timezone.
                </li>
                <li>
                  ISO 8601 timestamps with timezone can be generated using this tool: <a href="http://www.timestampgenerator.com/">Timestamp Generator</a>
                </li>
                <li>
                  Observations and Metadata will be resent at the next scheduled sync (:00, :15, :30, :45).
                </li>
              </ul>
              <div>
                <label
                  htmlFor="resyncStart">
                  Start Time<br />(ISO 8601 with Timezone)
                </label>
                <div>
                  <input
                    id="resyncStart"
                    name="resyncStart"
                    type="text"
                    value={this.state.resyncStart}
                    onChange={this.updateSyncTime} />
                  {this.state.resyncStartValid ? (
                    <div
                      className={cx('valid-species')} >
                      &#10004;
                    </div>
                  ) : (
                    <div
                      className={cx('invalid-species')} >
                      &#10008;
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="resyncEnd">
                  End Time<br />(ISO 8601 with Timezone)
                </label>
                <div>
                  <input
                    id="resyncEnd"
                    name="resyncEnd"
                    type="text"
                    value={this.state.resyncEnd}
                    onChange={this.updateSyncTime} />
                  {this.state.resyncEndValid ? (
                    <div
                      className={cx('valid-species')} >
                      &#10004;
                    </div>
                  ) : (
                    <div
                      className={cx('invalid-species')} >
                      &#10008;
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={this.forceSync} >
                Delete Sync Records
              </button>
            </div>
          </div>
        }
      </div>
    );
  }
}

IntegrationHawkCount.propTypes = {
  gatherHawkCount: PropTypes.func.isRequired,
  sendHawkCountCrossref: PropTypes.func.isRequired,
  updateWorkingTaxonomy: PropTypes.func.isRequired,
  sendHawkCountActive: PropTypes.func.isRequired,
  hawkcountLandscape: PropTypes.object.isRequired,
  hawkcountSite: PropTypes.object,
  hawkcountConditionFields: PropTypes.array,
  hawkcountConditionLookup: PropTypes.array,
  hawkcountSpecies: PropTypes.array,
  hawkcountSpeciesSpecies: PropTypes.array,
  workingTaxonomy: PropTypes.number,
  workingOrg: PropTypes.number,
  workingProject: PropTypes.number,
  taxonomyIndex: PropTypes.array,
  taxonomyLookup: PropTypes.object,
  taxonomySchema: PropTypes.object,
  adminSchema: PropTypes.object.isRequired,
  contextHelp: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    hawkcountLandscape: state.integrations.hawkcountLandscape,
    hawkcountSite: state.integrations.hawkcountSite,
    hawkcountConditionFields: state.integrations.hawkcountConditionFields,
    hawkcountConditionLookup: state.integrations.hawkcountConditionLookup,
    hawkcountSpecies: state.integrations.hawkcountSpecies,
    hawkcountSpeciesSpecies: state.integrations.hawkcountSpeciesSpecies,
    workingOrg: state.admin.workingOrg,
    workingProject: state.admin.workingProject,
    workingTaxonomy: state.admin.workingTaxonomy,
    taxonomyIndex: state.admin.activeTaxonomy.index,
    taxonomyLookup: state.admin.activeTaxonomy.lookup,
    taxonomySchema: state.admin.organizations[state.admin.workingOrg].TaxonomyLookup[state.admin.workingTaxonomy],
    adminSchema: state.admin.adminSchemas[state.admin.workingProject],
    contextHelp: state.admin.contextHelp,
  };
}

export default connect(mapStateToProps, { gatherHawkCount, sendHawkCountCrossref, sendHawkCountConditions, sendHawkCountActive, sendHawkCountForceSync, updateWorkingTaxonomy })(IntegrationHawkCount);
