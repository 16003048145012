import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import values from 'lodash/values';
import uniq from 'lodash/uniq';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactHighcharts from 'react-highcharts';
import moment from 'moment-timezone';
import { getProjectOverview } from '../actions/admin';
import classNames from '../utils/classNames.js';
import styles from 'css/components/schema';

const cx = classNames.bind(styles);

const dayTypes = ['observation_days', 'metadata_days'];

class ProjectManager extends Component {
  constructor(props) {
    super(props);

    this.updateTooltip = this.updateTooltip.bind(this);
    this.clearTooltip = this.clearTooltip.bind(this);
    this.generateUserComp = this.generateUserComp.bind(this);

    this.state = {
      calendarTooltipCount: '',
      calendarTooltipObservation: '',
      calendarTooltipMetadata: '',
      calendarTooltipDay: '',
      calendarTooltipPos: [-9999, -9999],
      userCompChart: false,
    };
  }

  componentWillMount() {
    const { getProjectOverview } = this.props;
    getProjectOverview(this.props.projectId, 'overview');
  }

  componentWillReceiveProps(nextProps) {
    const { getProjectOverview } = this.props;

    if (this.props.projectId !== nextProps.projectId) {
      getProjectOverview(nextProps.projectId, 'overview');
    }

    if (Object.keys(this.props.projectOverview.user_count).length === 0 && Object.keys(nextProps.projectOverview.user_count).length > 0) {
      this.generateUserComp(nextProps.projectOverview.user_count);
    }
  }

  updateTooltip(event) {
    this.setState({
      calendarTooltipCount: parseInt(event.target.dataset.count, 10),
      calendarTooltipObservation: parseInt(event.target.dataset.observation, 10),
      calendarTooltipMetadata: parseInt(event.target.dataset.metadata, 10),
      calendarTooltipDay: event.target.dataset.day,
      calendarTooltipPos: [event.pageX, event.pageY],
    });
  }

  clearTooltip(event) {
    this.setState({
      calendarTooltipCount: 0,
      calendarTooltipObservation: 0,
      calendarTooltipMetadata: 0,
      calendarTooltipDay: '',
      calendarTooltipPos: [-9999, -9999],
    });
  }

  componentDidUpdate() {
    if ('obsHeatmap' in this) {
      const dayRects = document.querySelectorAll('[data-count]');
      if ('forEach' in dayRects) {
        dayRects.forEach((thisday) => {
          thisday.addEventListener('mouseover', this.updateTooltip, false);
          thisday.addEventListener('mouseout', this.clearTooltip, false);
        });
      }
    }
  }

  componentWillUnmount() {
    if ('obsHeatmap' in this) {
      const dayRects = document.querySelectorAll('[data-count]');
      if ('forEach' in dayRects) {
        dayRects.forEach((thisday) => {
          thisday.removeEventListener('mouseover', this.updateTooltip, false);
          thisday.removeEventListener('mouseout', this.clearTooltip, false);
        });
      }
    }
  }

  generateUserComp(userCount) {
    const authorSeries = Object.keys(userCount).map((thisAuthor) => {
      return {
        name: userCount[thisAuthor].display_name,
        y: userCount[thisAuthor].count,
      };
    });

    const userCompChartConfig = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 300
        },
        title: {
            text: null
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Submissions',
            colorByPoint: true,
            data: authorSeries
        }]
    };

    const userCompChart = (
      <ReactHighcharts config={userCompChartConfig} isPureConfig ref={(chart) => { this.userChart = chart; }} />
    );

    this.setState({
      userCompChart,
    });
  }

  render() {
    const { adminSchema, projectActivity, projectOverview, projectId } = this.props;

    const observationHeat = {};
    let maxValue = 0;

    const allDays = uniq([...Object.keys(projectOverview.observation_days), ...Object.keys(projectOverview.metadata_days)]);

    allDays.forEach((thisDay) => {
      observationHeat[thisDay] = {
          dateRaw: moment(thisDay),
          date: thisDay,
          count: 0,
          observation_days: 0,
          metadata_days: 0,
        };
    });

    dayTypes.forEach((dayType) => {
      Object.keys(projectOverview[dayType]).forEach((thisDay) => {
        observationHeat[thisDay].count += projectOverview[dayType][thisDay].count;
        observationHeat[thisDay][dayType] = projectOverview[dayType][thisDay].count;
        // Keep track of max count for scaling
        if (observationHeat[thisDay].count > maxValue) {
          maxValue = observationHeat[thisDay].count;
        }
      });
    });

    const firstDate = moment(adminSchema.start_date);
    const lastDate = moment.min(moment().tz(adminSchema.timezone).startOf('day'), moment(adminSchema.end_date));
    const elapsedDays = lastDate.diff(firstDate, 'days') + 1;

    return (
      <div
        className={cx('container')} >
        <h2>{projectActivity.name}</h2>
        {(allDays.length > 0 && elapsedDays > 1) &&
          <div>
            <h3
              className={cx('overview-section-head')}>
              Submission Calendar
            </h3>
            <div
              className={cx('context-help')} >
              This calendar shows when data was collected for this project. If you hover your mouse over the squares, a message will appear with the date and types of data that were collected on that day.
            </div>
            <div
              className={cx('calendar-tooltip')}
              style={{
                position: 'fixed',
                top: this.state.calendarTooltipPos[1],
                left: this.state.calendarTooltipPos[0],
              }} >
              {(this.state.calendarTooltipObservation === 1) &&
                <div
                  className={cx('calendar-tooltip-detail')} >
                  {this.state.calendarTooltipObservation} Observation
                </div>
              }
              {(this.state.calendarTooltipObservation > 1) &&
                <div
                  className={cx('calendar-tooltip-detail')} >
                  {this.state.calendarTooltipObservation} Observations
                </div>
              }
              {(this.state.calendarTooltipMetadata > 0) &&
                <div
                  className={cx('calendar-tooltip-detail')} >
                  {this.state.calendarTooltipMetadata} Metadata
                </div>
              }
              <div
                className={cx('calendar-tooltip-detail')} >
                {this.state.calendarTooltipDay}
              </div>
            </div>
            <div
              className={cx('react-calendar-heatmap-container')} >
              <CalendarHeatmap
                startDate={firstDate.toDate()}
                endDate={lastDate.toDate()}
                showOutOfRangeDays={false}
                values={values(observationHeat)}
                classForValue={(value) => {
                  if (!value) {
                    return 'obsheat-color-empty';
                  }
                  const normalized = parseInt((value.count / maxValue) * 10, 10);
                  return `obsheat-color-scale-${normalized}`;
                }}
                titleForValue={(value) => { return value ? `${value.count} Reports on ${value.dateRaw.format('MMMM D')}` : null; }}
                tooltipDataAttrs={(value) => {
                  let dataTip = null;
                  let dataDay = null;
                  let dataObservation = null;
                  let dataMetadata = null;
                  if (value && value.count) {
                    dataTip = value.count;
                    dataObservation = value.observation_days;
                    dataMetadata = value.metadata_days;
                    dataDay = `${value.dateRaw.format('MMMM D')}`;
                  }
                  return {'data-count': dataTip, 'data-observation': dataObservation, 'data-metadata': dataMetadata, 'data-day': dataDay };
                }}
                ref={(ref) => { this.obsHeatmap = ref; }} />
            </div>
          </div>
        }
        {Object.keys(projectOverview.user_count).length > 0 &&
          <div>
            <h3
              className={cx('overview-section-head')}>
              Contributors
            </h3>
            <div
              className={cx('context-help')} >
              This chart shows which users have submitted data to this project. The sections on the graph correspond to how many data points each user has submitted.
            </div>
            <div
              className={cx('chart-user-comp')} >
              {this.state.userCompChart}
            </div>
          </div>
        }
      </div>
    );
  }
}

ProjectManager.propTypes = {
  getProjectOverview: PropTypes.func,
  projectOverview: PropTypes.object,
  adminSchema: PropTypes.object,
  projectActivity: PropTypes.object,
  projectId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    projectOverview: state.admin.projectOverview,
    adminSchema: state.admin.adminSchemas[state.admin.workingProject],
    projectActivity: state.admin.projectActivity[state.admin.workingProject],
    projectId: state.admin.workingProject,
  };
}

export default connect(mapStateToProps, { getProjectOverview })(ProjectManager);
