import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Fuse from 'fuse.js';
import Autosuggest from 'react-autosuggest';
import classNames from '../utils/classNames.js';
import styles from 'css/components/speciesbuttonadder';
import { pushSpeciesButtons, pushFocusButtons } from '../actions/admin';

const cx = classNames.bind(styles);

const minSearchCharacters = 2;
const maxSearchResults = 10;

// These are the settings for the fuzzy search
// Weights are provided to prioritize common_name
const fuseOptions = {
  shouldSort: true,
  threshold: 0.25,
  location: 0,
  distance: 100,
  maxPatternLength: 20,
  minMatchCharLength: minSearchCharacters,
  keys: [{
    name: 'common_name',
    weight: 0.7
  }, {
    name: 'code',
    weight: 0.3
  }]
};

class SpeciesButtonAdder extends Component {
  constructor(props) {
    super(props);

    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);

		this.state = {
      currentTaxonomy: props.taxonomyId,
      value: '',
      suggestions: [],
      speciesSearch: new Fuse(props.activeTaxonomyIndex, fuseOptions),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.currentTaxonomy !== nextProps.taxonomyId) {
      this.setState({
        currentTaxonomy: nextProps.taxonomyId,
        speciesSearch: new Fuse(nextProps.activeTaxonomyIndex, fuseOptions),
      });
    }
  }

  // Use Fuse.js to search through the catalog for fuzzy matches
  getSuggestions(value) {
    const { buttonGroup, speciesButtonList, focusButtonList } = this.props;

    const inputLength = value.length;

    // Don't search until the user has entered a few characters
    if (inputLength > minSearchCharacters) {
      let searchResults = this.state.speciesSearch.search(value);

      // Only display a maximum number of results
      searchResults = searchResults.slice(0, Math.min(maxSearchResults, searchResults.length));

      searchResults = searchResults.filter((thisSpecies) => {
        if (buttonGroup === 'species_list') {
          return speciesButtonList.indexOf(thisSpecies.id) < 0;
        } else {
          return focusButtonList.indexOf(thisSpecies.id) < 0;
        }
      });

      return searchResults;
    }

    return [];
  }

  // The search box is a controlled input
  onSearchInput(event, { newValue }) {
    this.setState({
      value: newValue
    });
  }

  // Generate a value to display to the user that combines that common name and code
  getSuggestionValue(suggestion) {
    const commonName = suggestion.common_name;
    const code = suggestion.code;
    return commonName + ' (' + code + ')';
  }

  // Display the search result within the results list
  renderSuggestion(suggestion) {
    return (
      <div
        className={cx('search-suggestion')} >
        <div
          className={cx('suggestion-names')} >
          <div
            className={cx('suggestion-item-name')} >
            {suggestion.common_name}
          </div>
          <div
            className={cx('suggestion-variation-name')} >
            {suggestion.code}
          </div>
        </div>
      </div>
    );
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  // Scroll to the suggestion
  // If the category is currently closed, we have to open it first
  onSuggestionSelected(event, { suggestion }) {
    const { buttonGroup, pushSpeciesButtons, pushFocusButtons } = this.props;

    // Prevent unintended actions for the speciesButton
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      value: '',
      suggestions: [],
    }, () => {
      if (buttonGroup === 'species_list') {
        pushSpeciesButtons(suggestion.id);
      } else {
        pushFocusButtons(suggestion.id);
      }
    });
  }

  render() {
    const { value, suggestions } = this.state;
    const { site, adminSchemas, organizations, activeTaxonomyIndex, activeTaxonomyLookup, fetching } = this.props;

    const inputProps = {
      placeholder: 'Search to Add Species',
      value,
      onChange: this.onSearchInput
    };

    const autoSuggestTheme = {
      container: cx('search-result-container'),
      containerOpen: cx('search-result-container--open'),
      input: cx('search-result-input'),
      inputOpen: cx('search-result-input--open'),
      inputFocused: cx('search-result-input--focused'),
      suggestionsContainer: cx('search-result-suggestions-container'),
      suggestionsContainerOpen: cx('search-result-suggestions-container--open'),
      suggestionsList: cx('search-result-suggestions-list'),
      suggestion: cx('search-result-suggestion'),
      suggestionFirst: cx('search-result-suggestion--first'),
      suggestionHighlighted: cx('search-result-suggestion--highlighted'),
      sectionContainer: cx('search-result-section-container'),
      sectionContainerFirst: cx('search-result-section-container--first'),
      sectionTitle: cx('search-result-section-title')
    };

    return (
      <div
        className={cx('container')} >
        <div
          className={cx('search-prompt')} >
          <Autosuggest
            theme={autoSuggestTheme}
            suggestions={suggestions}
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps} />
        </div>
      </div>
    );
  }
}

SpeciesButtonAdder.propTypes = {
  buttonGroup: PropTypes.string.isRequired,
  pushSpeciesButtons: PropTypes.func.isRequired,
  speciesButtonList: PropTypes.array.isRequired,
  focusButtonList: PropTypes.array.isRequired,
  adminSchemas: PropTypes.object.isRequired,
  workingProject: PropTypes.number.isRequired,
  taxonomyId: PropTypes.number.isRequired,
  activeTaxonomyLookup: PropTypes.object.isRequired,
  activeTaxonomyIndex: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    speciesButtonList: state.admin.speciesButtons,
    focusButtonList: state.admin.focusButtons,
    adminSchemas: state.admin.adminSchemas,
    workingProject: state.admin.workingProject,
    taxonomyId: state.admin.activeTaxonomy.meta.id,
    activeTaxonomyLookup: state.admin.activeTaxonomy.lookup,
    activeTaxonomyIndex: state.admin.activeTaxonomy.index,
  };
}

export default connect(mapStateToProps, { pushSpeciesButtons, pushFocusButtons })(SpeciesButtonAdder);
