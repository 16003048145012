import { embedService } from '../services';

const fetchData = (organizationSlug, projectSlug, segments) => {
  return embedService.getEmbed(organizationSlug, projectSlug, segments)
  .then((res) => {
    return res.data;
  })
  // Returning [] as a placeholder now so it does not error out when this service
  // fails. We should be handling this in our DISPATCH_REQUEST_FAILURE
  .catch(() => []);
};

export default fetchData;

