import { hot } from 'react-hot-loader';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Loading from 'react-loading';
import classNames from '../utils/classNames.js';
import styles from 'css/components/home';
import Replay from 'components/Replay';
import dunkadooTablet from '../images/Dunkadoo-Tablet.png';

const cx = classNames.bind(styles);

class Home extends Component {
  constructor(props) {
    super(props);

    const replayDisplay = (
      <div
        className={cx('home-loading-container')} >
        <Loading
          type="bars"
          color="#e3e3e3"
          className={cx('loading-object')} />
      </div>
    );

    this.state = {
      replayDisplay,
      loaded: false,
    };
  }

  componentDidMount() {
    const { recentObs } = this.props;

    if (recentObs.length > 0) {
      const replayDisplay = (
        <Replay />
      );

      this.setState({
        replayDisplay,
        loaded: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.recentObs.length > 0) {
      const replayDisplay = (
        <Replay />
      );

      this.setState({
        replayDisplay,
        loaded: true,
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.replayDisplay}
        <div
          className={cx('home-data-container')} >
          <h1>Welcome to Dunkadoo</h1>
          <div
            className={cx('intro-block')} >
            <div
              className={cx('intro-image-container')} >
              <img className={cx('intro-image')} alt="tablet" src={dunkadooTablet} />
            </div>
            <div
              className={cx('intro-text')} >
              <p>Dunkadoo is a non-profit that provides research tools to help scientists collect data in the field built on years of collaboration with biologists doing research ranging from California Condor monitoring in California to bat studies in South Texas to sea bird counts on the coast of Maine. Most field sites are still recording sightings on paper and typing their data into a computer each night after a long day of counting. We knew we could save them time and reduce errors by providing them with enterprise grade apps and cloud tools designed for the demands of their research.</p>
              <p>At Dunkadoo, our mission is to create new tools and technology to make scientists more effective and to bring their message to the public.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  recentObs: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    recentObs: state.landscape.recent,
  };
}

export default hot(module)(connect(mapStateToProps, {})(Home));
