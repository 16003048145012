import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/mapmarker';

const cx = classNames.bind(styles);

class SimpleMarker extends Component {
  render() {
    return (
      <div
        className={cx('simple-container')} />
    );
  }
}

SimpleMarker.propTypes = {
};

export default SimpleMarker;
