/* eslint consistent-return: 0, no-else-return: 0*/
import { polyfill } from 'es6-promise';
import { push } from 'react-router-redux';
import request from 'axios';
import md5 from 'spark-md5';
import * as types from 'types';

const moment = require('moment-timezone');

polyfill();

export function makeAdminRequest(method, siteId, data, action = '') {
  return request[method]('/ui/v1/project/data/' + siteId + (action !== '' ? '/' + action : action), data);
}

export function retrieveRecentSuccess(data) {
  return { type: types.GET_RECENT_SUCCESS, data };
}

export function retrieveRecentFailure(data) {
  return {
    type: types.GET_RECENT_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function retrieveRecent(siteId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeAdminRequest('get', siteId, null, 'recent?t=' + moment().valueOf())
      .then((recentObservations) => dispatch(retrieveRecentSuccess(recentObservations.data)))
      .catch(() => dispatch(retrieveRecentFailure({ siteId: siteId, error: 'Unable to Connect to the Internet' })));
  };
}

export function sendLandscapeRecentRunning() {
  return {
    type: types.LANDSCAPE_RECENT_RUNNING
  };
}

export function startLandscapeRecent() {
  return (dispatch) => {
    dispatch(sendLandscapeRecentRunning());
  };
}

export function sendClearLandscapeRecent() {
  return {
    type: types.CLEAR_LANDSCAPE_RECENT
  };
}

export function clearLandscapeRecent() {
  return (dispatch) => {
    dispatch(sendClearLandscapeRecent());
  };
}

export function sendExitLandscapeRecent() {
  return {
    type: types.EXIT_LANDSCAPE_RECENT
  };
}

export function exitLandscapeRecent() {
  return (dispatch) => {
    dispatch(sendExitLandscapeRecent());
  };
}

export function navigateProject(organizationSlug, projectSlug) {
  return dispatch => {
      dispatch(push('/explore/' + organizationSlug + '/' + projectSlug));
  };
}

export function sendClearProjectData() {
  return {
    type: types.CLEAR_PROJECT_DATA
  };
}

export function clearProject() {
  return (dispatch) => {
    dispatch(sendClearProjectData());
  };
}

export function makeLandscapeRecentRequest(startTime) {
  return request.get('/ui/v1/landscape/recent/' + startTime);
}

export function retrieveLandscapeRecentSuccess(data) {
  return { type: types.GET_LANDSCAPE_RECENT_UPDATE_SUCCESS, data };
}

export function retrieveLandscapeRecentFailure(data) {
  return {
    type: types.GET_LANDSCAPE_RECENT_UPDATE_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function updateLandscapeRecent(startTime) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeLandscapeRecentRequest(startTime)
      .then((recentObservations) => dispatch(retrieveLandscapeRecentSuccess(recentObservations.data)))
      .catch(() => dispatch(retrieveRecentFailure({ startTime, error: 'Unable to Connect to the Internet' })));
  };
}

export function fetchProjectActivity(updateCallback, passThrough) {
  const intervalRequest = {
    url: '/ui/v1/project/admin',
    method: 'get'
  };

  request(intervalRequest)
    .then((response) => {
      updateCallback(response.data, passThrough);
    })
    .catch((error) => {
      console.warn('Failed to Retrieve projectActivity');
      console.warn(error);
    });
}
