import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/textupdates';

const moment = require('moment-timezone');

const cx = classNames.bind(styles);

class TextUpdates extends Component {
  render() {
    const { recentOrdered, taxonomy } = this.props;

    const orderedUpdates = recentOrdered.map((thisObs) => {
      const updateObs = thisObs;
      updateObs.time = moment(thisObs.recorded_at);
      return updateObs;
    });

    orderedUpdates.sort((a, b) => {
      return b.time.valueOf() - a.time.valueOf();
    });

    const outputUpdates = orderedUpdates.map((thisObs) => {
      const momentTime = moment.tz(thisObs.recorded_at, moment.tz.guess());
      const formatTime = momentTime.format('LTS');

      return (
        <div className={cx('row')} key={'textupdate_' + thisObs.uuid} >
          <div
            className={cx('observation')} >
            <div className={cx('count')}>
              <p className={cx('text', 'detail')}>{thisObs.count}</p>
            </div>
            <div className={cx('common')}>
              <p className={cx('text', 'detail')}>{taxonomy[thisObs.species_id].common_name}</p>
            </div>
            <div className={cx('time')}>
              <p className={cx('text', 'detail')}> at {formatTime}</p>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className={cx('container')}>
        <div className={cx('table')}>
          {outputUpdates}
        </div>
      </div>
    );
  }
}

TextUpdates.propTypes = {
  recentOrdered: PropTypes.array.isRequired,
  taxonomy: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    recentOrdered: state.project.recentObservations.ordered,
    taxonomy: state.taxonomy.taxonomy,
  };
}

export default connect(mapStateToProps, { })(TextUpdates);
