import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/boidmarker';

const cx = classNames.bind(styles);

class ReplayProjectMarker extends Component {
  render() {
    const { name, logo, url, $hover } = this.props;

    // Popup org logo and project name - didn't help that much and doesn't work on mobile
    // <div
    // className={cx('active-project-logo', {'active-project-logo-active': $hover, 'active-project-text-only': !logo})} >
    //   {logo &&
    //     <img
    //       src={logo}
    //       alt={name} />
    //   }
    //   <h3>
    //     {name}
    //   </h3>
    // </div>

    return (
      <Link
        to={url}
        className={cx('active-project-point')} >
        <div className={cx('active-project-ripple-one')} />
        <div className={cx('active-project-ripple-two')} />
        <div className={cx('active-project-ripple-three')} />
      </Link>
    );
  }
}

ReplayProjectMarker.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
};

export default ReplayProjectMarker;
