import React from 'react';
import classNames from '../utils/classNames.js';
import { Link } from 'react-router';
import styles from 'css/components/about';

const cx = classNames.bind(styles);

const Terms = () => {
  return (
    <div className={cx('about')}>
      <div className={cx('description', 'legal')}>
<h1><span className={cx('legal-header')}>Service Terms and Conditions</span></h1>

<p><span className={cx('legal-standard')}>Effective date: August 3, 2017</span></p>

<p><span className={cx('legal-standard')}>These Service Terms and
Conditions (“Agreement”) constitute a contract between Dunkadoo, Inc. with
offices at 70 Devon Rd, Bloomfield Hills, MI 48302 (“Dunkadoo”), and you. This
Agreement includes and incorporates the webpage Order Form with which Customer
purchased the Services and any subsequent Order Forms (submitted in written or
electronic form). By accessing or using the Services, you agree to be bound by
this Agreement. If you are entering into this Agreement on behalf of a company,
organization or other entity, you represent that you have such authority to
bind such entity and are agreeing to this Agreement on behalf of such entity.
If you do not have such authority to enter into this Agreement or do not agree
with these terms and conditions, you may not use the Services.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>1.</span><span className={cx('section-contents')}>DEFINITIONS </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.1.</span><span className={cx('section-contents')}>“Dunkadoo Cloud
Services” means any connection and subsequent interactions that the user’s
software, browser, or servers make to Dunkadoo’s Services.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.2.</span><span className={cx('section-contents')}>“Dunkadoo Mobile
Software” shall mean the Company’s mobile applications that are intended to be
installed on tablets and other mobile devices and that interact with the
Software and as may be further described on the applicable Order Form.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.3.</span><span className={cx('section-contents')}>“Dunkadoo WebApp” means
the web portal currently available at https://dunkadoo.org/dashboard which
allows users to manage the software and Customer Data.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.4.</span><span className={cx('section-contents')}>“Customer Data” shall
mean all electronic data or information submitted or transmitted by or on
behalf of Customer or Users to the System.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.5.</span><span className={cx('section-contents')}>“Intellectual Property
Rights” means all patents, registered designs, unregistered designs, design
rights, utility models, semiconductor topography rights, database rights,
copyright and other similar statutory rights, trade mark, service mark and any
know how relating to algorithms, drawings, tests, reports and procedures,
models, manuals, formulae, methods, processes and the like (including
applications for any of the preceding rights) or any other intellectual or
industrial property rights of whatever nature in each case in any part of the
world and whether or not registered or registerable, for the full period and
all extensions and renewals where applicable.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.6.</span><span className={cx('section-contents')}>“Malicious Code” shall
mean viruses, worms, time bombs, Trojan horses and other harmful files,
scripts, agents or programs.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.7.</span><span className={cx('section-contents')}>“Order Form” shall mean
the online ordering documents or descriptions of Services for Customer’s
purchases of Services from Company that are completed in connection with
entering into this Agreement.  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.8.</span><span className={cx('section-contents')}>“Professional Services”
shall mean the implementation, integration, onboarding, consulting, support,
training, customer support and similar services as described in an Order Form. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.9.</span><span className={cx('section-contents')}>“Project Start Date”
shall mean the date mutually agreed upon by the parties following the
completion of an applicable Order Form, upon which date Company shall commence
provision of Professional Services under an Order Form.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.10.</span><span className={cx('section-contents')}>“Services”
shall mean the provision of the System and Professional Services collectively.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.11.</span><span className={cx('section-contents')}>“Software”
means the Dunkadoo Mobile Applications, Dunkadoo Cloud Services, and Dunkadoo
WebApp.  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.12.</span><span className={cx('section-contents')}>“Start
Date” shall mean the date on which Company shall make the System available to
Customer as set forth in an applicable Order Form and as may be updated
pursuant to additional Order Forms. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.13.</span><span className={cx('section-contents')}>“Subscription
Term” shall mean the subscription period set forth in an applicable Order Form,
including any renewals or extensions thereof as set forth in an Order Form or
otherwise agreed to by the parties.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.14.</span><span className={cx('section-contents')}>“System”
shall mean the Software, Applications, forms, reports, associated
documentation, Company’s database, and all software, hardware and systems
accessed or utilized by Company, in connection with providing access to the
Software and Applications to Customer under this Agreement.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>1.15.</span><span className={cx('section-contents')}>“Users”
means any and all Customer employees, clients, contractors or agents who are
authorized by Customer and who have been issued a User ID and password to
access and use the System for Customer’s internal business purposes.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>2.</span><span className={cx('section-contents')}>USE OF THE SYSTEM  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.1.</span><span className={cx('section-contents')}>Company
Responsibilities.  Company shall, during the Subscription Term:</span></p>

<p className={cx('level-three')}><span className={cx('section-label')}>2.1.1.</span><span className={cx('section-contents')}>Provide the System in a
manner consistent with general industry standards reasonably applicable to the
provision thereof;</span></p>

<p className={cx('level-three')}><span className={cx('section-label')}>2.1.2.</span><span className={cx('section-contents')}>Provide basic email or
online forum support to customer; and</span></p>

<p className={cx('level-three')}><span className={cx('section-label')}>2.1.3.</span><span className={cx('section-contents')}>Use commercially
reasonable efforts to make the System available 24 hours a day, seven days a
week, except for planned downtime or any unavailability caused by circumstances
beyond Company’s reasonable control. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.2.</span><span className={cx('section-contents')}>Access to System and
Applications.  Subject to the terms and conditions of this Agreement, Company
grants to Customer a non-exclusive, non-transferable, limited term right,
without the right to sub-license, for Users to access, display and use the
System remotely via the Internet during the Subscription Term solely for
Customer’s business purpose(s) of managing Customer Data on behalf of Customer or,
if applicable, on behalf of Customer’s clients.  In addition, Company grants to
Customer a non-exclusive, non-transferable, limited term right, without the
right to sub-license, for Customer to install the Applications on Customer or
Customer approved mobile devices that are approved for use with the
Applications by Users and to use the Applications solely in connection with the
System during the Subscription Term.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.3.</span><span className={cx('section-contents')}>Dunkadoo will make the
Services available and the Services will perform substantially in accordance
with the description of the services found at https://dunkadoo.org/pricing.
Notwithstanding the foregoing, Dunkadoo reserves the right to suspend
Customer’s (or any User’s) access to the Services: (i) for scheduled or
emergency maintenance, (ii) immediately in the event that Customer breaches any
provision of this Agreement and fails to correct that breach within the
applicable cure period; (iii) as it deems reasonably necessary to respond to
any actual or potential security concern that may affect customers; or (iv)
based on Dunkadoo’s reasonable belief that Customer’s or Users’ use of the
Services is interfering with use by other customers and users or violating
applicable laws, rules, regulations or third party rights.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.4.</span><span className={cx('section-contents')}> Customer
Responsibilities.  Customer is responsible for all activities that occur in
Customer’s account(s), including all Users.  Customer shall: (a) have sole
responsibility for the accuracy, quality, integrity, legality, reliability, and
appropriateness of all Customer Data; (b) prevent unauthorized access to, or
use of, the System, and notify Company promptly of any such unauthorized access
or use; and (c) comply with all applicable laws and regulations in using the
System.  Customer shall not upload to, or store within, the System (and the
Customer Data shall not contain) any personally identifiable information,
including, without limitation, social security numbers, financial account
numbers (i.e. credit card, checking account, savings account, etc.), medical,
employment, or insurance numbers, and passport numbers. For purposes of clarity,
Customer may use personal information required to use the System such as
customer name, email address, and mobile phone number or derived information
such as segments, scores, or lifecycle attributes used to make decisions within
the System.  Customer acknowledges and agrees that Customer and/or Users may be
required to agree to additional terms and/or privacy notices in connection with
their use of the System and Customer is responsible for causing its Users to
agree to such terms and notices.  Customer also acknowledges and agrees that
the operation and use of the Applications requires the transmission of location
information while the Applications are in use and Customer is responsible for
disclosing this functionality to all Users and obtaining their consent to
collect such information.  Customer is responsible for supplying or approving
all equipment and mobile devices that are compatible with the System (including
the Applications) for its and all Users’ use of the System.  If Users do not
accept these terms or notices, Customer and/or Users do not provide appropriate
mobile devices or Users do not enable certain features of their mobile devices
(e.g., location), Users may not be able to access and use the Applications or
the System.  Customer is solely responsible for ensuring compliance with these
requirements.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.5.</span><span className={cx('section-contents')}>Use Guidelines. 
Customer shall use the System solely as contemplated by this Agreement and
shall not: (a) license, sublicense, sell, resell, rent, lease, transfer,
assign, distribute, time share or otherwise commercially exploit or make the
System available to any third party except as contemplated by this Agreement;
(b) send via or store within the System infringing, obscene, threatening,
defamatory, fraudulent, abusive, or otherwise unlawful or tortious material,
including material that is harmful to children or violates third party privacy
rights; (c) send via the System any unsolicited commercial or non-commercial
communication; (d) send via, upload to, or store within the System any Malicious
Code; or (e) attempt to gain unauthorized access to the System or its related
systems or networks.  If Customer is authorizing any clients, contractors or
agents to access the System, Customer is solely responsible for their acts and
omissions and Customer is solely responsible for directly supporting all such
Users.  Further, Customer will not make any representation, guarantee or
warranty to any actual or prospective User regarding the performance or
functional characteristics of the System inconsistent with or beyond those
permitted, in writing, by Company, in its sole discretion.  Company will not be
liable for any such terms and conditions inconsistent with or in addition to
those contained in this Agreement.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.6.</span><span className={cx('section-contents')}>Authorized Use Only.
Customer is responsible for the acts and omissions of all Users. Customer will
implement reasonable controls to ensure that the System is only accessed and
used by the then-currently authorized Users. Customer will take all reasonable
steps to ensure that each User’s access to and use of the System is in
compliance with the terms of this Agreement. Customer will be responsible for
any breaches by Users, and will cooperate with Company in the enforcement of
this Agreement against all third party client, contractor and agent Users.
Company will have the right to immediately discontinue a User’s access to and
use of the System if such User’s acts or omissions would, if they were the acts
or omissions of Customer, be a violation of any of the terms of this Agreement
or otherwise impedes or disrupts any third party’s use of the System. Where
reasonably possible, Company will deliver notice to Customer of the termination
of a User’s access to and use of the System.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.7.</span><span className={cx('section-contents')}>Additional Customer
Obligations.  Customer will: (a) cooperate with Company on all reasonable
requests for information including data, service contract details, and other
information important to an effective implementation of the System; (b)
allocate one internal employee for a specified period of time to assist in
developing and implementing the System; and (c) have in place the appropriate
browser and other software and hardware for accessing the System.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>2.8.</span><span className={cx('section-contents')}>Customer will be fully
responsible for Users’ compliance with this Agreement and any breach of this
Agreement by a User shall be deemed to be a breach by Customer.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>3.</span><span className={cx('section-contents')}>FEES &amp; PAYMENT  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>3.1.</span><span className={cx('section-contents')}>Service Fees.  Company
or one of its resellers will invoice Customer (which may occur online or
through the System) for access and use of the System and for work performed as
set forth in the applicable Order Form or as agreed to in the System.  Company
or one of its resellers will also charge Customer for all renewals as agreed to
by Customer and Customer may have the opportunity to renew System access within
the System or through communication with Dunkadoo.  Except as otherwise
specified in the applicable Order Form, fees are based on the System access and
Services purchased and not actual usage; payment obligations are
non-cancelable; except as expressly set forth in this Agreement, fees paid or
committed to are non-refundable; and the System access and Services purchased
cannot be decreased during the relevant Subscription Term.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>3.2.</span><span className={cx('section-contents')}>If a Customer uses only
Free Services, Dunkadoo will not charge such Customer any Fees for use of such
Free Services or download, installation or use of the Software associated with
Free Services. Such Customer may discontinue using the Free Services at any
time, but must immediately remove any Software from its devices.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>3.3.</span><span className={cx('section-contents')}>Dunkadoo shall invoice
Customer for the increase in the maximum number of Users at the subscription
rate and payment terms specified at https://dunkadoo.org/pricing, which will be
prorated for the remainder of the then applicable subscription Term. For any
future subscription Term, the number of Users and applicable Fees will reflect
any Subscription Upgrades.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>3.4.</span><span className={cx('section-contents')}>Overdue Payments. 
Customer’s failure to pay as set forth herein shall constitute a material
breach of this Agreement and any such payments will accrue interest at the rate
of one and one-half percent (1 1/2%) per month or the highest rate permitted by
law, whichever is less.  If Customer’s account is overdue (except with respect
to charges then under reasonable and good faith dispute), Company may, in
addition to any of its other rights or remedies, suspend Customer’s access to
the System and/or suspend provision of Professional Services until such amounts
are paid in full, and/or upon written notice Company may terminate this
Agreement and any or all outstanding Order Forms, as applicable.  Company’s
rights under this section will be in addition to all other rights and remedies
available to Company upon Customer’s default. </span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>4.</span><span className={cx('section-contents')}>PROPRIETARY RIGHTS</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>4.1.</span><span className={cx('section-contents')}>Reservation of
Rights.   Subject to the limited rights expressly granted hereunder, Company
reserves all rights, title and interest in and to the System, including all
related intellectual property rights.  No rights are granted to Customer
hereunder other than as expressly set forth herein. As between Company and
Customer, Customer exclusively owns all rights, title and interest in and to
all Customer Data and Company is hereby authorized to use the Customer Data to
perform the Services and, subject to the confidentiality obligations set forth
herein, to improve and enhance the System.  Company may use the trademarks and
trade names of Customer solely in connection with its authorized provision of
the System.  Customer shall not (a) modify, copy or create derivative works
based on the System or any portion thereof; or (b) reverse engineer the System
or any portion thereof (except to the extent that such a restriction would be a
breach of applicable law). </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>4.2.</span><span className={cx('section-contents')}>Improvements;
Deliverables.  Company shall own all rights, title and interest, including all
intellectual property rights, in and to any improvements to the System or any
new programs, upgrades, modifications or enhancements developed by Company in
connection with this Agreement or the provision of the System or Services to
Customer, even when refinements and improvements result from Customer’s request
or feedback.  To the extent, if any, that ownership in such refinements and
improvements does not automatically vest in Company by virtue of this Agreement
or otherwise, Customer hereby transfers to Company all rights, title, and
interest which Customer may have, and such transfer is irrevocable,
irreversible and binding on Customer’s successors. Company hereby grants
Customer a worldwide, perpetual, non-exclusive, non-transferable, royalty-free
license to use for its internal business purposes any items developed by
Company for Customer in the performance of Professional Services under an Order
Form that are expressly described as deliverables (“Deliverables”).  Except as
expressly set forth in this Agreement, Company shall retain all ownership
rights to the Deliverables. </span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>5.</span><span className={cx('section-contents')}>CONFIDENTIALITY  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>5.1.</span><span className={cx('section-contents')}>Definition of
Confidential Information.  As used herein, “Confidential Information” means all
confidential and proprietary information of a party (“Disclosing Party”)
disclosed to the other party (“Receiving Party”) that (a) if disclosed orally
is designated as confidential at the time of disclosure, (b) if disclosed in
writing is marked as “Confidential” and/or “Proprietary”, or (c) that
reasonably should be understood to be confidential given the nature of the
information and the circumstances of disclosure, including, without limitation,
the terms and conditions of this Agreement, any Order Form, the Customer Data,
provision of the Services, business and marketing plans, technology and
technical information, product designs, and business processes. 
Notwithstanding the foregoing, each party may disclose the existence and terms
of this Agreement, in confidence, to a potential purchaser of or successor to
any portion of such party’s business resulting from the reorganization,
spin-off, or sale of all or a portion of all of the assets of any business,
division, or group of such party.  Confidential Information (except for
Customer Data) shall not include any information that:  (i) is or becomes
generally known to the public without breach of any obligation owed to the
Disclosing Party; (ii) was known to the Receiving Party prior to its disclosure
by the Disclosing Party without breach of any obligation owed to the Disclosing
Party; (iii) was independently developed by the Receiving Party without breach
of any obligation owed to the Disclosing Party; or (iv) is received from a
third party without breach of any obligation owed to the Disclosing Party.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>5.2.</span><span className={cx('section-contents')}>Confidentiality.  The
Receiving Party shall not disclose or use any Confidential Information of the
Disclosing Party for any purpose outside the scope of this Agreement, except
with the Disclosing Party’s prior written permission.  Notwithstanding the
foregoing, the Receiving Party may disclose such Confidential Information to
those of its employees who need to know such information for purposes of
performing the Services.  The Receiving Party shall use the same degree of care
to protect the Confidential Information as it uses to protect its own
information of a confidential and proprietary nature, but in no event shall it
use less than a reasonable degree of care.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>5.3.</span><span className={cx('section-contents')}>Compelled Disclosure;
Remedies.  If the Receiving Party is compelled by law, an order issued by a
judge or public competent authority to disclose Confidential Information of the
Disclosing Party, it shall provide the Disclosing Party with prior notice of
such compelled disclosure (to the extent legally permitted) and reasonable
assistance, at Disclosing Party’s cost, if the Disclosing Party wishes to
contest the disclosure. If the Receiving Party discloses or uses (or threatens
to disclose or use) any Confidential Information of the Disclosing Party in breach
of confidentiality protections hereunder, the Disclosing Party shall have the
right, in addition to any other remedies available to it, to seek to file a
lawsuit to enjoin such acts. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>5.4.</span><span className={cx('section-contents')}>Customer acknowledges
that Dunkadoo does not wish to receive any Confidential Information from
Customer that is not necessary for Dunkadoo to perform its obligations under
this Agreement, and, unless the parties specifically agree otherwise, Dunkadoo
may reasonably presume that any unrelated information received from Customer is
not confidential or Confidential Information, unless such information is marked
as “Confidential.”</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>6.</span><span className={cx('section-contents')}>DATA PROTECTION</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.1.</span><span className={cx('section-contents')}>In this Section, the
terms “personal data,” “data processor,” “data subject,” “process and
processing” and “data controller” shall be as defined in the applicable Data
Protection Laws.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.2.</span><span className={cx('section-contents')}>For the purposes of the
Data Protection Laws, as between Customer and Dunkadoo, the parties agree that
Customer shall at all times be the data controller and Dunkadoo shall be the
data processor with respect to the processing of Customer Personal Data in
connection with this Agreement.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.3.</span><span className={cx('section-contents')}>By entering into this
Agreement, Customer agrees that Dunkadoo may collect, retain and use certain
Customer Personal Data (which may include, without limitation, names, mobile
telephone numbers, IP addresses and email addresses of Users) in connection
with the Services. As the data controller of such Customer Personal Data,
Customer represents and warrants to Dunkadoo that its provision of personal
data to Dunkadoo and instructions for processing such personal data in
connection with the Services shall comply with all Data Protection Laws. This
shall include (without limitation) ensuring that Customer: (i) has given
adequate notice and made all appropriate disclosures to data subjects regarding
Customer’s and Dunkadoo’s use and disclosure of Customer Personal Data,
including (without limitation) for the provision of the Services; and (ii) has
or obtains all necessary rights, and where applicable, all appropriate and
valid consents from the data subjects to share such personal data with Dunkadoo
and to permit use of Customer Personal Data by Dunkadoo for the purposes of the
provision of the Services and performing its obligations under this Agreement
or as may be required by applicable law, including (without limitation)
notifying the data subject of the transfer of Customer Personal Data outside of
the European Economic Area to countries whose laws they have acknowledged may
provide a lower standard of data protection than exists in the European
Economic Area.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.4.</span><span className={cx('section-contents')}>At the request of Customer,
Dunkadoo and Customer shall negotiate a separate data processing agreement
and/or model contract clauses setting forth each party’s obligations in respect
of any processing of Customer Personal Data, which agreement and/or model
contract clauses will be incorporated herein by reference once executed by the
parties.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.5.</span><span className={cx('section-contents')}>Customer acknowledges
that Dunkadoo is reliant on Customer for direction as to the extent to which Dunkadoo
is entitled to use and process Customer Personal Data. Consequently, Dunkadoo
will not be liable for any claim brought by a data subject to the extent that
such action or omission resulted directly from Customer’s instructions.
Customer undertakes to comply in all respects with any applicable laws,
regulations, standards and guidelines applicable to personal data and shall use
all reasonable endeavors to where possible anonymize personal data sent to Dunkadoo.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.6.</span><span className={cx('section-contents')}>In accordance with
applicable Data Protection Laws, Dunkadoo shall take all commercially reasonable
measures to protect the security and confidentiality of Customer Personal Data
against any accidental or illicit destruction, alteration or unauthorized
access or disclosure to third parties.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>6.7.</span><span className={cx('section-contents')}>Customer may, upon at
least thirty (30) days prior notice, and no more than once per 12 month period,
appoint an independent third party auditor to physically inspect and audit, at
Customer’s sole cost and expense, any facilities owned or controlled by Dunkadoo
in which Customer Personal Data is processed or stored, provided that such
inspection: (i) shall occur on a mutually agreed upon date during Dunkadoo’s
regular business hours; (ii) does not interfere with any of Dunkadoo’s business
operations; and, (iii) does not, in Dunkadoo’s reasonable discretion, create
any risk to the confidentiality, integrity, or availability of any data stored
or processed by Dunkadoo. Prior to any audit, Customer, and any appointed
auditor, must enter into a nondisclosure and confidentiality agreement as may
be required by Dunkadoo.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>7.</span><span className={cx('section-contents')}>WARRANTIES &amp;
DISCLAIMERS</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>7.1.</span><span className={cx('section-contents')}>Customer Warranties. 
Customer represents and warrants that: (a) Customer has all right to provide
the Customer Data to Company and the provision and use of the Customer Data in
connection with this Agreement does not violate any applicable law, rule,
regulation or order; (b) the Customer Data shall not infringe on any copyright,
patent, trade secret or other proprietary right held by any third party; and
(c) Customer shall not use the Services in a manner that violates any
applicable law, rule, regulation or order.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>7.2.</span><span className={cx('section-contents')}>Company Warranties.  For
Customers enrolled in one of the editions of Services requiring purchase, Dunkadoo
represents and warrants that it will not knowingly include, in any Dunkadoo
software released to Users and provided to Customer hereunder, any computer
code or other computer instructions, devices or techniques, including without
limitation those known as viruses, disabling devices, trojans, or time bombs,
that intentionally disrupt, disable, harm, infect, defraud, damage, or
otherwise impede in any manner, the operation of a network, computer program or
computer system or any component thereof, including its security or User data.
If, at any time, Dunkadoo fails to comply with this warranty, Customer may
promptly notify Dunkadoo in writing of any such noncompliance. Dunkadoo will,
within thirty (30) days of receipt of such written notification, either correct
the noncompliance or provide Customer with a plan for correcting the
noncompliance. If the noncompliance is not corrected or if a reasonably
acceptable correction plan is not established during such period, Customer may
terminate this Agreement and receive a refund of any pre-paid but unearned
subscription Fees, prorated on a monthly basis, as its sole and exclusive
remedy for such noncompliance. This provision does not apply to Customer’s use of
Free Services.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>7.3.</span><span className={cx('section-contents')}>Disclaimer.  EXCEPT AS
EXPRESSLY CONTAINED IN THIS AGREEMENT, ALL WARRANTIES, CONDITIONS AND OTHER
TERMS IMPLIED BY STATUTE, COMMON LAW OR IN ANY OTHER WAY, INCLUDING ANY IMPLIED
WARRANTIES AS TO QUALITY, PERFORMANCE, TITLE, NONINFRINGEMENT, MERCHANTABILITY
AND FITNESS FOR A PARTICULAR PURPOSE AND ALL WARRANTIES ARISING OUT OF COURSE
OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE, ARE EXCLUDED FROM THIS AGREEMENT
TO THE FULLEST EXTENT PERMITTED BY LAW. COMPANY DOES NOT WARRANT THAT THE
SYSTEM WILL MEET THE REQUIREMENTS OF CUSTOMER OR ANY USERS OR THAT THE
OPERATION OR USE OF THE SYSTEM WILL BE UNINTERRUPTED OR ERROR FREE.  FURTHER,
COMPANY DISCLAIMS ANY AND ALL WARRANTIES THAT THE SYSTEM, OR CUSTOMER’S USE OF
THE SYSTEM IN CONNECTION WITH ANY CUSTOMER DATA, WILL MEET CUSTOMER’S
REQUIREMENT OR THAT IT COMPLIES WITH ANY OF CUSTOMER’S OBLIGATIONS UNDER ANY
APPLICABLE REGULATORY AUTHORITY AND CUSTOMER IS SOLELY RESPONSIBLE FOR ALL SUCH
OBLIGATIONS.  COMPANY FURTHER DISCLAIMS ALL WARRANTIES, CONDITIONS AND OTHER
TERMS THAT COMPANY HAS ANY KNOWLEDGE OF THE CONTENT OF THE CUSTOMER DATA OR
WHETHER CUSTOMER’S USE OF THE SYSTEM OR TRANSMISSION OF CUSTOMER DATA TO COMPANY
COMPLIES WITH ANY APPLICABLE LAWS, REGULATIONS, RULES OR ORDERS.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>8.</span><span className={cx('section-contents')}>INDEMNIFICATION  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>8.1.</span><span className={cx('section-contents')}>By Company. For
Customers enrolled in one of the editions of Services requiring purchase, Dunkadoo
shall indemnify and hold Customer harmless from liability to third parties
resulting from infringement by the Services of any patent or any copyright or
misappropriation of any trade secret, provided Dunkadoo is promptly notified of
any and all threats, claims and proceedings related thereto and given
reasonable assistance and the opportunity to assume sole control over defense
and settlement; Dunkadoo will not be responsible for any settlement it does not
approve. The foregoing obligations do not apply with respect to portions or
components of the Services (i) not created by Dunkadoo, (ii) resulting in whole
or in part from Customer specifications, (iii) that are modified after delivery
by Dunkadoo, (iv) combined with other products, processes or materials where
the alleged infringement relates to such combination, (v) where Customer
continues allegedly infringing activity after being notified thereof or after
being informed of modifications that would have avoided the alleged
infringement, or (vi) where Customer’s use of Services is not strictly in
accordance with this Agreement and all related Documentation. If Dunkadoo
receives information about an actual or alleged infringement or
misappropriation claim that would be subject to indemnification rights set
forth in this Section, Dunkadoo shall have the option, at its expense, to: (a)
modify the Software to be non-infringing; or (b) obtain for Customer a license
to continue using the Software. If Dunkadoo determines it is not commercially
reasonable to perform either of the above options, then Dunkadoo may at its
option elect to terminate the license for the Services and refund the unearned
portion of any pre-paid subscription Fees, prorated on a monthly basis. THIS
SECTION STATES CUSTOMER’S SOLE AND EXCLUSIVE REMEDY FOR INFRINGEMENT,
MISAPPROPRIATION AND/OR CLAIMS ALLEGING INFRINGEMENT OR MISAPPROPRIATION.
Customer will indemnify Dunkadoo from all damages, costs, settlements,
attorneys’ fees and expenses related to any claim related to (I) infringement
or misappropriation not otherwise subject to Dunkadoo's indemnification obligation
set forth in this section and (II) Customer’s breach relating to user-generated
content and User consents, respectively, Section “Restrictions,” Section “Intellectual
Property Rights; Ownership” or Section “Data Protection.” Dunkadoo’s
obligations under this section do not apply to Customer’s use of Free Services.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>8.2.</span><span className={cx('section-contents')}>By Customer.  Customer
shall defend, indemnify, and hold Company, and its respective officers,
directors, and employees, harmless against any loss, damage, or costs
(including reasonable attorneys’ fees) incurred in connection with a Claim (a)
that is made or brought against Company by a third party alleging that
Company’s use of the Customer Data within the scope of this Agreement infringes
the intellectual property rights of such third party or violates any applicable
law, regulation, rule or order; or (b) arises due to Customer’s breach or
alleged breach of this Agreement. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>8.3.</span><span className={cx('section-contents')}>Procedure.  As an
express condition to the indemnifying party’s obligation under this Section,
the party seeking indemnification must: (a) promptly notify the indemnifying party
in writing of the applicable Claim for which indemnification is sought; and (b)
provide the indemnifying party with all non-monetary assistance, information
and authority reasonably required for the defense and settlement of such Claim.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>9.</span><span className={cx('section-contents')}>LIMITATIONS</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>9.1.</span><span className={cx('section-contents')}>Limitation of
Liability.  IN NO EVENT SHALL EITHER PARTY’S AGGREGATE LIABILITY ARISING OUT OF
OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER
THEORY OF LIABILITY, EXCEED THE AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM
CUSTOMER UNDER THE APPLICABLE ORDER OR SOW IN THE 12 MONTHS PRECEDING THE INCIDENT
GIVING RISE TO LIABILITY. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>9.2.</span><span className={cx('section-contents')}>Exclusion of
Consequential and Related Damages.  IN NO EVENT SHALL EITHER PARTY HAVE ANY
LIABILITY TO THE OTHER PARTY FOR ANY LOST PROFITS OR FOR ANY INDIRECT, SPECIAL,
INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR SPECIAL DAMAGES OF ANY KIND OR NATURE
HOWEVER CAUSED AND, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
LIABILITY, WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
DAMAGES.  FURTHER, COMPANY SHALL NOT HAVE ANY LIABILITY TO CUSTOMER FOR (I) THE
ACTS OR OMISSION OF ANY OR ALL USERS, INCLUDING ANY ACTS OR OMISSIONS OF ANY
CLIENT, CONTRACTOR OR AGENT USERS AUTHORIZED BY CUSTOMER TO USE THE SYSTEM OR
ANY OF THEIR USE OF ANY CUSTOMER DATA OBTAINED THROUGH THE SYSTEM; OR (II) WITH
RESPECT TO THE CONTENT OF THE CUSTOMER DATA OR WHETHER CUSTOMER’S USE OF THE
SYSTEM OR THE TRANSMISSION OF CUSTOMER DATA TO COMPANY COMPLIES WITH ANY
APPLICABLE LAWS, REGULATIONS, RULES OR ORDERS.  CUSTOMER ACKNOWLEDGES AND
AGREES THAT COMPANY DOES NOT, AND HAS NO DUTY TO, INVESTIGATE OR ANALYZE THE
CUSTOMER DATA OR THE CONTENT THEREOF.  THE EXCLUSIONS SET FORTH IN THIS SECTION
WILL NOT APPLY TO ANY LIABILITY ARISING OUT OF OR IN CONNECTION WITH ANY
LIABILITY OF COMPANY WHICH CANNOT BE EXCLUDED UNDER APPLICABLE LAW.</span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>10.</span><span className={cx('section-contents')}>TERM &amp; TERMINATION 
</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>10.1.</span><span className={cx('section-contents')}>Term. 
This Agreement commences on the Effective Date and continues, unless </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>10.2.</span><span className={cx('section-contents')}>terminated
as set forth in this Section or unless either party provides written notice of
non-renewal at least thirty (30) days prior to the applicable anniversary of
the Effective Date, for successive one year periods (the “Term”). Subscriptions
to the System commence on the Start Date and continue for the Subscription Term
specified in the applicable Order Form.   Thereafter, the subscription will be
automatically renewed for an additional Subscription Term to avoid interruption
in service.  Company shall suspend Customer’s access to the System after the
expiration of the then-current Subscription Term if such a renewal has not been
executed. Professional Services shall commence on the Project Start Date and
shall continue until such Professional Services are completed.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>10.3.</span><span className={cx('section-contents')}>Termination
for Cause.  A party may terminate this Agreement for cause upon 30 days’
written notice of a material breach to the other party if such breach remains
uncured at the expiration of such 30 day period.  Upon any termination for
cause by Customer, Company shall refund Customer any prepaid fees covering the
remainder of the Subscription Term after the date of termination, less expenses
incurred by Company to perform the Services. Termination for cause by Customer
shall not relieve Customer of the obligation to pay any fees accrued or payable
to Company prior to the effective date of termination.  Upon any termination
for cause by Company, Customer shall remain obligated to pay all fees owed for
the remainder of the Subscription Term, all of which fees shall become immediately
due and payable in full. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>10.4.</span><span className={cx('section-contents')}>System. 
Following the termination or expiration of this Agreement or the applicable
Order Form, (a) Customer will immediately cease use of the System and verify in
writing to Company that it has destroyed, permanently erased or returned to
Company the Applications and any of Company’s Confidential Information in its
possession or control; and (b) all rights granted under this Agreement
immediately terminate. Sections 3, 4, 5, 6.3, 7, 8, 9.3 and 10 survive
termination or expiration of this Agreement. </span></p>

<p className={cx('level-one')}><span className={cx('section-label')}>11.</span><span className={cx('section-contents')}>GENERAL PROVISIONS</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.1.</span><span className={cx('section-contents')}>Relationship
of the Parties.  The relationship between the parties created by this Agreement
is one of independent contractors and neither party shall have the power or
authority to bind or obligate the other except as expressly set forth in this
Agreement. This Agreement does not create a partnership, franchise, joint
venture, agency, fiduciary, or employment relationship between the parties.
There are no third-party beneficiaries to this Agreement. </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.2.</span><span className={cx('section-contents')}>Assignment. 
Neither party may assign any of its rights or obligations hereunder, whether by
operation of law or otherwise, without the prior written consent of the other
party (not to be unreasonably withheld, conditioned or delayed). 
Notwithstanding the foregoing, either party may assign this Agreement in its
entirety (including all Order Forms), without consent of the other party, in
connection with a merger, acquisition, corporate reorganization, or sale of all
or substantially all of its assets not involving a direct competitor of the
other party.  Any attempt by a party to assign its rights or obligations under
this Agreement in breach of this Section shall be void and of no effect. 
Subject to the foregoing, this Agreement shall bind and inure to the benefit of
the parties, their respective successors and permitted assigns.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.3.</span><span className={cx('section-contents')}>Publicity.
Customer agrees to participate in press announcements, case studies, trade
shows, or other marketing reasonably requested by Dunkadoo. During the Term and
for thirty (30) days thereafter, Customer grants Dunkadoo the right, free of
charge, to use Customer’s name and/or logo, worldwide, to identify Customer as
such on Dunkadoo’s website or other marketing or advertising materials.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.4.</span><span className={cx('section-contents')}>Force
Majeure.  If performance of any obligation hereunder (except payment of monies
due) is prevented, restricted or interfered with by any force majeure,
including without limitation act of God; fire or other casualty or accident;
strikes or labor disputes; war or other violence; internet, network or data
transmission unavailability or outages outside of Company’s control; outages or
unavailability of or delays in procuring materials, services, power or
supplies; any law, order, proclamation, regulation, ordinance, demand or
requirement of any governmental or intergovernmental agency or body; or any
other act or condition whatsoever beyond the reasonable control of the party
affected thereby, the party so affected will be excused from such performance
during the time such prevention, restriction or interference persists.  Where
any such failure of a party occurs and lasts more than one month, the other
party may (at its sole discretion) terminate this Agreement by serving notice
of termination with immediate effect, or suspend the performance of this
Agreement.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.5.</span><span className={cx('section-contents')}>Notice.
Any formal legal notice (but excluding operational notices or notices of
non-renewal which may be communicated through the System) to be given by one
party to the other under this Agreement will be in writing.  Delivery will be
by trackable express courier delivery service (delivery charge prepaid) to the
appropriate address set forth in the Order Form, and will be deemed to have
been served on actual delivery.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.6.</span><span className={cx('section-contents')}>Trademarks.
The Dunkadoo name and logos (including, without limitation, those of Dunkadoo’s
affiliates), all product and service names, all graphics, all button icons, and
all trademarks, service marks and logos appearing within this Site, unless
otherwise noted, are trademarks (whether registered or not), service marks,
and/or trade dress of Dunkadoo and/or its affiliates (the “Dunkadoo Marks”).
All other trademarks, product names, company names, logos, service marks and/or
trade dress mentioned, displayed, cited or otherwise indicated within this Site
are the property of their respective owners. You are not authorized to display
or use the Dunkadoo Marks in any manner without our prior written permission. You
are not authorized to display or use trademarks, product names, company names,
logos, service marks and/or trade dress of other owners featured within this
Site without the prior written permission of such owners. The use or misuse of
the Dunkadoo Marks or other trademarks, product names, company names, logos,
service marks and/or trade dress or any other materials contained herein,
except as permitted herein, is expressly prohibited.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.7.</span><span className={cx('section-contents')}>Your
Feedback. Any Feedback you provide to us through this Site will be and remain
our exclusive property. Your submission of Feedback will constitute an
assignment to us of all worldwide rights, title and interests in your Feedback,
including all copyrights and other intellectual property rights in your
Feedback. We will be entitled to reduce to practice, exploit, make, use, copy,
disclose, display or perform publicly, distribute, improve and modify any
Feedback you submit for any purpose whatsoever, without restriction and without
compensating you in any way. For this reason, we ask that you not send us any
Feedback that you do not wish to assign to us.</span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.8.</span><span className={cx('section-contents')}>Governing
Law.  This Agreement shall be governed exclusively by the laws of the State of Michigan,
United States, without regard to its conflicts of laws rules.   The United
Nations Convention on Contracts for the International Sale of Goods will not
apply to this Agreement.  This Agreement has been written in the English
language, and the parties agree that the English version will govern.  </span></p>

<p className={cx('level-two')}><span className={cx('section-label')}>11.9.</span><span className={cx('section-contents')}>Miscellaneous. 
This Agreement, including all Order Forms, constitutes the entire agreement
between the parties, and supersedes all prior and contemporaneous agreements,
proposals or representations, written or oral, concerning its subject matter. 
No modification, amendment, or waiver of any provision of this Agreement shall
be effective unless in writing and signed by both parties.  To the extent of
any conflict or inconsistency between the provisions in the body of this
Agreement and any Order Form, the terms of this Agreement shall prevail unless
expressly stated otherwise in such Order Form.  Any provision of this Agreement
which is prohibited and unenforceable in any jurisdiction shall be replaced
with a valid provision that is closer to the scope and intent of the invalid
provision and shall not affect the validity of the remaining provisions hereof.
This Agreement may be executed in two (2) counterparts with the exact same
contents, which taken together shall form one legal instrument.</span></p>
      </div>
    </div>
  );
};

export default Terms;
