import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import classNames from '../utils/classNames.js';
import { uploadOrgImage, deleteOrgImage } from '../actions/admin';
import styles from '../css/components/schema';

const cx = classNames.bind(styles);

const imageFields = ['logo_url', 'sponsor_logo'];
const imageFieldMeta = {
  logo_url: {
    title: 'Organization Logo',
    height: 100,
    width: 250,
    help: 'This image will appear at the top of all of your public pages. We will resize the image automatically. Use a version of your logo that is easily recognized in a small size.'
  },
  sponsor_logo: {
    title: 'Sponsor Logo (Optional)',
    height: 250,
    width: 250,
    help: 'If you have a sponsor for your research, you can upload an image of their logo to show on your public pages. If you don\'t have a sponsor, you can leave this empty. If you have multiple sponsors, you can upload an image that contains multiple logos in a single image.'
  },
};

class OrgImages extends Component {
  constructor(props) {
    super(props);

    this.saveImageUpload = this.saveImageUpload.bind(this);
    this.cancelImageUpload = this.cancelImageUpload.bind(this);
    this.deleteExistingImage = this.deleteExistingImage.bind(this);
    this.onDropImage = this.onDropImage.bind(this);
    this.buildImageHolders = this.buildImageHolders.bind(this);

    const imageHolders = this.buildImageHolders();

    this.state = {
      ...imageHolders,
    };
  }

  buildImageHolders() {
    const imageHolders = {};

    imageFields.forEach((thisImageField) => {
      imageHolders[thisImageField] = {
        lastModified: 0,
        lastModifiedDate: new Date(1970, 0, 1),
        name: '',
        preview: null,
        size: 0,
        type: null,
      };
    });

    return imageHolders;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.workingOrg !== nextProps.workingOrg) {
      const imageHolders = this.buildImageHolders();
      this.setState({
        ...imageHolders,
      });
    }
  }

  saveImageUpload(field) {
    const { uploadOrgImage, workingOrg } = this.props;

    uploadOrgImage(workingOrg, field, this.state[field]);

    this.setState({
      [field]: {
        lastModified: 0,
        lastModifiedDate: new Date(1970, 0, 1),
        name: '',
        preview: null,
        size: 0,
        type: null,
      }
    });
  }

  cancelImageUpload(field) {
    this.setState({
      [field]: {
        lastModified: 0,
        lastModifiedDate: new Date(1970, 0, 1),
        name: '',
        preview: null,
        size: 0,
        type: null,
      }
    });
  }

  deleteExistingImage(field) {
    const { deleteOrgImage, workingOrg } = this.props;

    deleteOrgImage(workingOrg, field);
  }

  onDropImage(field, fileContents) {
    this.setState({
      [field]: fileContents,
    });
  }

  render() {
    const { organization, workingOrg } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2>{organization.name}</h2>
        {imageFields.map((thisImageField) => {
          return (
            <div
              key={thisImageField}>
              <h3
                className={cx('image-upload-label')} >
                {imageFieldMeta[thisImageField].title}
              </h3>
              <div
                className={cx('context-help')} >
                {imageFieldMeta[thisImageField].help}
              </div>
              {organization[thisImageField] ? (
                <div>
                  <div
                    className={cx('image-existing-container')} >
                    <img
                      className={cx('image-existing', 'image-conformer', {[thisImageField]: true})}
                      src={organization[thisImageField]}
                      alt={thisImageField} />
                  </div>
                  <button
                    id={thisImageField}
                    onClick={() => this.deleteExistingImage(thisImageField)}
                    className={cx('image-handle-button')} >
                    Delete Image
                  </button>
                </div>
              ) : (
                <Dropzone
                  onDrop={(acceptedFiles, rejectedFiles) => {
                    this.onDropImage(thisImageField, acceptedFiles[0]);
                  }}
                  accept={'image/jpeg,image/gif,image/png,image/bmp'}
                  style={{}}
                  multiple={false} >
                  {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                    if (isDragActive) {
                      console.log(acceptedFiles);
                      return (
                        <div
                          className={cx('image-dropzone')} >
                          Drop here to select!
                        </div>
                      );
                    }
                    if (isDragReject) {
                      return (
                        <div
                          className={cx('image-dropzone')} >
                          Please select a jpeg, png, gif, or bmp file.
                        </div>
                      );
                    }
                    if (this.state[thisImageField].size > 0) {
                      return (
                        <div
                          className={cx('image-dropzone')} >
                          <img
                            className={cx('image-uploaded', 'image-conformer', {[thisImageField]: true})}
                            src={this.state[thisImageField].preview}
                            alt={thisImageField} />
                          <span
                            className={cx('image-upload-preview')} >
                            Preview
                          </span>
                        </div>
                      );
                    }
                    return (
                      <div
                        className={cx('image-dropzone')} >
                        <span
                          className={cx('image-upload-instructions')} >
                          Drag and drop your logo here. You can also click to select the file on your computer.
                        </span>
                      </div>
                    );
                  }}
                </Dropzone>
              )}
              {this.state[thisImageField].size > 0 &&
                <div>
                  <button
                    id={thisImageField}
                    onClick={() => this.saveImageUpload(thisImageField)}
                    className={cx('image-handle-button')} >
                    Save Image
                  </button>
                  <button
                    id={thisImageField}
                    onClick={() => this.cancelImageUpload(thisImageField)}
                    className={cx('image-handle-button')} >
                    Cancel
                  </button>
                </div>
              }
            </div>
          );
        })}
      </div>
    );
  }
}

OrgImages.propTypes = {
  uploadOrgImage: PropTypes.func.isRequired,
  deleteOrgImage: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  workingOrg: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    organization: state.admin.organizations[state.admin.workingOrg],
    workingOrg: state.admin.workingOrg,
  };
}

export default connect(mapStateToProps, { uploadOrgImage, deleteOrgImage })(OrgImages);
