import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';
import classNames from '../utils/classNames.js';
import { updateUserPermissions } from '../actions/admin';
import styles from 'css/components/useradmin';
import * as types from '../types';

const cx = classNames.bind(styles);

class ProjectManager extends Component {
  constructor(props) {
    super(props);

    this.addUserById = this.addUserById.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
    this.adminToggle = this.adminToggle.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.workingProject !== nextProps.workingProject) {
    }
  }

  addUserById(event) {
    const { updateUserPermissions, editing, assetType, assetId} = this.props;

    const userInfo = {
      id: event.target.value,
    };

    if (editing === types.ACTIVITY_USERS_PROJECT) {
      userInfo.administrator = true;
    }

    updateUserPermissions(assetType, assetId, userInfo, 'add');

    ReactGA.event({
      category: 'Dashboard: ' + clone(assetType).charAt(0).toUpperCase() + clone(assetType).slice(1),
      action: 'User: Add Existing Connection',
      label: this.props.adminInfo.name,
      value: event.target.value,
    });
  }

  sendInvite(event) {
    const { updateUserPermissions, assetType, assetId} = this.props;

    event.preventDefault();
    event.stopPropagation();

    const email = this.inviteEmail.value.toLowerCase();

    if (email.length > 0) {
      updateUserPermissions(assetType, assetId, {email}, 'invite');

      ReactGA.event({
        category: 'Dashboard: ' + clone(assetType).charAt(0).toUpperCase() + clone(assetType).slice(1),
        action: 'User: Add New User',
        label: email,
      });
    }
  }

  adminToggle(userId) {
    const { updateUserPermissions, assetType, assetId, admins} = this.props;

    updateUserPermissions(assetType, assetId, {id: userId, administrator: (admins.indexOf(userId) === -1)}, 'add');

    ReactGA.event({
      category: 'Dashboard: ' + clone(assetType).charAt(0).toUpperCase() + clone(assetType).slice(1),
      action: 'User: Toggle Project Admin',
      label: this.props.adminInfo.name,
      value: userId,
    });
  }

  removeUser(userId) {
    const { updateUserPermissions, assetType, assetId} = this.props;

    updateUserPermissions(assetType, assetId, {id: userId}, 'remove');

    ReactGA.event({
      category: 'Dashboard: ' + clone(assetType).charAt(0).toUpperCase() + clone(assetType).slice(1),
      action: 'User: Remove',
      label: this.props.adminInfo.name,
      value: userId,
    });
  }

  render() {
    const { assetType, editing, team, admins, techs, invitations, adminOption, adminInfo, users, userIndex, user, userHelp } = this.props;

    const sortedUserIndex = sortBy(userIndex, ['last_name', 'first_name']);
    const sortedTeamIds = sortBy(team, [(u) => { return users[u].last_name; }, (u) => { return users[u].first_name; }]);

    const sortedTeam = sortedTeamIds.map((thisUserId) => {
      const updateUser = users[thisUserId];
      updateUser.offerDelete = !((editing === types.ACTIVITY_USERS_ORG) && (thisUserId === user.id));
      updateUser.offerAdmin = adminOption;
      return updateUser;
    });

    if (invitations) {
      invitations.forEach((thisInvite) => {
        sortedTeam.push({
          id: thisInvite,
          picture: 'https://cdn.dunkadoo.org/static/dunkadoo-avatar_60.png',
          display_name: 'Pending Invitation',
          email: thisInvite,
          offerDelete: false,
          offerAdmin: false,
        });
      });
    }

    return (
      <div
        className={cx('container')} >
        <h2>{adminInfo.name}</h2>
        <div
          className={cx('context-help')} >
          {userHelp}
        </div>
        <div
          className={cx('user-table')} >
          <div
            className={cx('user-row', 'user-table-header')} >
            <div
              className={cx('asset-header')} >
              {assetType} Users
            </div>
          </div>
          {sortedTeam.map((thisUser) => {
            return (
              <div
                className={cx('user-row')}
                key={thisUser.id} >
                <div
                  className={cx('user-image')} >
                  <img src={thisUser.picture + '?sz=60'} alt={thisUser.display_name} />
                </div>
                <div
                  className={cx('user-name')} >
                  {thisUser.display_name}
                </div>
                <div
                  className={cx('user-email')} >
                  {thisUser.email}
                </div>
                {adminOption &&
                  <div
                    className={cx('user-admin')} >
                    {thisUser.offerAdmin &&
                      <label
                        htmlFor={'admin_toggle_' + thisUser.id}>
                        <input
                          id={'admin_toggle_' + thisUser.id}
                          type="checkbox"
                          checked={admins.indexOf(thisUser.id) > -1}
                          onChange={() => {
                            event.preventDefault();
                            this.adminToggle(thisUser.id);
                          }} />
                        Admin
                      </label>
                    }
                  </div>
                }
                <div
                  className={cx('user-remove')} >
                  {thisUser.offerDelete &&
                    <button
                      className={cx('remove-user-button')}
                      onClick={(event) => {
                        event.preventDefault();
                        this.removeUser(thisUser.id);
                        }
                      } >
                      &#x2716;
                    </button>
                  }
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={cx('add-user-wrapper')} >
          <div
            className={cx('add-user-container')} >
            <div
              className={cx('add-user-header')} >
              Add a User
            </div>
            <div
              className={cx('context-help')} >
              You can add additional users here.
            </div>
            <div
              className={cx('add-user-subheader')} >
              Team Member
            </div>
            <div
              className={cx('context-help')} >
              If the user is already connected to you on Dunkadoo, they will appear in this list. Select the user from this list, and they will be added immediately.
            </div>
            <select
              className={cx('add-user-select')}
              name="userById"
              defaultValue=""
              onChange={this.addUserById} >
              <option
                value=""
                disabled >
                Select a User
              </option>
              {sortedUserIndex.map((thisUser) => {
                return (
                  <option
                    key={thisUser.id}
                    value={thisUser.id} >
                    {thisUser.last_name.length > 3 ? (thisUser.last_name + ', ' + thisUser.first_name + ' - ' + thisUser.email) : (thisUser.display_name + ' - ' + thisUser.email)}
                  </option>
                );
              })}
            </select>
            <div
              className={cx('add-user-subheader')} >
              Invite by Email Address
            </div>
            <div
              className={cx('context-help')} >
              You can add users by their email address. After entering the email address, press &quot;Send.&quot; If the user already has a Dunkadoo account, their name will appear in the table above immediately. If they don&apos;t have an account yet, they will receive an email invitation and their email address will show &quot;Pending Invitation.&quot; Dunkadoo uses Google Accounts, so you may want to ask which email address to use.
            </div>
            <div
              className={cx('add-user-email-form')}>
              <form
                onSubmit={this.sendInvite} >
                <input
                  className={cx('add-user-email')}
                  type="email"
                  placeholder="jdoe@dunkadoo.org"
                  ref={(input) => { this.inviteEmail = input; }} />
                <input
                  className={cx('add-user-submit')}
                  type="submit"
                  value="Send" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectManager.propTypes = {
  updateUserPermissions: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  adminInfo: PropTypes.object.isRequired,
  team: PropTypes.array.isRequired,
  assetId: PropTypes.number.isRequired,
  adminOption: PropTypes.bool.isRequired,
  editing: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let assetType;
  let assetId;
  let adminInfo;
  let team;
  let admins;
  let techs;
  let invitations;
  let adminOption;
  let userHelp;
  if (state.admin.editing === types.ACTIVITY_USERS_ORG) {
    assetType = 'organization';
    assetId = state.admin.workingOrg;
    adminInfo = state.admin.orgProjects[state.admin.workingOrg];
    team = state.admin.organizations_users.users[state.admin.workingOrg] || [];
    admins = state.admin.organizations_users.admins[state.admin.workingOrg] || [];
    techs = state.admin.organizations_users.techs[state.admin.workingOrg] || [];
    invitations = state.admin.organizations_users.invitations[state.admin.workingOrg] || [];
    adminOption = false;
    userHelp = 'Organization Users are the administrators for your Organization. These users can create and change Projects and Taxonomies. They can also access and make changes to all of your Observations and Metadata.';
  } else if (state.admin.editing === types.ACTIVITY_USERS_PROJECT) {
    assetType = 'project';
    assetId = state.admin.workingProject;
    adminInfo = state.admin.adminSchemas[state.admin.workingProject];
    team = state.admin.projects_users.users[state.admin.workingProject] || [];
    admins = state.admin.projects_users.admins[state.admin.workingProject] || [];
    techs = state.admin.projects_users.techs[state.admin.workingProject] || [];
    invitations = state.admin.projects_users.invitations[state.admin.workingProject] || [];
    adminOption = true;
    userHelp = 'Project Users collect data and manage this Project. This Project will appear on the tablet for all users in this list. If the "Admin" box is checked, this user can make changes to the Project and edit other users\'s data. New users will be added to the project as administrators. If you do not want them to have administrator access, uncheck the "Admin" box after adding them. Project Administrators do not have to be Organization Administrators. This allows you to add an administrator or technician for just this Project without granting access to your whole Organization.';
  }

  return {
    assetType,
    assetId,
    adminInfo,
    team,
    admins,
    techs,
    invitations,
    adminOption,
    userHelp,
    userIndex: state.admin.users.index,
    users: state.admin.users.lookup,
    editing: state.admin.editing,
    user: state.admin.user,
  };
}

export default connect(mapStateToProps, { updateUserPermissions })(ProjectManager);
