import React, { Component } from 'react';
import classNames from '../utils/classNames.js';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Waypoint from 'react-waypoint';
import styles from 'css/components/pricing';

const cx = classNames.bind(styles);

class Pricing extends Component {
  constructor(props) {
    super(props);

    this.reportPricingView = this.reportPricingView.bind(this);
  }

  reportPricingView(segment) {
    ReactGA.event({
      category: 'Pricing',
      action: 'Read ' + segment,
    });
  }

  render() {
    return (
      <div className={cx('container')}>
        <div
          className={cx('intro')} >
          <p>Join the platform at the forefront of biological fieldwork.</p>
        </div>
        <div
          className={cx('pricing-table-wrapper')} >
          <table
            className={cx('pricing-table')} >
            <thead>
              <tr
                className={cx('feature-tier')} >
                <th />
                <th
                  className={cx('col-nonprofit', 'tier')} >
                  Non-Profit
                </th>
                <th
                  className={cx('col-commercial', 'tier')} >
                  Commercial
                </th>
              </tr>
              <tr
                className={cx('feature-price')} >
                <th />
                <th
                  className={cx('col-nonprofit')} >
                  <span className={cx('price-headline')}>Free</span>
                  <span className={cx('price-detail')} />
                </th>
                <th
                  className={cx('col-commercial')} >
                  <span className={cx('price-headline')}>$19</span>
                  <span className={cx('price-detail')}>/user/month</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Collect Data
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Online Project Management
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Keep Your Data Synchronized
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Public Education and Outreach
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Support
                </td>
                <td className={cx('col-nonprofit', 'optional')} >
                  Optional
                </td>
                <td className={cx('col-commercial', 'optional')} >
                  Email Included
                </td>
              </tr>
              <tr
                className={cx('feature-cta')} >
                <td
                  className={cx('col-detail-bottom')} />
                <td
                  className={cx('feature-cta')} >
                  <Link
                    to="/login"
                    className={cx('feature-cta-button')} >
                    <span className={cx('feature-cta-button-highlight')}>Get Started</span>
                  </Link>
                </td>
                <td
                  className={cx('feature-cta')} >
                  <a
                    href="mailto:sales@dunkadoo.org?Subject=Interested%20in%20Dunkadoo"
                    className={cx('feature-cta-button')} >
                    <span className={cx('feature-cta-button-highlight')}>Contact Us</span>
                    <span className={cx('feature-cta-button-detail')}>Sales@Dunkadoo.org</span>
                  </a>
                </td>
              </tr>
              <tr
                className={cx('feature-section-promo')} >
                <td
                  colSpan="3" >
                  <img
                    alt="Dunkadoo on All of Your Devices"
                    className={cx('dunkadoo-devices')}
                    src="https://cdn.dunkadoo.org/static/pricing-dunkadoo-devices.png" />
                </td>
              </tr>
              <tr
                className={cx('feature-section-header')} >
                <td
                  colSpan="3" >
                  Data Collection
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  <ReactGA.OutboundLink
                    eventLabel="Pricing: Google Play Store"
                    to="https://play.google.com/store/apps/details?id=org.dunkadoo.mobileprod" >
                    <img
                      alt="Google Play Store"
                      className={cx('app-store-download')}
                      src="https://cdn.dunkadoo.org/email/get-it-on-google-play.png" />
                    Android App for Phones and Tablets
                  </ReactGA.OutboundLink>
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  <ReactGA.OutboundLink
                    eventLabel="Pricing: Google Play Store"
                    to="https://itunes.apple.com/us/app/dunkadoo/id1248418890" >
                    <img
                      alt="Google Play Store"
                      className={cx('app-store-download')}
                      src="https://cdn.dunkadoo.org/email/download-on-the-app-store.png" />
                    iOS App for iPhones and iPads
                  </ReactGA.OutboundLink>
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Collect Data Offline
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Real-Time Data Synchronization with an Internet Connection
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Data Changes Sync Between Cloud and Apps
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  GPS Positions for Observations
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Real-Time Datasheet
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-section-header')} >
                <td
                  colSpan="3" >
                  Online Project Management
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Add Users to Collaborate
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Create New Projects
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Add New Taxonomies
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Changes to Projects and Taxonomies Can Be Made During the Project
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-section-header')} >
                <td
                  colSpan="3" >
                  Quality Control
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Flag Observations for Review
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Edits on Mobile Apps Synchronize to the Cloud
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Edits on Cloud Synchronize to Mobile Devices
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-section-header')} >
                <td
                  colSpan="3" >
                  Public Education and Outreach
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Share Your Research Online and Connect with a Wider Audience
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Real-Time Charts and Updates
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Real-Time Interactive Maps
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Highlight Notable Observations
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Species Composition with Adjustable Time Range
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Species Season Charts
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Weather Forecast
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Engage the Public for Donations
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-section-header')} >
                <td
                  colSpan="3" >
                  Support
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Email Support
                </td>
                <td className={cx('col-nonprofit', 'optional')} >
                  Optional
                  <br />
                  ($5/user/mo)
                </td>
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Phone Support
                </td>
                <td className={cx('col-nonprofit', 'optional')} >
                  Optional
                </td>
                <td className={cx('col-commercial', 'optional')} >
                  Optional
                </td>
              </tr>
              <tr
                className={cx('feature-section-header')} >
                <td
                  colSpan="3" >
                  Which Version Do I Need?
                </td>
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Citizen Scientists and Volunteers
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-nonprofit', 'not-included')} />
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  Non-Profits
                </td>
                <td className={cx('col-nonprofit', 'included')} >
                  ✔
                </td>
                <td className={cx('col-nonprofit', 'not-included')} />
              </tr>
              <tr
                className={cx('feature-row')} >
                <td
                  className={cx('col-detail')} >
                  For-Profits
                </td>
                <td className={cx('col-nonprofit', 'not-included')} />
                <td className={cx('col-commercial', 'included')} >
                  ✔
                </td>
              </tr>
              <tr
                className={cx('feature-cta')} >
                <td
                  className={cx('col-detail-bottom')} />
                <td
                  className={cx('feature-cta')} >
                  <Link
                    to="/login"
                    className={cx('feature-cta-button')} >
                    <span className={cx('feature-cta-button-highlight')}>Get Started</span>
                  </Link>
                </td>
                <td
                  className={cx('feature-cta')} >
                  <a
                    href="mailto:sales@dunkadoo.org?Subject=Interested%20in%20Dunkadoo"
                    className={cx('feature-cta-button')} >
                    <span className={cx('feature-cta-button-highlight')}>Contact Us</span>
                    <span className={cx('feature-cta-button-detail')}>Sales@Dunkadoo.org</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportPricingView('Pricing Table');
        }} />
        <div
          className={cx('intro')} >
          <p>As an innovative non-profit, we believe in the importance of creating sustaining revenue streams. Not only does your team get access to our best-in-class research platform, but you also support our mission to continuously improve our services and support environmental research.</p>
        </div>
      </div>
    );
  }
}

Pricing.propTypes = {
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, { })(Pricing);
