export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const RECEIVED_MESSAGES = 'RECEIVED_MESSAGES';
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

export const TOGGLE_LOGIN_MODE = 'TOGGLE_LOGIN_MODE';
export const MANUAL_LOGIN_USER = 'MANUAL_LOGIN_USER';
export const LOGIN_SUCCESS_USER = 'LOGIN_SUCCESS_USER';
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_SUCCESS_USER = 'SIGNUP_SUCCESS_USER';
export const SIGNUP_ERROR_USER = 'SIGNUP_ERROR_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_SUCCESS_USER = 'LOGOUT_SUCCESS_USER';
export const LOGOUT_ERROR_USER = 'LOGOUT_ERROR_USER';

export const SITE_ACTIVE = 'SITE_ACTIVE';

export const CLEAR_LANDSCAPE_RECENT = 'CLEAR_LANDSCAPE_RECENT';
export const LANDSCAPE_RECENT_RUNNING = 'LANDSCAPE_RECENT_RUNNING';
export const EXIT_LANDSCAPE_RECENT = 'EXIT_LANDSCAPE_RECENT';
export const GET_LANDSCAPE_REQUEST = 'GET_LANDSCAPE_REQUEST';
export const GET_LANDSCAPE_SUCCESS = 'GET_LANDSCAPE_SUCCESS';
export const GET_LANDSCAPE_RECENT_REQUEST = 'GET_LANDSCAPE_RECENT_REQUEST';
export const GET_LANDSCAPE_RECENT_SUCCESS = 'GET_LANDSCAPE_RECENT_SUCCESS';
export const GET_LANDSCAPE_RECENT_FAILURE = 'GET_LANDSCAPE_RECENT_FAILURE';
export const GET_LANDSCAPE_RECENT_UPDATE_REQUEST = 'GET_LANDSCAPE_RECENT_UPDATE_REQUEST';
export const GET_LANDSCAPE_RECENT_UPDATE_SUCCESS = 'GET_LANDSCAPE_RECENT_UPDATE_SUCCESS';
export const GET_LANDSCAPE_RECENT_UPDATE_FAILURE = 'GET_LANDSCAPE_RECENT_UPDATE_FAILURE';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const CHANGE_COMPOSITION_RANGE = 'CHANGE_COMPOSITION_RANGE';
export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';

export const GET_RECENT_REQUEST = 'GET_RECENT_REQUEST';
export const GET_RECENT_SUCCESS = 'GET_RECENT_SUCCESS';
export const GET_RECENT_FAILURE = 'GET_RECENT_FAILURE';

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const REQUEST_ADMIN_SUCCESS = 'REQUEST_ADMIN_SUCCESS';
export const REQUEST_ADMIN_FAILURE = 'REQUEST_ADMIN_FAILURE';

export const REPORT_TYPE_OBSERVATIONS = 'REPORT_TYPE_OBSERVATIONS';
export const REPORT_TYPE_METADATA = 'REPORT_TYPE_METADATA';

export const FOCUS_ORGANIZATION = 'FOCUS_ORGANIZATION';
export const FOCUS_PROJECT = 'FOCUS_PROJECT';
export const FOCUS_TAXONOMY = 'FOCUS_TAXONOMY';

export const ACTIVITY_OVERVIEW = 'ACTIVITY_OVERVIEW';
export const ACTIVITY_DETAILS_ORG = 'ACTIVITY_DETAILS_ORG';
export const ACTIVITY_QUESTIONS_PROJECT = 'ACTIVITY_QUESTIONS_PROJECT';
export const ACTIVITY_METADATA_QUESTIONS_PROJECT = 'ACTIVITY_METADATA_QUESTIONS_PROJECT';
export const ACTIVITY_IMAGES_ORG = 'ACTIVITY_IMAGES_ORG';
export const ACTIVITY_DETAILS_PROJECT = 'ACTIVITY_DETAILS_PROJECT';
export const ACTIVITY_DETAILS_PROJECT_PUBLIC = 'ACTIVITY_DETAILS_PROJECT_PUBLIC';
export const ACTIVITY_DETAILS_PROJECT_EMBED = 'ACTIVITY_DETAILS_PROJECT_EMBED';
export const ACTIVITY_DETAILS_TAXONOMY = 'ACTIVITY_DETAILS_TAXONOMY';
export const ACTIVITY_TAXONOMY_ADD_SPECIES = 'ACTIVITY_TAXONOMY_ADD_SPECIES';
export const ACTIVITY_INTEGRATION_HAWKCOUNT = 'ACTIVITY_INTEGRATION_HAWKCOUNT';
export const ACTIVITY_USERS_ORG = 'ACTIVITY_USERS_ORG';
export const ACTIVITY_USERS_PROJECT = 'ACTIVITY_USERS_PROJECT';
export const ACTIVITY_SPECIES_BUTTON = 'ACTIVITY_SPECIES_BUTTON';
export const ACTIVITY_SPECIES_FOCUS = 'ACTIVITY_SPECIES_FOCUS';
export const ACTIVITY_EDIT_OBSERVATION = 'ACTIVITY_EDIT_OBSERVATION';
export const ACTIVITY_EDIT_METADATA = 'ACTIVITY_EDIT_METADATA';
export const ACTIVITY_DOWNLOAD = 'ACTIVITY_DOWNLOAD';
export const ACTIVITY_SUPERADMIN_PROJECT = 'ACTIVITY_SUPERADMIN_PROJECT';
export const ACTIVITY_ADD_ORGANIZATION = 'ACTIVITY_ADD_ORGANIZATION';
export const ACTIVITY_ADD_PROJECT = 'ACTIVITY_ADD_PROJECT';
export const ACTIVITY_ADD_TAXONOMY = 'ACTIVITY_ADD_TAXONOMY';
export const ACTIVITY_ADD_SUPERADMIN_TAXONOMY = 'ACTIVITY_ADD_SUPERADMIN_TAXONOMY';
export const ACTIVITY_ADD_INTEGRATION_HAWKCOUNT = 'ACTIVITY_ADD_INTEGRATION_HAWKCOUNT';

export const CREATE_EDITOR_REQUEST = 'CREATE_EDITOR_REQUEST';
export const REQUEST_EDITOR_SUCCESS = 'REQUEST_EDITOR_SUCCESS';
export const REQUEST_EDITOR_FAILURE = 'REQUEST_EDITOR_FAILURE';
export const CHANGE_WORKING_ORG = 'CHANGE_WORKING_ORG';
export const CHANGE_WORKING_PROJECT = 'CHANGE_WORKING_PROJECT';
export const CHANGE_WORKING_TAXONOMY = 'CHANGE_WORKING_TAXONOMY';
export const CHANGE_ADMIN_TIER = 'CHANGE_ADMIN_TIER';
export const CHANGE_ADMIN_EDITING = 'CHANGE_ADMIN_EDITING';
export const UPDATE_ADMIN_ORG_SUCCESS = 'UPDATE_ADMIN_ORG_SUCCESS';
export const UPDATE_ADMIN_ORG_FAILURE = 'UPDATE_ADMIN_ORG_FAILURE';
export const UPDATE_ADMIN_PROJECT_SUCCESS = 'UPDATE_ADMIN_PROJECT_SUCCESS';
export const UPDATE_ADMIN_PROJECT_FAILURE = 'UPDATE_ADMIN_PROJECT_FAILURE';
export const UPDATE_TAXONOMY_REQUEST = 'UPDATE_TAXONOMY_REQUEST';
export const UPDATE_TAXONOMY_SUCCESS = 'UPDATE_TAXONOMY_SUCCESS';
export const UPDATE_TAXONOMY_FAILURE = 'UPDATE_TAXONOMY_FAILURE';
export const UPDATE_USER_PERMISSIONS_REQUEST = 'UPDATE_USER_PERMISSIONS_REQUEST';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_FAILURE = 'UPDATE_USER_PERMISSIONS_FAILURE';
export const UPDATE_BRIEF_SUCCESS = 'UPDATE_BRIEF_SUCCESS';
export const UPDATE_BRIEF_FAILURE = 'UPDATE_BRIEF_FAILURE';
export const UPDATE_OVERVIEW_SUCCESS = 'UPDATE_OVERVIEW_SUCCESS';
export const UPDATE_OVERVIEW_FAILURE = 'UPDATE_OVERVIEW_FAILURE';
export const UPDATE_ORG_IMAGE_REQUEST = 'UPDATE_ORG_IMAGE_REQUEST';
export const UPDATE_ORG_IMAGE_SUCCESS = 'UPDATE_ORG_IMAGE_SUCCESS';
export const UPDATE_ORG_IMAGE_FAILURE = 'UPDATE_ORG_IMAGE_FAILURE';
export const DELETE_ORG_IMAGE_REQUEST = 'DELETE_ORG_IMAGE_REQUEST';
export const DELETE_ORG_IMAGE_SUCCESS = 'DELETE_ORG_IMAGE_SUCCESS';
export const DELETE_ORG_IMAGE_FAILURE = 'DELETE_ORG_IMAGE_FAILURE';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_FAILURE = 'ADD_ORGANIZATION_FAILURE';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';
export const ADD_TAXONOMY_SUCCESS = 'ADD_TAXONOMY_SUCCESS';
export const ADD_TAXONOMY_FAILURE = 'ADD_TAXONOMY_FAILURE';
export const ADD_INTEGRATION_HAWKCOUNT_SUCCESS = 'ADD_INTEGRATION_HAWKCOUNT_SUCCESS';
export const ADD_INTEGRATION_HAWKCOUNT_FAILURE = 'ADD_INTEGRATION_HAWKCOUNT_FAILURE';
export const INTEGRATION_HAWKCOUNT_LANDSCAPE_SUCCESS = 'INTEGRATION_HAWKCOUNT_LANDSCAPE_SUCCESS';
export const INTEGRATION_HAWKCOUNT_LANDSCAPE_FAILURE = 'INTEGRATION_HAWKCOUNT_LANDSCAPE_FAILURE';
export const ADD_ASSET_FOUND = 'ADD_ASSET_FOUND';
export const GET_PROJECT_TEMPLATES_REQUEST = 'GET_PROJECT_TEMPLATES_REQUEST';
export const GET_PROJECT_TEMPLATES_SUCCESS = 'GET_PROJECT_TEMPLATES_SUCCESS';
export const GET_PROJECT_TEMPLATES_FAILURE = 'GET_PROJECT_TEMPLATES_FAILURE';
export const GET_TAXONOMY_TEMPLATES_REQUEST = 'GET_TAXONOMY_TEMPLATES_REQUEST';
export const GET_TAXONOMY_TEMPLATES_SUCCESS = 'GET_TAXONOMY_TEMPLATES_SUCCESS';
export const GET_TAXONOMY_TEMPLATES_FAILURE = 'GET_TAXONOMY_TEMPLATES_FAILURE';
export const INITIATE_RECORD = 'INITIATE_RECORD';
export const GET_RECORD_SUCCESS = 'GET_RECORD_SUCCESS';
export const GET_RECORD_FAILURE = 'GET_RECORD_FAILURE';
export const SET_RECORD_SUCCESS = 'SET_RECORD_SUCCESS';
export const SET_RECORD_FAILURE = 'SET_RECORD_FAILURE';
export const REQUEST_ADMIN_USER = 'REQUEST_ADMIN_USER';
export const REQUEST_ADMIN_USER_REQUEST = 'REQUEST_ADMIN_USER_REQUEST';
export const REQUEST_ADMIN_USER_SUCCESS = 'REQUEST_ADMIN_USER_SUCCESS';
export const REQUEST_ADMIN_USER_FAILURE = 'REQUEST_ADMIN_USER_FAILURE';
export const UPDATE_USER_DATA_REQUEST = 'UPDATE_USER_DATA_REQUEST';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE';
export const REQUEST_TAXONOMY_SUCCESS = 'REQUEST_TAXONOMY_SUCCESS';
export const REQUEST_TAXONOMY_FAILURE = 'REQUEST_TAXONOMY_FAILURE';
export const REQUEST_TAXONOMIES_SUCCESS = 'REQUEST_TAXONOMIES_SUCCESS';
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS';
export const REQUEST_ORGANIZATIONS_SUCCESS = 'REQUEST_ORGANIZATIONS_SUCCESS';
export const INITIATE_SPECIES_BUTTONS = 'INITIATE_SPECIES_BUTTONS';
export const PUSH_SPECIES_BUTTONS = 'PUSH_SPECIES_BUTTONS';
export const POP_SPECIES_BUTTONS = 'POP_SPECIES_BUTTONS';
export const INITIATE_FOCUS_BUTTONS = 'INITIATE_FOCUS_BUTTONS';
export const PUSH_FOCUS_BUTTONS = 'PUSH_FOCUS_BUTTONS';
export const POP_FOCUS_BUTTONS = 'POP_FOCUS_BUTTONS';
export const TOGGLE_CONTEXT_HELP = 'TOGGLE_CONTEXT_HELP';

// Integrations
export const INTEGRATION_HAWKCOUNT_GATHER_SUCCESS = 'INTEGRATION_HAWKCOUNT_GATHER_SUCCESS';
export const INTEGRATION_HAWKCOUNT_GATHER_FAILURE = 'INTEGRATION_HAWKCOUNT_GATHER_FAILURE';
export const INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS = 'INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS';
export const INTEGRATION_HAWKCOUNT_CROSSREF_FAILURE = 'INTEGRATION_HAWKCOUNT_CROSSREF_FAILURE';
export const INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS = 'INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS';
export const INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_FAILURE = 'INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_FAILURE';
export const INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS = 'INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS';
export const INTEGRATION_HAWKCOUNT_ACTIVE_FAILURE = 'INTEGRATION_HAWKCOUNT_ACTIVE_FAILURE';
export const INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS = 'INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS';
export const INTEGRATION_HAWKCOUNT_FORCESYNC_FAILURE = 'INTEGRATION_HAWKCOUNT_FORCESYNC_FAILURE';

export const SPECIES_CARD = 'SPECIES_CARD';
