import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import update from 'immutability-helper';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import classNames from '../utils/classNames.js';
import styles from 'css/components/embedmanager';
import schemaEditorStyles from 'css/components/schema';
import schemaSchemaEditorStyles from 'css/components/schemaeditors';

const cx = classNames.bind(styles);
const cxSchema = classNames.bind(schemaEditorStyles);
const cxSchemaSchemaEditors = classNames.bind(schemaSchemaEditorStyles);

const embedPageComponentsInput = [
  'daytallies',
  'seasontallies',
  'flagged',
  'map',
  'livechart',
  'textupdates',
  'composition',
  'hourly'
];

const embedPageComponentsData = {
  daytallies: {
    name: 'Daily Tallies',
    flag: 'public_show_daytallies',
    height: 190,
  },
  seasontallies: {
    name: 'Season Tallies',
    flag: 'public_show_seasontallies',
    height: 190,
  },
  flagged: {
    name: 'Notable Observations',
    flag: 'public_show_flagged',
    height: 300,
  },
  map: {
    name: 'Map',
    flag: 'public_show_map',
    height: 800,
  },
  livechart: {
    name: 'Real-Time Chart',
    flag: 'public_show_livechart',
    height: 500,
  },
  textupdates: {
    name: 'Text Updates',
    flag: 'public_show_textupdates',
    height: 260,
  },
  composition: {
    name: 'Species Composition Chart',
    flag: 'public_show_composition',
    height: 690,
  },
  hourly: {
    name: 'Hourly Counts',
    flag: 'public_show_hourly',
    height: 600,
  }
};

class EmbedManager extends Component {
  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.toggleSegments = this.toggleSegments.bind(this);
    this.settingsUpdate = this.settingsUpdate.bind(this);
    this.moveComponent = this.moveComponent.bind(this);

    const calcHeight = embedPageComponentsInput.map((thisComponentName) => {
      return props.adminSchema[embedPageComponentsData[thisComponentName].flag] ? embedPageComponentsData[thisComponentName].height : 0;
    }).reduce((a, b) => {
      return a + b;
    });

    const availableComponents = embedPageComponentsInput.filter((thisComponentName) => { return props.adminSchema[embedPageComponentsData[thisComponentName].flag]; });

    this.state = {
      embedPageComponents: cloneDeep(availableComponents),
      segments: cloneDeep(availableComponents),
      frameHeight: calcHeight,
      frameWidth: 600,
    };
  }

  handleFocus(event) {
    event.target.select();
  }

  toggleSegments(event) {
    const target = event.target;
    const name = target.name;

    if (target.checked) {
      this.setState({
        segments: sortBy(update(this.state.segments, {
          $push: [name],
        }), [(thisComponentName) => { return this.state.embedPageComponents.indexOf(thisComponentName); }]),
        frameHeight: this.state.frameHeight + embedPageComponentsData[name].height,
      }, () => {
        window.setTimeout(() => {
          try {
            this.previewFrame.contentWindow.location.reload(true);
          } catch (e) {console.log(e)}
        }, 500);
      });
    } else {
      this.setState({
        segments: sortBy(update(this.state.segments, {
          $set: without(this.state.segments, name),
        }), [(thisComponentName) => { return this.state.embedPageComponents.indexOf(thisComponentName); }]),
        frameHeight: this.state.frameHeight - embedPageComponentsData[name].height,
      }, () => {
          window.setTimeout(() => {
            try {
              this.previewFrame.contentWindow.location.reload(true);
            } catch (e) {console.log(e)}
          }, 500);
      });
    }
  }

  settingsUpdate(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: parseInt(value, 10),
    });
  }

  moveComponent(index, newIndex) {
    const newEmbedPageComponents = cloneDeep(this.state.embedPageComponents);
    newEmbedPageComponents.splice(newIndex, 0, newEmbedPageComponents.splice(index, 1)[0]);

    this.setState({
      embedPageComponents: newEmbedPageComponents,
      segments: sortBy(this.state.segments, [(thisComponentName) => { return newEmbedPageComponents.indexOf(thisComponentName); }]),
    }, () => {
      window.setTimeout(() => {
        try {
          this.previewFrame.contentWindow.location.reload(true);
        } catch (e) {console.log(e)}
      }, 500);
    });
  }

  render() {
    const { adminSchema, organizationSlug, projectId } = this.props;

    const embedOutput = this.state.embedPageComponents.filter((thisComponentName) => { return adminSchema[embedPageComponentsData[thisComponentName].flag]; }).map((thisComponentName, thisComponentIndex) => {
      const thisComponent = embedPageComponentsData[thisComponentName];
      return (
        <div
          key={thisComponentName}
          className={cxSchema('public-component-container')} >
          <div
            className={cxSchema('public-component-actions')} >
            <h3
              className={cxSchema('public-component-header')} >
              {thisComponent.name}
            </h3>
            <div
              className={cxSchema('public-component-options')} >
              <div
                className={cxSchema('public-show-toggle')} >
                <input
                  id={thisComponentName}
                  type="checkbox"
                  name={thisComponentName}
                  className={cxSchema('public-show-toggle-checkbox')}
                  checked={this.state.segments.indexOf(thisComponentName) > -1}
                  onChange={this.toggleSegments} />
                <label
                  className={cxSchema('public-show-toggle-label')}
                  htmlFor={thisComponentName} >
                  <span
                    className={cxSchema('public-show-toggle-inner')} />
                  <span
                    className={cxSchema('public-show-toggle-switch')} />
                </label>
              </div>
            </div>
          </div>
          <div
            className={cxSchema('public-component-tools')} >
            <div
              className={cxSchemaSchemaEditors('tool-container', 'question-block-tools')} >
              <button
                className={cxSchemaSchemaEditors('tool-move-up', {'tool-hidden': thisComponentIndex === 0})}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.moveComponent(thisComponentIndex, thisComponentIndex - 1);
                }} >
                <div className={cxSchemaSchemaEditors('tool-move-up-icon')} />
              </button>
            </div>
            <div
              className={cxSchemaSchemaEditors('tool-container', 'question-block-tools')} >
              <button
                className={cxSchemaSchemaEditors('tool-move-down', {'tool-hidden': thisComponentIndex === this.state.embedPageComponents.length - 1})}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.moveComponent(thisComponentIndex, thisComponentIndex + 1);
                }} >
                <div className={cxSchemaSchemaEditors('tool-move-down-icon')} />
              </button>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div
        className={cx('container')} >
        <h2>{adminSchema.name}</h2>
        <div
          className={cx('context-help')} >
          <span className={cx('emphasis')}>Generate Code to Embed Dunkadoo in Your Website</span>
          <p>This tool generates code that you can input into your website to display real-time Dunkadoo visualizations.</p>
        </div>
        <div
          className={cx('segment-controller-container')} >
          <h3
            className={cx('section-head')} >
            Segments in Embedded Page
          </h3>
          {embedOutput}
          <h3
            className={cx('section-head')} >
            Settings
          </h3>
          <label
            htmlFor={'frameWidth'}
            className={cx('prompt')} >
            Width
          </label>
          <input
            id={'frameWidth'}
            name="frameWidth"
            type="number"
            onChange={this.settingsUpdate}
            value={this.state.frameWidth} />
          <label
            htmlFor={'frameHeight'}
            className={cx('prompt')} >
            Height
          </label>
          <input
            id={'frameHeight'}
            name="frameHeight"
            type="number"
            onChange={this.settingsUpdate}
            value={this.state.frameHeight} />
        </div>
        <h3
          className={cx('section-head')} >
          Preview
        </h3>
        <div
          style={{
            width: this.state.frameWidth + 20,
          }}
          className={cx('segment-controller-note')} >
          <p>
            Some sections will only be displayed when the project is live or relevant data is available.
          </p>
        </div>
        <div
          style={{
            width: this.state.frameWidth + 20,
          }}
          className={cx('preview-container')} >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxWidth: this.state.frameWidth,
              maxHeight: this.state.frameHeight
            }} >
            <iframe
              width={this.state.frameWidth}
              height={this.state.frameHeight - 100}
              src={'https://dunkadoo.org/embed/' + organizationSlug + '/' + adminSchema.slug + (this.state.segments.length === 0 ? '' : '?segments=' + this.state.segments.join(','))}
              style={{
                border: 0,
                width: '100%',
                maxWidth: this.state.frameWidth,
                height: this.state.frameHeight - 100,
              }}
              frameBorder="0"
              ref={(ref) => { this.previewFrame = ref; }} />
            <a
              href={'https://dunkadoo.org/explore/' + organizationSlug + '/' + adminSchema.slug}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: 700
              }} >
              <img
                src="https://cdn.dunkadoo.org/static/dunkadoo-wordmark-link.png"
                alt="View More on Dunkadoo"
                style={{
                  width: 200,
                  margin: '8px auto 0',
                  clear: 'both'
                }} />
              Click Here to View More
            </a>
          </div>
        </div>
        <h3
          className={cx('section-head')} >
          Copy the Embed Code
        </h3>
        <textarea
          className={cx('generated-code-container')}
          type="text"
          value={
`<div style="display:flex;flex-direction:column;width:100%;max-width:${this.state.frameWidth}px;max-height:${this.state.frameHeight}px">
<iframe
  frameborder="0"
  style="border:0;width:100%;max-width:${this.state.frameWidth}px;height:${this.state.frameHeight - 100}px;"
  src="https://dunkadoo.org/embed/${organizationSlug}/${adminSchema.slug}${this.state.segments.length === 0 ? '' : '?segments=' + this.state.segments.join(',')}" allowfullscreen>
</iframe>
<a href="https://dunkadoo.org/explore/${organizationSlug}/${adminSchema.slug}" style="display:flex;flex-direction:column;align-items:center;width:100%;text-align:center;font-size:20px;font-weight:700;" ><img src="https://cdn.dunkadoo.org/static/dunkadoo-wordmark-link.png" alt="View More on Dunkadoo" style="width:200px;margin:8px auto 0;clear:both;" />Click Here to View More</a></div>`}
          onFocus={this.handleFocus} />
        <h3
          className={cx('section-head')} >
          Place the Embed Code in Your Website
        </h3>
        <div
          className={cx('segment-controller-container')} >
          <p>
            This code will embed a frame on your website with your project&apos;s Dunkadoo data visualizations. You can create multiple embedded pages to place in different sections of your website.
          </p>
          <p>
            Unlike your project&apos;s Public Page, the settings above will apply to your embedded pages until you replace the code on your website. For security reasons, the data displayed on your embedded pages is based on the settings for your Public Page. If you disable your Public Page or one of the sections, the data will no longer be available on your embedded pages.
          </p>
        </div>
      </div>
    );
  }
}

EmbedManager.propTypes = {
  adminSchema: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    adminSchema: state.admin.adminSchemas[state.admin.workingProject],
    organizationSlug: state.admin.orgProjects[state.admin.workingOrg].slug,
    projectId: state.admin.workingProject,
  };
}

export default connect(mapStateToProps, { })(EmbedManager);
