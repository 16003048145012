import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import ProjectMap from 'components/ProjectMap';
import classNames from '../utils/classNames.js';
import styles from 'css/components/projectlist';

const cx = classNames.bind(styles);

class ProjectList extends Component {

  render() {
    const { projects, organizations } = this.props;

    const organizationList = sortBy(Object.keys(organizations), (thisOrgId) => { return organizations[thisOrgId].name; });

    organizationList.forEach((thisOrgId) => {
      organizations[thisOrgId].projects = sortBy(organizations[thisOrgId].projects, (thisProjectId) => { return projects[thisProjectId].name; })
    });

    const organizationBlocks = organizationList.map((thisOrgId) => {
      return (
        <div
          key={thisOrgId}
          className={cx('organization-block')} >
          <div
            className={cx('organization-header')} >
            {organizations[thisOrgId].logo_url &&
              <div
                className={cx('organization-logo-container')} >
                <img
                  src={organizations[thisOrgId].logo_url}
                  alt={organizations[thisOrgId].name}
                  className={cx('organization-logo')} />
              </div>
            }
            <div
              className={cx('organization-name')} >
              {organizations[thisOrgId].name}
            </div>
          </div>
          <div
            className={cx('project-link-container')} >
            {organizations[thisOrgId].projects.map((thisProject) => {
              return (
                <div
                  key={thisProject}
                  className={cx('project-link-wrapper')} >
                  <Link
                    to={'/explore/' + organizations[thisOrgId].slug + '/' + projects[thisProject].slug}
                    className={cx('project-link')} >
                    {projects[thisProject].name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      );
    });

    return (
      <div
        className={cx('container')} >
        <div
          className={cx('map-container')} >
          <ProjectMap />
        </div>
        {organizationBlocks}
      </div>
    );
  }
}

ProjectList.propTypes = {
  projects: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.landscape.projects,
    organizations: state.landscape.organizations,
  };
}

export default connect(mapStateToProps, { })(ProjectList);
