import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import classNames from '../utils/classNames.js';
import { connect } from 'react-redux';
import { manualLogin, signUp, toggleLoginMode } from '../actions/users';
import styles from '../css/components/login';
import hourGlassSvg from '../images/hourglass.svg';

const cx = classNames.bind(styles);

class LoginOrRegister extends Component {
  /*
   * This replaces getInitialState. Likewise getDefaultProps and propTypes are just
   * properties on the constructor
   * Read more here: https://facebook.github.io/react/blog/2015/01/27/react-v0.13.0-beta-1.html#es6-classes
   */
  constructor(props) {
    super(props);
    // this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  // handleOnSubmit(event) {
  //   event.preventDefault();

  //   const { manualLogin, signUp, user: { isLogin } } = this.props;
  //   const email = ReactDOM.findDOMNode(this.refs.email).value;
  //   const password = ReactDOM.findDOMNode(this.refs.password).value;

  //   if (isLogin) {
  //     manualLogin({ email, password });
  //   } else {
  //     signUp({ email, password });
  //   }
  // }

  render() {
    const { isWaiting } = this.props.user;

    return (
      <div
        className={cx('login', {
        waiting: isWaiting
      })}>
        <div
          className={cx('container')}>
          <div
            className={cx('google-container')}>
            <h2
              className={cx('heading')}>
              Login or Create an Account
            </h2>
            <a
              className={cx('signin-google-button-container')}
              href="/auth/google">
              <div
                className={cx('signin-google-button')} />
            </a>
          </div>
          <div
            className={cx('google-explanation')} >
            <h2>Dunkadoo Lets You Sign In With a Google Account</h2>
            <div>
              We don&apos;t want you to have to create a new account to use Dunkadoo, so we use Google Sign In.
            </div>
            <h2>Don&apos;t have a Google Account?</h2>
            <div>
              Don&apos;t worry! Any email address (even your work email) can be registered with Google for free.
            </div>
            <ReactGA.OutboundLink
              eventLabel="LoginOrRegister: Create Google Account"
              to="https://accounts.google.com/SignUpWithoutGmail?hl=en" >
              Create a Google Account
            </ReactGA.OutboundLink>
            <h2>Account Security</h2>
            <div>
              Dunkadoo is focused on security, so we partner with Google for authentication. We communicate with Google using OAuth, an industry standard protocol for secure authentication, and we never see your password. Google verifies your identity on their servers and then communicates with our server to validate your account. If you protect your Google Account with Two-Factor Authentication, then your Dunkadoo Account will be protected too.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginOrRegister.propTypes = {
  user: PropTypes.object,
  manualLogin: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  toggleLoginMode: PropTypes.func.isRequired
};

// Function passed in to `connect` to subscribe to Redux store updates.
// Any time it updates, mapStateToProps is called.
function mapStateToProps({user}) {
  return {
    user
  };
}

// Connects React component to the redux store
// It does not modify the component class passed to it
// Instead, it returns a new, connected component class, for you to use.
export default connect(mapStateToProps, { manualLogin, signUp, toggleLoginMode })(LoginOrRegister);

