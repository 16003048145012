/* eslint consistent-return: 0, no-else-return: 0*/
import { polyfill } from 'es6-promise';
import request from 'axios';
import md5 from 'spark-md5';
import * as types from '../types';

polyfill();

export function getMyPermissionsRequest() {
  return request.get('/ui/v1/user');
}

export function getMyPermissionsSuccess(data) {
  return { type: types.UPDATE_USER_DATA_SUCCESS, data };
}

export function getMyPermissionsFailure(data) {
  return {
    type: types.UPDATE_USER_DATA_FAILURE,
    error: data.error
  };
}

export function getMyPermissions() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.UPDATE_USER_DATA_REQUEST });
    return getMyPermissionsRequest()
      .then(updatedAdminPermissions => dispatch(getMyPermissionsSuccess(updatedAdminPermissions.data)))
      .catch(() => dispatch(getMyPermissionsFailure({ error: 'Unable to Connect to the Internet' })));
  };
}

export function makeOrgAdminRequest(method, orgId, data, action = '') {
  return request[method]('/ui/v1/organization/admin/' + orgId + (action !== '' ? '/' + action : action), data);
}

export function updateOrgInfoSuccess(data) {
  return { type: types.UPDATE_ADMIN_ORG_SUCCESS, data };
}

export function updateOrgInfoFailure(data) {
  return {
    type: types.UPDATE_ADMIN_ORG_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function updateOrgInfo(updatedOrg) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeOrgAdminRequest('post', updatedOrg.id, updatedOrg)
      .then(updatedAdminOrg => dispatch(updateOrgInfoSuccess(updatedAdminOrg.data)))
      .catch(() => dispatch(updateOrgInfoFailure({ projectId: updatedOrg.id, error: 'Unable to Connect to the Internet' })));
  };
}

export function createOrganizationSuccess(data) {
  return { type: types.ADD_ORGANIZATION_SUCCESS, data };
}

export function createOrganizationFailure(data) {
  return {
    type: types.ADD_ORGANIZATION_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function createOrganization(orgData) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeOrgAdminRequest('post', 0, orgData, 'create').then((orgProjectsResponse) => {
      getMyPermissionsRequest().then((userResponse) => {
        dispatch(createOrganizationSuccess({
          orgProjects: orgProjectsResponse.data.orgProjects,
          adminSchemas: orgProjectsResponse.data.adminSchemas,
          projectActivity: orgProjectsResponse.data.projectActivity,
          allUsers: userResponse.data.allUsers,
          myUser: userResponse.data.myUser,
        }));
      }).catch(() => {
        dispatch(createOrganizationFailure({ orgId: orgData.id, error: 'Unable to Connect to the Internet' }));
      });
    }).catch(() => {
      dispatch(createOrganizationFailure({ orgId: orgData.id, error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function makeOrgImage(orgId, image, field) {
  const data = new FormData();
  data.append('image', image);
  return request.post('/ui/v1/organization/admin/' + orgId + '/image/' + field, data);
}

export function updateOrgImageSuccess(data) {
  return { type: types.UPDATE_ORG_IMAGE_SUCCESS, data };
}

export function updateOrgImageFailure(data) {
  return {
    type: types.UPDATE_ORG_IMAGE_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function uploadOrgImage(orgId, field, image) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.UPDATE_ORG_IMAGE_REQUEST });
    return makeOrgImage(orgId, image, field)
      .then((updatedAdminSites) => dispatch(updateOrgImageSuccess(updatedAdminSites.data)))
      .catch(() => dispatch(updateOrgImageFailure({ orgId, error: 'Unable to Connect to the Internet' })));
  };
}

export function destroyOrgImage(orgId, image, field) {
  return request.delete('/ui/v1/organization/admin/' + orgId + '/image/' + field);
}

export function deleteOrgImageSuccess(data) {
  return { type: types.DELETE_ORG_IMAGE_SUCCESS, data };
}

export function deleteOrgImageFailure(data) {
  return {
    type: types.DELETE_ORG_IMAGE_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function deleteOrgImage(orgId, field, image) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.DELETE_ORG_IMAGE_REQUEST });
    return destroyOrgImage(orgId, image, field)
      .then(updatedAdminSites => dispatch(deleteOrgImageSuccess(updatedAdminSites.data)))
      .catch(() => dispatch(deleteOrgImageFailure({ orgId, error: 'Unable to Connect to the Internet' })));
  };
}

export function makeProjectAdminRequest(method, projectId, data, action = '') {
  return request[method]('/ui/v1/project/admin/' + projectId + (action !== '' ? '/' + action : action), data);
}

export function updateProjectInfoSuccess(data) {
  return { type: types.UPDATE_ADMIN_PROJECT_SUCCESS, data };
}

export function updateProjectInfoFailure(data) {
  return {
    type: types.UPDATE_ADMIN_PROJECT_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function updateProjectInfo(updatedProject) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeProjectAdminRequest('post', updatedProject.id, updatedProject)
      .then(updatedAdminProject => dispatch(updateProjectInfoSuccess(updatedAdminProject.data)))
      .catch(() => dispatch(updateProjectInfoFailure({ projectId: updatedProject.id, error: 'Unable to Connect to the Internet' })));
  };
}

export function getProjectTemplatesSuccess(data) {
  return { type: types.GET_PROJECT_TEMPLATES_SUCCESS, data };
}

export function getProjectTemplatesFailure(data) {
  return {
    type: types.GET_PROJECT_TEMPLATES_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function getProjectTemplates() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.GET_PROJECT_TEMPLATES_REQUEST });
    return makeProjectAdminRequest('get', '', {})
      .then(updatedAdminOrg => dispatch(getProjectTemplatesSuccess(updatedAdminOrg.data)))
      .catch(() => dispatch(getProjectTemplatesFailure({ error: 'Unable to Connect to the Internet' })));
  };
}

export function createProjectSuccess(data) {
  return { type: types.ADD_PROJECT_SUCCESS, data };
}

export function createProjectFailure(data) {
  return {
    type: types.ADD_PROJECT_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function createProject(projectData) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeProjectAdminRequest('post', 0, projectData, 'create').then((orgProjectsResponse) => {
      getMyPermissionsRequest().then((userResponse) => {
        dispatch(createProjectSuccess({
          orgProjects: orgProjectsResponse.data.orgProjects,
          adminSchemas: orgProjectsResponse.data.adminSchemas,
          projectActivity: orgProjectsResponse.data.projectActivity,
          allUsers: userResponse.data.allUsers,
          myUser: userResponse.data.myUser,
        }));
      }).catch(() => {
        dispatch(createProjectFailure({ projectId: projectData.id, error: 'Unable to Connect to the Internet' }));
      });
    }).catch(() => {
      dispatch(createProjectFailure({ projectId: projectData.id, error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function makeTaxonomyAdminRequest(method, taxonomyId, data, action = '') {
  return request[method]('/ui/v1/taxonomy/admin/' + taxonomyId + (action !== '' ? '/' + action : action), data, { timeout: 60000 });
}

export function updateTaxonomySuccess(data) {
  return { type: types.UPDATE_TAXONOMY_SUCCESS, data };
}

export function updateTaxonomyFailure(data) {
  return {
    type: types.UPDATE_TAXONOMY_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function updateTaxonomy(taxonomyId, taxonomyDelta) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.UPDATE_TAXONOMY_REQUEST });
    return makeTaxonomyAdminRequest('post', taxonomyId, {taxonomyDelta}, 'update')
      .then(updatedTaxonomy => dispatch(updateTaxonomySuccess(updatedTaxonomy.data)))
      .catch(() => dispatch(updateTaxonomyFailure({ taxonomyId, error: 'Unable to Connect to the Internet' })));
  };
}

export function addSpecies(taxonomyId, newSpecies) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.UPDATE_TAXONOMY_REQUEST });
    return makeTaxonomyAdminRequest('post', taxonomyId, {newSpecies}, 'add')
      .then(updatedTaxonomy => dispatch(updateTaxonomySuccess(updatedTaxonomy.data)))
      .catch(() => dispatch(updateTaxonomyFailure({ taxonomyId, error: 'Unable to Connect to the Internet' })));
  };
}

export function getTaxonomyTemplatesSuccess(data) {
  return { type: types.GET_TAXONOMY_TEMPLATES_SUCCESS, data };
}

export function getTaxonomyTemplatesFailure(data) {
  return {
    type: types.GET_TAXONOMY_TEMPLATES_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function getTaxonomyTemplates() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.GET_TAXONOMY_TEMPLATES_REQUEST });
    return makeTaxonomyAdminRequest('get', '', {})
      .then(updatedAdminOrg => dispatch(getTaxonomyTemplatesSuccess(updatedAdminOrg.data)))
      .catch(() => dispatch(getTaxonomyTemplatesFailure({ error: 'Unable to Connect to the Internet' })));
  };
}

export function createTaxonomySuccess(data) {
  return { type: types.ADD_TAXONOMY_SUCCESS, data };
}

export function createTaxonomyFailure(data) {
  return {
    type: types.ADD_TAXONOMY_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function createTaxonomy(projectData) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeTaxonomyAdminRequest('post', 0, projectData, 'create').then((orgProjectsResponse) => {
      getMyPermissionsRequest().then((userResponse) => {
        dispatch(createTaxonomySuccess({
          orgProjects: orgProjectsResponse.data.orgProjects,
          adminSchemas: orgProjectsResponse.data.adminSchemas,
          projectActivity: orgProjectsResponse.data.projectActivity,
          allUsers: userResponse.data.allUsers,
          myUser: userResponse.data.myUser,
        }));
      }).catch(() => {
        dispatch(createTaxonomyFailure({ projectId: projectData.id, error: 'Unable to Connect to the Internet' }));
      });
    }).catch(() => {
      dispatch(createTaxonomyFailure({ projectId: projectData.id, error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function sendWorkingOrg(orgId) {
  return {
    type: types.CHANGE_WORKING_ORG,
    orgId
  };
}

export function updateWorkingOrg(orgId) {
  return (dispatch) => {
    dispatch(sendWorkingOrg(parseInt(orgId, 10)));
  };
}

export function sendWorkingProject(projectId) {
  return {
    type: types.CHANGE_WORKING_PROJECT,
    projectId
  };
}

export function updateWorkingProject(projectId) {
  return (dispatch) => {
    dispatch(sendWorkingProject(parseInt(projectId, 10)));
  };
}

export function sendWorkingTaxonomy(taxonomyId) {
  return {
    type: types.CHANGE_WORKING_TAXONOMY,
    taxonomyId
  };
}

export function updateWorkingTaxonomy(taxonomyId) {
  return (dispatch) => {
    dispatch(sendWorkingTaxonomy(parseInt(taxonomyId, 10)));
  };
}

export function sendInitiateSpeciesButtons(speciesList) {
  return {
    type: types.INITIATE_SPECIES_BUTTONS,
    speciesList
  };
}

export function initiateSpeciesButtons(speciesList) {
  return (dispatch) => {
    dispatch(sendInitiateSpeciesButtons(speciesList));
  };
}

export function sendInitiateFocusButtons(focusList) {
  return {
    type: types.INITIATE_FOCUS_BUTTONS,
    focusList
  };
}

export function initiateFocusButtons(focusList) {
  return (dispatch) => {
    dispatch(sendInitiateFocusButtons(focusList));
  };
}

export function sendPushSpeciesButtons(speciesId) {
  return {
    type: types.PUSH_SPECIES_BUTTONS,
    speciesId
  };
}

export function pushSpeciesButtons(speciesId) {
  return (dispatch) => {
    dispatch(sendPushSpeciesButtons(speciesId));
  };
}

export function sendPushFocusButtons(speciesId) {
  return {
    type: types.PUSH_FOCUS_BUTTONS,
    speciesId
  };
}

export function pushFocusButtons(speciesId) {
  return (dispatch) => {
    dispatch(sendPushFocusButtons(speciesId));
  };
}

export function sendPopSpeciesButtons(speciesId) {
  return {
    type: types.POP_SPECIES_BUTTONS,
    speciesId
  };
}

export function popSpeciesButtons(speciesId) {
  return (dispatch) => {
    dispatch(sendPopSpeciesButtons(speciesId));
  };
}

export function sendPopFocusButtons(speciesId) {
  return {
    type: types.POP_FOCUS_BUTTONS,
    speciesId
  };
}

export function popFocusButtons(speciesId) {
  return (dispatch) => {
    dispatch(sendPopFocusButtons(speciesId));
  };
}

export function sendAdminTier(tier) {
  return {
    type: types.CHANGE_ADMIN_TIER,
    tier
  };
}

export function updateAdminTier(tier) {
  return (dispatch) => {
    dispatch(sendAdminTier(tier));
  };
}

export function sendAdminEditing(edit) {
  return {
    type: types.CHANGE_ADMIN_EDITING,
    edit
  };
}

export function updateAdminEditing(edit) {
  return (dispatch) => {
    dispatch(sendAdminEditing(edit));
  };
}

export function sendFoundAsset() {
  return {
    type: types.ADD_ASSET_FOUND,
  };
}

export function foundNewAsset(tier) {
  return (dispatch) => {
    dispatch(sendFoundAsset(tier));
  };
}

export function makeBriefRequest(projectId, recordType) {
  return request.get('/ui/v1/project/admin/brief/' + projectId + '/' + recordType);
}

export function projectBriefSuccess(data) {
  return { type: types.UPDATE_BRIEF_SUCCESS, data };
}

export function projectBriefFailure(data) {
  return {
    type: types.UPDATE_BRIEF_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function getProjectBrief(projectId, recordType) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeBriefRequest(projectId, recordType)
      .then(briefRecords => dispatch(projectBriefSuccess(briefRecords.data)))
      .catch(() => dispatch(projectBriefFailure({ projectId: projectId.id, error: 'Unable to Connect to the Internet' })));
  };
}

export function makeOverviewRequest(projectId) {
  return request.get('/ui/v1/project/admin/overview/' + projectId);
}

export function projectOverviewSuccess(data) {
  return { type: types.UPDATE_OVERVIEW_SUCCESS, data };
}

export function projectOverviewFailure(data) {
  return {
    type: types.UPDATE_OVERVIEW_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function getProjectOverview(projectId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return makeOverviewRequest(projectId)
      .then(briefRecords => dispatch(projectOverviewSuccess(briefRecords.data)))
      .catch(() => dispatch(projectOverviewFailure({ projectId: projectId.id, error: 'Unable to Connect to the Internet' })));
  };
}

export function sendInitiateRecord(record) {
  return {
    type: types.INITIATE_RECORD,
    record
  };
}

export function initiateRecord(record) {
  return (dispatch) => {
    dispatch(sendInitiateRecord(record));
  };
}

export function editRecord(method, uuid, projectId, recordType, data) {
  return request[method]('/ui/v1/project/admin/' + projectId + '/' + recordType + '/' + uuid, data);
}

export function getRecordSuccess(data) {
  return { type: types.GET_RECORD_SUCCESS, data };
}

export function getRecordFailure(data) {
  return {
    type: types.GET_RECORD_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function getRecord(uuid, projectId, recordType) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return editRecord('get', uuid, projectId, recordType, null)
      .then(recordData => dispatch(getRecordSuccess(recordData.data)))
      .catch(() => dispatch(getRecordFailure({ uuid, error: 'Unable to Connect to the Internet' })));
  };
}

export function setRecordSuccess(data) {
  return { type: types.SET_RECORD_SUCCESS, data };
}

export function setRecordFailure(data) {
  return {
    type: types.SET_RECORD_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function setRecord(uuid, projectId, recordType, record) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return editRecord('post', uuid, projectId, recordType, record)
      .then(recordData => dispatch(setRecordSuccess(recordData.data)))
      .catch(() => dispatch(setRecordFailure({ uuid, error: 'Unable to Connect to the Internet' })));
  };
}

export function makeUserRequest(method, assetType, assetId, data, action = '') {
  return request[method]('/ui/v1/user/admin/' + assetType + '/' + assetId + (action !== '' ? '/' + action : action), data);
}

export function updateUserPermissionsSuccess(data) {
  return { type: types.UPDATE_USER_PERMISSIONS_SUCCESS, data };
}

export function updateUserPermissionsFailure(data) {
  return {
    type: types.UPDATE_USER_PERMISSIONS_FAILURE,
    id: data.userId,
    error: data.error
  };
}

export function updateUserPermissions(assetType, assetId, userInfo, action) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    dispatch({ type: types.UPDATE_USER_PERMISSIONS_REQUEST });
    return makeUserRequest('post', assetType, assetId, userInfo, action)
      .then(updatedAdminPermissions => dispatch(updateUserPermissionsSuccess(updatedAdminPermissions.data)))
      .catch(() => dispatch(updateUserPermissionsFailure({ assetId, error: 'Unable to Connect to the Internet' })));
  };
}

export function sendToggleContextHelp() {
  return {
    type: types.TOGGLE_CONTEXT_HELP
  };
}

export function toggleContextHelp() {
  return (dispatch) => {
    dispatch(sendToggleContextHelp());
  };
}
