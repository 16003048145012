import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import SchemaEditor from '../components/SchemaEditor';
import { updateProjectInfo } from '../actions/admin';
import classNames from '../utils/classNames.js';
import * as types from '../types';
import styles from 'css/components/schemaeditors';

const cx = classNames.bind(styles);

class ProjectSchemaEditor extends Component {
  constructor(props) {
    super(props);

    this.updateSchema = this.updateSchema.bind(this);
  }

  updateSchema(updatedSchema, updatedUsedVariables) {
    const { updateProjectInfo, adminSchema, editSchema, usedVariables } = this.props;

    const updatedProject = {
      id: adminSchema.id,
      [editSchema]: updatedSchema,
      [usedVariables]: updatedUsedVariables,
    };

    updateProjectInfo(updatedProject);

    ReactGA.event({
      category: 'Dashboard: Project',
      action: 'Edit Schema: ' + this.props.editing,
      label: this.props.adminSchema.name,
    });
  }

  render() {
    const { adminSchema, projectActivity, workingProject, schemaReportType, editSchema, usedVariables, helpMessage } = this.props;

    return (
      <div
        className={cx('container')} >
        <h2>{projectActivity.name}</h2>
        {adminSchema.schema_lock ? (
          <div>
            <h2
              className={cx('container-subheader')} >
              Question Editing Locked
            </h2>
            {adminSchema.schema_lock_reason &&
              <div
                className={cx('schema-lock-explanation')} >
                {adminSchema.schema_lock_reason}
              </div>
            }
            {adminSchema.schema_lock_contact &&
              <h2
                className={cx('container-subheader')} >
                Contact Your Project Coordinator
              </h2>
            }
            {adminSchema.schema_lock_contact &&
              <div
                className={cx('schema-lock-explanation')} >
                {adminSchema.schema_lock_contact}
              </div>
            }
          </div>
        ) : (
          <div>
            <h2
              className={cx('container-subheader')} >
              Edit Questions
            </h2>
            <div
              className={cx('context-help')} >
              {helpMessage}
            </div>
            <SchemaEditor
              schema={adminSchema[editSchema]}
              updateSchema={this.updateSchema}
              assetId={workingProject}
              usedVariables={adminSchema[usedVariables]}
              reportType={schemaReportType} />
          </div>
        )}
      </div>
    );
  }
}

ProjectSchemaEditor.propTypes = {
  updateProjectInfo: PropTypes.func,
  editing: PropTypes.string.isRequired,
  adminSchema: PropTypes.object,
  schemaReportType: PropTypes.string,
  editSchema: PropTypes.string,
  usedVariables: PropTypes.string,
  projectActivity: PropTypes.object,
  workingProject: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const editing = state.admin.editing;
  const adminSchema = state.admin.adminSchemas[state.admin.workingProject];
  let schemaReportType;
  let editSchema;
  let usedVariables;
  let helpMessage;
  switch (editing) {
    case types.ACTIVITY_QUESTIONS_PROJECT:
      schemaReportType = types.REPORT_TYPE_OBSERVATIONS;
      editSchema = 'schema';
      usedVariables = 'used_variables';
      helpMessage = 'These questions will appear every time an Observation is entered, and the data export will include the answers to these questions in each row. You can add questions that will appear when a particular species is found in the Taxonomy Manager.';
      break;
    case types.ACTIVITY_METADATA_QUESTIONS_PROJECT:
      schemaReportType = types.REPORT_TYPE_METADATA;
      editSchema = 'metadata_schema';
      usedVariables = 'used_variables_metadata';
      helpMessage = 'These questions will be collected in the Metadata interface. The data export will include the answers to these questions in every Metadata row.';
      break;
    default:
      schemaReportType = types.REPORT_TYPE_OBSERVATIONS;
      editSchema = 'schema';
      usedVariables = 'used_variables';
      helpMessage = 'These questions will appear every time an Observation is entered. You can add questions that will appear when a particular species is found in the Taxonomy Manager.';
  }

  return {
    editing,
    adminSchema,
    schemaReportType,
    editSchema,
    usedVariables,
    helpMessage,
    projectActivity: state.admin.projectActivity[state.admin.workingProject],
    workingProject: state.admin.workingProject,
  };
}

export default connect(mapStateToProps, { updateProjectInfo })(ProjectSchemaEditor);
