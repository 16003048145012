import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import update from 'immutability-helper';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { popSpeciesButtons, initiateSpeciesButtons, initiateFocusButtons } from '../actions/admin';
import SpeciesButton from './SpeciesButton';
import classNames from '../utils/classNames.js';
import styles from 'css/components/speciesbuttons';

const cx = classNames.bind(styles);

const style = {
  display: 'block',
  minWidth: '260px',
  width: '100%',
  margin: '0 auto 0',
};

const SortableItem = SortableElement(({card, index, buttonGroup}) => {
  return (
    <SpeciesButton
      buttonGroup={buttonGroup}
      className={cx('species-button-selector')}
      index={index}
      id={card.id}
      text={card.common_name} />
  );
});

const SortableList = SortableContainer(({cards, buttonGroup}) => {
  return (
    <div>
      {cards.map((card, index) => (
        <SortableItem
          key={card.id}
          index={index}
          card={card}
          buttonGroup={buttonGroup} />
      ))}
    </div>
  );
});

// @DragDropContext(HTML5Backend)
class SpeciesButtons extends Component {
  constructor(props) {
    super(props);

    this.moveSpeciesButton = this.moveSpeciesButton.bind(this);
    this.generateSpeciesButtonList = this.generateSpeciesButtonList.bind(this);

    if (props.buttonGroup === 'species_list') {
      this.state = {
        currentTaxonomy: props.taxonomyId,
        lastReceivedSpeciesButtons: props.speciesButtonList,
        cards: this.generateSpeciesButtonList(props.speciesButtonList, props.activeTaxonomyLookup),
      };
    } else {
      this.state = {
        currentTaxonomy: props.taxonomyId,
        lastReceivedSpeciesButtons: props.focusButtonList,
        cards: this.generateSpeciesButtonList(props.focusButtonList, props.activeTaxonomyLookup),
      };
    }
  }

  generateSpeciesButtonList(listIds, taxonomy) {
    return listIds.map((thisSpecies) => {
      return (
        taxonomy[thisSpecies]
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.buttonGroup === 'species_list') {
      if ((this.state.currentTaxonomy !== nextProps.taxonomyId) || (JSON.stringify(this.state.lastReceivedSpeciesButtons) !== JSON.stringify(nextProps.speciesButtonList))) {
        this.setState({
          currentTaxonomy: nextProps.taxonomyId,
          lastReceivedSpeciesButtons: nextProps.speciesButtonList,
          cards: this.generateSpeciesButtonList(nextProps.speciesButtonList, nextProps.activeTaxonomyLookup)
        });
      }
    } else {
      if ((this.state.currentTaxonomy !== nextProps.taxonomyId) || (JSON.stringify(this.state.lastReceivedSpeciesButtons) !== JSON.stringify(nextProps.focusButtonList))) {
        this.setState({
          currentTaxonomy: nextProps.taxonomyId,
          lastReceivedSpeciesButtons: nextProps.focusButtonList,
          cards: this.generateSpeciesButtonList(nextProps.focusButtonList, nextProps.activeTaxonomyLookup)
        });
      }
    }
  }

  moveSpeciesButton({oldIndex, newIndex}) {
    const {
      buttonGroup,
      updateSpeciesList,
      initiateSpeciesButtons,
      initiateFocusButtons
    } = this.props;
    const {
      cards
    } = this.state;

    const dragSpeciesButton = cards[oldIndex];

    this.setState(update(this.state, {
      cards: {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, dragSpeciesButton],
        ],
      },
    }), () => {
      if (buttonGroup === 'species_list') {
        initiateSpeciesButtons(this.state.cards.map((thisSpecies => { return thisSpecies.id; })));
      } else {
        initiateFocusButtons(this.state.cards.map((thisSpecies => { return thisSpecies.id; })));
      }
    });
  }

  render() {
    const { buttonGroup } = this.props;
    const { cards } = this.state;

    return (
      <div style={style}>
        {cards[0] && (
          <SortableList
            cards={cards}
            buttonGroup={buttonGroup}
            onSortEnd={this.moveSpeciesButton} />
        )}
      </div>
    );
  }
}

SpeciesButtons.propTypes = {
  buttonGroup: PropTypes.string.isRequired,
  popSpeciesButtons: PropTypes.func.isRequired,
  initiateSpeciesButtons: PropTypes.func.isRequired,
  initiateFocusButtons: PropTypes.func.isRequired,
  speciesButtonList: PropTypes.array.isRequired,
  focusButtonList: PropTypes.array.isRequired,
  taxonomyId: PropTypes.number.isRequired,
  activeTaxonomyLookup: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    speciesButtonList: state.admin.speciesButtons,
    focusButtonList: state.admin.focusButtons,
    taxonomyId: state.admin.activeTaxonomy.meta.id,
    activeTaxonomyLookup: state.admin.activeTaxonomy.lookup,
  };
}

export default connect(mapStateToProps, { popSpeciesButtons, initiateSpeciesButtons, initiateFocusButtons })(SpeciesButtons);
