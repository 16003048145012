import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from 'react-loading';
import classNames from '../utils/classNames.js';
import { logOut } from '../actions/users';
import Navigation from '../containers/Navigation';
import PanelAdmin from '../containers/PanelAdmin';
import Footer from '../containers/Footer';
import Message from '../containers/Message';
import styles from '../css/main';

const cx = classNames.bind(styles);

class Embed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;

    return (
      <div className={cx('app')}>
        <div
          className={cx('app-container')} >
          <main
            className={cx('app-wrapper')} >
            <div
              className={cx('app-content')} >
              {children}
            </div>
          </main>
        </div>
      </div>
    );
  }
}

Embed.propTypes = {
  children: PropTypes.object,
  currentPage: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    currentPage: ownProps.location.pathname,
  };
}

export default connect(mapStateToProps, { logOut })(Embed);
