import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Dimensions from '../utils/local_modules/react-dimensions';
import Waypoint from 'react-waypoint';
import classNames from '../utils/classNames.js';
import styles from 'css/components/features';

const cx = classNames.bind(styles);

class Features extends Component {
  constructor(props) {
    super(props);

    this.reportFeatureView = this.reportFeatureView.bind(this);
  }

  reportFeatureView(segment) {
    ReactGA.event({
      category: 'Features',
      action: 'Read ' + segment,
    });
  }

  render() {
    const { containerWidth, containerHeight } = this.props;

    const maxVideoHeight = 400;
    const videoWidth = parseInt(Math.min(maxVideoHeight * (454 / 320), containerWidth), 10);
    const videoHeight = parseInt(Math.min(maxVideoHeight, (containerWidth * (320 / 454))), 10);
    const overlayHeight = Math.max(300, videoHeight + 80);

    return (
      <div
        className={cx('container')} >
        <div
          style={{
            height: overlayHeight,
          }}
          className={cx('tablet-video-container')} >
          <video
            loop
            muted
            autoPlay
            poster="https://cdn.dunkadoo.org/static/dunkadoo-tablet-demo.jpg"
            style={{
              width: videoWidth,
              maxHeight: maxVideoHeight,
            }}
            className={cx('tablet-video')} >
            <source
              src="https://cdn.dunkadoo.org/static/dunkadoo-tablet-demo.webm"
              type="video/webm" />
            <source
              src="https://cdn.dunkadoo.org/static/dunkadoo-tablet-demo.mp4"
              type="video/mp4" />
          </video>
          <div
            style={{
              height: overlayHeight,
            }}
            className={cx('tablet-video-overlay')} >
            &nbsp;
          </div>
          <div
            style={{
              height: overlayHeight,
            }}
            className={cx('tablet-video-information')} >
            <div
              className={cx('headline')} >
              Your Data
            </div>
            <div
              className={cx('headline')} >
              From Field to Everywhere
            </div>
            <div
              className={cx('headline')} >
              Instantly
            </div>
          </div>
        </div>
        <div
          className={cx('segment-header')} >
          Take Your Fieldwork to the Next Level
        </div>
        <div
          className={cx('segment-contents', 'steps')} >
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/steps-1-taxonomy.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Step 1: Select and Customize Your Taxonomy
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/steps-2-project.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Step 2: Configure Your Questions and Metadata
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/steps-3-collect-data.jpg" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Step 3: Collect Data with Your Team in the Field
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/steps-4-data-export.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Step 4: Export Your Data for Analysis
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/steps-5-share-map.jpg" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Bonus: Share Your Research with the Public and Collaborators
                </div>
              </div>
            </div>
          </div>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportFeatureView('Steps');
        }} />
        <div
          className={cx('dream-info')} >
          <div
            className={cx('dream-professions')} >
            <h3>A Biologist</h3>
            <h3>An Ecologist</h3>
            <h3>An Ornithologist</h3>
            <h3>A Herpetologist</h3>
            <h3>A Botanist</h3>
            <h3>A Lepidopterist</h3>
            <h3>A Forester</h3>
            <h3>A Mammalogist</h3>
            <h3>An Ichthyologist</h3>
            <h3>An Entomologist</h3>
            <h3>A Biologist</h3>
          </div>
          <h3
            className={cx('possessive-dream')} >
            &apos;s Dream
          </h3>
        </div>
        <div
          className={cx('segment-header')} >
          <span className={cx('subheader')} >Your research is focused on species.</span>
          <span className={cx('subheader')} >Why isn&apos;t your software?</span>
        </div>
        <div
          className={cx('segment-contents')} >
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/intro-taxonomy.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Understanding the Natural World
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo is built around taxonomies. Unlike tools you may have used before, our taxonomies are as living and growing as the life you study. As your understanding of your research grows, you can add attributes, questions, and new species.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Custom
                  </div>
                  <div
                    className={cx('details')} >
                    We&apos;re always adding new taxonomies. <a href="mailto:support@dunkadoo.org?Subject=Taxonomy%20Import">Contact us</a>, and we&apos;ll help you get your taxonomies imported. We can even work with you to make your taxonomies available to other Dunkadoo researchers if you want.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Standard
                  </div>
                  <div
                    className={cx('details')} >
                    Dunkadoo already has many standard taxonomies ready to use. You can easily make copies to customize.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/biologists-species-bee.jpg" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  The Study of Species
                </div>
                <div
                  className={cx('details')} >
                  Every species is different, so shouldn&apos;t your software be smart enough to understand that? Once you&apos;ve setup your species in Dunkadoo, we&apos;ll remember to ask you the right questions for each species in the field. Can your spreadsheets and notebooks do that?
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Species Questions
                  </div>
                  <div
                    className={cx('details')} >
                    With the power to ask different questions for different species, you can revolutionize the way you collect field data. When a species is found, your questions will appear automatically. For the first time, you can collect detailed per-species data without stacks of forms and confusing datasheets.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Attributes
                  </div>
                  <div
                    className={cx('details')} >
                    Whether you categorize within a species by morph, age, sex, capture status, or subspecies, Dunkadoo can help you collect your data faster. Attributes are a special type of question that can be quickly chosen and are broken out for quick reference in datasheets and data exports.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Species Codes
                  </div>
                  <div
                    className={cx('details')} >
                    If you use short codes for species, like ECHIN for Purple Coneflower or GREG for Great Egret, you can add those too. Now you can enter sightings as fast as you can find them.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportFeatureView('Taxonomies');
        }} />
        <div
          className={cx('segment-header')} >
            Data Collection
        </div>
        <div
          className={cx('segment-contents')} >
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-devices.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Collect Data on Phones and Tablets
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo&apos;s mobile apps run on Android phones, Android tablets, iPhones, and iPads. In fact, your team probably already has lots devices ready to run Dunkadoo.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Android
                  </div>
                  <div
                    className={cx('details')} >
                    <ReactGA.OutboundLink
                      eventLabel="Features: Google Play Store"
                      to="https://play.google.com/store/apps/details?id=org.dunkadoo.mobileprod"
                      rel="noopener noreferrer" >
                      <img
                        alt="Google Play Store"
                        className={cx('app-store-download')}
                        src="https://cdn.dunkadoo.org/email/get-it-on-google-play.png" />
                      Android App for Phones and Tablets
                    </ReactGA.OutboundLink>
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    iOS
                  </div>
                  <div
                    className={cx('details')} >
                    <ReactGA.OutboundLink
                      eventLabel="Features: Apple App Store"
                      to="https://itunes.apple.com/us/app/dunkadoo/id1248418890"
                      rel="noopener noreferrer" >
                      <img
                        alt="Google Play Store"
                        className={cx('app-store-download')}
                        src="https://cdn.dunkadoo.org/email/download-on-the-app-store.png" />
                      iOS App for iPhones and iPads
                    </ReactGA.OutboundLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-count.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Rapidly Enter Species
                </div>
                <div
                  className={cx('details')} >
                  Select the Species Buttons you want to show up on the Count page of the Mobile App. Tap on the species for each individual sighted or enter larger counts on the keypad. If you don&apos;t have a button configured for a species you found, you can search for it from your taxonomy.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-questions.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Answer Questions
                </div>
                <div
                  className={cx('details')} >
                  With every observation, questions are automatically generated based on the species and your Project design.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Text
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Number
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Options
                  </div>
                  <div
                    className={cx('details')} >
                    Input the range of responses for quick selection in the Mobile App. With the right planning and well thought out options, you can greatly speed up your data collection.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Open Ended Lists
                  </div>
                  <div
                    className={cx('details')} >
                    When you have long lists of possible options and you can&apos;t anticipate all of the possible findings, the Open Ended List question will display suggestions as you type in your response. You can also type in a custom response if your response isn&apos;t in the list.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-metadata.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Record Metadata
                </div>
                <div
                  className={cx('details')} >
                  Whether you record information about each location, hour, day, or contract, Dunkadoo&apos;s Metadata tools help you to collect your context data in the field.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Start &amp; End Times
                  </div>
                  <div
                    className={cx('details')} >
                    Input start and end times with every Metadata to make analysis easier.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-datasheet.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Live Datasheet
                </div>
                <div
                  className={cx('details')} >
                  Fieldwork can be hectic, so Dunkadoo helps you stay organized with a datasheet that updates with every change.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Attributes
                  </div>
                  <div
                    className={cx('details')} >
                    If you use species attributes, Dunkadoo will automatically break them out in lines below the species totals.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Smart Start Times
                  </div>
                  <div
                    className={cx('details')} >
                    Set your datasheet to tabulate on the top of the hour, at the start of your first Metadata, or at the local sunrise.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Fast Editor Access
                  </div>
                  <div
                    className={cx('details')} >
                    Do you need to quickly edit your data? By simply touching on the cell containing the species and time, the Editor will filter only those observations.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-offline.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Collect Data Offline
                </div>
                <div
                  className={cx('details')} >
                  Whether you&apos;re going into the field for an afternoon or a month, Dunkadoo can securely store your data. When you reconnect, all of your data will get submitted immediately.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-online.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Submit Data Instantly
                </div>
                <div
                  className={cx('details')} >
                  With a connection to the internet, your data submits to Dunkadoo in seconds.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-position.jpg" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Location Data
                </div>
                <div
                  className={cx('details')} >
                  Every observation automatically includes GPS Coordinates. This is perfect for geospatial (GIS) analysis.<br />(Precision and availability of GPS data is limited by the device used to collect data.)
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-collection-flags.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Flag Data
                </div>
                <div
                  className={cx('details')} >
                  Quality Control is hard work, and trying to remember what you were thinking when you made your field notes is even harder. That&apos;s why Dunkadoo lets you flag observations to help you find them faster later.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Review
                  </div>
                  <div
                    className={cx('details')} >
                    Observations flagged for review will be highlighted in red on the Editor page online.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Notable
                  </div>
                  <div
                    className={cx('details')} >
                    Whether you have additional procedures for difficult to find or identify species or whether you want to share exciting observations with your fans, it only takes a second to add a notable flag.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx('segment-blurb')} >
          <span className={cx('headline')} >What&apos;s with all the black and white?</span>
          <div className={cx('detail-columns')} >After tens of thousands of hours of user testing, development, and use, we have come to appreciate a simple, high-contrast color scheme. When you&apos;re recording data in the field, it doesn&apos;t matter how slick the graphic design looks if you can&apos;t read it in bright sunlight. By using black and white, we make our services more usable in the challenging conditions that our researchers find themselves in every day.</div>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportFeatureView('Data Collection');
        }} />
        <div
          className={cx('segment-header')} >
            Project Management
        </div>
        <div
          className={cx('segment-contents')} >
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/project-management-submission-calendar.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Manage Your Research Online
                </div>
                <div
                  className={cx('details')} >
                  If you have a cabinet of field notebooks, you understand that your data is only as valuable as it is accessible.
                  <br />
                  Dunkadoo gives you the tools to manage your field season from your office - no matter how far your team has to travel to get the data.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/project-management-edit-project.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Setup New Projects in Minutes
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo makes it easy to configure your Project in minutes. With an intuitive interface and help tips right next to the settings, you&apos;ll be a pro in no time.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/project-management-everywhere.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Your Data - Everywhere
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo was engineered to keep your data, and your team, synchronized everywhere. With groundbreaking bidirectional syncing capabilities, Dunkadoo keeps your database and all of your team&apos;s devices updated.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Cloud
                  </div>
                  <div
                    className={cx('details')} >
                    Whether edits happen in the Mobile Apps or from your computer, everything is stored in the central database on Dunkadoo&apos;s cloud services.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Mobile Apps
                  </div>
                  <div
                    className={cx('details')} >
                    Every time you sign-in on one of the Mobile Apps with an internet connection, all of that user&apos;s Project data will synchronize.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/project-management-evolving.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Evolve Your Research
                  <br />
                  While It&apos;s in Progress
                </div>
                <div
                  className={cx('details')} >
                  The industry veterans who founded Dunkadoo knew that making changes midway through a study was going to be our greatest challenge, but they couldn&apos;t think of a single major research project they had been involved in that hadn&apos;t required some changes after starting. In founding Dunkadoo, they set out to make this monumental engineering challenge unnoticeable to users.
                  <br />
                  Today, Dunkadoo allows you to make changes to your observation questions, metadata questions, and taxonomy at any point during your study, and these changes will appear everywhere.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/project-management-invite-users.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Manage Your Team and Collaborators
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo makes it easy to add and remove users from your Projects. Need to get a big team started quickly? Type in email addresses, hit send, and they can be collecting data in minutes.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Organization
                  </div>
                  <div
                    className={cx('details')} >
                    Add team members at the Organization level to grant them access to manage all of your Projects, Taxonomies, and data.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Project
                  </div>
                  <div
                    className={cx('details')} >
                    At the Project level, you can assign users to collect data or manage the Project. If they don&apos;t need access to all of your Projects, add them just to the Projects where they&apos;re needed.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportFeatureView('Project Management');
        }} />
        <div
          className={cx('segment-header')} >
          Data Export
        </div>
        <div
          className={cx('segment-contents')} >
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/steps-4-data-export.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Download Your Data
                </div>
                <div
                  className={cx('details')} >
                  Export your data anytime from the online Dashboard.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    CSV
                  </div>
                  <div
                    className={cx('details')} >
                    CSV files are the standard for sharing data between spreadsheet applications without using proprietary formats. These files can be opened in any spreadsheet program and can be imported into most databases.
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    JSON
                  </div>
                  <div
                    className={cx('details')} >
                    Dunkadoo data is highly structured, and the JavaScript Object Notation (JSON) format allows us to preserve this organization in exports. If you have experience working with this format, you&apos;ll appreciate the structure of the data.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-export-api.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  API Access
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo has a robust API for exporting data to our partners. You can <a href="mailto:support@dunkadoo.org?Subject=API%20Access">contact our support team</a> to discuss connecting Dunkadoo&apos;s powerful data collection features with your application.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/data-export-integrations.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Partner Integrations
                </div>
                <div
                  className={cx('details')} >
                  Dunkadoo works with partners like HawkCount.org to automatically submit your data as it is collected. We&apos;re always looking to add new integrations, so <a href="mailto:apps@dunkadoo.org?Subject=Dunkadoo%20Integrations">contact our team</a> to discuss ways to automatically submit your data.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportFeatureView('Data Export');
        }} />
        <div
          className={cx('segment-header')} >
          Public Education and Outreach
        </div>
        <div
          className={cx('segment-contents')} >
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public-share-your-research.jpg" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Share Your Research with a Global Audience
                </div>
                <div
                  className={cx('details')} >
                  Anywhere you can find a connection to the internet is now a platform for global science outreach and education.
                  <br />
                  Show the world how cool science can be! Whether you are a non-profit with a mission to inform the public about conservation, a researcher with an outreach component to your grant, or you&apos;re wanting your research to make a broader impact, Dunkadoo has the tools you need to engage the public.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_textupdates_crop.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Real-Time Data
                </div>
                <div
                  className={cx('details')} >
                  Seconds after you enter your sightings in the field, they appear on the web.
                  <br />
                  When we first introduced this feature, scientists were alarmed. What if something gets misidentified? What is the scientific value of real-time data?
                  <br />
                  In the years since, the community has learned to love real-time data. It&apos;s strangely mesmerizing and exciting to watch for updates to appear.
                  <br />
                  We&apos;ve heard stories of birders watching the migration from a hospital bed and visitors running to count platforms when cool birds approach.
                  <br />
                  The merit of real-time data is in the magic it brings to a wider audience. Much like nest cameras have enchanted millions who watch Ospreys and Eagles raise their chicks, we hope that live data will inspire the next generation of biologists and environmentalists.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public-configure-page.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Public Pages
                </div>
                <div
                  className={cx('details')} >
                  You can activate a Public Page for your Project at any time from the Dashboard. On this page you can choose what to show and what not to show, and you can add additional information about your research for the public to read.
                </div>
              </div>
              <div
                className={cx('segment-block-minor-container')} >
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    See Examples of Public Pages
                  </div>
                  <div
                    className={cx('details')} >
                    <Link
                      to="/project" >
                      Click here for a list of featured Projects.
                    </Link>
                  </div>
                </div>
                <div
                  className={cx('segment-block-minor-details')} >
                  <div
                    className={cx('headline')} >
                    Which Data Should I Show?
                  </div>
                  <div
                    className={cx('details')} >
                    Each of the sections on public pages can be independently turned on and off. Don&apos;t need mapping data? You can turn it off with an easy switch.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_livechart.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Real-Time Charts and Updates
                </div>
                <div
                  className={cx('details')} >
                  This scatter plot shows the last two hours of observations and slowly slides by.
                  <br />
                  This chart, like other items on public pages, is smart enough to update itself if you need to make a change to your data.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_map.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Real-Time Interactive Maps
                </div>
                <div
                  className={cx('details')} >
                  As you collect data, GPS coordinates are automatically added. This map updates new findings in real-time, and users can interact with the map to discover what you&apos;re finding in the field with only seconds of delay.
                  <br />
                  This map is perfect for events like birding festivals where attendees are looking for the best birds. Simply add your tour guides on the Dunkadoo Dashboard, and they can add birds from their phones as they find them. The sightings will then appear on your map on the public page, and attendees can find the local highlights.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_flagged.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Highlight Notable Observations
                </div>
                <div
                  className={cx('details')} >
                  When you select &quot;Flag Notable&quot; in the Mobile App, these selected observations will be highlighted in this section and will stay on the page for as long as you want.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_composition.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Species Composition with Adjustable Time Range
                </div>
                <div
                  className={cx('details')} >
                  Over a field season, species composition often changes. This interactive graph allows visitors to change the date range displayed on the pie chart. They can also drill deeper into the chart and highlight focus species you have selected for the Project.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_hourly.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Hourly Data
                </div>
                <div
                  className={cx('details')} >
                  This chart allows more seasoned collaborators, volunteers, and visitors to dig more deeply into your data.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_speciescharts.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Species Season Charts
                </div>
                <div
                  className={cx('details')} >
                  It can be difficult for visitors to understand changes in species composition and abundance that may happen over a period of months. These graphs show sightings of each species over time.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public_weather.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Weather Forecast
                </div>
                <div
                  className={cx('details')} >
                  Share a three day weather forecast for your site provided by the United States National Weather Service.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public-data-notice.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Data Notice
                </div>
                <div
                  className={cx('details')} >
                  We provide a section for you to inform your visitors how they may, and may not, use your data. For example, you may stipulate that your data may only be used with written notice.
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('segment-block')} >
            <div
              className={cx('segment-block-image')} >
              <img
                alt="Dunkadoo runs on any device"
                src="https://cdn.dunkadoo.org/static/public-donations.png" />
            </div>
            <div
              className={cx('segment-block-description')} >
              <div
                className={cx('segment-block-major-details')} >
                <div
                  className={cx('headline')} >
                  Engage the Public for Donations
                </div>
                <div
                  className={cx('details')} >
                  At the bottom of each public page, we offer a place for you to thank sponsors and encourage the public to donate to your research. You simply add a link to the donation page on your website or on PayPal, and we don&apos;t keep any portion of their donation as it goes directly to you.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Waypoint
          onEnter={() => {
          this.reportFeatureView('Public Education and Outreach');
        }} />
        <div
          className={cx('segment-blurb', 'centered')} >
          <span className={cx('headline')} >Ready to Get Started?</span>
          <Link
            to="/login" >
            Click Here to Create an Account
          </Link>
        </div>
      </div>
    );
  }
}

Features.propTypes = {
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, { })(
  Dimensions({
    getHeight: function(element) {
      return element.clientHeight;
    },
    getWidth: function(element) {
      return element.clientWidth;
    },
    elementResize: true
})(Features));
