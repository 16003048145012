import React from 'react';
import classNames from '../utils/classNames.js';
import { Link } from 'react-router';
import styles from 'css/components/about';

const cx = classNames.bind(styles);

const Acknowledgements = () => {
  return (
    <div className={cx('about')}>
      <div className={cx('description')}>
        <h1>Acknowledgements</h1>
        <h2>Taxonomies</h2>
        <ul>
          <li>American Ornithological Society (AOS; R.T. Chesser, et al., comps.). 2017. Fifty-eighth supplement to the American Ornithological Society&apos;s check-list of North American birds. Auk 134:751-773. DOI: 10.1642/AUK-17-72.1.</li>
          <li>Lepage, Denis. Avibase. Downloaded from https://avibase.bsc-eoc.org</li>
          <li>Clements, J. F., T. S. Schulenberg, M. J. Iliff, D. Roberson, T. A. Fredericks, B. L. Sullivan, and C. L. Wood. 2017. The eBird/Clements checklist of birds of the world: v2016.</li>
          <li>Pyle, P., and D.F. DeSante. 2009. Updates to four-letter and six-letter alpha codes based on revisions by the American Ornithologists&apos; Union in 2007-2008. North American Bird Bander 34:65-67.</li>
        </ul>
        <h2>Service Donations</h2>
        <div>
          <a href="https://tkjs.us/2qC52oh" title="Protected by TrackJS JavaScript Error Monitoring">
            <img
              src="https://trackjs.com/assets/external/badge.gif"
              height="40px"
              alt="Protected by TrackJS JavaScript Error Monitoring"
              style={{borderRadius: 2}} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Acknowledgements;
