import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import classNames from '../utils/classNames.js';
import styles from 'css/components/speciescomp';
import zip from 'lodash/zip';
import uniqBy from 'lodash/uniqBy';
import ReactHighcharts from 'react-highcharts';
import ReactHighstock from 'react-highcharts/ReactHighstock';
import highchartsExporting from 'highcharts-exporting';
import moment from 'moment-timezone';
import downloadIcon from '../images/Download-Icon.png';

const cx = classNames.bind(styles);

const pieOccupancy = 7;

class SpeciesComp extends Component {
  constructor(props) {
    super(props);

    this.timeRangeGenerator = this.timeRangeGenerator.bind(this);
    this.exportFilenameGenerator = this.exportFilenameGenerator.bind(this);
    this.generateChartConfigs = this.generateChartConfigs.bind(this);
    this.updateCharts = this.updateCharts.bind(this);
    this.leaveDrilldown = this.leaveDrilldown.bind(this);
    this.getSeriesColor = this.getSeriesColor.bind(this);
    this.generateVolumeGraph = this.generateVolumeGraph.bind(this);
    this.updateVolumeGraphDateRange = this.updateVolumeGraphDateRange.bind(this);
    this.groupingChange = this.groupingChange.bind(this);
    this.drillData = this.drillData.bind(this);

    this.state = {
      speciesDays: props.speciesDays,
      dateList: props.dateList,
      dailyTotal: props.dailyTotal,
      seasonSpecies: this.generateChartConfigs(),
      volumeGraph: false,
      grouping: 'all',
      rawStart: moment.tz(props.projectData.startDate, props.projectData.timezone).tz('UTC').valueOf(),
      rawEnd: Math.min(moment.tz(props.projectData.endDate, props.projectData.timezone).tz('UTC').valueOf(), moment().tz(props.projectData.timezone).startOf('day').tz('UTC').valueOf()),
      startDate: moment.tz(props.projectData.startDate, props.projectData.timezone).tz('UTC').format(),
      endDate: moment.min(moment.tz(props.projectData.endDate, props.projectData.timezone).tz('UTC'), moment().tz(props.projectData.timezone).startOf('day').tz('UTC')).format(),
      isMobile: true,
      listenForRange: null,
      noDataToDisplay: false,
      drilled: false,
      interactionDate: false,
    };
  }

  componentDidMount() {
    const isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 600px)').matches) || window.innerWidth <= 600;

    this.setState({
      isMobile,
    }, () => {
      this.generateVolumeGraph();
      this.updateCharts();
    });

    // HighCharts Compatibility
    if (!('speciesCompTimeRangeGenerator' in window)) {
      window.speciesCompTimeRangeGenerator = this.timeRangeGenerator.bind(this);
      window.speciesCompExportFilenameGenerator = this.exportFilenameGenerator.bind(this);

      highchartsExporting(ReactHighcharts.Highcharts);
    }
  }

  componentWillReceiveProps(nextProps) {
    let additionalObservations = [];

    if ((this.props.recentObservationsOrdered.length !== nextProps.recentObservationsOrdered.length) || ((nextProps.recentObservationsOrdered.length > 0) && (this.props.recentObservationsOrdered[this.props.recentObservationsOrdered.length - 1].uuid !== nextProps.recentObservationsOrdered[nextProps.recentObservationsOrdered.length - 1].uuid))) {
      nextProps.recentObservationsOrdered.forEach((thisObs) => {
        if (!(thisObs.uuid in this.props.recentObservationsIdentified)) {
          additionalObservations.push(thisObs);
        }
      });
    }

    if ((this.props.recentCoordinatesOrdered.length !== nextProps.recentCoordinatesOrdered.length) || ((nextProps.recentCoordinatesOrdered.length > 0) && (this.props.recentCoordinatesOrdered[this.props.recentCoordinatesOrdered.length - 1].uuid !== nextProps.recentCoordinatesOrdered[nextProps.recentCoordinatesOrdered.length - 1].uuid))) {
      nextProps.recentCoordinatesOrdered.forEach((thisObs) => {
        if (!(thisObs.uuid in this.props.recentCoordinatesIdentified)) {
          additionalObservations.push(thisObs);
        }
      });
    }

    if (additionalObservations.length > 0) {
      const dateList = this.state.dateList;
      const speciesDays = this.state.speciesDays;

      additionalObservations = uniqBy(additionalObservations, 'uuid');

      additionalObservations.forEach((thisObs) => {
        const obsTime = moment.tz(thisObs.recorded_at, this.props.projectData.timezone);
        const obsDay = obsTime.startOf('day').tz('UTC').format();

        // Day not there yet
        // Species not there yet

        if (dateList.indexOf(obsDay) === -1) {
          console.log('need to add day');
        }

        speciesDays[thisObs.species_id][dateList.indexOf(obsDay)] += thisObs.count;
      });

      this.setState({
        dateList,
        speciesDays,
      }, () => {
        this.updateCharts();
      });
    }
  }

  getSeriesColor(species) {
    const colorScheme = ['#BE1E2D', '#BE1E3A', '#BF1E48', '#C01E56', '#C11E65', '#C21E73', '#C31E81', '#C41E90', '#C51E9F', '#C61EAE', '#C71EBD', '#C31EC7', '#B51EC8', '#A71EC9', '#991ECA', '#8B1ECB', '#7D1ECC', '#6E1ECD', '#601ECE', '#511ECF', '#421ED0', '#331ED1', '#241ED1', '#1E27D2', '#1E36D3', '#1E46D4', '#1E55D5', '#1E65D6', '#1E75D7', '#1E86D8', '#1E96D9', '#1EA7DA', '#1EB7DA', '#1EC8DB', '#1ED9DC', '#1EDDD0', '#1DDEC0', '#1DDFB1', '#1DE0A1', '#1DE191', '#1DE281', '#1DE370', '#1DE460', '#1DE44F', '#1DE53E', '#1DE62D', '#1EE71D', '#2FE81D', '#41E91D', '#52EA1D', '#64EB1D', '#76EC1D', '#88ED1D', '#9AED1D', '#ACEE1D', '#BFEF1D', '#D1F01D', '#E4F11D', '#F2ED1D', '#F3DB1D', '#F4CA1D', '#F5B81D', '#F6A61D', '#F7951D'];
    let speciesCode = 0;

    for (let thisChar = 0; thisChar < species.length; thisChar += 1) {
      speciesCode += species.charCodeAt(thisChar) * 31;
    }

    speciesCode %= colorScheme.length;

    return colorScheme[speciesCode];
  }

  drillData() {
    this.setState({
      drilled: true,
    }, () => {
      ReactGA.event({
        category: 'Public: SpeciesComp',
        action: 'drillData',
        label: this.props.projectData.name,
      });

      this.updateCharts();
    });
  }

  leaveDrilldown() {
    this.setState({
      drilled: false,
    }, () => {
      ReactGA.event({
        category: 'Public: SpeciesComp',
        action: 'leaveDrilldown',
        label: this.props.projectData.name,
      });

      this.updateCharts();
    });
  }

  updateCharts() {
    const { taxonomy, focusSpeciesList } = this.props;

    let speciesList = Object.keys(this.state.speciesDays);
    let sortedSpeciesInput = [];

    // If the user selected the option to display only focus species, filter list
    if (this.state.grouping === 'focus') {
      speciesList = speciesList.filter((thisSpecies) => {
        const speciesId = parseInt(thisSpecies, 10);
        return focusSpeciesList.indexOf(speciesId) > -1;
      });
    }

    const startPeriod = Math.max(0, this.state.dateList.indexOf(this.state.startDate));

    const endIndex = this.state.dateList.indexOf(this.state.endDate);
    const endOfData = this.state.speciesDays[speciesList[0]].length - 1;
    let endPeriod = endOfData;
    if (endIndex !== -1) {
      endPeriod = Math.min(endIndex, endOfData);
    }

    /*
     * Species Composition Pie Chart
    */
    speciesList.forEach((species) => {
      const speciesCount = this.state.speciesDays[species].slice(startPeriod, endPeriod + 1).reduce((a, b) => {
        return a + b;
      }, 0);
      sortedSpeciesInput.push([taxonomy[species].common_name, speciesCount]);
    });

    sortedSpeciesInput = sortedSpeciesInput.filter((thisSpecies) => {
      return thisSpecies[1] > 0;
    });

    sortedSpeciesInput.sort((a, b) => {
      return b[1] - a[1];
    });

    const accumulateSpeciesData = [];

    for (let i = 0; i < Math.min(pieOccupancy, sortedSpeciesInput.length); i++) {
      const curSpeciesName = sortedSpeciesInput[i][0];

      accumulateSpeciesData.push({
        name: curSpeciesName,
        y: sortedSpeciesInput[i][1],
        color: this.getSeriesColor(curSpeciesName),
      });
    }

    const otherSpeciesData = [];
    if (sortedSpeciesInput.length > pieOccupancy) {
      let otherCount = 0;

      for (let i = pieOccupancy; i < sortedSpeciesInput.length; i += 1) {
        const curSpeciesName = sortedSpeciesInput[i][0];
        otherCount += sortedSpeciesInput[i][1];
        otherSpeciesData.push({
          name: curSpeciesName,
          y: sortedSpeciesInput[i][1],
          color: this.getSeriesColor(curSpeciesName)
        });
      }

      accumulateSpeciesData.push({
        name: 'Other',
        y: otherCount,
        color: this.getSeriesColor('Other'),
        events: {
          click: () => {
            this.drillData();
          }
        }
      });
    }

    const chartConfig = this.state.seasonSpecies;

    if (!this.state.drilled) {
      chartConfig.series[0] = {
        name: 'Most Abundant',
        colorByPoint: true,
        data: accumulateSpeciesData,
        dataLabels: {
          enabled: !this.state.isMobile
        },
        showInLegend: this.state.isMobile
      };
    } else {
      chartConfig.series[0] = {
        name: 'Other',
        colorByPoint: true,
        data: otherSpeciesData,
        dataLabels: {
          enabled: !this.state.isMobile
        },
        showInLegend: this.state.isMobile
      };
    }

    this.setState({
      seasonSpecies: chartConfig,
      noDataToDisplay: accumulateSpeciesData.length === 0,
    });
  }

  timeRangeGenerator() {
    let startDate = moment.tz(this.props.projectData.startDate, this.props.projectData.timezone).tz('UTC');
    let endDate = moment.tz(this.props.projectData.endDate, this.props.projectData.timezone).tz('UTC');

    if ('state' in this) {
      startDate = moment(this.state.startDate);
      endDate = moment(this.state.endDate);
    }

    if (startDate.isSame(endDate, 'day')) {
      return moment(this.state.startDate).format('MMMM Do');
    }

    return startDate.format('MMMM Do') + ' to ' + endDate.format('MMMM Do');
  }

  exportFilenameGenerator() {
    const { projectData } = this.props;

    return 'Species Composition - ' + projectData.name + ' - ' + this.timeRangeGenerator();
  }

  generateChartConfigs() {
    const { projectData } = this.props;

    const seasonSpeciesConfig = {
      chart: {
        plotBackgroundColor: null,
        backgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          margin: '0 auto 0'
        }
      },
      title: {
        text: null,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            formatter: function () {
              if (this.key === 'Other') {
                return '<span style="text-decoration:underline;">Other</span>';
              }
              return this.key;
            }
          }
        }
      },
      exporting: {
        enabled: true,
        filename: this.exportFilenameGenerator(),
        buttons: {
          contextButton: {
            symbol: 'url(' + downloadIcon + ')',
            width: 22,
            height: 22,
            x: -2,
            y: -2,
            symbolX: 20,
            symbolY: 20,
            symbolFill: null,
            menuItems: [{
                text: 'Download PNG',
                onclick: function () {
                  this.exportChart({
                    type: 'image/png',
                    filename: window.speciesCompExportFilenameGenerator(),
                    subtitle: {
                      text: window.speciesCompTimeRangeGenerator(),
                    },
                  });
                }
              }, {
                text: 'Download JPEG',
                onclick: function () {
                    this.exportChart({
                      type: 'image/jpeg',
                      filename: window.speciesCompExportFilenameGenerator(),
                      subtitle: {
                        text: window.speciesCompTimeRangeGenerator(),
                      },
                    });
                }
              }, {
                text: 'Download PDF',
                onclick: function () {
                  this.exportChart({
                      type: 'application/pdf',
                      filename: window.speciesCompExportFilenameGenerator(),
                      subtitle: {
                        text: window.speciesCompTimeRangeGenerator(),
                      },
                    });
                },
              separator: false
            }],
            theme: {
              fill: null,
              states: {
                hover: {
                  fill: '#FFFFFF',
                },
                select: {
                  fill: '#FFFFFF'
                }
              }
            }
          }
        },
        chartOptions: {
          title: {
            text: projectData.name,
          },
          subtitle: {
            text: this.timeRangeGenerator(),
          },
          credits: {
            enabled: true,
            text: '© ' + moment().format('YYYY') + ' ' + projectData.organization.name + ' - Chart generated by Dunkadoo.org'
          },
          chart: {
            plotBackgroundColor: '#FFFFFF',
            backgroundColor: '#FFFFFF',
          },
          plotOptions: {
            pie: {
              dataLabels: {
                formatter: function () {
                  return this.key;
                }
              }
            }
          },
        },
        sourceWidth: 800,
        sourceHeight: 800,
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormatter: function () {
          if (this.name === 'Other') {
            return 'Count: <b>' + String(this.y) + ' (' + this.percentage.toFixed(0) + '%)</b><br/><b>Click to Expand</b>';
          }
          return 'Count: <b>' + String(this.y) + ' (' + this.percentage.toFixed(0) + '%)</b>';
        }
      }
    };

    seasonSpeciesConfig.series = [{ name: 'Most Abundant', colorByPoint: true, data: [] }];

    return seasonSpeciesConfig;
  }

  generateVolumeGraph() {
    const { projectData } = this.props;

    const dateObjects = this.state.dateList.map((thisDay) => { return moment.tz(thisDay, projectData.timezone); });
    const dates = dateObjects.map((thisDay) => { return thisDay.valueOf(); });
    const dailyData = zip(dates, this.state.dailyTotal);
    const endOfLastDay = dateObjects[dateObjects.length - 1].valueOf();
    dailyData.push([
      endOfLastDay,
      this.state.dailyTotal[this.state.dailyTotal.length - 1]
    ]);

    const volumeGraph = {
      chart: {
        reflow: true,
        borderWidth: 0,
        height: 150,
        backgroundColor: null,
        zoomType: 'x',
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'datetime',
        visible: false,
        minRange: 12 * 3600 * 1000,
        title: {
          text: null
        },
        labels: false,
        events: {
          afterSetExtremes: (updatedExtremes) => {
            if ((Math.abs(updatedExtremes.min - this.state.rawStart) + Math.abs(updatedExtremes.max - this.state.rawEnd)) > 12 * 3600 * 1000) {
              this.updateVolumeGraphDateRange(updatedExtremes);
            }
          }
        }
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        min: 0,
        showFirstLabel: false
      },
      tooltip: {
        formatter: function () {
          return false;
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          fillColor: {
            linearGradient: [
              0, 0, 0, 70
            ],
            stops: [
              [
                0,
                '#7cb5ec'
              ],
              [1, 'rgba(255,255,255,0)']
            ]
          },
          lineWidth: 1,
          marker: {
            enabled: false
          },
          shadow: false,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          enableMouseTracking: false
        }
      },
      series: [
        {
          type: 'area',
          name: 'Total Daily Count',
          data: dailyData
        }
      ],
      exporting: {
        enabled: false
      },
      rangeSelector: {
        enabled: true,
        height: 35,
        buttons: [{
          type: 'hour',
          count: 18,
          text: '1d'
        }, {
          type: 'week',
          count: 1,
          text: '1w'
        }, {
          type: 'month',
          count: 1,
          text: '1m'
        }, {
          type: 'all',
          text: 'All'
        }],
      },
      scrollbar: {
        enabled: true,
        height: 20,
      },
      navigator: {
        enabled: true,
        height: 80,
        xAxis: {
          type: 'datetime',
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            enabled: false
          },
          showLastTickLabel: true,
          tickInterval: 24 * 3600 * 1000,
          minRange: 12 * 3600 * 1000,
          title: {
            text: null
          },
        }
      }
    };

    this.setState({
      volumeGraph,
    });
  }

  updateVolumeGraphDateRange(updatedExtremes) {
    const volumeGraph = this.state.volumeGraph;
    volumeGraph.xAxis.min = updatedExtremes.min;
    volumeGraph.xAxis.max = updatedExtremes.max;

    let interactionDate = this.state.interactionDate;
    if (!interactionDate) {
      ReactGA.event({
        category: 'Public: SpeciesComp',
        action: 'updateVolumeGraphDateRange',
        label: this.props.projectData.name,
      });

      interactionDate = true;
    }

    this.setState({
      volumeGraph,
      rawStart: updatedExtremes.min,
      rawEnd: updatedExtremes.max,
      startDate: moment.tz(updatedExtremes.min, this.props.projectData.timezone).startOf('day').tz('UTC').format(),
      endDate: moment.tz(updatedExtremes.max, this.props.projectData.timezone).startOf('day').tz('UTC').format(),
      interactionDate,
    }, () => {
      this.updateCharts();
    });
  }

  groupingChange(event) {
    const target = event.target;

    this.setState({
      grouping: target.value,
      drilled: false,
    }, () => {
      ReactGA.event({
        category: 'Public: SpeciesComp',
        action: 'groupingChange: ' + target.value,
        label: this.props.projectData.name,
      });

      this.updateCharts();
    });
  }

  render() {
    return (
      <div className={cx('speciescomp-region')}>
        <h3 className={cx('speciescomp', 'section-head')}>Species Composition<br /><span className={cx('speciescomp', 'section-subhead')}>{moment(this.state.startDate).format('MMMM Do')} to {moment(this.state.endDate).format('MMMM Do')}</span></h3>
        <div>
          <form
            className={cx('grouping-swapper')}
            onSubmit={(event) => {
              event.preventDefault();
            }} >
            <label htmlFor={'grouping'}>
              Display:
            </label>
            <select
              id={'grouping'}
              name="grouping"
              onChange={this.groupingChange}
              value={this.state.grouping} >
              <option value="all">All Species</option>
              <option value="focus">Focus Species</option>
            </select>
          </form>
        </div>
        {this.state.noDataToDisplay &&
          <h3 className={cx('speciescomp', 'section-head')}><span className={cx('speciescomp', 'section-subhead')}>No Data for This Period</span></h3>
        }
        <div className={cx('speciescomp', 'chart', 'season')} key={'speciescompchart-season'}>
          <ReactHighcharts config={this.state.seasonSpecies} isPureConfig={false} ref={(chart) => { this.seasonChart = chart; }} />
        </div>
        {this.state.drilled &&
          <div
            className={cx('exit-drilled-container')} >
            <button
              className={cx('exit-drilled-button')}
              onClick={() => {
                this.leaveDrilldown();
              }} >
              Return to Most Abundant Species
            </button>
          </div>
        }
        <div
        className={cx('speciescomp-instructions')} >
          Drag the Sliders Below to Change the Dates Shown
        </div>
        {this.state.volumeGraph &&
          <ReactHighstock config={this.state.volumeGraph} isPureConfig ref={(chart) => { this.volumeChart = chart; }} />
        }
      </div>
    );
  }
}

SpeciesComp.propTypes = {
  projectData: PropTypes.object.isRequired,
  taxonomy: PropTypes.object.isRequired,
  dateList: PropTypes.array.isRequired,
  dailyTotal: PropTypes.array.isRequired,
  speciesDays: PropTypes.object.isRequired,
  focusSpeciesList: PropTypes.array.isRequired,
  recentObservationsOrdered: PropTypes.array,
  recentObservationsIdentified: PropTypes.object,
  recentCoordinatesOrdered: PropTypes.array,
  recentCoordinatesIdentified: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    taxonomy: state.taxonomy.taxonomy,
    dateList: state.project.dateList,
    dailyTotal: state.project.dailyTotal,
    speciesDays: state.project.speciesDays,
    focusSpeciesList: state.project.projectData.focus_species_list,
    recentObservationsOrdered: state.project.recentObservations.ordered,
    recentObservationsIdentified: state.project.recentObservations.identified,
    recentCoordinatesOrdered: state.project.recentCoordinates.ordered,
    recentCoordinatesIdentified: state.project.recentCoordinates.identified,
  };
}

export default connect(mapStateToProps, { })(SpeciesComp);
