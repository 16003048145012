import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import some from 'lodash/some';
import classNames from '../utils/classNames.js';
import { updateWorkingOrg, updateWorkingProject, updateWorkingTaxonomy, updateAdminEditing, updateAdminTier, foundNewAsset } from '../actions/admin';
import styles from '../css/components/paneladmin';
import * as types from '../types';

const cx = classNames.bind(styles);

function translateTier(tier) {
  switch (tier) {
    case types.FOCUS_ORGANIZATION:
      return 'Dashboard: Organization';
    case types.FOCUS_PROJECT:
      return 'Dashboard: Project';
    case types.FOCUS_TAXONOMY:
      return 'Dashboard: Taxonomy';
    default:
      return tier;
  }
}

const orgActivities = [
  {
    activity: types.ACTIVITY_DETAILS_ORG,
    title: 'Organization Details',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_IMAGES_ORG,
    title: 'Organization Images',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_USERS_ORG,
    title: 'Users',
    needData: false,
    needAdmin: false,
  }
];

const projectActivities = [
  {
    activity: types.ACTIVITY_OVERVIEW,
    title: 'Overview',
    needData: true,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_DETAILS_PROJECT,
    title: 'Project Details',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_QUESTIONS_PROJECT,
    title: 'Questions',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_METADATA_QUESTIONS_PROJECT,
    title: 'Metadata Questions',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_SPECIES_BUTTON,
    title: 'Species Buttons',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_SPECIES_FOCUS,
    title: 'Focus Species',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_DETAILS_PROJECT_PUBLIC,
    title: 'Public Page',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_DETAILS_PROJECT_EMBED,
    title: 'Embed Code',
    needData: true,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_EDIT_OBSERVATION,
    title: 'Edit Observations',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_EDIT_METADATA,
    title: 'Edit Metadata',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_USERS_PROJECT,
    title: 'Users',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_DOWNLOAD,
    title: 'Download Data',
    needData: true,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_SUPERADMIN_PROJECT,
    title: 'SuperAdmin',
    needData: false,
    needAdmin: true,
  },
  {
    activity: types.ACTIVITY_INTEGRATION_HAWKCOUNT,
    title: 'SuperAdmin HawkCount',
    needData: false,
    needAdmin: true,
  },
];

const taxActivities = [
  {
    activity: types.ACTIVITY_DETAILS_TAXONOMY,
    title: 'Edit Taxonomy',
    needData: false,
    needAdmin: false,
  },
  {
    activity: types.ACTIVITY_TAXONOMY_ADD_SPECIES,
    title: 'Add a Species',
    needData: false,
    needAdmin: false,
  }
];

const adminBlocks = [
  {
    name: 'Manage',
    focus: types.FOCUS_ORGANIZATION,
    activities: orgActivities,
    displaySegments: false,
    needOrgManager: true,
    segments: [],
    reducer: 'workingOrg',
    change: 'changeWorkingOrg',
  },
  {
    name: 'Projects',
    focus: types.FOCUS_PROJECT,
    activities: projectActivities,
    displaySegments: true,
    needOrgManager: false,
    segments: 'Projects',
    reducer: 'workingProject',
    change: 'changeWorkingProject',
  },
  {
    name: 'Taxonomies',
    focus: types.FOCUS_TAXONOMY,
    activities: taxActivities,
    displaySegments: true,
    needOrgManager: true,
    segments: 'Taxonomies',
    reducer: 'workingTaxonomy',
    change: 'changeWorkingTaxonomy',
  },
];

class PanelAdmin extends Component {
  constructor(props) {
    super(props);

    this.pickAProject = this.pickAProject.bind(this);
    this.changeWorkingOrg = this.changeWorkingOrg.bind(this);
    this.changeWorkingProject = this.changeWorkingProject.bind(this);
    this.changeWorkingTaxonomy = this.changeWorkingTaxonomy.bind(this);
    this.changeEditing = this.changeEditing.bind(this);
    this.startAddOrganization = this.startAddOrganization.bind(this);
    this.startAddProject = this.startAddProject.bind(this);
    this.startAddTaxonomy = this.startAddTaxonomy.bind(this);
    this.startAddSuperAdminTaxonomy = this.startAddSuperAdminTaxonomy.bind(this);
    this.startAddHawkCount = this.startAddHawkCount.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      filterOrgsInput: '',
      hideDemoOrgs: !!props.userIsSuperadmin,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editNewAsset) {
      // We don't know the id of the new organization/project/taxonomy, so handle it this way on the front-end
      if (nextProps.tier === types.FOCUS_ORGANIZATION) {
        const orgIds = Object.keys(nextProps.orgProjects).sort((a, b) => { return parseInt(a, 10) - parseInt(b, 10); });
        nextProps.updateWorkingOrg(orgIds[orgIds.length - 1]);
        nextProps.updateAdminEditing(types.ACTIVITY_DETAILS_ORG);
        window.scrollTo(0, 0);
      } else if (nextProps.tier === types.FOCUS_PROJECT) {
        const projectIds = Object.keys(nextProps.adminSchemas).sort((a, b) => { return a - b; });
        nextProps.updateWorkingOrg(nextProps.adminSchemas[projectIds[projectIds.length - 1]].organization_id);
        nextProps.updateWorkingProject(projectIds[projectIds.length - 1]);
        nextProps.updateAdminEditing(types.ACTIVITY_DETAILS_PROJECT);
        window.scrollTo(0, 0);
      } else if (nextProps.tier === types.FOCUS_TAXONOMY) {
        let newestTaxonomy = 0;
        let taxonomyParentOrg = 0;
        Object.keys(nextProps.orgProjects).forEach((thisOrgId) => {
          if (('TaxonomyList' in nextProps.orgProjects[thisOrgId]) && (nextProps.orgProjects[thisOrgId].TaxonomyList.length > 0)) {
            const orgMax = max(nextProps.orgProjects[thisOrgId].TaxonomyList);
            if (orgMax > newestTaxonomy) {
              newestTaxonomy = orgMax;
              taxonomyParentOrg = thisOrgId;
            }
          }
        });
        nextProps.updateWorkingOrg(taxonomyParentOrg);
        nextProps.updateWorkingTaxonomy(newestTaxonomy);
        nextProps.updateAdminEditing(types.ACTIVITY_DETAILS_TAXONOMY);
        window.scrollTo(0, 0);
      }

      nextProps.foundNewAsset();
    }
  }

  // Similar Code in Dashboard
  pickAProject(orgId) {
    const { updateWorkingOrg, updateWorkingProject, updateAdminEditing, updateAdminTier, orgProjects, adminSchemas, projectActivity, workingOrg, workingProject, tier, editing } = this.props;

    let orderedProjects;

    if (orgId) {
      orderedProjects = orgProjects[orgId].ProjectList;
    } else {
      orderedProjects = Object.keys(projectActivity)
      .map((m) => {
        return parseInt(m, 10);
      })
      .sort((a, b) => {
        return a - b;
      });
    }

    const activeProjects = orderedProjects.filter((thisProjectId) => {
      return projectActivity[thisProjectId].observation_count + projectActivity[thisProjectId].metadata_count > 0;
    });

    if (activeProjects.length > 0) {
      updateWorkingOrg(adminSchemas[activeProjects[0]].organization_id);
      updateWorkingProject(activeProjects[0]);
      updateAdminEditing(types.ACTIVITY_OVERVIEW);
    } else if (orderedProjects.length > 0) {
      updateWorkingOrg(adminSchemas[orderedProjects[0]].organization_id);
      updateWorkingProject(orderedProjects[0]);
      updateAdminEditing(types.ACTIVITY_DETAILS_PROJECT);
    } else {
      updateWorkingOrg(orgId);
      updateAdminEditing(types.ACTIVITY_DETAILS_ORG);
    }

    updateAdminTier(types.FOCUS_PROJECT);
  }

  componentWillMount() {
    const { workingProject, editing } = this.props;

    if (isEmpty(workingProject) && isEmpty(editing)) {
      this.pickAProject(null);
    }
  }

  changeWorkingOrg(orgId) {
    const { workingOrg } = this.props;

    if (orgId !== workingOrg) {
      this.pickAProject(orgId);
    }

    ReactGA.event({
      category: translateTier(types.FOCUS_ORGANIZATION),
      action: 'changeWorkingOrg',
      label: this.props.orgProjects[orgId].name,
    });
  }

  changeWorkingProject(projectId) {
    const { updateWorkingProject, updateAdminTier, updateAdminEditing, projectActivity, tier } = this.props;

    if ([types.FOCUS_TAXONOMY, types.FOCUS_ORGANIZATION].indexOf(tier) > -1) {
      if (projectActivity[projectId].observation_count + projectActivity[projectId].metadata_count > 0) {
        updateAdminEditing(types.ACTIVITY_OVERVIEW);
      } else {
        updateAdminEditing(types.ACTIVITY_DETAILS_PROJECT);
      }
    }

    updateWorkingProject(projectId);

    updateAdminTier(types.FOCUS_PROJECT);

    ReactGA.event({
      category: translateTier(types.FOCUS_PROJECT),
      action: 'changeWorkingProject',
      label: this.props.adminSchemas[projectId].name,
    });
  }

  changeWorkingTaxonomy(taxonomyId) {
    const { updateWorkingTaxonomy, updateAdminTier, updateAdminEditing } = this.props;

    updateWorkingTaxonomy(taxonomyId);
    updateAdminEditing(types.ACTIVITY_DETAILS_TAXONOMY);

    updateAdminTier(types.FOCUS_TAXONOMY);

    ReactGA.event({
      category: translateTier(types.FOCUS_TAXONOMY),
      action: 'changeWorkingTaxonomy',
      label: this.props.orgProjects[this.props.workingOrg].TaxonomyLookup[taxonomyId].name,
    });
  }

  changeEditing(edit) {
    const { updateAdminTier, updateAdminEditing, editing } = this.props;

    if (edit !== editing) {
      updateAdminEditing(edit);
    }

    if (orgActivities.map((thisOrg) => { return thisOrg.activity; }).indexOf(edit) > -1) {
      updateAdminTier(types.FOCUS_ORGANIZATION);

      ReactGA.event({
        category: translateTier(types.FOCUS_ORGANIZATION),
        action: 'changeEditing',
        label: edit,
      });
    } else {
      ReactGA.event({
        category: translateTier(this.props.tier),
        action: 'changeEditing',
        label: edit,
      });
    }
  }

  startAddOrganization(event) {
    const { updateWorkingOrg, updateWorkingProject, updateAdminTier, updateAdminEditing, projectActivity } = this.props;

    event.preventDefault();
    event.stopPropagation();

    updateWorkingOrg(-1);
    updateWorkingProject(-1);
    updateAdminTier(types.FOCUS_ORGANIZATION);
    updateAdminEditing(types.ACTIVITY_ADD_ORGANIZATION);

    ReactGA.event({
      category: translateTier(types.FOCUS_ORGANIZATION),
      action: 'startAddOrganization',
      label: types.ACTIVITY_ADD_ORGANIZATION,
    });
  }

  startAddProject(event) {
    const { updateWorkingOrg, updateWorkingProject, updateAdminTier, updateAdminEditing, projectActivity } = this.props;

    event.preventDefault();
    event.stopPropagation();

    updateWorkingOrg(-1);
    updateWorkingProject(-1);
    updateAdminTier(types.FOCUS_PROJECT);
    updateAdminEditing(types.ACTIVITY_ADD_PROJECT);

    ReactGA.event({
      category: translateTier(types.FOCUS_PROJECT),
      action: 'startAddProject',
      label: types.ACTIVITY_ADD_PROJECT,
    });
  }

  startAddTaxonomy(event) {
    const { updateWorkingOrg, updateWorkingProject, updateAdminTier, updateAdminEditing, projectActivity } = this.props;

    event.preventDefault();
    event.stopPropagation();

    updateWorkingOrg(-1);
    updateWorkingProject(-1);
    updateAdminTier(types.FOCUS_TAXONOMY);
    updateAdminEditing(types.ACTIVITY_ADD_TAXONOMY);

    ReactGA.event({
      category: translateTier(types.FOCUS_TAXONOMY),
      action: 'startAddTaxonomy',
      label: types.ACTIVITY_ADD_TAXONOMY,
    });
  }

  startAddSuperAdminTaxonomy(event) {
    const { updateWorkingOrg, updateWorkingProject, updateAdminTier, updateAdminEditing, projectActivity } = this.props;

    event.preventDefault();
    event.stopPropagation();

    updateWorkingOrg(-1);
    updateWorkingProject(-1);
    updateAdminTier(types.FOCUS_TAXONOMY);
    updateAdminEditing(types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY);

    ReactGA.event({
      category: translateTier(types.FOCUS_TAXONOMY),
      action: 'startAddSuperAdminTaxonomy',
      label: types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY,
    });
  }

  startAddHawkCount(event) {
    const { updateWorkingOrg, updateWorkingProject, updateAdminTier, updateAdminEditing, projectActivity } = this.props;

    event.preventDefault();
    event.stopPropagation();

    updateWorkingOrg(-1);
    updateWorkingProject(-1);
    updateAdminTier(types.FOCUS_PROJECT);
    updateAdminEditing(types.ACTIVITY_ADD_INTEGRATION_HAWKCOUNT);

    ReactGA.event({
      category: translateTier(types.FOCUS_PROJECT),
      action: 'startAddHawkCount',
      label: types.ACTIVITY_ADD_INTEGRATION_HAWKCOUNT,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    const {
      orgProjects,
      orgAdminAccess,
      adminSchemas,
      projectActivity,
      workingOrg,
      workingProject,
      workingTaxonomy,
      tier,
      editing,
      userIsSuperadmin
    } = this.props;

    const {
      filterOrgsInput,
      hideDemoOrgs,
    } = this.state;

    const buildSiteNav = Object.keys(orgProjects).sort((a, b) => {
        const nameA = orgProjects[a].name.toLowerCase();
        const nameB = orgProjects[b].name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }).filter((thisOrgKey) => {
        if (hideDemoOrgs && !some(orgProjects[thisOrgKey].Projects, {demo: false})) {
          return false;
        }
        if (filterOrgsInput.length !== 0) {
          return orgProjects[thisOrgKey].name.toLowerCase().indexOf(filterOrgsInput.toLowerCase()) > -1;
        }

        return true;
      }).map((thisOrgKey) => {
      const orgId = parseInt(thisOrgKey, 10);
      const thisOrg = orgProjects[thisOrgKey];
      const canAccessOrgManagerActivities = orgAdminAccess.indexOf(orgId) > -1;

      return (
        <div
          key={orgId}
          className={cx('organization-container')} >
          <div
            className={cx('organization-title')}
            onClick={e => this.changeWorkingOrg(orgId, e)} >
            {thisOrg.name}
          </div>
          {workingOrg === orgId && adminBlocks.map((thisBlock) => {
            return (
              <div
                key={thisBlock.name} >
                {(!thisBlock.needOrgManager || canAccessOrgManagerActivities) &&
                  <h4
                    className={cx('org-subhead')} >
                    {thisBlock.name}
                  </h4>
                }
                {((thisBlock.segments in thisOrg) && thisOrg[thisBlock.segments].length === 0) &&
                  <div
                    className={cx('site-activity-empty')} >
                    Please Add Below
                  </div>
                }
                {(thisBlock.displaySegments ? thisOrg[thisBlock.segments] : [{id: orgId}]).map((thisSegment) => {
                  const manageExpanded = !thisBlock.needOrgManager || (thisBlock.needOrgManager && canAccessOrgManagerActivities);
                  const showOrgActivities = (thisBlock.focus === types.FOCUS_ORGANIZATION) && canAccessOrgManagerActivities;
                  const segmentExpanded = ((this.props[thisBlock.reducer] === thisSegment.id) && (tier === thisBlock.focus));
                  return (
                    <div
                      className={cx('site-explorer')}
                      key={thisSegment.id} >
                      {(thisBlock.displaySegments && manageExpanded) &&
                        <div
                          className={cx('site-title', {'site-active': segmentExpanded})}
                          onClick={e => this[thisBlock.change](thisSegment.id, e)} >
                          {thisSegment.name}
                        </div>
                      }
                      {(segmentExpanded || showOrgActivities) &&
                        <div
                          className={cx('site-activities')} >
                          {thisBlock.activities.map((activity) => {
                            if (activity.needAdmin && !userIsSuperadmin) {
                              return false;
                            } else if (activity.needData && (projectActivity[thisSegment.id].observation_count + projectActivity[thisSegment.id].observation_count === 0)) {
                              return false;
                            }
                            return (
                              <div
                                key={activity.activity}
                                className={cx('site-activity', {'active-activity': editing === activity.activity})}
                                onClick={() => this.changeEditing(activity.activity)} >
                                {activity.title}
                              </div>
                            );
                          })}
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    });

    return (
      <div
        className={cx('panel')} >
        {Object.keys(orgProjects).length > 3 &&
          <div
            className={cx('org-search-container')} >
            <span
              className={cx('search-icon')} >
              <i
                className={cx('material-icons')} >
                &#xE8B6;
              </i>
            </span>
            <input
              id="filterOrgsInput"
              name="filterOrgsInput"
              type="search"
              placeholder="Filter Organizations"
              className={cx('org-filter-input')}
              value={filterOrgsInput}
              onChange={this.handleInputChange} />
          </div>
        }
        {userIsSuperadmin &&
          <div
            className={cx('org-hide-container')} >
            <label
              id="hideDemoOrgs"
              htmlFor="hideDemoOrgs" >
              Hide Demo Orgs
            </label>
            <input
              id="hideDemoOrgs"
              name="hideDemoOrgs"
              type="checkbox"
              checked={hideDemoOrgs}
              onChange={this.handleInputChange} />
          </div>
        }
        {buildSiteNav}
        <div
          className={cx('panel-actions')} >
          <div
            onClick={(event) => {
              this.startAddOrganization(event);
            }}
            role="button"
            className={cx('panel-action')} >
            Add a New Organization
          </div>
          <div
            onClick={(event) => {
              this.startAddProject(event);
            }}
            role="button"
            className={cx('panel-action')} >
            Add a New Project
          </div>
          <div
            onClick={(event) => {
              this.startAddTaxonomy(event);
            }}
            role="button"
            className={cx('panel-action')} >
            Add a New Taxonomy
          </div>
          {userIsSuperadmin &&
            <div
              onClick={(event) => {
                this.startAddSuperAdminTaxonomy(event);
              }}
              role="button"
              className={cx('panel-action')} >
              SuperAdmin Taxonomy
            </div>
          }
          {userIsSuperadmin &&
            <div
              onClick={(event) => {
                this.startAddHawkCount(event);
              }}
              role="button"
              className={cx('panel-action')} >
              SuperAdmin Add HawkCount
            </div>
          }
        </div>
      </div>
    );
  }
}

PanelAdmin.propTypes = {
  updateWorkingOrg: PropTypes.func.isRequired,
  updateWorkingProject: PropTypes.func.isRequired,
  updateWorkingTaxonomy: PropTypes.func.isRequired,
  updateAdminTier: PropTypes.func.isRequired,
  updateAdminEditing: PropTypes.func.isRequired,
  foundNewAsset: PropTypes.func.isRequired,
  projectActivity: PropTypes.object.isRequired,
  orgProjects: PropTypes.object.isRequired,
  orgAdminAccess: PropTypes.array,
  adminSchemas: PropTypes.object.isRequired,
  workingOrg: PropTypes.number,
  workingProject: PropTypes.number,
  workingTaxonomy: PropTypes.number,
  tier: PropTypes.string.isRequired,
  editing: PropTypes.string.isRequired,
  editNewAsset: PropTypes.bool.isRequired,
  userIsSuperadmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    projectActivity: state.admin.projectActivity,
    orgProjects: state.admin.orgProjects,
    orgAdminAccess: state.admin.user.adminOrgs,
    adminSchemas: state.admin.adminSchemas,
    workingOrg: state.admin.workingOrg,
    workingProject: state.admin.workingProject,
    workingTaxonomy: state.admin.workingTaxonomy,
    tier: state.admin.tier,
    editing: state.admin.editing,
    editNewAsset: state.admin.editNewAsset,
    userIsSuperadmin: state.admin.user.superadmin,
  };
}

export default connect(mapStateToProps, { updateWorkingOrg, updateWorkingProject, updateWorkingTaxonomy, updateAdminEditing, updateAdminTier, foundNewAsset })(PanelAdmin);
