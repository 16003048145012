import { combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import * as types from 'types';

const hawkcountLandscape = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.ADD_INTEGRATION_HAWKCOUNT_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_LANDSCAPE_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return keyBy(action.data, 'project_id');
      }
      return state;
    default:
      return state;
  }
};

const hawkcountSite = (
  state = {
    id: null,
    project_id: null,
    site_id: null,
  },
  action
) => {
  switch (action.type) {
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data.hawkcountSiteData;
      }
      return state;
    default:
      return state;
  }
};

const hawkcountConditionFields = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data.hawkcountConditionFields;
      }
      return state;
    default:
      return state;
  }
};

const hawkcountConditionLookup = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data.hawkcountConditionLookup;
      }
      return state;
    default:
      return state;
  }
};

const hawkcountSpecies = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data.hawkcountSpecies;
      }
      return state;
    default:
      return state;
  }
};

const hawkcountSpeciesSpecies = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data.hawkcountSpeciesSpecies;
      }
      return state;
    default:
      return state;
  }
};

const integrationsReducer = combineReducers({
  hawkcountLandscape,
  hawkcountSite,
  hawkcountConditionFields,
  hawkcountConditionLookup,
  hawkcountSpecies,
  hawkcountSpeciesSpecies,
});

export default integrationsReducer;
