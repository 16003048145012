import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import ReactGA from 'react-ga';
import createRoutes from './routes';
import * as types from './types';
import configureStore from './store/configureStore';
import fetchDataForRoute from './utils/fetchDataForRoute';
import { fetchProjectData, fetchExploreData, fetchEmbedData, fetchLandscapeRecent } from './fetch-data/';

// Grab the state from a global injected into
// server-generated HTML
const initialState = window.__INITIAL_STATE__;

const store = configureStore(initialState, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);
const routes = createRoutes(store);

// We need this to access the store from within HighCharts
window.store = store;

// Initialize Google Analytics
ReactGA.initialize(store.getState().environment.GOOGLE_ANALYTICS_ID);

/**
 * Callback function handling frontend route changes.
 */
function onUpdate() {
  const currentState = store.getState();

  const gaOptions = {};

  gaOptions.page =  window.location.pathname + window.location.search;

  if ('id' in currentState.admin.user) {
    gaOptions.userId = currentState.admin.user.id;
  }

  ReactGA.set(gaOptions);
  ReactGA.pageview(gaOptions.page);

  // Prevent duplicate fetches when first loaded.
  // Explanation: On server-side render, we already have __INITIAL_STATE__
  // So when the client side onUpdate kicks in, we do not need to fetch twice.
  // We set it to null so that every subsequent client-side navigation will
  // still trigger a fetch data.
  // Read more: https://github.com/choonkending/react-webpack-node/pull/203#discussion_r60839356
  if (window.__INITIAL_STATE__ !== null) {
    window.__INITIAL_STATE__ = null;
    return;
  }

  const matchedRoute = this.state.routes[this.state.routes.length - 1];

  const preloaders = [];

  store.dispatch({ type: types.CREATE_REQUEST });
  preloaders.push(fetchDataForRoute(this.state)
        .then((data) => {
          store.dispatch({
            type: types.REQUEST_SUCCESS,
            data
          });
        })
        .catch((error) => {
          console.error(error);
        }));

  if ('fetchHome' in matchedRoute) {
    store.dispatch({ type: types.GET_LANDSCAPE_RECENT_REQUEST });
    preloaders.push(fetchLandscapeRecent()
          .then((data) => {
            store.dispatch({
              type: types.GET_LANDSCAPE_RECENT_SUCCESS,
              data
            });
          })
          .catch((error) => {
            console.error(error);
          }));
  }

  if ('fetchProject' in matchedRoute) {
    store.dispatch({ type: types.GET_PROJECT_REQUEST });
    preloaders.push(fetchProjectData(this.state.params.slug)
          .then((data) => {
            store.dispatch({
              type: types.GET_PROJECT_SUCCESS,
              data
            });
          })
          .catch((error) => {
            console.error(error);
          }));
  }

  if ('fetchExplore' in matchedRoute) {
    store.dispatch({ type: types.GET_PROJECT_REQUEST });
    preloaders.push(fetchExploreData(this.state.params.organizationSlug, this.state.params.projectSlug)
          .then((data) => {
            store.dispatch({
              type: types.GET_PROJECT_SUCCESS,
              data
            });
          })
          .catch((error) => {
            console.error(error);
          }));
  }

  if ('fetchEmbed' in matchedRoute) {
    store.dispatch({ type: types.GET_PROJECT_REQUEST });
    const segments = 'segments' in this.state.query ? this.state.query.segments.split(',') : ['daytallies', 'seasontallies', 'flagged', 'map', 'livechart', 'textupdates', 'composition'];
    preloaders.push(fetchEmbedData(this.state.params.organizationSlug, this.state.params.projectSlug, segments)
          .then((data) => {
            store.dispatch({
              type: types.GET_PROJECT_SUCCESS,
              data
            });
          })
          .catch((error) => {
            console.error(error);
          }));
  }

  Promise.all(preloaders);
}

hydrate(
  <Provider store={store}>
    <Router history={history} onUpdate={onUpdate}>
      {routes}
    </Router>
  </Provider>, document.getElementById('app'));
