import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/mapmarker';

const cx = classNames.bind(styles);

class MapMarker extends Component {
  constructor(props) {
    super(props);

    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);

    this.state = {
      isMobile: false,
      manuallyFeatured: false,
    };
  }

  componentDidMount() {
    this.setState({
      isMobile: (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch),
    });
  }

  mouseEnter() {
    this.setState({
      manuallyFeatured: true,
    });
  }

  mouseLeave() {
    this.setState({
      manuallyFeatured: false,
    });
  }

  render() {
    const { describes, orgLogo, name, $hover, selectedMarker, tightenZoom } = this.props;

    const featured = $hover || this.state.manuallyFeatured;

    return (
      <div
        className={cx('container', {
          'container-featured': featured,
          'container-contents': featured,
          'mobile': this.state.isMobile,
          'medium': name.length > 20,
          'long': name.length > 35})
        }
        onClick={() => {
          if (this.state.isMobile) {
            this.mouseEnter();
            tightenZoom(describes);
          } else {
            selectedMarker(describes);
          }
        }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave} >
        <div
          className={cx('org-logo')} >
          {orgLogo ?
            (
              <img
                src={orgLogo}
                alt={name} />
            ) : (
              <div>
                {!featured &&
                <span>
                  {name}
                </span>
                }
              </div>
            )
          }
        </div>
        <div className={cx('name')}>
          {name}
        </div>
        {this.state.isMobile &&
          <div
            className={cx('click-instructions')}
            onClick={(event) => {
              event.stopPropagation();
              selectedMarker(describes);
            }} >
            Select
          </div>
        }
      </div>
    );
  }
}

MapMarker.propTypes = {
  tightenZoom: PropTypes.func,
  selectedMarker: PropTypes.func,
  describes: PropTypes.string,
  orgLogo: PropTypes.string,
  name: PropTypes.string,
};

export default MapMarker;
