import { combineReducers } from 'redux';
import environment from '../reducers/environment';
import landscape from '../reducers/landscape';
import admin from '../reducers/admin';
import integrations from '../reducers/integrations';
import metadata from '../reducers/metadata';
import project from '../reducers/project';
import taxonomy from '../reducers/taxonomy';
import { routerReducer as routing } from 'react-router-redux';
import user from '../reducers/user';
import message from '../reducers/message';
import * as types from '../types';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.CREATE_REQUEST:
      return true;
    case types.REQUEST_SUCCESS:
    case types.REQUEST_FAILURE:
    case types.UPDATE_ADMIN_PROJECT_SUCCESS:
    case types.UPDATE_ADMIN_PROJECT_FAILURE:
    case types.GET_RECORD_SUCCESS:
    case types.SET_RECORD_SUCCESS:
    case types.REQUEST_TAXONOMY_SUCCESS:
    case types.REQUEST_TAXONOMY_FAILURE:
    case types.UPDATE_ORG_IMAGE_SUCCESS:
    case types.DELETE_ORG_IMAGE_SUCCESS:
    case types.GET_PROJECT_SUCCESS:
    case types.UPDATE_TAXONOMY_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isLoading = (
  state = false,
  action
) => {
  switch (action.type) {
    case types.REQUEST_ADMIN_USER_REQUEST:
    case types.UPDATE_ORG_IMAGE_REQUEST:
    case types.DELETE_ORG_IMAGE_REQUEST:
    case types.GET_PROJECT_REQUEST:
    case types.UPDATE_TAXONOMY_REQUEST:
    case types.GET_PROJECT_TEMPLATES_REQUEST:
    case types.GET_TAXONOMY_TEMPLATES_REQUEST:
      return true;
    case types.REQUEST_ADMIN_USER_SUCCESS:
    case types.GET_PROJECT_SUCCESS:
    case types.UPDATE_ADMIN_ORG_SUCCESS:
    case types.UPDATE_ADMIN_ORG_FAILURE:
    case types.UPDATE_ADMIN_PROJECT_SUCCESS:
    case types.UPDATE_ADMIN_PROJECT_FAILURE:
    case types.REQUEST_TAXONOMY_FAILURE:
    case types.UPDATE_TAXONOMY_SUCCESS:
    case types.UPDATE_TAXONOMY_FAILURE:
    case types.UPDATE_USER_PERMISSIONS_SUCCESS:
    case types.UPDATE_USER_PERMISSIONS_FAILURE:
    case types.UPDATE_ORG_IMAGE_SUCCESS:
    case types.UPDATE_ORG_IMAGE_FAILURE:
    case types.DELETE_ORG_IMAGE_SUCCESS:
    case types.DELETE_ORG_IMAGE_FAILURE:
    case types.GET_RECORD_FAILURE:
    case types.SET_RECORD_SUCCESS:
    case types.SET_RECORD_FAILURE:
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_ORGANIZATION_FAILURE:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_PROJECT_FAILURE:
    case types.ADD_TAXONOMY_SUCCESS:
    case types.ADD_TAXONOMY_FAILURE:
    case types.GET_PROJECT_TEMPLATES_SUCCESS:
    case types.GET_TAXONOMY_TEMPLATES_SUCCESS:
      return false;
    default:
      return state;
  }
};

// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  environment,
  landscape,
  admin,
  integrations,
  metadata,
  project,
  taxonomy,
  isFetching,
  isLoading,
  user,
  message,
  routing
});

export default rootReducer;
