import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from '../utils/classNames.js';
import styles from 'css/components/weather';
import { fetchWeatherData } from 'actions/project';

const ReactHighcharts = require('react-highcharts');

const cx = classNames.bind(styles);

const weatherChartReference = 'weatherChart';
const mobileHours = 48;
const desktopHours = 72;

const beaufortLookup = ['Calm',
'Light Air',
'Light Breeze',
'Gentle Breeze',
'Moderate Breeze',
'Fresh Breeze',
'Strong Breeze',
'Near Gale',
'Gale',
'Strong Gale',
'Storm',
'Violent Storm',
'Hurricane'];

function getCardinal(inputAngle) {
  const directions = 8;
  const degree = 360 / directions;
  const angle = inputAngle + (degree / 2);

  if (angle >= 0 * degree && angle < 1 * degree) {
    return 'N';
  } else if (angle >= 1 * degree && angle < 2 * degree) {
    return 'NE';
  } else if (angle >= 2 * degree && angle < 3 * degree) {
    return 'E';
  } else if (angle >= 3 * degree && angle < 4 * degree) {
    return 'SE';
  }	if (angle >= 4 * degree && angle < 5 * degree) {
    return 'S';
  }	if (angle >= 5 * degree && angle < 6 * degree) {
    return 'SW';
  }	if (angle >= 6 * degree && angle < 7 * degree) {
    return 'W';
  }	if (angle >= 7 * degree && angle < 8 * degree) {
    return 'NW';
  }

  return 'N';
}

class WeatherChart extends Component {
  constructor(props) {
    super(props);

    this.windArrow = this.windArrow.bind(this);
    this.drawWindArrows = this.drawWindArrows.bind(this);
    this.drawBlocksForWindArrows = this.drawBlocksForWindArrows.bind(this);

    this.state = {
      forecastObject: false,
      isMobile: true,
      weatherFailed: false,
    };
  }

  componentDidMount() {
    const { projectData, forecastData } = this.props;

    this.setState({
      isMobile: (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) || screen.width <= 960
    });
    ReactHighcharts.Highcharts.setOptions({
      global: {
          useUTC: false
      }
    });

    if (Object.keys(forecastData).length !== 0) {
      const forecastDataKeys = Object.keys(forecastData);
      for (let i = 0; i < forecastDataKeys.length; i++) {
        if (Array.isArray(forecastData[forecastDataKeys[i]])) {
          forecastData[forecastDataKeys[i]] = forecastData[forecastDataKeys[i]].slice(0, Math.min(this.state.isMobile ? mobileHours : desktopHours, forecastData[forecastDataKeys[i]].length));
        }
      }

      const forecastOptions = {
        chart: {
          // renderTo: this.container,
          marginBottom: 70,
          marginRight: 40,
          marginTop: 50,
          plotBorderWidth: 1,
          height: 310,
          backgroundColor: null,
          style: {
            fontFamily: 'Source Sans Pro',
          },
        },
        title: {
          text: null,
        },
        exporting: {
          enabled: false
        },
        credits: {
          text: 'Forecast from <a href="http://forecast.weather.gov/MapClick.php?lat=' + String(projectData.latitude) + '&lon=' + String(projectData.longitude) + '&FcstType=digital">weather.gov</a>',
          //href: this.specteo_weather['credit'],
          href: null,
          position: {
            x: -40
          },
          style: {
            cursor: 'pointer',
            color: '#909090',
            fontSize: '12px'
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          width: 400,
          style: {
            width: 400,
          },
          formatter: function () {
            // Create the header with reference to the time interval
            const index = this.points[0].point.index;
            let ret = '<strong>' + ReactHighcharts.Highcharts.dateFormat('%A, %b %e, %H:%M', this.x) + '-' +
                    ReactHighcharts.Highcharts.dateFormat('%H:%M', this.points[0].point.to) + '</strong><br>';

            ret += '<table>';

            // Add all series
            ReactHighcharts.Highcharts.each(this.points, function (point) {
                const series = point.series;
                ret += '<tr><td><span style="color:' + series.color + '">\u25CF</span> ' + series.name +
                    ': </td><td style="white-space:nowrap">' + String(point.y) +
                    series.options.tooltip.valueSuffix + '</td></tr>';
            });

            // Add wind
            ret += '<tr><td style="vertical-align: top">\u25CF Wind</td><td style="white-space:nowrap">' + getCardinal(forecastData.windDirectionIntervals[index][1]) +
                ' ' + ReactHighcharts.Highcharts.numberFormat(forecastData.windSpeedIntervals[index][1], 1) + ' kts<br>' + beaufortLookup[forecastData.beaufortIntervals[index][1]] + '</td></tr>';

            // Close
            ret += '</table>';

            return ret;
          }
        },
        xAxis: [{ // Bottom X axis
          type: 'datetime',
          tickInterval: 2 * 36e5, // two hours
          minorTickInterval: 36e5, // one hour
          tickLength: 0,
          gridLineWidth: 1,
          gridLineColor: (ReactHighcharts.Highcharts.theme && ReactHighcharts.Highcharts.theme.background2) || '#F0F0F0',
          startOnTick: false,
          endOnTick: false,
          minPadding: 0,
          maxPadding: 0,
          offset: 30,
          showLastLabel: true,
          labels: {
            format: '{value:%H}'
          }
        }, { // Top X axis
          linkedTo: 0,
          type: 'datetime',
          tickInterval: 24 * 3600 * 1000,
          labels: {
            format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}',
            align: 'left',
            x: 3,
            y: -5
          },
          opposite: true,
          tickLength: 20,
          gridLineWidth: 1
        }],
        yAxis: [{ // temperature axis
          title: {
            text: null
          },
          labels: {
            format: '{value}°',
            style: {
              fontSize: '12px'
            },
            x: -3
          },
          plotLines: [{ // zero plane
            value: 0,
            color: '#BBBBBB',
            width: 1,
            zIndex: 2
          }],
          // Custom positioner to provide even temperature ticks from top down
          tickPositioner: () => {
            let max = Math.ceil(this.max) + 1,
              pos = max - 12, // start
              ret;

            if (pos < this.min) {
              ret = [];
              while (pos <= max) {
                ret.push(pos += 1);
              }
            } // else return undefined and go auto
            return ret;
          },
          maxPadding: 0.3,
          tickInterval: 1,
          gridLineColor: (ReactHighcharts.Highcharts.theme && ReactHighcharts.Highcharts.theme.background2) || '#F0F0F0'
        }, { // Precipitation Axis
          title: {
            text: null
          },
          labels: {
            enabled: false
          },
          gridLineWidth: 0,
          tickLength: 0
        }, { // Precipitation Chance Axis
          title: {
            text: null
          },
          min: 0,
          max: 100,
          labels: {
            enabled: false
          },
          gridLineWidth: 0,
          tickLength: 0,
        }, { // Cloud Cover
          allowDecimals: false,
          max: 100,
          min: 0,
          title: { // Title on top of axis
            text: '%',
            offset: 0,
            align: 'high',
            rotation: 0,
            style: {
              fontSize: '12px',
              color: '‎#4682b4'
            },
            textAlign: 'left',
            x: 3
          },
          labels: {
            style: {
              fontSize: '12px',
              color: '‎#4682b4'
            },
            y: 2,
            x: 3
          },
          gridLineWidth: 0,
          opposite: true,
          showLastLabel: false
        }],

        legend: {
          enabled: false
        },

        plotOptions: {
          series: {
            pointPlacement: 'between'
          }
        },
        series: [{
          name: 'Temperature',
          data: forecastData.temperatureIntervals,
          type: 'spline',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true
              }
            }
          },
          tooltip: {
            valueSuffix: '°F'
          },
          zIndex: 1,
          color: '#FF3333',
          negativeColor: '#48AFE8',
          threshold: 32
        }, {
          name: 'Precipitation',
          data: forecastData.quantitativePrecipitationIntervals,
          type: 'column',
          color: '#48A5DC',
          yAxis: 1,
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          shadow: false,
          dataLabels: {
            enabled: true,
            formatter: () => {
              if (this.y > 0) {
                return this.y;
              }
              return null;
            },
            style: {
              fontSize: '8px'
            }
          },
          tooltip: {
            valueSuffix: ' in'
          }
        }, {
          name: 'Chance of Precip',
          data: forecastData.probabilityOfPrecipitationIntervals,
          type: 'area',
          color: '#68CFE8',
          fillOpacity: .3,
          yAxis: 2,
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          shadow: false,
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            valueSuffix: '%'
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true
              }
            }
          },
        }, {
          name: 'Cloud Cover',
          color: '#87CEEB',
          data: forecastData.skyCoverIntervals,
          marker: {
            enabled: false
          },
          shadow: false,
          tooltip: {
            valueSuffix: '%'
          },
          dashStyle: 'shortdot',
          yAxis: 2
        }]
      };

      this.setState({
        forecastObject: forecastOptions
      }, () => {
        this.drawWindArrows(this[weatherChartReference]);
      });
    } else {
      this.setState({
        weatherFailed: true
      });
    }
  }

  /**
   * Create wind speed symbols for the Beaufort wind scale. The symbols are rotated
   * around the zero centerpoint.
   */
  windArrow(level) {
    let path;

    // The stem and the arrow head
    path = [
        'M', 0, 7, // base of arrow
        'L', -1.5, 7,
        0, 10,
        1.5, 7,
        0, 7,
        0, -10 // top
    ];

    if (level === 0) {
        path = [];
    }

    if (level === 2) {
        path.push('M', 0, -8, 'L', 4, -8); // short line
    } else if (level >= 3) {
        path.push(0, -10, 7, -10); // long line
    }

    if (level === 4) {
        path.push('M', 0, -7, 'L', 4, -7);
    } else if (level >= 5) {
        path.push('M', 0, -7, 'L', 7, -7);
    }

    if (level === 5) {
        path.push('M', 0, -4, 'L', 4, -4);
    } else if (level >= 6) {
        path.push('M', 0, -4, 'L', 7, -4);
    }

    if (level === 7) {
        path.push('M', 0, -1, 'L', 4, -1);
    } else if (level >= 8) {
        path.push('M', 0, -1, 'L', 7, -1);
    }

    return path;
  }

  /**
   * Draw the wind arrows. Each arrow path is generated by the windArrow function above.
   */
  drawWindArrows(meteogram) {
    const { forecastData } = this.props;

    const chart = meteogram.getChart();

    chart.series[0].data.forEach((point, i) => {
      let arrow = {};
      let x = 0;
      let y = 0;

      if (forecastData.resolution > 36e5 || i % 2 === 0) {
        // Draw the wind arrows
        x = point.plotX + chart.plotLeft + 7;
        y = 255;
        if (forecastData.beaufortIntervals[i][1] === 0) {
          arrow = chart.renderer.circle(x, y, 10).attr({
              fill: 'none'
          });
        } else {
          arrow = chart.renderer.path(
              this.windArrow(forecastData.beaufortIntervals[i][1])
          ).attr({
              rotation: parseInt(forecastData.windDirectionIntervals[i][1], 10),
              translateX: x, // rotation center
              translateY: y // rotation center
          });
        }
        arrow.attr({
          stroke: (ReactHighcharts.Highcharts.theme && ReactHighcharts.Highcharts.theme.contrastTextColor) || 'black',
          'stroke-width': 1.5,
          zIndex: 5
        })
        .add();
      }
    });
  }

  /**
   * Draw blocks around wind arrows, below the plot area
   */
  drawBlocksForWindArrows(chart) {
    const { forecastData } = this.props;

    const xAxis = chart.xAxis[0];
    let x;
    let pos;
    let max;
    let isLong;
    let isLast;
    let i;

    for (pos = xAxis.min, max = xAxis.max, i = 0; pos <= max + 36e5; pos += 36e5, i += 1) {
      // Get the X position
      isLast = pos === max + 36e5;
      x = Math.round(xAxis.toPixels(pos)) + (isLast ? 0.5 : -0.5);

      // Draw the vertical dividers and ticks
      if (forecastData.resolution > 36e5) {
          isLong = pos % forecastData.resolution === 0;
      } else {
          isLong = i % 2 === 0;
      }

      chart.renderer.path(['M', x, chart.plotTop + chart.plotHeight + (isLong ? 0 : 28),
        'L', x, chart.plotTop + chart.plotHeight + 32, 'Z'])
        .attr({
            'stroke': chart.options.chart.plotBorderColor,
            'stroke-width': 1
        })
        .add();
    }
  }

  render() {
    return (
      <div className={cx('weather-chart')}>
        {!this.state.weatherFailed &&
          <div>
            <h3
              className={cx('section-head')}>
              Site Weather
            </h3>
            <div
              className={cx('weather-container')}>
              {this.state.forecastObject &&
                <ReactHighcharts config={this.state.forecastObject} isPureConfig ref={(chart) => { this.weatherChart = chart; }} />
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

WeatherChart.propTypes = {
  projectData: PropTypes.object.isRequired,
  forecastData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    forecastData: state.project.forecastData,
  };
}

export default connect(mapStateToProps, { })(WeatherChart);
