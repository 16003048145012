import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import classNames from '../utils/classNames.js';
import ReactHighcharts from 'react-highcharts';
import styles from 'css/components/sparkchart';
import moment from 'moment-timezone';

const minSparkHeight = 40;
const minSparkWidth = 120;
const defaultShowSpecies = 8;

const cx = classNames.bind(styles);

class SparkChart extends Component {
  constructor(props) {
    super(props);

    this.showMoreSpecies = this.showMoreSpecies.bind(this);
    this.orderingChange = this.orderingChange.bind(this);
    this.updateSparkcharts = this.updateSparkcharts.bind(this);

    const speciesList = Object.keys(props.speciesDays);

    const speciesTotals = {};

    speciesList.forEach((thisSpecies) => {
      speciesTotals[thisSpecies] = props.speciesDays[thisSpecies].reduce((a, b) => { return a + b; }, 0);
    });

    this.state = {
      hideSurplusSpecies: true,
      sparkchartOutput: [],
      speciesTotals,
      ordering: 'focus_taxonomic',
    };
  }

  componentWillMount() {
    this.updateSparkcharts();
  }

  orderingChange(event) {
    const target = event.target;

    this.setState({
      ordering: target.value
    }, () => {
      ReactGA.event({
        category: 'Public: SpeciesCharts',
        action: 'orderingChange: ' + target.value,
        label: this.props.projectData.name,
      });

      this.updateSparkcharts();
    });
  }

  showMoreSpecies() {
    this.setState({
      hideSurplusSpecies: false
    }, () => {
      ReactGA.event({
        category: 'Public: SpeciesCharts',
        action: 'showMoreSpecies',
        label: this.props.projectData.name,
      });

      this.updateSparkcharts();
    });
  }

  updateSparkcharts() {
    const { projectData, taxonomy, speciesDays, dateList, focusSpeciesList } = this.props;

    let speciesRows = Object.keys(speciesDays);
    speciesRows = speciesRows.map((speciesString) => {
      return parseInt(speciesString, 10);
    });

    if (this.state.ordering === 'focus_taxonomic') {
      speciesRows = speciesRows.sort((a, b) => {
        if ((focusSpeciesList.indexOf(a) > -1) && (focusSpeciesList.indexOf(b) === -1)) {
          return -1;
        } else if ((focusSpeciesList.indexOf(a) === -1) && (focusSpeciesList.indexOf(b) > -1)) {
          return 1;
        }
        return taxonomy[a].order - taxonomy[b].order;
      });
    } else if (this.state.ordering === 'focus_abundance') {
      speciesRows = speciesRows.sort((a, b) => {
        if ((focusSpeciesList.indexOf(a) > -1) && (focusSpeciesList.indexOf(b) === -1)) {
          return -1;
        } else if ((focusSpeciesList.indexOf(a) === -1) && (focusSpeciesList.indexOf(b) > -1)) {
          return 1;
        }
        return this.state.speciesTotals[b] - this.state.speciesTotals[a];
      });
    } else if (this.state.ordering === 'focus_alphabet') {
      speciesRows = speciesRows.sort((a, b) => {
        if ((focusSpeciesList.indexOf(a) > -1) && (focusSpeciesList.indexOf(b) === -1)) {
          return -1;
        } else if ((focusSpeciesList.indexOf(a) === -1) && (focusSpeciesList.indexOf(b) > -1)) {
          return 1;
        }
        const speciesA = taxonomy[a].common_name.toUpperCase();
        const speciesB = taxonomy[b].common_name.toUpperCase();
        if (speciesA < speciesB) {
          return -1;
        }
        return 1;
      });
    } else if (this.state.ordering === 'taxonomic') {
      speciesRows = speciesRows.sort((a, b) => {
        return taxonomy[a].order - taxonomy[b].order;
      });
    } else if (this.state.ordering === 'abundance') {
      speciesRows = speciesRows.sort((a, b) => {
        return this.state.speciesTotals[b] - this.state.speciesTotals[a];
      });
    } else if (this.state.ordering === 'alphabet') {
      speciesRows = speciesRows.sort((a, b) => {
        const speciesA = taxonomy[a].common_name.toUpperCase();
        const speciesB = taxonomy[b].common_name.toUpperCase();
        if (speciesA < speciesB) {
          return -1;
        }
        return 1;
      });
    }

    let flipRows = false;
    let collapsable = false;
    let collapseCount = defaultShowSpecies;

    const neededSpecies = speciesRows.slice(0, this.state.hideSurplusSpecies ? defaultShowSpecies : speciesRows.length);

    const generateSpeciesTable = neededSpecies.map((speciesId) => {
      flipRows = !flipRows;
      collapseCount -= 1;
      if (collapseCount < 0) {
        collapsable = true;
      }
      const speciesChartTemplate = {
        chart: {
          renderTo: this,
          height: 40,
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [2, 0, 2, 0],
          skipClone: true,
          className: cx('highchartsTooltipMod')
        },
        title: {
          text: ''
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        colors: ['#0E32A8'],
        xAxis: {
          type: 'datetime',
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          labels: {
            enabled: true,
            x: 0,
            y: 10,
            style: {
              color: 'rgba(14, 50, 168, .7)',
              fontWeight: '200',
              'text-shadow': '0px 0px 2px #ffffff',
            },
            align: 'left',
          },
          title: {
            text: null
          },
          startOnTick: true,
          endOnTick: true,
          showFirstLabel: false,
          offset: 0,
          tickAmount: 2,
          minTickInterval: 1,
          minorTickInterval: null,
          min: 0,
          allowDecimals: false,
        },
        legend: {
          enabled: false
        },
        tooltip: {
          headerFormat: '<span style="font-size: 14px; font-weight: bold;">{series.name}</span><br/><span style="font-size: 12px">{point.x:%B %e}</span><br/>',
          pointFormat: 'Count: {point.y}',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          hideDelay: 0,
          shared: true,
          padding: 0,
          positioner (w, h, point) {
            return { x: point.plotX - w, y: (point.plotY - h) * 0.66 };
          }
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver'
          }
        }
      };

      const sparkOutput = [];

      for (let i = 0; i < dateList.length; i++) {
        sparkOutput.push({
          x: moment.tz(dateList[i], projectData.timezone).toDate(),
          // x: parseInt(moment.tz(dateList[i], projectData.timezone).format('x'), 10),
          y: speciesDays[speciesId][i]
        });
      }

      const curSpecies = {};
      for (const k in speciesChartTemplate) curSpecies[k] = speciesChartTemplate[k];
      curSpecies.series = [{ type: 'area', name: taxonomy[speciesId].common_name, data: sparkOutput }];

      const leaveStatic = true;

      return (
        <div className={cx('row', { 'active': flipRows }, { 'collapsable': collapsable }, { 'collapsed': this.state.hideSurplusSpecies })} key={'sparkspeciesrow_' + String(projectData.id) + '_' + String(speciesId)}>
          <div className={cx('name')}>
            {taxonomy[speciesId].common_name}
          </div>
          <div className={cx('chart')}>
            <ReactHighcharts config={curSpecies} isPureConfig={leaveStatic} />
          </div>
          <div className={cx('count')}>
            {this.state.speciesTotals[speciesId]}
          </div>
        </div>
      );
    });

    this.setState({
      sparkchartOutput: generateSpeciesTable,
    });
  }

  render() {
    return (
      <div className={cx('sparktable')}>
        <h3 className={cx('section-head')}>Daily Counts</h3>
        <div>
          <form
            className={cx('ordering-swapper')}
            onSubmit={(event) => {
              event.preventDefault();
            }} >
            <label htmlFor={'ordering'}>
              Sort By:
            </label>
            <select
              id={'ordering'}
              name="ordering"
              onChange={this.orderingChange}
              value={this.state.ordering} >
              <option value="focus_taxonomic">Taxonomic with Focus Species First</option>
              <option value="focus_alphabet">Alphabetic with Focus Species First</option>
              <option value="focus_abundance">Abundance with Focus Species First</option>
              <option value="taxonomic">Taxonomic</option>
              <option value="alphabet">Alphabetic</option>
              <option value="abundance">Abundance</option>
            </select>
          </form>
        </div>
        <div className={cx('table')}>
          {this.state.sparkchartOutput}
          <div
            className={cx('show-more-button', { 'hideSubmit': !this.state.hideSurplusSpecies })}
            type="submit"
            value="See More"
            onClick={this.showMoreSpecies} >
            Show More Species
          </div>
        </div>
      </div>
    );
  }
}

SparkChart.propTypes = {
  projectData: PropTypes.object.isRequired,
  taxonomy: PropTypes.object.isRequired,
  dateList: PropTypes.array.isRequired,
  speciesDays: PropTypes.object.isRequired,
  focusSpeciesList: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    taxonomy: state.taxonomy.taxonomy,
    dateList: state.project.dateList,
    speciesDays: state.project.speciesDays,
    focusSpeciesList: state.project.projectData.focus_species_list,
  };
}

export default connect(mapStateToProps, { })(SparkChart);
