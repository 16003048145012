import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, IndexLink } from 'react-router';
import { connect } from 'react-redux';
import classNames from '../utils/classNames.js';
import mobile from 'is-mobile';
import { logOut } from '../actions/users';
import { toggleContextHelp } from '../actions/admin';
import styles from '../css/components/navigation';
import wordmarkDunkadoo from '../images/Dunkadoo-Wordmark.png';

const cx = classNames.bind(styles);

const mobileBreakpoint = 600;

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.linkSelected = this.linkSelected.bind(this);
    this.navigationLinks = this.navigationLinks.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.initiateLogout = this.initiateLogout.bind(this);

    this.state = {
      isMobile: false,
      linksDisplayed: true,
    };
  }

  componentDidMount() {
    this.setState({
      isMobile: mobile(),
      linksDisplayed: !mobile(),
    });
  }

  linkSelected() {
    if (this.state.isMobile) {
      this.setState({
        linksDisplayed: false,
      });
    }
  }

  navigationLinks() {
    const { authenticated, toggleContextHelp } = this.props;

    return [
      <div
        className={cx('links')}
        key={0} >
        { authenticated &&
          <div
            className={cx('link')}
            key={1} >
            <Link
              onClick={this.linkSelected}
              to="/dashboard">
              Dashboard
            </Link>
          </div>
        }
        { authenticated ? (
          <div
            className={cx('link')}
            key={2} >
            <Link
              onClick={this.linkSelected}
              to="/explore">
              Explore
            </Link>
          </div>
        ) : (
          <div
            className={cx('link')}
            key={3} >
            <Link
              onClick={this.linkSelected}
              to="/explore">
              Explore
            </Link>
          </div>
        )}
        { !authenticated &&
          <div
            className={cx('link')}
            key={4} >
            <Link
              onClick={this.linkSelected}
              to="/features">
              Features
            </Link>
          </div>
        }
        { !authenticated &&
          <div
            className={cx('link')}
            key={5} >
            <Link
              onClick={this.linkSelected}
              to="/pricing">
              Pricing
            </Link>
          </div>
        }
        <div
          className={cx('link')}
          key={6} >
          <Link
            onClick={this.linkSelected}
            to="/about">
            About
          </Link>
        </div>
        <div
          className={cx('link')}
          key={7} >
          <a
            onClick={this.linkSelected}
            href="https://blog.dunkadoo.org">
            Blog
          </a>
        </div>
        { authenticated ? (
          <div
            className={cx('link')}
            key={8} >
            <a
              onClick={this.linkSelected}
              onClick={this.initiateLogout}>
              Logout
            </a>
          </div>
        ) : (
          <div
            className={cx('link')}
            key={9} >
            <Link
              onClick={this.linkSelected}
              to="/login">
              Login
            </Link>
          </div>
        ) }
        { authenticated &&
          <button
            key={10}
            className={cx('link', 'context-help-toggle')}
            onClick={(event) => {
              event.preventDefault();
              toggleContextHelp();
              }
            } >
            ?
          </button>
        }
      </div>
    ];
  }

  openMenu() {
    this.setState({
      linksDisplayed: !this.state.linksDisplayed,
    });
  }

  initiateLogout(event) {
    const { logOut } = this.props;

    event.preventDefault();

    logOut();
  }

  render() {
    const allLinks = this.navigationLinks();

    return (
      <div
        className={cx('navigation')} >
        <h1 className={cx('brand')}>
          <IndexLink to="/">
            <img src={wordmarkDunkadoo} alt="Dunkadoo" />
          </IndexLink>
        </h1>
        {this.state.isMobile &&
          <div
            className={cx('mobile-expand-collapse', {'links-displayed': this.state.linksDisplayed})}
            onClick={this.openMenu} >
            <div />
            <div />
            <div />
          </div>
        }
        <nav
          className={cx({'links-collapsed': !this.state.linksDisplayed, 'links-displayed': this.state.linksDisplayed})}
          role="navigation">
          {allLinks}
        </nav>
      </div>
    );
  }
}

Navigation.propTypes = {
  toggleContextHelp: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticated: state.user.authenticated,
  };
}

export default connect(mapStateToProps, { logOut, toggleContextHelp })(Navigation);
