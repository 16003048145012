import React from 'react';
import Page from '../pages/Page';
import EmbedContainer from '../containers/Embed';
import { title, meta, link } from './assets';

const Embed = props => (
  <Page title={title} meta={meta} link={link}>
    <EmbedContainer {...props} />
  </Page>
);

export default Embed;

