import { combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import * as types from 'types';

const taxonomy = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_SUCCESS:
      // Cast order to float for easy sorting later
      const tempTaxonomy = (action.data.Species || []).map((thisSpecies) => {
        const tempSpecies = thisSpecies;
        tempSpecies.order = parseFloat(tempSpecies.order);
        return tempSpecies;
      });
      return keyBy(tempTaxonomy, 'id');
    default:
      return state;
  }
};

const taxonomyReducer = combineReducers({
  taxonomy,
});

export default taxonomyReducer;
