import React from 'react';
import { Link } from 'react-router';
import classNames from '../utils/classNames.js';
import styles from '../css/components/footer';

const cx = classNames.bind(styles);

const Footer = ({ }) => {
    return (
      <nav className={cx('footer')} role="Footer">
        <Link to="/privacy" className={cx('item')} activeClassName={cx('active')}>Privacy Policy</Link>
        <div
          className={cx('separator')} >
          &#8226;
        </div>
        <Link to="/terms" className={cx('item')} activeClassName={cx('active')}>Terms of Service</Link>
        <div
          className={cx('separator')} >
          &#8226;
        </div>
        <Link to="/cookies" className={cx('item')} activeClassName={cx('active')}>Cookie Policy</Link>
        <div
          className={cx('separator')} >
          &#8226;
        </div>
        <Link to="/acknowledgements" className={cx('item')} activeClassName={cx('active')}>Acknowledgements</Link>
        <br
          className={cx('separator')} />
        <div
          className={cx('item')} >
          Website Copyright © {new Date().getFullYear()} Dunkadoo
        </div>
        <div
          className={cx('separator')} >
          &#8226;
        </div>
        <div
          className={cx('item')} >
          Data Owned by the Collecting Organization
        </div>
      </nav>
    );
};

export default Footer;
