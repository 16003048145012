import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import classNames from '../utils/classNames.js';
import Dimensions from '../utils/local_modules/react-dimensions';
import GoogleMapReact from 'google-map-react';
import { fitBounds, ptInBounds, withStateSelector } from 'google-map-react/utils';
import Modal from 'react-modal';
import ObsMarker from 'components/ObsMarker';
import styles from 'css/components/mapupdates';

Modal.setAppElement('body');

const moment = require('moment-timezone');

const cx = classNames.bind(styles);

const zoomLevels = [360, 180, 90, 45, 22.5, 11.25, 5.625, 2.813, 1.406, 0.703, 0.352, 0.176, 0.088, 0.044, 0.022, 0.011, 0.005, 0.003, 0.001, 0.0005];
const zoomMultiplier = 0.6;

const timeControlWindows = [
  {
    min: 60 * 24 * 365 * 100,
    count: 'All',
    unit: '',
    breakpoint: 0
  },
  {
    min: 60 * 24 * 30,
    count: '1',
    unit: 'month',
    breakpoint: 0
  },
  {
    min: 60 * 24 * 7,
    count: '1',
    unit: 'week',
    breakpoint: 0
  },
  {
    min: 60 * 24,
    count: '1',
    unit: 'day',
    breakpoint: 0
  },
  {
    min: 60 * 12,
    count: '12',
    unit: 'hours',
    breakpoint: 550
  },
  {
    min: 60 * 6,
    count: '6',
    unit: 'hours',
    breakpoint: 600
  },
  {
    min: 60,
    count: '1',
    unit: 'hour',
    breakpoint: 0
  },
  {
    min: 30,
    count: '30',
    unit: 'min',
    breakpoint: 300
  },
  {
    min: 10,
    count: '10',
    unit: 'min',
    breakpoint: 700
  },
  {
    min: 5,
    count: '5',
    unit: 'min',
    breakpoint: 650
  }
];

class MapUpdates extends Component {
  constructor(props) {
    super(props);

    this.getSeriesColor = this.getSeriesColor.bind(this);
    this.onChildClick = this.onChildClick.bind(this);
    this.clusterPosition = this.clusterPosition.bind(this);
    this.setBounds = this.setBounds.bind(this);
    this.tightenZoom = this.tightenZoom.bind(this);
    this.joinIds = this.joinIds.bind(this);
    this.calculateObservationClusters = this.calculateObservationClusters.bind(this);
    this.unhoveredObservations = this.unhoveredObservations.bind(this);
    this.clearFeaturedObservations = this.clearFeaturedObservations.bind(this);
    this.hoveredObservations = this.hoveredObservations.bind(this);
    this.hoverObservationDetails = this.hoverObservationDetails.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.toggleNotable = this.toggleNotable.bind(this);
    this.mapInteraction = this.mapInteraction.bind(this);
    this.loadMobileModal = this.loadMobileModal.bind(this);
    this.closeMobileModal = this.closeMobileModal.bind(this);

    let timeWindow;
    for (let i = timeControlWindows.length - 1; i >= 0; i -= 1) {
      if (props.mapHours * 60 >= timeControlWindows[i].min) {
        // Select one interval greater
        timeWindow = timeControlWindows[i - 1].min;
      } else {
        break;
      }
    }

    this.state = {
      currentObs: Object.keys(props.recentCoordinatesIdentified),
      observationClusters: [],
      featuredObservations: [],
      timeWindow,
      maxWindow: timeWindow,
      mapInteraction: false,
      resettingMap: true,
      center: null,
      zoom: null,
      featureNotable: props.mapNotable,
      isMobile: false,
      mobileModalOpen: false,
    };
  }

  getSeriesColor(species) {
    const colorScheme = ['#BE1E2D', '#BE1E3A', '#BF1E48', '#C01E56', '#C11E65', '#C21E73', '#C31E81', '#C41E90', '#C51E9F', '#C61EAE', '#C71EBD', '#C31EC7', '#B51EC8', '#A71EC9', '#991ECA', '#8B1ECB', '#7D1ECC', '#6E1ECD', '#601ECE', '#511ECF', '#421ED0', '#331ED1', '#241ED1', '#1E27D2', '#1E36D3', '#1E46D4', '#1E55D5', '#1E65D6', '#1E75D7', '#1E86D8', '#1E96D9', '#1EA7DA', '#1EB7DA', '#1EC8DB', '#1ED9DC', '#1EDDD0', '#1DDEC0', '#1DDFB1', '#1DE0A1', '#1DE191', '#1DE281', '#1DE370', '#1DE460', '#1DE44F', '#1DE53E', '#1DE62D', '#1EE71D', '#2FE81D', '#41E91D', '#52EA1D', '#64EB1D', '#76EC1D', '#88ED1D', '#9AED1D', '#ACEE1D', '#BFEF1D', '#D1F01D', '#E4F11D', '#F2ED1D', '#F3DB1D', '#F4CA1D', '#F5B81D', '#F6A61D', '#F7951D'];
    let speciesCode = 0;

    for (let thisChar = 0; thisChar < species.length; thisChar += 1) {
      speciesCode += species.charCodeAt(thisChar) * 31;
    }

    speciesCode %= colorScheme.length;

    return colorScheme[speciesCode];
  }

  componentWillMount() {
    this.setBounds(this.props.recentCoordinatesOrdered, this.props.recentCoordinatesOrdered, this.props.recentCoordinatesIdentified);
  }

  componentDidMount() {
    // http://detectmobilebrowsers.com/
    const a = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));

    this.setState({
      isMobile,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.currentObs.length !== Object.keys(nextProps.recentCoordinatesIdentified).length) {
      if (this.state.mapInteraction) {
        this.calculateObservationClusters(nextProps.recentCoordinatesOrdered, nextProps.recentCoordinatesIdentified);
      } else {
        this.setState({
          resettingMap: true,
        }, () => {
          this.setBounds(nextProps.recentCoordinatesOrdered, nextProps.recentCoordinatesOrdered, nextProps.recentCoordinatesIdentified);
        });
      }

      this.setState({
        currentObs: Object.keys(nextProps.recentCoordinatesIdentified)
      });
    }
  }

  onChildClick(markerKey) {
    const { projects, organizations } = this.props;
  }

  setBounds(observationsToConsider, recentCoordinatesOrdered, recentCoordinatesIdentified) {
    const { containerHeight, containerWidth } = this.props;

    if (observationsToConsider.length > 1) {
      let north = -180;
      let south = 180;
      let west = 180;
      let east = -180;

      observationsToConsider.forEach((thisObservation) => {
        const latitude = thisObservation.latitude;
        const longitude = thisObservation.longitude;

        if (latitude > north) {
          north = latitude;
        }
        if (latitude < south) {
          south = latitude;
        }
        if (longitude > east) {
          east = longitude;
        }
        if (longitude < west) {
          west = longitude;
        }
      });

      const bounds = {
        ne: {
          lat: north,
          lng: east
        },
        sw: {
          lat: south,
          lng: west
        }
      };

      const size = {
        width: containerWidth,
        height: containerHeight,
      };

      let center;
      let zoom;
      if ((north === south) && (east === west)) {
        center = bounds.ne;
        zoom = 20;
      } else {
        const fit = fitBounds(bounds, size);
        center = fit.center;
        zoom = fit.zoom;
      }

      // Leave room for the map markers
      if (this.state.isMobile) {
        zoom -= 2;
      } else {
        zoom -= 1;
      }

      zoom = Math.min(zoom, 19);

      this.setState({
        center,
        zoom
      }, () => {
        this.calculateObservationClusters(recentCoordinatesOrdered, recentCoordinatesIdentified);
      });
    } else {
      const center = {
        lat: observationsToConsider[0].latitude,
        lng: observationsToConsider[0].longitude,
      };
      const zoom = 19;

      this.setState({
        center,
        zoom
      }, () => {
        this.calculateObservationClusters(recentCoordinatesOrdered, recentCoordinatesIdentified);
      });
    }
  }

  clusterPosition(groupOfObservations) {
    let lat = 0;
    let lng = 0;

    groupOfObservations.forEach((thisObservation) => {
      lat += thisObservation.latitude;
      lng += thisObservation.longitude;
    });

    lat /= groupOfObservations.length;
    lng /= groupOfObservations.length;

    const position = {
      lat,
      lng
    };

    return position;
  }

  tightenZoom(observations) {
    const { recentCoordinatesOrdered, recentCoordinatesIdentified } = this.props;

    this.setBounds(observations, recentCoordinatesOrdered, recentCoordinatesIdentified);
  }

  resetMap() {
    const { recentCoordinatesOrdered, recentCoordinatesIdentified, mapNotable } = this.props;

    // We handle it this way because we get an onChange when we initialize the map
    this.setState({
      resettingMap: true,
      timeWindow: this.state.maxWindow,
      featureNotable: mapNotable,
    }, () => {
      ReactGA.event({
        category: 'Public: MapUpdates',
        action: 'resetMap',
        label: this.props.projectData.name,
      });

      this.setBounds(recentCoordinatesOrdered, recentCoordinatesOrdered, recentCoordinatesIdentified);
    });
  }

  joinIds(idA, idB) {
    if (idA < idB) {
      return idA + idB;
    }
    return idB + idA;
  }

  calculateObservationClusters(recentCoordinatesOrdered, recentCoordinatesIdentified) {
    const distanceThreshold = zoomLevels[this.state.zoom] * zoomMultiplier;

    const startTime = moment().subtract(this.state.timeWindow, 'minutes');

    const timeBoundCoordinatesOrdered = recentCoordinatesOrdered.filter((thisObservation) => {
      const timeControl = moment.tz(thisObservation.recorded_at, 'UTC').isSameOrAfter(startTime);
      const notableControl = this.state.featureNotable ? thisObservation.notable : true;
      return timeControl && notableControl;
    });

    const grid = {};
    const observationClusters = [];

    timeBoundCoordinatesOrdered.forEach((thisObs) => {
      const xGrid = parseInt((thisObs.latitude + 180) / distanceThreshold, 10);
      const yGrid = parseInt((thisObs.longitude + 180) / distanceThreshold, 10);
      if (!(xGrid in grid)) {
        grid[xGrid] = {};
      }
      if (!(yGrid in grid[xGrid])) {
        grid[xGrid][yGrid] = [];
      }
      grid[xGrid][yGrid].push(thisObs);
    });

    Object.keys(grid).forEach((thisX) => {
      Object.keys(grid[thisX]).forEach((thisY) => {
        observationClusters.push({
          uuid: grid[thisX][thisY][0].uuid,
          ...this.clusterPosition(grid[thisX][thisY]),
          constituents: grid[thisX][thisY]
        });
      });
    });

    this.setState({
      observationClusters,
    });
  }

  hoveredObservations(featuredObservations) {
    if (this.state.featuredObservationTimeout) {
      window.clearTimeout(this.state.featuredObservationTimeout);
    }

    this.setState({
      featuredObservations,
      featuredObservationTimeout: false,
    }, () => {
      ReactGA.event({
        category: 'Public: MapUpdates',
        action: 'hoveredObservations',
        label: this.props.projectData.name,
      });
    });
  }

  hoverObservationDetails() {
    if (this.state.featuredObservationTimeout) {
      window.clearTimeout(this.state.featuredObservationTimeout);
    }

    this.setState({
      featuredObservationTimeout: false,
    });
  }

  clearFeaturedObservations() {
    window.clearTimeout(this.state.featuredObservationTimeout);
    this.setState({
      featuredObservations: [],
      featuredObservationTimeout: false,
    });
  }

  unhoveredObservations() {
    this.setState({
      featuredObservationTimeout: window.setTimeout(this.clearFeaturedObservations, 5000),
    });
  }

  setTimeWindow(minutes) {
    const { recentCoordinatesOrdered, recentCoordinatesIdentified } = this.props;

    this.setState({
      timeWindow: minutes,
    }, () => {
      ReactGA.event({
        category: 'Public: MapUpdates',
        action: 'setTimeWindow: ' + moment.duration(minutes, 'minutes').humanize(),
        label: this.props.projectData.name,
      });

      this.calculateObservationClusters(recentCoordinatesOrdered, recentCoordinatesIdentified);
    });
  }

  toggleNotable() {
    const { recentCoordinatesOrdered, recentCoordinatesIdentified } = this.props;

    this.setState({
      featureNotable: !this.state.featureNotable,
    }, () => {
      this.calculateObservationClusters(recentCoordinatesOrdered, recentCoordinatesIdentified);
    });
  }

  mapInteraction(event) {
    const { recentCoordinatesOrdered, recentCoordinatesIdentified } = this.props;
    if (('zoom' in event) && (event.zoom !== this.state.zoom)) {
      this.setState({
        zoom: event.zoom,
      }, () => {
        this.calculateObservationClusters(recentCoordinatesOrdered, recentCoordinatesIdentified);
      });
    }

    if (('center' in event) && ((event.center.lat !== this.state.center.lat) || (event.center.lng !== this.state.center.lng))) {
      this.setState({
        center: event.center,
      });
    }

    if (this.state.resettingMap) {
      this.setState({
        mapInteraction: false,
        resettingMap: false,
      });
    } else {
      this.setState({
        mapInteraction: true,
      });
    }
  }

  loadMobileModal() {
    this.setState({
      mobileModalOpen: true,
    });
  }

  closeMobileModal() {
    this.setState({
      mobileModalOpen: false,
    });
  }

  render() {
    const { containerHeight, containerWidth, googleAPIKey, taxonomy, mapHours, mapNotable } = this.props;

    const mobileMapModalStyle = {
      overlay: {
          position: 'fixed',
          width: '100vw',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#FFFFFF',
          zIndex: 9998,
        },
      content: {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        padding: 0,
        overflow: 'hidden',
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
        border: 'none',
        zIndex: 9999,
      }
    };

    const mapSetup = {
      key: googleAPIKey,
      language: 'en'
    };

    const mapOptions = {
      mapTypeId: 'satellite',
      maxZoom: 19,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: this.state.isMobile ? 'greedy' : 'cooperative',
    };

    const promptMapOptions = {
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      draggable: false,
      scrollwheel: false,
      fullscreenControl: false,
    };

    const observationMarkers = this.state.observationClusters.map((thisCluster) => {
      return (
        <ObsMarker
          key={thisCluster.uuid}
          lat={thisCluster.lat}
          lng={thisCluster.lng}
          observations={thisCluster.constituents}
          hoveredObservations={this.hoveredObservations}
          unhoveredObservations={this.unhoveredObservations}
          tightenZoom={this.tightenZoom}
          selectedMarker={this.onChildClick}
          mobile={this.state.isMobile} />
      );
    });

    let notableCount = 0;
    if (this.state.observationClusters.length > 1) {
      notableCount = this.state.observationClusters.map((thisCluster) => {
        return thisCluster.constituents.filter((thisObs) => { return thisObs.notable; }).length;
      }).reduce((a, b) => {
        return a + b;
      });
    } else if (this.state.observationClusters.length === 1) {
      notableCount = this.state.observationClusters[0].constituents.filter((thisObs) => { return thisObs.notable; }).length;
    }

    const notableToDisplay = !mapNotable && (notableCount > 0);

    const interactiveMap = (
      <div
        className={cx('interactive-map')} >
        <div
          className={cx('map-and-controls')} >
          <div
            className={cx('toolbar', {'toolbar-wide': containerWidth >= 450, 'toolbar-thin': containerWidth < 450})}
            style={{
              width: this.state.isMobile ? 'calc(100vw - 12px)' : containerWidth - 12,
            }} >
            {this.state.isMobile &&
              <button
                className={cx('mobile-modal-close-button')}
                onClick={() => {
                  this.closeMobileModal();
                  }}>
                  &#10006;
              </button>
            }
            {(this.state.isMobile && notableToDisplay) &&
              <div>
                <button
                  className={cx('reset-button', 'control-button-mobile')}
                  onClick={this.resetMap} >
                  Reset Map
                </button>
                <button
                  className={cx('notable-toggle', 'control-button-mobile', {'notable-toggle-engaged': this.state.featureNotable})}
                  onClick={this.toggleNotable} >
                  Notable
                </button>
              </div>
            }
            {(!this.state.isMobile || !notableToDisplay) &&
              <button
                className={cx('reset-button')}
                onClick={this.resetMap} >
                Reset Map
              </button>
            }
            {(!this.state.isMobile && notableToDisplay) &&
              <button
                className={cx('notable-toggle', {'notable-toggle-engaged': this.state.featureNotable})}
                onClick={this.toggleNotable} >
                Notable
              </button>
            }
            <div
              className={cx('timebound-button-container')} >
              {timeControlWindows.map((thisWindow) => {
                if (thisWindow.min > this.state.maxWindow) {
                  return false;
                } else if ((thisWindow.min !== this.state.maxWindow) && (containerWidth < thisWindow.breakpoint)) {
                  return false;
                }
                return (
                  <button
                    key={thisWindow.min}
                    className={cx('timebound-button', {'timebound-button-selected': this.state.timeWindow === thisWindow.min})}
                    onClick={() => {
                      this.setTimeWindow(thisWindow.min);
                    }} >
                    {thisWindow.count}<br />{thisWindow.unit}
                  </button>
                );
              })}
            </div>
          </div>
          <div
            className={cx('map')}
            style={{
              height: this.state.isMobile ? containerHeight - 150 : containerHeight - 12,
              width: this.state.isMobile ? '100vw' : containerWidth,
            }} >
            <GoogleMapReact
              bootstrapURLKeys={mapSetup}
              center={this.state.center}
              zoom={this.state.zoom}
              options={mapOptions}
              onChange={this.mapInteraction} >
              {observationMarkers}
            </GoogleMapReact>
          </div>
        </div>
        <div
          className={cx('observation-display', {'observation-detail-fading': this.state.featuredObservationTimeout})}
          onMouseEnter={this.hoverObservationDetails}
          onMouseLeave={this.unhoveredObservations} >
          {this.state.featuredObservations.map((thisObservation) => {
            return (
              <div
                key={thisObservation.uuid}
                className={cx('observation-detail')} >
                <span
                  style={{color: this.getSeriesColor(taxonomy[thisObservation.species_id].common_name)}}
                  className={cx('species-block')} >
                  &#x2022;
                </span>
                <div
                  className={cx('observation-detail-content')} >
                  {taxonomy[thisObservation.species_id].common_name}
                  {this.state.isMobile ? <br /> : ' - '}                
                  {moment.tz(thisObservation.recorded_at, 'UTC').tz(moment.tz.guess()).format('dddd, MMMM Do, LT')}
                </div>
              </div>
            );
          })}
          {this.state.featuredObservations.length === 0 &&
            <p
              className={cx('map-observation-instructions')} >
              Select Marker on Map
            </p>
          }
        </div>
      </div>
    );

    const promptMap = (
      <div
        className={cx('prompt-map-container')} >
        <div
          className={cx('prompt-map-prompt')}
          onClick={this.loadMobileModal} >
          Touch to Open Mobile Map Viewer
        </div>
        <div
          className={cx('prompt-map')} >
          <GoogleMapReact
            bootstrapURLKeys={mapSetup}
            center={this.state.center}
            zoom={this.state.zoom}
            options={promptMapOptions} />
        </div>
      </div>
    );

    return (
      <div>
        <div
          className={cx('map-wrapper')} >
          {this.state.isMobile ? promptMap : interactiveMap}
        </div>
        <Modal
          isOpen={this.state.mobileModalOpen}
          style={mobileMapModalStyle}
          contentLabel="Mobile Map" >
          {interactiveMap}
        </Modal>
      </div>
    );
  }
}

MapUpdates.propTypes = {
  projectData: PropTypes.object.isRequired,
  recentCoordinatesOrdered: PropTypes.array.isRequired,
  recentCoordinatesIdentified: PropTypes.object.isRequired,
  taxonomy: PropTypes.object,
  mapHours: PropTypes.number,
  mapNotable: PropTypes.bool,
  googleAPIKey: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    recentCoordinatesOrdered: state.project.recentCoordinates.ordered,
    recentCoordinatesIdentified: state.project.recentCoordinates.identified,
    taxonomy: state.taxonomy.taxonomy,
    mapHours: state.project.projectData.public_map_hours,
    mapNotable: state.project.projectData.public_map_notable,
    googleAPIKey: state.environment.GOOGLE_APIKEY,
  };
}

export default connect(mapStateToProps, {  })(
  Dimensions({
    getHeight: function(element) {
      return Math.min(window.innerHeight, 600);
    },
    getWidth: function(element) {
      return Math.min(element.clientWidth, 800);
    }
})(MapUpdates));
