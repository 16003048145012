/* eslint consistent-return: 0, no-else-return: 0*/
import { polyfill } from 'es6-promise';
import request from 'axios';
import md5 from 'spark-md5';
import * as types from '../types';

polyfill();

export function makeHawkCountAdminRequest(method, projectId, data, action = '') {
  return request[method]('/ui/v1/integrations/hawkcount/admin/' + projectId + (action !== '' ? '/' + action : action), data);
}

export function createHawkCountSuccess(data) {
  return { type: types.ADD_INTEGRATION_HAWKCOUNT_SUCCESS, data };
}

export function createHawkCountFailure(data) {
  return {
    type: types.ADD_INTEGRATION_HAWKCOUNT_FAILURE,
    id: data.projectId,
    error: data.error
  };
}

export function createHawkCount(integrationData) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeHawkCountAdminRequest('post', 0, integrationData, 'create').then((hawkcountLandscape) => {
      dispatch(createHawkCountSuccess(hawkcountLandscape.data));
    }).catch(() => {
      dispatch(createHawkCountFailure({ projectId: integrationData.project_id, error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function gatherHawkCountSuccess(data) {
  return { type: types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS, data };
}

export function gatherHawkCountFailure(data) {
  return {
    type: types.INTEGRATION_HAWKCOUNT_GATHER_FAILURE,
    data: data.hawkcountSiteID,
    error: data.error
  };
}

export function gatherHawkCount(hawkcountSiteID) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeHawkCountAdminRequest('get', hawkcountSiteID, null, 'gather').then((hawkcountSiteData) => {
      dispatch(gatherHawkCountSuccess(hawkcountSiteData.data));
    }).catch(() => {
      dispatch(gatherHawkCountFailure({ hawkcountSiteID, error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function sendHawkCountCrossrefSuccess(data) {
  return { type: types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS, data };
}

export function sendHawkCountCrossrefFailure(data) {
  return {
    type: types.INTEGRATION_HAWKCOUNT_CROSSREF_FAILURE,
    id: null,
    error: data.error
  };
}

export function sendHawkCountCrossref(projectId, crossref) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeHawkCountAdminRequest('post', projectId, crossref, 'crossref').then((hawkcountSiteData) => {
      dispatch(sendHawkCountCrossrefSuccess(hawkcountSiteData.data));
    }).catch(() => {
      dispatch(sendHawkCountCrossrefFailure({ error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function sendHawkCountConditionsSuccess(data) {
  return { type: types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS, data };
}

export function sendHawkCountConditionsFailure(data) {
  return {
    type: types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_FAILURE,
    id: null,
    error: data.error
  };
}

export function sendHawkCountConditions(projectId, conditions) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeHawkCountAdminRequest('post', projectId, conditions, 'conditionsfields').then((hawkcountSiteData) => {
      dispatch(sendHawkCountConditionsSuccess(hawkcountSiteData.data));
    }).catch(() => {
      dispatch(sendHawkCountConditionsFailure({ error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function sendHawkCountActiveSuccess(data) {
  return { type: types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS, data };
}

export function sendHawkCountActiveFailure(data) {
  return {
    type: types.INTEGRATION_HAWKCOUNT_ACTIVE_FAILURE,
    id: null,
    error: data.error
  };
}

export function sendHawkCountActive(integrationId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeHawkCountAdminRequest('post', integrationId, null, 'active').then((hawkcountSiteData) => {
      dispatch(sendHawkCountActiveSuccess(hawkcountSiteData.data));
    }).catch(() => {
      dispatch(sendHawkCountActiveFailure({ error: 'Unable to Connect to the Internet' }));
    });
  };
}

export function sendHawkCountForceSyncSuccess(data) {
  return { type: types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS, data };
}

export function sendHawkCountForceSyncFailure(data) {
  return {
    type: types.INTEGRATION_HAWKCOUNT_FORCESYNC_FAILURE,
    id: null,
    error: data.error
  };
}

export function sendHawkCountForceSync(projectId, timeWindow) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    makeHawkCountAdminRequest('post', projectId, timeWindow, 'forcesync').then((hawkcountSiteData) => {
      dispatch(sendHawkCountForceSyncSuccess(hawkcountSiteData.data));
    }).catch(() => {
      dispatch(sendHawkCountForceSyncFailure({ error: 'Unable to Connect to the Internet' }));
    });
  };
}
