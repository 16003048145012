import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import { retrieveRecent, clearProject } from '../actions/project';
import isEmpty from 'lodash/isEmpty';
import fill from 'lodash/fill';
import moment from 'moment-timezone';
import classNames from '../utils/classNames.js';
import Tallies from 'components/Tallies';
import MapUpdates from 'components/MapUpdates';
import LiveChart from 'components/LiveChart';
import TextUpdates from 'components/TextUpdates';
import NotableUpdates from 'components/NotableUpdates';
import SparkChart from 'components/SparkChart';
import SpeciesComp from 'components/SpeciesComp';
import HourlyCounts from 'components/HourlyCounts';
import WeatherChart from 'components/WeatherChart';
import styles from 'css/components/project';

const cx = classNames.bind(styles);

const updateFrequency = 10;

class Project extends Component {
  constructor(props) {
    super(props);

    this.dataUpdater = this.dataUpdater.bind(this);
    this.displayLearnMore = this.displayLearnMore.bind(this);

    this.state = {
      liveChartInterval: null,
      learnMore: false,
    };
  }

  componentDidMount() {
    const { retrieveRecent, projectData } = this.props;

    this.setState({
      liveChartInterval: window.setInterval(() => {
        this.dataUpdater();
      }, updateFrequency * 1000),
    });
  }

  componentWillUnmount() {
    const { clearProject } = this.props;

    window.clearInterval(this.state.liveChartInterval);

    clearProject();
  }

  dataUpdater() {
    const { retrieveRecent, projectData } = this.props;
    retrieveRecent(projectData.id);
  }

  displayLearnMore() {
    this.setState({
      learnMore: !this.state.learnMore,
    }, () => {
      ReactGA.event({
        category: 'Public',
        action: 'displayLearnMore: ' + String(this.state.learnMore),
        label: this.props.projectData.name,
      });
    });
  }

  render() {
    const {
      dataPresent,
      dayTally,
      forecastData,
      projectData,
      recentCoordinates,
      recentNotable,
      recentObservations,
      seasonTally,
    } = this.props;

    if (isEmpty(projectData)) {
      return (
        <div />
      );
    }

    const visualizationBlocks = [];
    visualizationBlocks.length = 10;
    fill(visualizationBlocks, false);

    if (projectData.public_show_daytallies && dayTally.count > 0) {
      visualizationBlocks[projectData.public_order_daytallies] = (
        <div
          key={projectData.public_order_daytallies} >
          <Tallies
            range="day"
            key={'daytally' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_seasontallies && seasonTally.count > 0) {
      visualizationBlocks[projectData.public_order_seasontallies] = (
        <div
          key={projectData.public_order_seasontallies} >
          <Tallies
            range="season"
            key={'seasontally' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_flagged && recentNotable.length > 0) {
      visualizationBlocks[projectData.public_order_flagged] = (
        <div
          key={projectData.public_order_flagged} >
          <NotableUpdates key={'flagged' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_map && recentCoordinates.length > 0) {
      visualizationBlocks[projectData.public_order_map] = (
        <div
          key={projectData.public_order_map} >
          <div
            className={cx('tip')} >
            <h4>Map</h4>
            <p>Find the locations of sightings on the map.</p>
            <p>Click on the markers to display the sightings in the box below the map. As you zoom in closer, the clusters will break into more detailed points.</p>
            <p>Click "Reset Map" if you would like to display all the sightings and have the map automatically update as new sightings come in.</p>
            <p>Select the time period buttons to only show sightings from that long ago.</p>
            <p>Drag the map to pan and use the + and - to control the zoom.</p>
          </div>
          <MapUpdates key={'map' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_livechart && moment().isSameOrBefore(moment.tz(projectData.endDate, 'UTC'))) {
      visualizationBlocks[projectData.public_order_livechart] = (
        <div
          key={projectData.public_order_livechart} >
          <div
            className={cx('tip')} >
            <h4>Live Updates</h4>
            <p>The sightings you see here are being collected on a tablet by a biologist out in the field.</p>
            <p>Hover your mouse over the points on the graph to see more details. Click on different species in the legend to show or hide them on the chart.</p>
            <p>If there's nothing showing, it may mean that this site doesn't have access to the internet or isn't counting right now.</p>
          </div>
          <LiveChart key={'livechart' + String(projectData.id)} />
          {!projectData.public_show_textupdates &&
            <div className={cx('project-block-spacer')} />
          }
        </div>
      );
    }
    if (projectData.public_show_textupdates && recentObservations.length > 0) {
      visualizationBlocks[projectData.public_order_textupdates] = (
        <div
          key={projectData.public_order_textupdates} >
          <TextUpdates key={'textupdates' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_composition) {
      visualizationBlocks[projectData.public_order_composition] = (
        <div
          key={projectData.public_order_composition} >
          <div
            className={cx('tip')} >
            <h4>Species Composition</h4>
            <p>Species composition changes over time depending on weather, seasons, and many other factors. This chart displays the composition over a time period you select.</p>
            <p>Click on pie pieces to see more detail. If there are more than seven species, click on "Other" to see a breakdown of the rest.</p>
            <h5>Display</h5>
            <p>Many sites have a protocol that is designed to maximize finding particular species. If you select "Focus Species," only these species will be shown.</p>
            <h5>Date Selector</h5>
            <p>The control box below the pie chart lets you select a date period for the chart.</p>
            <p>You can push the buttons "1d," "1w," or "1m" to zoom the graph to 1 day, 1 week, or 1 month.</p>
            <p>The graph shows the overall volume seen on each day. You can drag the sliders on each side of this graph to adjust the start and end dates.</p>
          </div>
          <SpeciesComp key={'speciescomp' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_hourly) {
      visualizationBlocks[projectData.public_order_hourly] = (
        <div
          key={projectData.public_order_hourly} >
          <div
            className={cx('tip')} >
            <h4>Hourly Data</h4>
            <p>The time shown in the top row is the start of the one hour period.</p>
            <h5>Select Day</h5>
            <p>Choose a date to load the hourly table for that day. Only days that have data are shown.</p>
          </div>
          <HourlyCounts key={'hourlycounts' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_speciescharts) {
      visualizationBlocks[projectData.public_order_speciescharts] = (
        <div
          key={projectData.public_order_speciescharts} >
          <div
            className={cx('tip')} >
            <h4>Daily Counts</h4>
            <p>These charts show which species are most numerous at different parts of the season. Hover your mouse over a chart to see the number for a given day. The right column shows season totals and the left side shows the maximum for a single day. Each graph is scaled so that the single-day maximum is the highest point on the chart.</p>
            <h5>Sort By</h5>
            <ul>
              <li><i>Focus Species:</i> Show the highest priority species at the top of the list.</li>
              <li><i>Taxonomic Order:</i> Sort the species by their scientific classification.</li>
              <li><i>Alphabetic Order:</i> Sort the species by their common name.</li>
              <li><i>Abundance:</i> Sort the species with the largest number counted at the top.</li>
            </ul>
          </div>
          <SparkChart key={'sparkchart' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_weather && (!isEmpty(forecastData))) {
      visualizationBlocks[projectData.public_order_weather] = (
        <div
          key={projectData.public_order_weather} >
          <WeatherChart key={'weatherchart' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }

    return (
      <div
        className={cx('project-container-outer')} >
        <div
          className={cx('learn-more-selector')}
          onClick={(event) => {
            event.preventDefault();
            this.displayLearnMore();
            }
          } >
          <button
            className={cx('learn-more-button')}
            onClick={(event) => {
              event.preventDefault();
              this.displayLearnMore();
              }
            } >
            ?
          </button>
          Learn More
        </div>
        <div
          className={cx('project-container', {'learn-more': this.state.learnMore})} >
          <div
            className={cx('organization-header')} >
            {projectData.organization.logo_url &&
              <div
                className={cx('organization-logo-container')} >
                {projectData.organization.website_url ? (
                  <ReactGA.OutboundLink
                    eventLabel={'Public: Organization Link - ' + projectData.organization.name}
                    to={projectData.organization.website_url}
                    className={cx('organization-link-wrapper')} >
                    <img
                      src={projectData.organization.logo_url}
                      alt={projectData.organization.name}
                      className={cx('organization-logo')} />
                  </ReactGA.OutboundLink>
                ) : (
                  <img
                    src={projectData.organization.logo_url}
                    alt={projectData.organization.name}
                    className={cx('organization-logo')} />
                )}
              </div>
            }
            <div
              className={cx('organization-name')} >
              <ReactGA.OutboundLink
                eventLabel={'Public: Organization Link - ' + projectData.organization.name}
                to={projectData.organization.website_url}
                className={cx('organization-link-wrapper')} >
                {projectData.organization.name}
              </ReactGA.OutboundLink>
            </div>
          </div>
          <div>
            <div
              className={cx('project-name-container')} >
              {projectData.name &&
                <h2
                  className={cx('project-name')} >
                  {projectData.name}
                </h2>
              }
            </div>
            {dataPresent ? (
              visualizationBlocks
            ) : (
              <h3>This project does not yet contain any data.</h3>
            )}
          </div>
          {(projectData.description && (projectData.description !== '')) &&
            <div>
              <h3
                className={cx('project-detail-header')}>
                {projectData.name}
              </h3>
              <div>
                {projectData.description.split('\\n').map((thisParagraph, thisPid) => {
                  return (
                    <p
                      key={thisPid}>
                      {thisParagraph}
                    </p>
                  );
                })}
              </div>
            </div>
          }
          {(projectData.directions && (projectData.directions !== '')) &&
            <div>
              <h3
                className={cx('project-detail-header')}>
                Directions
              </h3>
              <div>
                {projectData.directions.split('\\n').map((thisParagraph, thisPid) => {
                  return (
                    <p
                      key={thisPid}>
                      {thisParagraph}
                    </p>
                  );
                })}
              </div>
            </div>
          }
          {(projectData.organization.description && (projectData.organization.description !== '')) &&
            <div>
              <h3
                className={cx('project-detail-header')}>
                {projectData.organization.name}
              </h3>
              <div>
                {projectData.organization.description.split('\\n').map((thisParagraph, thisPid) => {
                  return (
                    <p
                      key={thisPid}>
                      {thisParagraph}
                    </p>
                  );
                })}
              </div>
            </div>
          }
          {(projectData.organization.data_notice && (projectData.organization.data_notice !== '')) &&
            <div>
              <h3
                className={cx('project-detail-header')}>
                About the Data
              </h3>
              <div>
                {projectData.organization.data_notice.split('\\n').map((thisParagraph, thisPid) => {
                  return (
                    <p
                      key={thisPid}>
                      {thisParagraph}
                    </p>
                  );
                })}
              </div>
            </div>
          }
          {(projectData.organization.donate_cta && (projectData.organization.donate_cta !== '')) &&
            <div>
              <h3
                className={cx('project-detail-header')}>
                Support
              </h3>
              <div
                className={cx('donate-description')} >
                {projectData.organization.donate_cta.split('\\n').map((thisParagraph, thisPid) => {
                  return (
                    <p
                      key={thisPid}>
                      {thisParagraph}
                    </p>
                  );
                })}
              </div>
              <div
                className={cx('donate-url-wrapper')} >
                {projectData.organization.donate_url &&
                  <ReactGA.OutboundLink
                    eventLabel={'Public: Organization Link - ' + projectData.organization.name}
                    to={projectData.organization.donate_url}
                    className={cx('donate-url')} >
                    Click Here to Donate
                  </ReactGA.OutboundLink>
                }
              </div>
            </div>
          }
          {(projectData.organization.sponsor_logo || projectData.organization.sponsor_description) &&
            <div
              className={cx('sponsor-container')} >
              {(projectData.organization.sponsor_logo && (projectData.organization.sponsor_logo !== '')) &&
                <div
                  className={cx('sponsor-logo-wrapper')} >
                  <img
                    src={projectData.organization.sponsor_logo}
                    alt={projectData.organization.name}
                    className={cx('sponsor-logo')} />
                </div>
              }
              {(projectData.organization.sponsor_description && (projectData.organization.sponsor_description !== '')) &&
                <div
                  className={cx('sponsor-description')} >
                  {projectData.organization.sponsor_description.split('\\n').map((thisParagraph, thisPid) => {
                    return (
                      <p
                        key={thisPid}>
                        {thisParagraph}
                      </p>
                    );
                  })}
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

Project.propTypes = {
  retrieveRecent: PropTypes.func.isRequired,
  clearProject: PropTypes.func.isRequired,
  dayTally: PropTypes.object.isRequired,
  seasonTally: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  forecastData: PropTypes.object,
  recentNotable: PropTypes.array,
  recentObservations: PropTypes.array,
  recentCoordinates: PropTypes.array,
};

function mapStateToProps(state) {
  let dataPresent = false;
  if (state.project.seasonTally.count > 0) {
    dataPresent = true;
  } else if (Object.keys(state.project.speciesDays).length > 0) {
    dataPresent = true;
  }

  return {
    projectData: state.project.projectData,
    dataPresent,
    dayTally: state.project.dayTally,
    seasonTally: state.project.seasonTally,
    recentNotable: state.project.recentNotable,
    recentObservations: state.project.recentObservations.ordered,
    recentCoordinates: state.project.recentCoordinates.ordered,
    forecastData: state.project.forecastData,
  };
}

export default connect(mapStateToProps, { retrieveRecent, clearProject })(Project);
