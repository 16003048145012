import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Loading from 'react-loading';
import classNames from '../utils/classNames.js';
import { logOut } from '../actions/users';
import Navigation from '../containers/Navigation';
import PanelAdmin from '../containers/PanelAdmin';
import Footer from '../containers/Footer';
import Message from '../containers/Message';
import styles from '../css/main';

Modal.setAppElement('body');

const cx = classNames.bind(styles);

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, loading, authenticated, currentPage, contextHelp } = this.props;

    const loadingModal = {
      overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(100, 100, 100, 0.6)'
        },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        border: 'none',
      }
    };

    return (
      <div className={cx('app', {'context-help-enabled': contextHelp, 'context-help-disabled': !contextHelp})}>
        <header
          className={cx('app-header')} >
          <Navigation />
        </header>
        <div
          className={cx('app-container')} >
          <main
            className={cx('app-wrapper')} >
            <div
              className={cx('app-content')} >
              {children}
            </div>
          </main>
          {(authenticated && (currentPage === '/dashboard')) &&
            <nav
              className={cx('app-panel')} >
              <PanelAdmin />
            </nav>
          }
        </div>
        <footer
          className={cx('app-footer')}>
          <Footer />
        </footer>
        <Modal
          isOpen={loading}
          style={loadingModal}
          contentLabel="Loading..."
        >
          <Loading type="spinningBubbles" color="#fff" height={100} width={100} />
        </Modal>
        <Message />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.object,
  loading: PropTypes.bool,
  authenticated: PropTypes.bool,
  currentPage: PropTypes.string,
  contextHelp: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    loading: state.isLoading,
    authenticated: state.user.authenticated,
    currentPage: ownProps.location.pathname,
    contextHelp: state.admin.contextHelp,
  };
}

export default connect(mapStateToProps, { logOut })(App);
