import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import { retrieveRecent, clearProject } from '../actions/project';
import isEmpty from 'lodash/isEmpty';
import fill from 'lodash/fill';
import moment from 'moment-timezone';
import classNames from '../utils/classNames.js';
import Tallies from 'components/Tallies';
import MapUpdates from 'components/MapUpdates';
import LiveChart from 'components/LiveChart';
import TextUpdates from 'components/TextUpdates';
import NotableUpdates from 'components/NotableUpdates';
import SpeciesComp from 'components/SpeciesComp';
import HourlyCounts from 'components/HourlyCounts';
import styles from 'css/components/project';

const cx = classNames.bind(styles);

const updateFrequency = 10;

class EmbedProject extends Component {
  constructor(props) {
    super(props);

    this.dataUpdater = this.dataUpdater.bind(this);

    this.state = {
      liveChartInterval: null,
      learnMore: false,
    };
  }

  componentDidMount() {
    const { retrieveRecent, projectData } = this.props;

    this.setState({
      liveChartInterval: window.setInterval(() => {
        this.dataUpdater();
      }, updateFrequency * 1000),
    });
  }

  componentWillUnmount() {
    const { clearProject } = this.props;

    window.clearInterval(this.state.liveChartInterval);

    clearProject();
  }

  dataUpdater() {
    const { retrieveRecent, projectData } = this.props;
    retrieveRecent(projectData.id);
  }

  render() {
    const { projectData, dayTally, seasonTally, recentNotable, recentObservations, recentCoordinates, segments } = this.props;

    if (isEmpty(projectData)) {
      return (
        <div />
      );
    }

    const visualizationBlocks = [];
    visualizationBlocks.length = 10;
    fill(visualizationBlocks, false);

    if ((projectData.public_show_daytallies && segments.indexOf('daytallies') > -1) && dayTally.count > 0) {
      visualizationBlocks[segments.indexOf('daytallies')] = (
        <div
          key={segments.indexOf('daytallies')} >
          <Tallies
            range="day"
            key={'daytally' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if ((projectData.public_show_seasontallies && segments.indexOf('seasontallies') > -1) && seasonTally.count > 0) {
      visualizationBlocks[segments.indexOf('seasontallies')] = (
        <div
          key={segments.indexOf('seasontallies')} >
          <Tallies
            range="season"
            key={'seasontally' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if ((projectData.public_show_flagged && segments.indexOf('flagged') > -1) && recentNotable.length > 0) {
      visualizationBlocks[segments.indexOf('flagged')] = (
        <div
          key={segments.indexOf('flagged')} >
          <NotableUpdates key={'flagged' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if ((projectData.public_show_map && segments.indexOf('map') > -1) && recentCoordinates.length > 0) {
      visualizationBlocks[segments.indexOf('map')] = (
        <div
          key={segments.indexOf('map')} >
          <MapUpdates key={'map' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if ((projectData.public_show_livechart && segments.indexOf('livechart') > -1) && (recentObservations.length > 0 && moment().isSameOrBefore(moment.tz(projectData.endDate, 'UTC')))) {
      visualizationBlocks[segments.indexOf('livechart')] = (
        <div
          key={segments.indexOf('livechart')} >
          <LiveChart key={'livechart' + String(projectData.id)} />
          {!projectData.public_show_textupdates &&
            <div className={cx('project-block-spacer')} />
          }
        </div>
      );
    }
    if ((projectData.public_show_textupdates && segments.indexOf('textupdates') > -1) && recentObservations.length > 0) {
      visualizationBlocks[segments.indexOf('textupdates')] = (
        <div
          key={segments.indexOf('textupdates')} >
          <TextUpdates key={'textupdates' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_composition && segments.indexOf('composition') > -1) {
      visualizationBlocks[segments.indexOf('composition')] = (
        <div
          key={segments.indexOf('composition')} >
          <SpeciesComp key={'speciescomp' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }
    if (projectData.public_show_hourly && segments.indexOf('hourly') > -1) {
      visualizationBlocks[segments.indexOf('hourly')] = (
        <div
          key={segments.indexOf('hourly')} >
          <HourlyCounts key={'hourlycounts' + String(projectData.id)} />
          <div className={cx('project-block-spacer')} />
        </div>
      );
    }

    return (
      <div
        className={cx('project-container-outer')} >
        <div
          className={cx('project-container')} >
          <div>
            {visualizationBlocks}
          </div>
        </div>
      </div>
    );
  }
}

EmbedProject.propTypes = {
  retrieveRecent: PropTypes.func.isRequired,
  clearProject: PropTypes.func.isRequired,
  dayTally: PropTypes.object.isRequired,
  seasonTally: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired,
  recentNotable: PropTypes.array,
  recentObservations: PropTypes.array,
  recentCoordinates: PropTypes.array,
  segments: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  return {
    projectData: state.project.projectData,
    dayTally: state.project.dayTally,
    seasonTally: state.project.seasonTally,
    recentNotable: state.project.recentNotable,
    recentObservations: state.project.recentObservations.ordered,
    recentCoordinates: state.project.recentCoordinates.ordered,
    segments: 'segments' in ownProps.location.query ? ownProps.location.query.segments.split(',') : ['daytallies', 'seasontallies', 'flagged', 'map', 'livechart', 'textupdates', 'composition', 'hourly'],
  };
}

export default connect(mapStateToProps, { retrieveRecent, clearProject })(EmbedProject);
