import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ReactGA from 'react-ga';
import GoogleMapReact from 'google-map-react';
import { fitBounds, ptInBounds, withStateSelector } from 'google-map-react/utils';
import BoidMarker from 'components/BoidMarker';
import ReplayProjectMarker from 'components/ReplayProjectMarker';
import classNames from '../utils/classNames.js';
import styles from 'css/components/replay';
import moment from 'moment-timezone';
import remove from 'lodash/remove';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import random from 'lodash/random';
import { updateLandscapeRecent, clearLandscapeRecent, startLandscapeRecent, exitLandscapeRecent } from 'actions/project';

const inBrowser = typeof window !== 'undefined';

const cx = classNames.bind(styles);

const maxBoids = 40;
const timeoutSeconds = 10;
const maxZoom = 7;

// Migrating North or South
const currentMonth = moment().month();

// https://en.wikipedia.org/wiki/Geographic_center_of_the_contiguous_United_States
const geographicCenter = [39.8333333, -98.585522];
// https://en.wikipedia.org/wiki/List_of_extreme_points_of_the_United_States
const northernZoom = [-95.153389, 49.384472];
const southernZoom = [-81.804905, 24.54409];

const southwestBound = {lon: -124.848974, lat: 24.396308};
const northeastBound = {lon: -66.885444, lat: 49.384358};

class Replay extends Component {
  constructor(props) {
    super(props);

    this.animateBoids = this.animateBoids.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.reportReplayAnalytics = this.reportReplayAnalytics.bind(this);
    this.initializeVisualization = this.initializeVisualization.bind(this);
    this.updateVisualization = this.updateVisualization.bind(this);
    this.getSeriesColor = this.getSeriesColor.bind(this);
    this.slowerSpeed = this.slowerSpeed.bind(this);
    this.fasterSpeed = this.fasterSpeed.bind(this);
    this.catchUp = this.catchUp.bind(this);

    this.state = {
      realTime: false,
      catchingUp: false,
      flock: [],
      boids: [],
      pendingObservations: [],
      pendingTimes: [],
      usedObservations: [],
      currentTime: null,
      timeInterval: 60,
      updaterInterval: null,
      animatorInterval: null,
      reportGAInterval: null,
      lastUpdated: moment(),
      currentSpecies: [],
      textUpdates: [],
      activeProjects: [],
      currentProjects: [],
      containerHeight: 200,
      containerWidth: 600,
      displayingData: false,
      center: {lat: geographicCenter[0], lng: geographicCenter[1]},
      zoom: maxZoom,
      visibilityHidden: null,
      visibilityChange: null,
      mountTime: false,
    };
  }

  getSeriesColor(species) {
    const colorScheme = ['#BE1E2D', '#BE1E3A', '#BF1E48', '#C01E56', '#C11E65', '#C21E73', '#C31E81', '#C41E90', '#C51E9F', '#C61EAE', '#C71EBD', '#C31EC7', '#B51EC8', '#A71EC9', '#991ECA', '#8B1ECB', '#7D1ECC', '#6E1ECD', '#601ECE', '#511ECF', '#421ED0', '#331ED1', '#241ED1', '#1E27D2', '#1E36D3', '#1E46D4', '#1E55D5', '#1E65D6', '#1E75D7', '#1E86D8', '#1E96D9', '#1EA7DA', '#1EB7DA', '#1EC8DB', '#1ED9DC', '#1EDDD0', '#1DDEC0', '#1DDFB1', '#1DE0A1', '#1DE191', '#1DE281', '#1DE370', '#1DE460', '#1DE44F', '#1DE53E', '#1DE62D', '#1EE71D', '#2FE81D', '#41E91D', '#52EA1D', '#64EB1D', '#76EC1D', '#88ED1D', '#9AED1D', '#ACEE1D', '#BFEF1D', '#D1F01D', '#E4F11D', '#F2ED1D', '#F3DB1D', '#F4CA1D', '#F5B81D', '#F6A61D', '#F7951D'];
    let speciesCode = 0;

    for (let thisChar = 0; thisChar < species.length; thisChar += 1) {
      speciesCode += species.charCodeAt(thisChar) * 31;
    }

    speciesCode %= colorScheme.length;

    return colorScheme[speciesCode];
  }

  handleVisibilityChange() {
    if (document[this.state.visibilityHidden]) {
      window.clearInterval(this.state.reportGAInterval);

      this.setState({
        mountTime: false,
      });
    }
  }

  componentDidMount() {
    const { clearLandscapeRecent, recentObs } = this.props;

    let pendingObservations = recentObs.map((thisObs) => {
      const updateObs = thisObs;
      updateObs.time = moment(thisObs.recorded_at);
      return updateObs;
    });

    const checkDay = moment().tz(moment.tz.guess()).startOf('day');
    let checkTodayObservations = [];
    while (checkTodayObservations.length === 0) {
      checkTodayObservations = pendingObservations.filter((thisObs) => {
        return checkDay.isSameOrBefore(thisObs.time);
      });

      checkDay.subtract(1, 'day');
    }

    pendingObservations = checkTodayObservations;

    const activeProjects = uniq(pendingObservations.map((thisObs) => {
      return thisObs.project_id;
    }));

    const startingTimes = pendingObservations.map((thisObs) => {
      return thisObs.time;
    });

    startingTimes.sort((a, b) => {
      return a.valueOf() - b.valueOf();
    });

    // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    let visibilityHidden, visibilityChange; 
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
      visibilityHidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityHidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityHidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (typeof document.addEventListener !== 'undefined' || typeof document[visibilityHidden] !== 'undefined') {
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    }

    this.setState({
      containerWidth: window.innerWidth <= 880 ? window.innerWidth : window.innerWidth - 40,
      containerHeight: window.innerWidth <= 600 ? 200 : Math.max(400, window.innerHeight - 350),
      pendingObservations,
      pendingTimes: startingTimes.map((thisTime) => { return thisTime.startOf('minute').format(); }),
      usedObservations: [],
      currentTime: startingTimes[0].clone().subtract(1, 'minutes'),
      lastUpdated: moment(),
      currentSpecies: [],
      activeProjects,
      currentProjects: [],
      textUpdates: [],
      visibilityHidden,
      visibilityChange,
      mountTime: moment(),
    }, this.initializeVisualization);

    // Pending observations were loaded in the constructor
    clearLandscapeRecent();
  }

  componentWillUnmount() {
    const { exitLandscapeRecent } = this.props;

    window.clearInterval(this.state.updaterInterval);
    window.clearInterval(this.state.animatorInterval);
    window.clearInterval(this.state.reportGAInterval);

    exitLandscapeRecent();
  }

  reportReplayAnalytics() {
    if (this.state.mountTime) {
      if (!document[this.state.visibilityHidden]) {
        const secondsWatched = parseInt(moment.utc(moment().diff(this.state.mountTime)).format('s'), 10);
        ReactGA.event({
          category: 'Replay',
          action: 'Watched Replay',
          label: String(secondsWatched) + ' Seconds',
          value: secondsWatched,
          nonInteraction: true,
        });
      }
    }
  }

  initializeVisualization() {
    const updaterInterval = window.setInterval(() => {
        this.updateVisualization();
      }, 1000);

    const animatorInterval = window.setInterval(() => {
        this.animateBoids();
      }, 500);

    const reportGAInterval = window.setInterval(this.reportReplayAnalytics, 10000);

    this.setState({
      updaterInterval,
      animatorInterval,
      reportGAInterval,
    });
  }

  updateVisualization() {
    const { recentObs, updateLandscapeRecent, startLandscapeRecent, projects } = this.props;

    let period = this.state.currentTime.clone().add(this.state.timeInterval, 'seconds');

    const nowTime = moment();

    if (period.isAfter(nowTime)) {
      period = nowTime;
    }

    let pendingObservations = this.state.pendingObservations;

    let insertObs = pendingObservations.filter((thisObs) => {
      return (this.state.usedObservations.indexOf(thisObs.uuid) === -1) && thisObs.time.isSameOrBefore(period);
    });

    insertObs = uniq(insertObs);

    insertObs.sort((a, b) => {
      return a.time.valueOf() - b.time.valueOf();
    });

    let buildFlock = this.state.flock;

    if (insertObs.length > 0) {

      const textUpdates = this.state.textUpdates;
      const currentSpecies = this.state.currentSpecies;
      const usedObservations = this.state.usedObservations;

      insertObs.forEach((thisObs) => {
        const speciesColor = this.getSeriesColor(thisObs.common_name);

        usedObservations.push(thisObs.uuid);

        if (!this.state.catchingUp) {
          const displayBoids = Math.min(thisObs.count, maxBoids);
          for (let i = 0; i < displayBoids; i += 1) {
            buildFlock.push({
              lat: parseFloat(thisObs.latitude),
              lon: parseFloat(thisObs.longitude),
              timeout: moment().add(timeoutSeconds, 'seconds'),
              angle: (currentMonth >= 6) ? random(Math.PI * (1 / 4), Math.PI * (3 / 4)) : random(Math.PI * (5 / 4), Math.PI * (7 / 4)),
              distance: random(150, 300),
              count: thisObs.count,
              color: speciesColor,
              project: thisObs.project_id,
              uuid: thisObs.uuid,
              individual: i,
              key: thisObs.uuid + '_' + String(i),
            });
          }
        }

        textUpdates.unshift(
          <Link
            key={this.state.currentTime.format('YYYY-MM-DD') + '_' + thisObs.uuid}
            className={cx('textupdates', 'observation')}
            to={'/explore/' + thisObs.organization_slug + '/' + thisObs.project_slug} >
            <div
              style={{
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '5px 5px 20px 5px',
                borderColor: 'transparent transparent ' + speciesColor + ' transparent',
              }} />
            <div
              className={cx('textupdates', 'information')} >
              <div className={cx('textupdates', 'count')}>
                {thisObs.count}
              </div>
              <div className={cx('textupdates', 'common')}>
                {thisObs.common_name}
              </div>
              <div className={cx('textupdates', 'time')}>
                at {thisObs.time.format('LTS')}
              </div>
            </div>
            <div className={cx('textupdates', 'organization')}>
              {thisObs.organization_name}
            </div>
            <div className={cx('textupdates', 'project')}>
              {thisObs.project_name}
            </div>
          </Link>
        );
      });

      this.setState({
        textUpdates,
        currentSpecies,
        usedObservations,
      });

      this.setState({
        catchingUp: false,
      });
    } else {
      let textUpdates = this.state.textUpdates;

      const previousCount = textUpdates.length;

      // Only display text updates for current day
      textUpdates = textUpdates.filter((thisUpdate) => {
        return thisUpdate.key.slice(0, 10) === period.format('YYYY-MM-DD');
      });

      if (textUpdates.length < previousCount) {
        this.setState({
          textUpdates,
        });
      }
    }

    // After observation processed, remove from pending list
    remove(pendingObservations, (thisObs) => {
      return this.state.usedObservations.indexOf(thisObs.uuid) > -1;
    });

    // If more than a minute has elapsed, get new observations
    // Only need to worry about it if live updates are needed
    if (this.state.lastUpdated.clone().add(15, 'seconds').isSameOrBefore(period)) {
      updateLandscapeRecent(this.state.lastUpdated.format());

      this.setState({
        lastUpdated: nowTime,
        realTime: true,
      });
    }

    // If there are new observations that have downloaded, add them to the pending observations list
    if (recentObs.length > 0) {
      const importObservations = recentObs.map((thisObs) => {
        const updateObs = thisObs;
        updateObs.time = moment(thisObs.recorded_at);
        return updateObs;
      });

      pendingObservations = concat(pendingObservations, importObservations);
      clearLandscapeRecent();
    }

    // Recalculate Map Position
    let center = this.state.center;
    let zoom = this.state.zoom;
    if (this.state.currentProjects.length > 1) {
      let north = -180;
      let south = 180;
      let west = 180;
      let east = -180;

      this.state.currentProjects.forEach((thisProject) => {
        if (projects[thisProject].latitude > north) {
          north = projects[thisProject].latitude;
        }
        if (projects[thisProject].latitude < south) {
          south = projects[thisProject].latitude;
        }
        if (projects[thisProject].longitude > east) {
          east = projects[thisProject].longitude;
        }
        if (projects[thisProject].longitude < west) {
          west = projects[thisProject].longitude;
        }
      });

      // Leave room for the map markers
      const bounds = {
        ne: {
          lat: north + 1,
          lng: east + 1
        },
        sw: {
          lat: south - 1,
          lng: west - 1
        }
      };

      const size = {
        width: this.state.containerWidth,
        height: this.state.containerHeight,
      };

      const mapParams = fitBounds(bounds, size);
      center = mapParams.center;
      // Leave room for the map markers
      zoom = Math.min(mapParams.zoom, this.state.zoom);
    } else if (this.state.currentProjects.length === 1) {
      // When there's only one project, manually handle
      center = {
        lat: projects[this.state.currentProjects[0]].latitude,
        lng: projects[this.state.currentProjects[0]].longitude,
      };
      zoom = Math.min(maxZoom, this.state.zoom);
    }

    if ((center.lat !== this.state.center.lat) && (center.lng !== this.state.center.lng)) {
      this.setState({
        center,
        zoom
      });
    }

    if (!this.state.displayingData) {
      startLandscapeRecent();

      this.setState({
        displayingData: true,
      });
    }

    this.setState({
      currentTime: period,
      pendingObservations,
    });
  }

  // Consider merging with above
  animateBoids() {
    const now = moment();

    const updateFlock = this.state.flock.filter((thisBoid) => {
      return thisBoid.timeout.isAfter(now);
    });

    const activeBoidProjects = updateFlock.map((thisBoid) => {
      return thisBoid.project;
    });

    const currentProjects = uniq([...this.state.currentProjects, ...activeBoidProjects]);
    const activeProjects = uniq([...currentProjects, ...this.state.activeProjects]);

    const boids = updateFlock.map((thisBoid) => {
      return (
        <BoidMarker
          key={thisBoid.key}
          uuid={thisBoid.uuid}
          individual={thisBoid.individual}
          lat={thisBoid.lat}
          lng={thisBoid.lon}
          angle={thisBoid.angle}
          distance={thisBoid.distance}
          color={thisBoid.color} />
      );
    });

    this.setState({
      flock: updateFlock,
      boids,
      activeProjects,
      currentProjects,
    });
  }

  slowerSpeed() {
    this.setState({
      timeInterval: this.state.timeInterval / 2,
    });
  }

  fasterSpeed() {
    this.setState({
      timeInterval: this.state.timeInterval * 2,
    });
  }

  catchUp() {
    this.setState({
      currentTime: moment(),
      timeInterval: 60,
      catchingUp: true,
    });
  }

  render() {
    const { recentRunning, googleAPIKey, projects } = this.props;

    const mapSetup = {
      key: googleAPIKey,
      language: 'en',
    };

    const mapOptions = {
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      draggable: false,
      scrollwheel: false,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          featureType: 'administrative',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#a3cffc'
            }
          ]
        }
      ]
    };

    const activeProjects = this.state.activeProjects.map((thisProjectId) => {
      if (!(thisProjectId in projects)) {
        return false;
      }

      return (
        <ReplayProjectMarker
          key={thisProjectId}
          describes={String(thisProjectId)}
          lat={projects[thisProjectId].latitude}
          lng={projects[thisProjectId].longitude}
          logo={projects[thisProjectId].Organization.logo_url}
          name={projects[thisProjectId].name}
          url={'/explore/' + projects[thisProjectId].Organization.slug + '/' + projects[thisProjectId].slug} />
      );
    });

    return (
      <div
        className={cx('replay-container', {'replay-loaded': recentRunning})} >
        <div
          className={cx('speed-control-button-container', {'speed-control-hidden': this.state.realTime})} >
          <button
            onClick={this.slowerSpeed}
            className={cx('slower-button')} >
            Slower
          </button>
          <div
            className={cx('speed-indicator')} >
            {String(this.state.timeInterval / 60)}x
          </div>
          <button
            onClick={this.fasterSpeed}
            className={cx('faster-button')} >
            Faster
          </button>
          <button
            onClick={this.catchUp}
            className={cx('now-button')} >
            Now 
          </button>
        </div>
        <div
          className={cx('current-time')} >
          {this.state.currentTime ? this.state.currentTime.tz(moment.tz.guess()).format('MMMM D') : ''}
          <br />
          {this.state.currentTime ? this.state.currentTime.tz(moment.tz.guess()).format('LT') : ''}
        </div>
        <div
          className={cx('replay-visualization')}
          style={{
            height: this.state.containerHeight,
          }}
          ref={(wrapper) => { this.wrapper = wrapper; }}>
          <GoogleMapReact
            bootstrapURLKeys={mapSetup}
            center={this.state.center}
            zoom={this.state.zoom}
            options={mapOptions} >
            {[...activeProjects, ...this.state.boids]}
          </GoogleMapReact>
        </div>
        <div
          className={cx('textupdates', 'container')} >
          {this.state.textUpdates}
        </div>
      </div>
    );
  }
}

Replay.propTypes = {
  updateLandscapeRecent: PropTypes.func.isRequired,
  clearLandscapeRecent: PropTypes.func.isRequired,
  exitLandscapeRecent: PropTypes.func.isRequired,
  startLandscapeRecent: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  recentObs: PropTypes.array.isRequired,
  recentRunning: PropTypes.bool.isRequired,
  googleAPIKey: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.landscape.projects,
    recentObs: state.landscape.recent,
    recentRunning: state.landscape.recentRunning,
    googleAPIKey: state.environment.GOOGLE_APIKEY,
  };
}

export default connect(mapStateToProps, { updateLandscapeRecent, clearLandscapeRecent, exitLandscapeRecent, startLandscapeRecent })(Replay);
