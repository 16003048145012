import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import classNames from '../utils/classNames.js';
import styles from '../css/components/schemaeditors';

const cx = classNames.bind(styles);

class SchemaTextArray extends Component {
  constructor(props) {
    super(props);

    this.ingressArray = this.ingressArray.bind(this);
    this.editTextArray = this.editTextArray.bind(this);
    this.removeTextArray = this.removeTextArray.bind(this);

    this.state = {
      textArray: this.ingressArray(props.textArray),
    };
  }

  ingressArray(array) {
    const inputArray = cloneDeep(array);

    // Provide an additional input for new elements
    inputArray.push('');

    return inputArray;
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.textArray.length !== (nextProps.textArray.length + 1)) {
      this.setState({
        textArray: this.ingressArray(nextProps.textArray),
      });
    }
  }

  editTextArray(value, index) {
    const { editArray } = this.props;

    const updateTextArray = this.state.textArray;

    if (index === (updateTextArray.length - 1)) {
      updateTextArray.push('');
    }

    updateTextArray[index] = value;

    this.setState({
      textArray: updateTextArray,
    }, () => {
      editArray(updateTextArray.slice(0, updateTextArray.length - 1));
    });
  }

  removeTextArray(index) {
    const { editArray } = this.props;

    const updateTextArray = this.state.textArray;
    updateTextArray.splice(index, 1);

    this.setState({
      textArray: updateTextArray,
    }, () => {
      editArray(updateTextArray.slice(0, updateTextArray.length - 1));
    });
  }

  render() {
    return (
      <div
        className={cx('text-array-edit-form')} >
        {this.state.textArray.map((thisElement, thisElementIndex) => {
          return (
            <div
              key={thisElementIndex}
              className={cx('text-array-edit-row')} >
              <input
                type="text"
                value={this.state.textArray[thisElementIndex]}
                onChange={(event) => {
                  this.editTextArray(event.target.value, thisElementIndex);
                }} />
              {(thisElementIndex !== (this.state.textArray.length - 1)) &&
                <button
                  className={cx('remove-text-array-button')}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.removeTextArray(thisElementIndex);
                    }
                  } >
                  &#x2716;
                </button>
              }
            </div>
          );
        })}
      </div>
    );
  }
}

SchemaTextArray.propTypes = {
  editArray: PropTypes.func.isRequired,
  textArray: PropTypes.array.isRequired,
};

export default SchemaTextArray;
