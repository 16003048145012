import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import update from 'immutability-helper';
import ReactGA from 'react-ga';
import clone from 'lodash/clone';
import without from 'lodash/without';
import isBoolean from 'lodash/isBoolean';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import Modal from 'react-modal';
import Loading from 'react-loading';
import Fuse from 'fuse.js';
import Autosuggest from 'react-autosuggest';
import moment from 'moment-timezone';
import uuidV1 from 'uuid/v1';
import Textarea from 'react-textarea-autosize';
import classNames from '../utils/classNames.js';
import { updateProjectInfo, updateWorkingProject, getProjectBrief, initiateRecord, getRecord, setRecord } from '../actions/admin';
import CoordMap from 'components/CoordMap';
import { getTaxonomy } from '../actions/taxonomy';
import styles from 'css/components/editor';
import * as types from '../types';
import speciesSearchStyles from 'css/components/speciesbuttonadder';

Modal.setAppElement('body');

const cx = classNames.bind(styles);
const cy = classNames.bind(speciesSearchStyles);

const minSearchCharacters = 2;
const maxSearchResults = 10;

// These are the settings for the fuzzy search
// Weights are provided to prioritize common_name
// Also appears in SpeciesButtonAdder
const fuseOptions = {
  shouldSort: true,
  threshold: 0.25,
  location: 0,
  distance: 100,
  maxPatternLength: 20,
  minMatchCharLength: minSearchCharacters,
  keys: [{
    name: 'common_name',
    weight: 0.6
  }, {
    name: 'code',
    weight: 0.4
  }]
};

class Editor extends Component {
  constructor(props) {
    super(props);

    this.initialState = this.initialState.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.recordCreator = this.recordCreator.bind(this);
    this.injestSiteObject = this.injestSiteObject.bind(this);
    this.collectRecord = this.collectRecord.bind(this);
    this.changeRecord = this.changeRecord.bind(this);
    this.changeSubField = this.changeSubField.bind(this);
    this.changeRadio = this.changeRadio.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
    this.updateObservationLocation = this.updateObservationLocation.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.finalizeRecord = this.finalizeRecord.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);

    this.state = this.initialState(props);
  }

  initialState(nextProps) {
    return {
      site: this.injestSiteObject(nextProps.adminSchema, nextProps.recordType),
      activeRecord: {},
      segmentTypes: [],
      timeField: (nextProps.recordType === 'observations') ? 'recorded_at' : 'start_at',
      dataFields: [],
      activeRecordEditor: false,
      errorMessage: '',
      speciesSearchValue: '',
      speciesSuggestions: [],
      speciesSearch: new Fuse(nextProps.activeTaxonomyIndex, fuseOptions),
    };
  }

  resetFields(record, segmentTypes, dataFields, recordType, activeTaxonomyLookup, adminSchema) {
    const tempRecord = clone(record);

    segmentTypes.forEach((thisSegment) => {
      tempRecord.checkboxContent[thisSegment] = {};
      dataFields[thisSegment].forEach((thisDataField) => {
        let thisQuestion;
        if ((thisSegment === 'data') && (recordType === 'observations')) {
          thisQuestion = adminSchema.schema[thisDataField];
        } else if ((thisSegment === 'species_data') && (recordType === 'observations')) {
          thisQuestion = activeTaxonomyLookup[record.species_id].species_schema[thisDataField];
        } else if (recordType === 'metadata') {
          thisQuestion = adminSchema.metadata_schema[thisDataField];
        }

        if (!(thisDataField in tempRecord[thisSegment])) {
          if (thisQuestion.type === 'text') {
            tempRecord[thisSegment][thisDataField] = '';
          } else if (thisQuestion.type === 'number') {
            tempRecord[thisSegment][thisDataField] = '';
          } else if (thisQuestion.type === 'radio') {
            tempRecord[thisSegment][thisDataField] = '';
          } else if (thisQuestion.type === 'list') {
            tempRecord[thisSegment][thisDataField] = '';
          } else if (thisQuestion.type === 'checkbox') {
            tempRecord[thisSegment][thisDataField] = [];
          }
        }

        if (thisQuestion.type === 'checkbox') {
          tempRecord.checkboxContent[thisSegment][thisDataField] = [];
          thisQuestion.options.forEach((thisOption, index) => {
            tempRecord.checkboxContent[thisSegment][thisDataField][index] = thisOption.text;
          });
        }
      });
    });

    return tempRecord;
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.projectId !== nextProps.projectId) || (nextProps.recordType !== this.props.recordType)) {
      this.setState(this.initialState(nextProps));
      return false;
    }
    if ((this.props.activeTaxonomyIndex.length !== nextProps.activeTaxonomyIndex.length) || (this.props.activeTaxonomyIndex.length > 0 && (this.props.activeTaxonomyIndex[0].id !== nextProps.activeTaxonomyIndex[0].id))) {
      this.setState({
        speciesSearch: new Fuse(nextProps.activeTaxonomyIndex, fuseOptions),
      });
    }
    if (((nextProps.activeTaxonomyIndex.length > 0) && (Object.keys(nextProps.activeRecord).length > 0)) && (this.props.activeRecord.uuid !== nextProps.activeRecord.uuid)) {
      const segmentTypes = [];
      const dataFields = {};
      if (nextProps.recordType === 'observations') {
        segmentTypes.push('species_data');

        if (nextProps.activeRecord.species_id) {
          dataFields.species_data = Object.keys(nextProps.activeTaxonomyLookup[nextProps.activeRecord.species_id].species_schema || {});

          if (dataFields.species_data.length > 0) {
            dataFields.species_data.sort((a, b) => {
              return nextProps.activeTaxonomyLookup[nextProps.activeRecord.species_id].species_schema[a].order - nextProps.activeTaxonomyLookup[nextProps.activeRecord.species_id].species_schema[b].order;
            });
          }
        } else {
          // Occurs when a new record is being created
          dataFields.species_data = [];
        }

        // Standard Questions
        segmentTypes.push('data');

        dataFields.data = Object.keys(nextProps.adminSchema.schema);
        if (dataFields.species_data.length > 0) {
          dataFields.data.sort((a, b) => {
            return nextProps.adminSchema.schema[a].order - nextProps.adminSchema.schema[b].order;
          });
        }
      } else if (nextProps.recordType === 'metadata') {
        dataFields.data = Object.keys(nextProps.adminSchema.metadata_schema);

        // These are helper fields used only on the tablet
        dataFields.data = without(dataFields.data, 'startTime', 'endTime');

        // Sort the fields by order
        if (dataFields.data.length > 0) {
          dataFields.data.sort((a, b) => {
            return nextProps.adminSchema.metadata_schema[a].order - nextProps.adminSchema.metadata_schema[b].order;
          });
        }

        segmentTypes.push('data');

        // Don't need species_data for metadata, but populate for consistency
        dataFields.species_data = [];
      }

      let tempRecord = nextProps.activeRecord;
      tempRecord.action = tempRecord.action || 'update';

      const displayTime = moment.tz(tempRecord[this.state.timeField], 'UTC').tz(nextProps.adminSchema.timezone);
      tempRecord.date = displayTime.format('YYYY-MM-DD');
      tempRecord.time = displayTime.format('HH:mm:ss');
      tempRecord.checkboxContent = {};

      const recordTime = moment.tz(tempRecord.recorded_at, 'UTC').tz(nextProps.adminSchema.timezone);
      tempRecord.record_date = recordTime.format('YYYY-MM-DD');
      tempRecord.record_time = recordTime.format('HH:mm:ss');

      if ((nextProps.recordType === 'observations') && (nextProps.activeTaxonomyIndex.length > 0)) {
        let commonName;
        if (nextProps.activeRecord.species_id) {
          commonName = nextProps.activeTaxonomyLookup[nextProps.activeRecord.species_id].common_name;
        } else {
          // Occurs when a new record is being created
          commonName = '';
        }
        tempRecord.common_name = commonName;

        this.setState({
          speciesSearchValue: commonName,
        });
      }

      tempRecord = this.resetFields(tempRecord, segmentTypes, dataFields, nextProps.recordType, nextProps.activeTaxonomyLookup, nextProps.adminSchema);

      if (nextProps.recordType === 'metadata') {
        const startTime = moment.tz(tempRecord.start_at, 'UTC').tz(nextProps.adminSchema.timezone);
        tempRecord.start_date = startTime.format('YYYY-MM-DD');
        tempRecord.start_time = startTime.format('HH:mm:ss');

        const endTime = moment.tz(tempRecord.end_at, 'UTC').tz(nextProps.adminSchema.timezone);
        tempRecord.end_date = endTime.format('YYYY-MM-DD');
        tempRecord.end_time = endTime.format('HH:mm:ss');
      }

      this.setState({
        activeRecord: tempRecord,
        segmentTypes,
        dataFields,
      });
    }
  }

  recordCreator() {
    const { initiateRecord, recordType, adminSchema, myUserId } = this.props;

    const tempRecord = {};
    tempRecord.action = 'create';
    tempRecord.uuid = uuidV1();
    tempRecord.data = {};
    tempRecord.recorded_at = moment().tz(adminSchema.timezone);
    tempRecord.device = 'Browser';
    tempRecord.user_id = myUserId;

    if (recordType === 'observations') {
      tempRecord.species_id = null;
      tempRecord.common_name = '';
      tempRecord.species_data = {};
      tempRecord.count = 1;
      tempRecord.attribute = '';
      tempRecord.flag_notable = false;
      tempRecord.flag_review = false;
    } else if (recordType === 'metadata') {
      tempRecord.start_at = moment().tz(adminSchema.timezone);
      tempRecord.end_at = moment().tz(adminSchema.timezone);
    }

    initiateRecord(tempRecord);

    ReactGA.event({
      category: 'Dashboard: Project',
      action: 'Editor: Spawn New ' + recordType,
      label: adminSchema.name,
    });
  }

  injestSiteObject(siteObject, nextRecordType) {
    const { getTaxonomy, projectId, getProjectBrief } = this.props;

    const updateSiteObject = clone(siteObject);

    getTaxonomy(siteObject.taxonomy_id);

    getProjectBrief(siteObject.id, nextRecordType);

    return updateSiteObject;
  }

  // Use Fuse.js to search through the catalog for fuzzy matches
  getSuggestions(value) {
    const { buttonGroup, speciesButtonList, focusButtonList } = this.props;

    const inputLength = value.length;

    // Don't search until the user has entered a few characters
    if (inputLength > minSearchCharacters) {
      let searchResults = this.state.speciesSearch.search(value);

      // Only display a maximum number of results
      searchResults = searchResults.slice(0, Math.min(maxSearchResults, searchResults.length));

      return searchResults;
    }

    return [];
  }

  // The search box is a controlled input
  onSearchInput(event, { newValue }) {
    this.setState({
      speciesSearchValue: newValue
    });
  }

  // Generate a value to display to the user that combines that common name and code
  getSuggestionValue(suggestion) {
    const commonName = suggestion.common_name;
    const code = suggestion.code;
    return commonName + ' (' + code + ')';
  }

  // Display the search result within the results list
  renderSuggestion(suggestion) {
    return (
      <div
        className={cx('search-suggestion')} >
        <div
          className={cx('suggestion-names')} >
          <div
            className={cx('suggestion-item-name')} >
            {suggestion.common_name}
          </div>
          <div
            className={cx('suggestion-variation-name')} >
            {suggestion.code}
          </div>
        </div>
      </div>
    );
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      speciesSuggestions: this.getSuggestions(value)
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      speciesSuggestions: []
    });
  }

  // Scroll to the suggestion
  // If the category is currently closed, we have to open it first
  onSuggestionSelected(event, { suggestion }) {
    const { activeTaxonomyLookup, recordType, adminSchema } = this.props;

    // Prevent unintended actions for the speciesButton
    event.preventDefault();
    event.stopPropagation();

    let tempRecord = clone(this.state.activeRecord);
    tempRecord.species_id = suggestion.id;
    tempRecord.common_name = suggestion.common_name;
    tempRecord.species_data = {};
    tempRecord.attribute = '';

    const dataFields = clone(this.state.dataFields);

    if (activeTaxonomyLookup[suggestion.id].species_schema) {
      dataFields.species_data = Object.keys(activeTaxonomyLookup[suggestion.id].species_schema);
    } else {
      dataFields.species_data = [];
    }

    tempRecord = this.resetFields(tempRecord, ['species_data'], dataFields, recordType, activeTaxonomyLookup, adminSchema);

    this.setState({
      speciesSearchValue: activeTaxonomyLookup[suggestion.id].common_name,
      speciesSuggestions: [],
      activeRecord: tempRecord,
      dataFields,
    });
  }

  changeRecord(event) {
    const target = event.target;

    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = isNaN(parseFloat(target.value)) ? '' : parseFloat(target.value);
        break;
      default:
        value = target.value;
    }

    const field = target.name;

    const updateRecord = clone(this.state.activeRecord);
    updateRecord[field] = value;

    this.setState({
      activeRecord: updateRecord
    });
  }

  changeSubField(segment, field, value, type) {
    let outputValue;
    switch (type) {
      case 'number':
        outputValue = isNaN(parseFloat(value)) ? '' : parseFloat(value);
        break;
      default:
        outputValue = value;
    }

    const updateRecord = clone(this.state.activeRecord);
    updateRecord[segment][field] = outputValue;

    this.setState({
      activeRecord: updateRecord
    });
  }

  changeRadio(segment, field, value) {
    const updateRecord = clone(this.state.activeRecord);
    updateRecord[segment][field] = value;

    this.setState({
      activeRecord: updateRecord
    });
  }

  changeCheckbox(segment, field, value) {
    const updateRecord = clone(this.state.activeRecord);

    if (updateRecord[segment][field].includes(value)) {
      updateRecord[segment][field] = without(updateRecord[segment][field], value);
    } else {
      updateRecord[segment][field].push(value);
    }

    updateRecord[segment][field].sort((a, b) => {
      return updateRecord.checkboxContent[segment][field].indexOf(a) - updateRecord.checkboxContent[segment][field].indexOf(b);
    });

    this.setState({
      activeRecord: updateRecord
    });
  }

  updateObservationLocation(newPosition) {
    this.setState({
      activeRecord: update(this.state.activeRecord, {
        latitude: {
          $set: newPosition.latitude,
        },
        longitude: {
          $set: newPosition.longitude,
        }
      })
    });
  }

  collectRecord(uuid) {
    const { getRecord, projectId, recordType } = this.props;

    getRecord(uuid, projectId, recordType);
  }

  deleteRecord(event) {
    const updateRecord = clone(this.state.activeRecord);
    updateRecord.action = 'delete';
    updateRecord.tablet_discarded = moment().tz('UTC').format();
    this.setState({
      activeRecord: updateRecord,
    }, () => {
      this.finalizeRecord(event);
    });
  }

  finalizeRecord(event) {
    const { setRecord, projectId, recordType, adminSchema } = this.props;

    event.preventDefault();

    const submitRecord = {};
    let readyToSubmit = true;
    let buildErrorMessage = [];

    try {
      submitRecord.action = this.state.activeRecord.action;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Save Failed</p>);
    }

    try {
      submitRecord.uuid = this.state.activeRecord.uuid;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Save Failed</p>);
    }

    try {
      const recorded_at = moment.tz(this.state.activeRecord.record_date + 'T' + this.state.activeRecord.record_time, adminSchema.timezone).tz('UTC');
      if (recorded_at.isValid()) {
        submitRecord.recorded_at = recorded_at.format();
      } else {
        throw new Error('Timestamp Failed');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Timestamp Failed</p>);
    }

    try {
      submitRecord.tablet_discarded = this.state.activeRecord.tablet_discarded || null;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Save Failed</p>);
    }

    try {
      submitRecord.data = this.state.activeRecord.data;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Questions Failed</p>);
    }

    try {
      submitRecord.device = this.state.activeRecord.device;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Questions Failed</p>);
    }

    if (recordType === 'observations') {
      try {
        submitRecord.attribute = this.state.activeRecord.attribute;
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Attribute Failed</p>);
      }

      try {
        submitRecord.species_id = parseInt(this.state.activeRecord.species_id, 10);
        if (isNaN(submitRecord.species_id)) {
          throw new Error('Species Selection Failed');
        }
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Species Selection Failed</p>);
      }

      try {
        submitRecord.count = parseInt(this.state.activeRecord.count, 10);
        if (isNaN(submitRecord.count)) {
          throw new Error('Species Selection Failed');
        }
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Count Value Failed</p>);
      }

      try {
        submitRecord.species_data = this.state.activeRecord.species_data;
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Species Questions Failed</p>);
      }

      try {
        submitRecord.flag_notable = this.state.activeRecord.flag_notable;
        if (!isBoolean(submitRecord.flag_notable)) {
          throw new Error('Notable Flag Failed');
        }
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Notable Flag Failed</p>);
      }

      try {
        submitRecord.flag_review = this.state.activeRecord.flag_review;
        if (!isBoolean(submitRecord.flag_review)) {
          throw new Error('Review Flag Failed');
        }
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Review Flag Failed</p>);
      }

      // Location Is Not Required
      if (!isNaN(this.state.activeRecord.latitude) && !isNaN(this.state.activeRecord.longitude)) {
        try {
          submitRecord.latitude = this.state.activeRecord.latitude;
          if ((submitRecord.latitude < -180) || (submitRecord.latitude > 180)) {
            throw new Error('Latitude Out of Range');
          }
        } catch (e) {
          readyToSubmit = false;
          buildErrorMessage.push(<p>Latitude Failed - Latitude Must Be Between -180 and 180</p>);
        }

        try {
          submitRecord.longitude = this.state.activeRecord.longitude;
          if ((submitRecord.longitude < -180) || (submitRecord.longitude > 180)) {
            throw new Error('Longitude Out of Range');
          }
        } catch (e) {
          readyToSubmit = false;
          buildErrorMessage.push(<p>Longitude Failed - Longitude Must Be Between -180 and 180</p>);
        }
      }
    } else {
      try {
        const start_at = moment.tz(this.state.activeRecord.start_date + 'T' + this.state.activeRecord.start_time, adminSchema.timezone).tz('UTC');
        if (start_at.isValid()) {
          submitRecord.start_at = start_at.format();
        } else {
          throw new Error('Timestamp Failed');
        }
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>Start Time Failed</p>);
      }

      try {
        const end_at = moment.tz(this.state.activeRecord.end_date + 'T' + this.state.activeRecord.end_time, adminSchema.timezone).tz('UTC');
        if (end_at.isValid()) {
          submitRecord.end_at = end_at.format();
        } else {
          throw new Error('Timestamp Failed');
        }
      } catch (e) {
        readyToSubmit = false;
        buildErrorMessage.push(<p>End Time Failed</p>);
      }
    }

    if (readyToSubmit) {
      setRecord(this.state.activeRecord.uuid, projectId, recordType, submitRecord);
      this.setState({
        errorMessage: '',
        activeRecord: {},
        dataFields: {},
        segmentTypes: [],
        activeRecordEditor: false,
      }, () => {
        ReactGA.event({
          category: 'Dashboard: Project',
          action: 'Editor: ' + submitRecord.action + ' Record ' + this.props.recordType,
          label: this.props.adminSchema.name,
          value: this.props.activeRecord.id || 0,
        });
      });
    } else {
      this.setState({
        errorMessage: buildErrorMessage,
      }, () => {
        ReactGA.exception({
          description: 'Editor: Failed to Save Record ' + this.state.activeRecord.uuid,
          fatal: false,
        });
      });
    }
  }

  render() {
    const { activeTaxonomyIndex, activeTaxonomyLookup, users, fetching, brief, adminSchema, recordType, promptNotable, promptReview } = this.props;

    const loadingModal = {
      overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(100, 100, 100, 0.6)'
        },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        border: 'none',
      }
    };

    const tableOfObs = [];
    let currentDay = '1900-000';

    if (activeTaxonomyIndex.length > 0 && brief.length > 0) {
      if (recordType === 'observations') {
        tableOfObs.push(
          <div
            key="header"
            className={cx('brief-entry', 'brief-header')} >
            <div
              className={cx('brief-time')} >
              Time
            </div>
            <div
              className={cx('brief-species')} >
              Species
            </div>
            <div
              className={cx('brief-count')} >
              Count
            </div>
          </div>
        );
      } else {
        tableOfObs.push(
          <div
            key="header"
            className={cx('brief-entry')} >
            <div
              className={cx('brief-start')} >
              Start
            </div>
            <div
              className={cx('brief-end')} >
              End
            </div>
          </div>
        );
      }

      // Show table of observations in project timezone
      let sortedBrief = brief.map((thisBrief) => {
        if (recordType === 'observations') {
          return update(thisBrief, {
            recorded_at: {
              $set: moment.tz(thisBrief.recorded_at, 'UTC').tz(adminSchema.timezone),
            }
          });
        } else {
          return update(thisBrief, {
            recorded_at: {
              $set: moment.tz(thisBrief.recorded_at, 'UTC').tz(adminSchema.timezone),
            },
            start_at: {
              $set: moment.tz(thisBrief.start_at, 'UTC').tz(adminSchema.timezone),
            },
            end_at: {
              $set: moment.tz(thisBrief.end_at, 'UTC').tz(adminSchema.timezone),
            }
          });
        }
      });

      sortedBrief = sortBy(sortedBrief, [(r) => { return -r[this.state.timeField].unix(); }]);

      for (let thisObs = 0; thisObs < sortedBrief.length; thisObs += 1) {
        const obsContent = sortedBrief[thisObs];
        const obsDay = obsContent[this.state.timeField].format('YYYY-DDDD');
        if (obsDay !== currentDay) {
          currentDay = obsDay;
          tableOfObs.push(
            <div
              key={obsDay}
              className={cx('brief-day')} >
              {obsContent[this.state.timeField].format('L')}
            </div>
          );
        }

        if (recordType === 'observations') {
          tableOfObs.push(
            <div
              key={obsContent.uuid}
              className={cx('brief-entry', {'brief-review': obsContent.flag_review, 'brief-selected': obsContent.uuid === this.state.activeRecord.uuid})}
              onClick={(event) => {
                event.preventDefault();
                this.collectRecord(obsContent.uuid);
              }} >
              <div
                className={cx('brief-time')} >
                {obsContent.recorded_at.format('LTS')}
              </div>
              <div
                className={cx('brief-species')} >
                {activeTaxonomyLookup[obsContent.species_id].common_name}
              </div>
              <div
                className={cx('brief-count')} >
                {obsContent.count}
              </div>
            </div>
          );
        } else {
          tableOfObs.push(
            <div
              key={obsContent.uuid}
              className={cx('brief-entry')}
              onClick={(event) => {
                event.preventDefault();
                this.collectRecord(obsContent.uuid);
              }} >
              <div
                className={cx('brief-start')} >
                {obsContent.start_at.format('LT')}
              </div>
              <div
                className={cx('brief-end')} >
                {obsContent.end_at.format('LT')}
              </div>
            </div>
          );
        }
      }
    }

    const autoSuggestTheme = {
      container: cy('search-result-container'),
      containerOpen: cy('search-result-container--open'),
      input: cy('search-result-input'),
      inputOpen: cy('search-result-input--open'),
      inputFocused: cy('search-result-input--focused'),
      suggestionsContainer: cy('search-result-suggestions-container'),
      suggestionsContainerOpen: cy('search-result-suggestions-container--open'),
      suggestionsList: cy('search-result-suggestions-list'),
      suggestion: cy('search-result-suggestion'),
      suggestionFirst: cy('search-result-suggestion--first'),
      suggestionHighlighted: cy('search-result-suggestion--highlighted'),
      sectionContainer: cy('search-result-section-container'),
      sectionContainerFirst: cy('search-result-section-container--first'),
      sectionTitle: cy('search-result-section-title')
    };

    const inputProps = {
      placeholder: 'Search to Change Species',
      value: this.state.speciesSearchValue,
      onChange: this.onSearchInput
    };

    let hasAttribute = false;
    if ((Object.keys(this.state.activeRecord).length > 0 && recordType === 'observations') && this.state.activeRecord.species_id) {
      hasAttribute = activeTaxonomyLookup[this.state.activeRecord.species_id].attribute ? activeTaxonomyLookup[this.state.activeRecord.species_id].attribute.length > 0 : false;
    }

    const questionFields = [];

    this.state.segmentTypes.forEach((thisSegment) => {
      this.state.dataFields[thisSegment].forEach((thisDataField) => {
        let thisQuestion;
        if ((thisSegment === 'data') && (recordType === 'observations')) {
          thisQuestion = this.state.site.schema[thisDataField];
        } else if ((thisSegment === 'species_data') && (recordType === 'observations')) {
          thisQuestion = activeTaxonomyLookup[this.state.activeRecord.species_id].species_schema[thisDataField];
        } else if (recordType === 'metadata') {
          thisQuestion = this.state.site.metadata_schema[thisDataField];
        }

        questionFields.push(
          <div
            key={thisSegment + thisDataField + String(thisQuestion.order)} >
            <label
              htmlFor={thisSegment + thisDataField}
              className={cx('question-prompt')} >
              {thisQuestion.prompt}
            </label>
            {thisQuestion.type === 'text' &&
              <Textarea
                id={thisSegment + thisDataField}
                name={thisSegment + thisDataField}
                className={cx('long-text')}
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.changeSubField(thisSegment, thisDataField, event.target.value, 'text');
                }}
                value={this.state.activeRecord[thisSegment][thisDataField]} />
            }
            {thisQuestion.type === 'number' &&
              <input
                id={thisSegment + thisDataField}
                name={thisSegment + thisDataField}
                type="number"
                step="any"
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.changeSubField(thisSegment, thisDataField, event.target.value, 'number');
                }}
                value={this.state.activeRecord[thisSegment][thisDataField]} />
            }
            {thisQuestion.type === 'radio' && thisQuestion.options.map((thisOption, index) => {
              return (
                <div
                  key={thisOption.text + '_' + String(index)} >
                  <input
                    id={thisSegment + thisDataField + '_' + String(index)}
                    name={thisSegment + thisDataField}
                    type="radio"
                    onChange={() => {
                      this.changeRadio(thisSegment, thisDataField, thisOption.text);
                    }}
                    value={thisOption.text}
                    checked={this.state.activeRecord[thisSegment][thisDataField] === thisOption.text} />
                  <label
                    htmlFor={thisSegment + thisDataField + '_' + String(index)}
                    className={cx('option-text')} >
                    {thisOption.text}
                  </label>
                </div>
              );
            })}
            {thisQuestion.type === 'list' &&
              <div>
                <input
                  id={thisSegment + thisDataField}
                  name={thisSegment + thisDataField}
                  list={'list_' + thisDataField}
                  type="list"
                  onChange={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.changeSubField(thisSegment, thisDataField, event.target.value, 'list');
                  }}
                  value={this.state.activeRecord[thisSegment][thisDataField]} />
                <datalist id={'list_' + thisDataField}>
                  {thisQuestion.options.map((thisOption, index) => {
                    return (
                      <option
                        key={thisOption.text + '_' + String(index)}
                        value={thisOption.text} />
                    );
                  })
                }
                </datalist>
              </div>
            }
            {thisQuestion.type === 'checkbox' && thisQuestion.options.map((thisOption, index) => {
              return (
                <div
                  key={thisOption.text + '_' + String(index)} >
                  <input
                    id={thisSegment + thisDataField + '_' + String(index)}
                    name={thisSegment + thisDataField + '_' + String(index)}
                    type="checkbox"
                    onChange={() => {
                      this.changeCheckbox(thisSegment, thisDataField, thisOption.text);
                    }}
                    value={thisOption.text}
                    checked={this.state.activeRecord[thisSegment][thisDataField].includes(thisOption.text)} />
                  <label
                    htmlFor={thisSegment + thisDataField + '_' + String(index)}
                    className={cx('option-text')} >
                    {thisOption.text}
                  </label>
                </div>
              );
            })}
            <br />
          </div>
        );
      });
    });

    return (
      <div
        className={cx('container')} >
        <h2>{this.state.site.name}</h2>
        <div
          className={cx('editor-activity-wrapper')} >
          <div
            className={cx('brief-container')} >
            <div
              className={cx('add-record-button-container')} >
              <button
                className={cx('add-record-button')}
                onClick={this.recordCreator} >
                Create New
              </button>
            </div>
            {tableOfObs}
          </div>
          {Object.keys(this.state.activeRecord).length > 0 &&
            <div
              className={cx('edit-container')} >
              <div
                className={cx('question-prompt')} >
                Creator
              </div>
              <div
                className={cx('context-help')} >
                This is the user who originally entered this report. This person cannot be changed.
              </div>
              <div
                className={cx('static-detail')} >
                {users[this.state.activeRecord.user_id].display_name}
              </div>
              {recordType === 'observations' &&
                <div>
                  <div
                    className={cx('question-prompt')} >
                    Species
                  </div>
                  <div
                    className={cx('context-help')} >
                    Select the species by typing the desired species name and selecting from the list of suggestions.
                  </div>
                  <div
                    className={cy('search-prompt')} >
                    <Autosuggest
                      theme={autoSuggestTheme}
                      suggestions={this.state.speciesSuggestions}
                      onSuggestionSelected={this.onSuggestionSelected}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps} />
                  </div>
                </div>
              }
              <div>
                <form
                  className={cx('info-form')}
                  onSubmit={this.finalizeRecord} >
                  {recordType === 'observations' &&
                    <div>
                      <label
                        htmlFor={'count'}
                        className={cx('question-prompt')} >
                        Count
                      </label>
                      <input
                        id={'count'}
                        name="count"
                        type="number"
                        onChange={this.changeRecord}
                        value={this.state.activeRecord.count} />
                      <br />
                      {hasAttribute &&
                        <div
                          className={cx('question-prompt')} >
                          Attribute
                        </div>
                      }
                      {hasAttribute && activeTaxonomyLookup[this.state.activeRecord.species_id].attribute.map((thisAttribute, index) => {
                        return (
                          <div
                            key={thisAttribute + '_' + String(index)} >
                            <input
                              id={thisAttribute + '_' + String(index)}
                              name="attribute"
                              type="radio"
                              onChange={this.changeRecord}
                              value={thisAttribute}
                              checked={this.state.activeRecord.attribute === thisAttribute} />
                            <label
                              htmlFor={thisAttribute + '_' + String(index)}
                              className={cx('option-text')} >
                              {thisAttribute}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  }
                  <label
                    htmlFor={'record_date'}
                    className={cx('question-prompt')} >
                    Date Recorded
                  </label>
                  {recordType === 'metadata' &&
                    <div
                      className={cx('context-help')} >
                      This is the day that the Metadata was originally created.
                    </div>
                  }
                  {recordType === 'observations' &&
                    <div
                      className={cx('context-help')} >
                      Input the day of the observation.
                    </div>
                  }
                  <input
                    id={'record_date'}
                    name="record_date"
                    type="date"
                    onChange={this.changeRecord}
                    value={this.state.activeRecord.record_date} />
                  <br />
                  <label
                    htmlFor={'record_time'}
                    className={cx('question-prompt')} >
                    Time Recorded
                  </label>
                  {recordType === 'metadata' &&
                    <div
                      className={cx('context-help')} >
                      This is the time that the Metadata was originally created.
                    </div>
                  }
                  {recordType === 'observations' &&
                    <div
                      className={cx('context-help')} >
                      Input the time of the observation.
                    </div>
                  }
                  <input
                    id={'record_time'}
                    name="record_time"
                    type="time"
                    step="1"
                    onChange={this.changeRecord}
                    value={this.state.activeRecord.record_time} />
                  <br />
                  {recordType === 'metadata' &&
                    <div>
                      <label
                        htmlFor={'start_date'}
                        className={cx('question-prompt')} >
                        Period Start Date
                      </label>
                      <div
                        className={cx('context-help')} >
                        This is the day when the Metadata period begins.
                      </div>
                      <input
                        id={'start_date'}
                        name="start_date"
                        type="date"
                        onChange={this.changeRecord}
                        value={this.state.activeRecord.start_date} />
                      <br />
                      <label
                        htmlFor={'start_time'}
                        className={cx('question-prompt')} >
                        Period Start Time
                      </label>
                      <div
                        className={cx('context-help')} >
                        This is the time when the Metadata period begins.
                      </div>
                      <input
                        id={'start_time'}
                        name="start_time"
                        type="time"
                        step="1"
                        onChange={this.changeRecord}
                        value={this.state.activeRecord.start_time} />
                      <br />
                      <label
                        htmlFor={'end_date'}
                        className={cx('question-prompt')} >
                        Period End Date
                      </label>
                      <div
                        className={cx('context-help')} >
                        This is the day when the Metadata period ends.
                      </div>
                      <input
                        id={'end_date'}
                        name="end_date"
                        type="date"
                        onChange={this.changeRecord}
                        value={this.state.activeRecord.end_date} />
                      <br />
                      <label
                        htmlFor={'end_time'}
                        className={cx('question-prompt')} >
                        Period End Time
                      </label>
                      <div
                        className={cx('context-help')} >
                        This is the time when the Metadata period ends.
                      </div>
                      <input
                        id={'end_time'}
                        name="end_time"
                        type="time"
                        step="1"
                        onChange={this.changeRecord}
                        value={this.state.activeRecord.end_time} />
                      <br />
                    </div>
                  }
                  {questionFields}
                  {(recordType === 'observations' && promptNotable) &&
                    <div>
                      <label
                        htmlFor="prompt_notable"
                        className={cx('question-prompt')} >
                        Notable
                      </label>
                      <input
                        id={'flag_notable'}
                        name="flag_notable"
                        type="checkbox"
                        checked={this.state.activeRecord.flag_notable}
                        onChange={this.changeRecord} />
                      <label
                        htmlFor={'flag_notable'}
                        className={cx('editor-flag')} >
                        Flag: Notable
                      </label>
                      <br />
                    </div>
                  }
                  {(recordType === 'observations' && promptReview) &&
                    <div>
                      <label
                        htmlFor="prompt_review"
                        className={cx('question-prompt')} >
                        Review
                      </label>
                      <input
                        id={'flag_review'}
                        name="flag_review"
                        type="checkbox"
                        checked={this.state.activeRecord.flag_review}
                        onChange={this.changeRecord} />
                      <label
                        htmlFor={'flag_review'}
                        className={cx('editor-flag')} >
                        Flag: Review
                      </label>
                      <br />
                    </div>
                  }
                  {(recordType === 'observations') &&
                    <div>
                      <label
                        htmlFor="prompt_review"
                        className={cx('question-prompt')} >
                        Location
                      </label>
                      <div
                        className={cx('context-help')} >
                        Use this map to select the location of the observation. If a location was found in the field using the device&apos;s GPS, it will be displayed here. If no position is available, click on the map to choose a location.
                      </div>
                      <CoordMap
                        updateCoords={this.updateObservationLocation}
                        name={this.state.activeRecord.uuid}
                        centerLatitude={isEmpty(this.state.activeRecord.latitude) ? this.state.site.latitude : this.state.activeRecord.latitude}
                        centerLongitude={isEmpty(this.state.activeRecord.longitude) ? this.state.site.longitude : this.state.activeRecord.longitude}
                        baseZoom={15}
                        latitude={this.state.activeRecord.latitude}
                        longitude={this.state.activeRecord.longitude} />
                      </div>
                  }
                  <p
                    className={cx('form-errors')} >
                    {this.state.errorMessage}
                  </p>
                  <div
                    className={cx('context-help')} >
                    Save this record. After saving, your updates will be available in the mobile app the next time you log in.
                  </div>
                  <input type="submit" value="Save" />
                </form>
                <div
                  className={cx('context-help')} >
                  Permanently delete this record.
                </div>
                <button
                  className={cx('edit-delete-button')}
                  onClick={this.deleteRecord} >
                  Delete
                </button>
              </div>
            </div>
          }
        </div>
        <Modal
          isOpen={fetching}
          style={loadingModal}
          contentLabel="Loading..."
        >
          <Loading type="spinningBubbles" color="#fff" height={100} width={100} />
        </Modal>
      </div>
    );
  }
}

Editor.propTypes = {
  getTaxonomy: PropTypes.func.isRequired,
  getProjectBrief: PropTypes.func.isRequired,
  getRecord: PropTypes.func.isRequired,
  setRecord: PropTypes.func.isRequired,
  initiateRecord: PropTypes.func.isRequired,
  adminSchemas: PropTypes.object.isRequired,
  activeTaxonomyLookup: PropTypes.object.isRequired,
  activeTaxonomyIndex: PropTypes.array.isRequired,
  fetching: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  promptNotable: PropTypes.bool.isRequired,
  promptReview: PropTypes.bool.isRequired,
  recordType: PropTypes.string.isRequired,
  brief: PropTypes.array.isRequired,
  activeRecord: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  myUserId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    adminSchema: state.admin.adminSchemas[state.admin.workingProject],
    adminSchemas: state.admin.adminSchemas,
    activeTaxonomyLookup: state.admin.activeTaxonomy.lookup,
    activeTaxonomyIndex: state.admin.activeTaxonomy.index,
    fetching: state.isFetching,
    projectId: state.admin.workingProject,
    promptNotable: state.admin.adminSchemas[state.admin.workingProject].flag_notable || false,
    promptReview: state.admin.adminSchemas[state.admin.workingProject].flag_review || false,
    recordType: state.admin.editing === types.ACTIVITY_EDIT_OBSERVATION ? 'observations' : 'metadata',
    brief: state.admin.brief,
    activeRecord: state.admin.activeRecord,
    users: state.admin.users.lookup,
    myUserId: state.admin.user.id,
  };
}

export default connect(mapStateToProps, { updateProjectInfo, updateWorkingProject, getTaxonomy, getProjectBrief, initiateRecord, getRecord, setRecord })(Editor);
