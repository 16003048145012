import { combineReducers } from 'redux';
import * as types from 'types';

const env = (
  state = 'development',
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const GOOGLE_APIKEY = (
  state = '',
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const GOOGLE_ANALYTICS_ID = (
  state = '',
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const environmentReducer = combineReducers({
  env,
  GOOGLE_APIKEY,
  GOOGLE_ANALYTICS_ID
});

export default environmentReducer;
