import { combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import reverse from 'lodash/reverse';
import ReactGA from 'react-ga';
import * as types from 'types';

const projectActivity = (
  state = {
    list: [],
    lookup: {}
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_ADMIN_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data;
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data.projectActivity;
      }
      return state;
    default:
      return state;
  }
};

const taxonomies = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.REQUEST_TAXONOMIES_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return keyBy(action.data, 'id');
      }
      return state;
    default:
      return state;
  }
};

const activeTaxonomy = (
  state = {
    lookup: {},
    index: [],
    meta: {},
    empty: false
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_TAXONOMY_SUCCESS:
    case types.REQUEST_TAXONOMY_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          lookup: keyBy(action.data.Species, 'id'),
          index: action.data.Species,
          meta: omit(action.data, 'Species'),
          empty: action.data.Species.length === 0,
        };
      }
      return state;
    case types.UPDATE_TAXONOMY_REQUEST:
    case types.CHANGE_WORKING_ORG:
    case types.CHANGE_WORKING_PROJECT:
      return {
        lookup: {},
        index: [],
        meta: {},
        empty: false,
      };
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
    case types.INTEGRATION_HAWKCOUNT_GATHER_SUCCESS:
      return {
        lookup: keyBy(action.data.taxonomy.Species, 'id'),
        index: action.data.taxonomy.Species,
        meta: omit(action.data.taxonomy, 'Species'),
        empty: action.data.taxonomy.Species.length === 0,
      };
    default:
      return state;
  }
};

const speciesButtons = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.INITIATE_SPECIES_BUTTONS:
      return action.speciesList;
    case types.PUSH_SPECIES_BUTTONS:
      return [...state, action.speciesId];
    case types.POP_SPECIES_BUTTONS:
      return state.filter(t => t !== action.speciesId);
    default:
      return state;
  }
};

const focusButtons = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.INITIATE_FOCUS_BUTTONS:
      return action.focusList;
    case types.PUSH_FOCUS_BUTTONS:
      return [...state, action.speciesId];
    case types.POP_FOCUS_BUTTONS:
      return state.filter(t => t !== action.speciesId);
    default:
      return state;
  }
};

const users = (
  state = {
    index: [],
    lookup: {},
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_USERS_SUCCESS:
    case types.UPDATE_USER_PERMISSIONS_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          index: action.data.userIndex,
          lookup: action.data.userLookup
        };
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          index: action.data.allUsers.userIndex,
          lookup: action.data.allUsers.userLookup
        };
      }
      return state;
    default:
      return state;
  }
};

const organizations_users = (
  state = {
    users: {},
    admins: {},
    techs: {},
    invitations: {},
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_USERS_SUCCESS:
    case types.UPDATE_USER_PERMISSIONS_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          users: action.data.organizations,
          admins: action.data.organizations_admin,
          techs: action.data.organizations_tech,
          invitations: action.data.organizations_invitations,
        };
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          users: action.data.allUsers.organizations,
          admins: action.data.allUsers.organizations_admin,
          techs: action.data.allUsers.organizations_tech,
          invitations: action.data.allUsers.organizations_invitations,
        };
      }
      return state;
    default:
      return state;
  }
};

const projects_users = (
  state = {
    users: {},
    admins: {},
    techs: {},
    invitations: {},
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_USERS_SUCCESS:
    case types.UPDATE_USER_PERMISSIONS_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          users: action.data.projects,
          admins: action.data.projects_admin,
          techs: action.data.projects_tech,
          invitations: action.data.projects_invitations,
        };
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return {
          users: action.data.allUsers.projects,
          admins: action.data.allUsers.projects_admin,
          techs: action.data.allUsers.projects_tech,
          invitations: action.data.allUsers.projects_invitations,
        };
      }
      return state;
    default:
      return state;
  }
};

const organizations = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_ORG_SUCCESS:
    case types.UPDATE_ORG_IMAGE_SUCCESS:
    case types.DELETE_ORG_IMAGE_SUCCESS:
    case types.REQUEST_ORGANIZATIONS_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return keyBy(action.data, 'id');
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      return action.data.orgProjects;
    default:
      return state;
  }
};

const orgProjects = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_ORG_SUCCESS:
    case types.UPDATE_ORG_IMAGE_SUCCESS:
    case types.DELETE_ORG_IMAGE_SUCCESS:
    case types.REQUEST_ORGANIZATIONS_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data;
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      return action.data.orgProjects;
    default:
      return state;
  }
};

const adminSchemas = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_PROJECT_SUCCESS:
    case types.REQUEST_EDITOR_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return keyBy(action.data, 'id');
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return keyBy(action.data.adminSchemas, 'id');
      }
      return state;
    default:
      return state;
  }
};

const workingOrg = (
  state = null,
  action
) => {
  switch (action.type) {
    case types.CHANGE_WORKING_ORG: {
      if (!(typeof window === 'undefined')) {
        window.localStorage.setItem('workingOrg', action.orgId);
      }
      return action.orgId;
    }
    default: {
      if (!(typeof window === 'undefined') && window.localStorage.getItem('workingOrg')) {
        return parseInt(window.localStorage.getItem('workingOrg'), 10);
      }
      return state;
    }
  }
};

const workingProject = (
  state = null,
  action
) => {
  switch (action.type) {
    case types.REQUEST_EDITOR_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        const orderedSchemas = sortBy(action.data, ['id']);
        if (!(typeof window === 'undefined')) {
          window.localStorage.setItem('workingProject', orderedSchemas[0].id);
        }
        return orderedSchemas[0].id;
      }
      return state;
    case types.CHANGE_WORKING_PROJECT: {
      if (!(typeof window === 'undefined')) {
        window.localStorage.setItem('workingProject', action.projectId);
      }
      return action.projectId;
    }
    default: {
      if (!(typeof window === 'undefined') && window.localStorage.getItem('workingProject')) {
        return parseInt(window.localStorage.getItem('workingProject'), 10);
      }
      return state;
    }
  }
};

const workingTaxonomy = (
  state = null,
  action
) => {
  switch (action.type) {
    case types.CHANGE_WORKING_TAXONOMY: {
      if (!(typeof window === 'undefined')) {
        window.localStorage.setItem('workingTaxonomy', action.taxonomyId);
      }
      return action.taxonomyId;
    }
    default: {
      if (!(typeof window === 'undefined') && window.localStorage.getItem('workingTaxonomy')) {
        return parseInt(window.localStorage.getItem('workingTaxonomy'), 10);
      }
      return state;
    }
  }
};

const tier = (
  state = '',
  action
) => {
  switch (action.type) {
    case types.CHANGE_ADMIN_TIER: {
      if (!(typeof window === 'undefined')) {
        window.localStorage.setItem('tier', action.tier);
      }
      return action.tier;
    }
    default: {
      if (!(typeof window === 'undefined') && window.localStorage.getItem('tier')) {
        return window.localStorage.getItem('tier');
      }
      return state;
    }
  }
};

const editing = (
  state = '',
  action
) => {
  switch (action.type) {
    case types.CHANGE_ADMIN_EDITING: {
      if (!(typeof window === 'undefined')) {
        window.localStorage.setItem('editing', action.edit);
      }
      return action.edit;
    }
    default: {
      if (!(typeof window === 'undefined') && window.localStorage.getItem('editing')) {
        return window.localStorage.getItem('editing');
      }
      return state;
    }
  }
};

const projectTemplates = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_PROJECT_TEMPLATES_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const taxonomyTemplates = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.GET_TAXONOMY_TEMPLATES_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const editNewAsset = (
  state = false,
  action
) => {
  switch (action.type) {
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      return true;
    case types.ADD_ASSET_FOUND:
      return false;
    default:
      return state;
  }
};

const user = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.REQUEST_ADMIN_USER_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data;
      }
      return state;
    case types.ADD_ORGANIZATION_SUCCESS:
    case types.ADD_PROJECT_SUCCESS:
    case types.ADD_TAXONOMY_SUCCESS:
      return action.data.myUser;
    default:
      return state;
  }
};

const projectOverview = (
  state = {
    user_count: {},
    observation_days: {},
    metadata_days: {},
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_OVERVIEW_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data;
      }
      return state;
    case types.CHANGE_WORKING_PROJECT:
    case types.CHANGE_ADMIN_EDITING:
      return {
        user_count: {},
        observation_days: {},
        metadata_days: {},
      };
    default:
      return state;
  }
};

const brief = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.UPDATE_BRIEF_SUCCESS:
    case types.SET_RECORD_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return reverse(sortBy(action.data, 'recorded_at'));
      }
      return state;
    case types.CHANGE_WORKING_PROJECT:
    case types.CHANGE_ADMIN_EDITING:
      return [];
    default:
      return state;
  }
};

const activeRecord = (
  state = {},
  action
) => {
  switch (action.type) {
    case types.GET_RECORD_SUCCESS:
      if ((typeof action.data !== 'undefined') && (('data' in action))) {
        return action.data;
      }
      return state;
    case types.INITIATE_RECORD:
      return action.record;
    case types.CHANGE_WORKING_ORG:
    case types.CHANGE_WORKING_PROJECT:
    case types.CHANGE_ADMIN_EDITING:
    case types.SET_RECORD_SUCCESS:
      return {};
    default:
      return state;
  }
};

const contextHelp = (
  state = true,
  action
) => {
  switch (action.type) {
    case types.TOGGLE_CONTEXT_HELP:
      if (!(typeof window === 'undefined')) {
        window.localStorage.setItem('contextHelp', !state);

        ReactGA.event({
          category: 'Dashboard',
          action: state ? 'Turn Context Help Off' : 'Turn Context Help On',
        });
      }
      return !state;
    default:
      if (!(typeof window === 'undefined') && window.localStorage.getItem('contextHelp')) {
        return window.localStorage.getItem('contextHelp') === 'true';
      }
      return state;
  }
};

const projectReducer = combineReducers({
  projectActivity,
  taxonomies,
  activeTaxonomy,
  speciesButtons,
  focusButtons,
  users,
  organizations_users,
  projects_users,
  organizations,
  orgProjects,
  adminSchemas,
  workingOrg,
  workingProject,
  workingTaxonomy,
  tier,
  editing,
  projectTemplates,
  taxonomyTemplates,
  editNewAsset,
  user,
  projectOverview,
  brief,
  activeRecord,
  contextHelp,
});

export default projectReducer;
