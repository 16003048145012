import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import isNumber from 'lodash/isNumber';
import UserAdmin from './UserAdmin';
import ProjectOverview from './ProjectOverview';
import OrgManager from './OrgManager';
import OrgImages from './OrgImages';
import ProjectManager from './ProjectManager';
import EmbedManager from './EmbedManager';
import ProjectSchemaEditor from './ProjectSchemaEditor';
import TaxonomyManager from './TaxonomyManager';
import TaxonomyAddSpecies from './TaxonomyAddSpecies';
import IntegrationHawkCount from './IntegrationHawkCount';
import Adder from './Adder';
import Editor from './Editor';
import Download from './Download';
import { updateWorkingOrg, updateWorkingProject, updateWorkingTaxonomy, updateAdminEditing, updateAdminTier } from '../actions/admin';
import classNames from '../utils/classNames.js';
import styles from 'css/components/schema';
import * as types from '../types';

const cx = classNames.bind(styles);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const { projectActivity, workingOrg, orgProjects, workingProject, adminSchemas, workingTaxonomy, updateWorkingOrg, updateWorkingProject, updateWorkingTaxonomy, updateAdminEditing, updateAdminTier, user } = this.props;

    // Detect if a project, organization, or taxonomy has been removed from this user since last login and repair
    let missingTaxonomy = true;
    let validTaxonomy = null;
    let parentOrg = null;
    if (isNumber(workingTaxonomy)) {
      Object.keys(orgProjects).forEach((thisOrgId) => {
        if (('TaxonomyList' in orgProjects[thisOrgId]) && (orgProjects[thisOrgId].TaxonomyList.length > 0)) {
          if (orgProjects[thisOrgId].TaxonomyList.indexOf(workingTaxonomy) > -1) {
            missingTaxonomy = false;
          }
          validTaxonomy = orgProjects[thisOrgId].TaxonomyList[0];
          parentOrg = thisOrgId;
        }
      });
    } else {
      missingTaxonomy = false;
    }

    if (missingTaxonomy || ((Object.keys(adminSchemas).indexOf(String(workingProject)) === -1) || (Object.keys(orgProjects).indexOf(String(workingOrg)) === -1))) {
      // Similar Code in PanelAdmin

      const orderedProjects = Object.keys(projectActivity)
      .map((m) => {
        return parseInt(m, 10);
      })
      .sort((a, b) => {
        return a - b;
      });

      const activeProjects = orderedProjects.filter((thisProjectId) => {
        return projectActivity[thisProjectId].observation_count + projectActivity[thisProjectId].metadata_count > 0;
      });

      if (missingTaxonomy) {
        updateWorkingOrg(parentOrg);
        updateWorkingProject(orgProjects[parentOrg].ProjectList[0]);
        updateWorkingTaxonomy(validTaxonomy);
        updateAdminEditing(types.ACTIVITY_DETAILS_ORG);
        updateAdminTier(types.FOCUS_ORGANIZATION);
      } else if (activeProjects.length > 0) {
        updateWorkingOrg(adminSchemas[activeProjects[0]].organization_id);
        updateWorkingProject(activeProjects[0]);
        updateAdminEditing(types.ACTIVITY_OVERVIEW);
        updateAdminTier(types.FOCUS_PROJECT);
      } else if (orderedProjects.length > 0) {
        updateWorkingOrg(adminSchemas[orderedProjects[0]].organization_id);
        updateWorkingProject(orderedProjects[0]);
        updateAdminEditing(types.ACTIVITY_DETAILS_PROJECT);
        updateAdminTier(types.FOCUS_PROJECT);
      }
    }
  }

  render() {
    const { editing } = this.props;

    return (
      <div
        style={{
          height: '100%',
        }} >
        {!editing &&
          <div>
              Select a Project on the Left
          </div>
        }
        {(editing === types.ACTIVITY_DETAILS_ORG) &&
          <div>
            <OrgManager />
          </div>
        }
        {(editing === types.ACTIVITY_IMAGES_ORG) &&
          <div>
            <OrgImages />
          </div>
        }
        {(editing === types.ACTIVITY_OVERVIEW) &&
          <div>
            <ProjectOverview />
          </div>
        }
        {([types.ACTIVITY_USERS_ORG, types.ACTIVITY_USERS_PROJECT].indexOf(editing) > -1) &&
          <div>
            <UserAdmin />
          </div>
        }
        {([types.ACTIVITY_DETAILS_PROJECT, types.ACTIVITY_DETAILS_PROJECT_PUBLIC, types.ACTIVITY_SPECIES_BUTTON, types.ACTIVITY_SPECIES_FOCUS, types.ACTIVITY_SUPERADMIN_PROJECT].indexOf(editing) > -1) &&
          <div>
            <ProjectManager />
          </div>
        }
        {(editing === types.ACTIVITY_DETAILS_PROJECT_EMBED) &&
          <div>
            <EmbedManager />
          </div>
        }
        {([types.ACTIVITY_QUESTIONS_PROJECT, types.ACTIVITY_METADATA_QUESTIONS_PROJECT].indexOf(editing) > -1) &&
          <div>
            <ProjectSchemaEditor />
          </div>
        }
        {([types.ACTIVITY_EDIT_OBSERVATION, types.ACTIVITY_EDIT_METADATA].indexOf(editing) > -1) &&
          <div
            style={{
              height: '100%',
            }} >
            <Editor />
          </div>
        }
        {(editing === types.ACTIVITY_DOWNLOAD) &&
          <div>
            <Download />
          </div>
        }
        {(editing === types.ACTIVITY_DETAILS_TAXONOMY) &&
          <div>
            <TaxonomyManager />
          </div>
        }
        {(editing === types.ACTIVITY_TAXONOMY_ADD_SPECIES) &&
          <div>
            <TaxonomyAddSpecies />
          </div>
        }
        {(editing === types.ACTIVITY_INTEGRATION_HAWKCOUNT) &&
          <div>
            <IntegrationHawkCount />
          </div>
        }
        {([types.ACTIVITY_ADD_ORGANIZATION, types.ACTIVITY_ADD_PROJECT, types.ACTIVITY_ADD_TAXONOMY, types.ACTIVITY_ADD_SUPERADMIN_TAXONOMY, types.ACTIVITY_ADD_INTEGRATION_HAWKCOUNT].indexOf(editing) > -1) &&
          <div>
            <Adder />
          </div>
        }
      </div>
        );
  }
}

Dashboard.propTypes = {
  updateWorkingOrg: PropTypes.func.isRequired,
  updateWorkingProject: PropTypes.func.isRequired,
  updateWorkingTaxonomy: PropTypes.func.isRequired,
  updateAdminEditing: PropTypes.func.isRequired,
  updateAdminTier: PropTypes.func.isRequired,
  orgProjects: PropTypes.object.isRequired,
  adminSchemas: PropTypes.object.isRequired,
  workingOrg: PropTypes.number,
  workingProject: PropTypes.number,
  workingTaxonomy: PropTypes.number,
  tier: PropTypes.string.isRequired,
  editing: PropTypes.string,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    projectActivity: state.admin.projectActivity,
    orgProjects: state.admin.orgProjects,
    orgAdminAccess: state.admin.user.adminOrgs,
    adminSchemas: state.admin.adminSchemas,
    workingOrg: state.admin.workingOrg,
    workingProject: state.admin.workingProject,
    workingTaxonomy: state.admin.workingTaxonomy,
    tier: state.admin.tier,
    editing: state.admin.editing,
    user: state.admin.user,
  };
}

export default connect(mapStateToProps, { updateWorkingOrg, updateWorkingProject, updateWorkingTaxonomy, updateAdminEditing, updateAdminTier })(Dashboard);