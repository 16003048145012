import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import range from 'lodash/range';
import padStart from 'lodash/padStart';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import hat from 'hat';
import Textarea from 'react-textarea-autosize';
import SchemaTextArray from '../components/SchemaTextArray';
import classNames from '../utils/classNames.js';
import * as types from '../types';
import styles from '../css/components/schemaeditors';

const cx = classNames.bind(styles);

const unacceptableVariableNames = new RegExp(/[^a-zA-Z0-9_]/i);

const questionNameLookup = {
  startTime: 'Metadata Start Time',
  endTime: 'Metadata End Time',
  info: 'Information',
  options: 'Options',
  radio: 'Options - Single Choice',
  checkbox: 'Options - Multiple Choice',
  list: 'Open Ended List',
  number: 'Number',
  text: 'Text',
};

class SchemaEditor extends Component {
  constructor(props) {
    super(props);

    this.ingressQuestion = this.ingressQuestion.bind(this);
    this.ingressSchema = this.ingressSchema.bind(this);
    this.editVariable = this.editVariable.bind(this);
    this.editMetadataTimeChoices = this.editMetadataTimeChoices.bind(this);
    this.editDefaults = this.editDefaults.bind(this);
    this.editType = this.editType.bind(this);
    this.editOptions = this.editOptions.bind(this);
    this.editPrompt = this.editPrompt.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.moveQuestion = this.moveQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.saveSchema = this.saveSchema.bind(this);

    this.state = {
      schemaArray: this.ingressSchema(props.schema),
      newQuestionNumber: Object.keys(props.schema).length + 1,
      questionIssues: {},
      errorMessage: false,
      informFieldValidation: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.reportType !== nextProps.reportType) || (this.props.assetId !== nextProps.assetId)) {
      this.setState({
        schemaArray: this.ingressSchema(nextProps.schema),
        newQuestionNumber: Object.keys(nextProps.schema).length,
        questionIssues: {},
        informFieldValidation: false,
      });
    }
  }

  ingressQuestion(rawQuestion) {
    const question = cloneDeep(rawQuestion);

    question.uuid = hat();

    question.optionsArray = [];
    question.singleDefault = '';
    question.multipleDefaults = [];

    if (['radio', 'checkbox', 'list'].indexOf(question.type) > -1) {
      question.optionsArray = question.options.map((thisOption) => { return thisOption.text; });
      question.multipleDefaults = question.options.filter((thisOption) => { return ('defaultSelected' in thisOption) ? thisOption.defaultSelected : false; });
      question.multipleDefaults = question.multipleDefaults.map((thisOption) => { return thisOption.text; });
      question.singleDefault = (question.multipleDefaults.length > 0) ? question.multipleDefaults[0] : '';
    }

    if (['startTime', 'endTime'].indexOf(question.type) > -1) {
      if (!('choices' in question)) {
        question.choices = 'precise';
      }
    } else {
      question.choices = null;
    }

    return question;
  }

  ingressSchema(schema) {
    const inputSchema = cloneDeep(schema);

    const variables = Object.keys(inputSchema);

    variables.forEach((thisVariable) => {
      inputSchema[thisVariable].variable = thisVariable;
      inputSchema[thisVariable].newQuestion = false;
      inputSchema[thisVariable] = this.ingressQuestion(inputSchema[thisVariable]);
    });

    let inputSchemaArray = variables.map((thisVariable) => {
      return inputSchema[thisVariable];
    });

    inputSchemaArray = sortBy(inputSchemaArray, 'order');

    return inputSchemaArray;
  }

  editVariable(value, index) {
    const schemaArray = this.state.schemaArray;
    let invalidEntry = false;

    if (!unacceptableVariableNames.test(value)) {
      schemaArray[index].variable = value.toLowerCase();
    } else {
      invalidEntry = true;
    }

    this.setState({
      schemaArray,
      informFieldValidation: invalidEntry,
    });
  }

  editMetadataTimeChoices(choice, question) {
    let schemaArray = this.state.schemaArray;

    schemaArray = schemaArray.map((thisQuestion) => {
      const updateQuestion = thisQuestion;

      if (['startTime', 'endTime'].indexOf(thisQuestion.type) > -1) {
        // If the user selects oneHour, it must be applied to startTime and endTime
        // If the user changes from oneHour, it must be reflected in the other value as well
        if ((thisQuestion.choices === 'oneHour') && (choice !== 'oneHour')) {
          updateQuestion.choices = choice;
        } else if (choice === 'oneHour') {
          updateQuestion.choices = choice;
        } else if (thisQuestion.type === question) {
          updateQuestion.choices = choice;
        }
      }

      return updateQuestion;
    });

    this.setState({
      schemaArray,
    });
  }

  editDefaults(selection, type, index) {
    const schemaArray = this.state.schemaArray;

    if (['radio', 'list'].indexOf(type) > -1) {
      schemaArray[index].singleDefault = selection;
    } else if (type === 'checkbox') {
      if (schemaArray[index].multipleDefaults.indexOf(selection) > -1) {
        schemaArray[index].multipleDefaults = without(schemaArray[index].multipleDefaults, selection);
      } else {
        schemaArray[index].multipleDefaults.push(selection);
      }
    }

    schemaArray[index].options = schemaArray[index].options.map((thisOption) => {
      const option = thisOption;

      if (['radio', 'list'].indexOf(schemaArray[index].type) > -1) {
        option.defaultSelected = (thisOption.text === schemaArray[index].singleDefault);
      } else if (schemaArray[index].type === 'checkbox') {
        option.defaultSelected = (schemaArray[index].multipleDefaults.indexOf(thisOption.text) > -1);
      }

      return option;
    });

    this.setState({
      schemaArray,
    });
  }

  editType(type, questionIndex) {
    const schemaArray = this.state.schemaArray;

    if (type === 'options') {
      schemaArray[questionIndex].type = 'radio';
    } else {
      schemaArray[questionIndex].type = type;
    }

    this.setState({
      schemaArray,
    });
  }

  editOptions(updatedArray, index) {
    const schemaArray = this.state.schemaArray;

    schemaArray[index].optionsArray = updatedArray;

    schemaArray[index].options = updatedArray.map((thisElement) => {
      const option = {};
      option.text = thisElement;

      if (['radio', 'list'].indexOf(schemaArray[index].type) > -1) {
        option.defaultSelected = (thisElement === schemaArray[index].singleDefault);
      } else if (schemaArray[index].type === 'checkbox') {
        option.defaultSelected = (schemaArray[index].multipleDefaults.indexOf(thisElement) > -1);
      }

      return option;
    });

    this.setState({
      schemaArray,
    });
  }

  editPrompt(prompt, index) {
    const schemaArray = this.state.schemaArray;

    schemaArray[index].prompt = prompt;

    this.setState({
      schemaArray,
    });
  }

  addQuestion(index) {
    const newQuestion = this.ingressQuestion({
      type: 'text',
      newQuestion: true,
      variable: 'question_' + this.state.newQuestionNumber,
      prompt: 'Question ' + this.state.newQuestionNumber,
    });

    const newSchemaArray = cloneDeep(this.state.schemaArray);
    newSchemaArray.splice(index, 0, newQuestion);

    this.setState({
      schemaArray: newSchemaArray,
      newQuestionNumber: this.state.newQuestionNumber + 1,
    });
  }

  moveQuestion(index, newIndex, uuid) {
    const newSchemaArray = cloneDeep(this.state.schemaArray);
    newSchemaArray.splice(newIndex, 0, newSchemaArray.splice(index, 1)[0]);

    this.setState({
      schemaArray: newSchemaArray,
    }, () => {
      document.getElementById(uuid).scrollIntoView();
    });
  }

  removeQuestion(index, uuid) {
    const newSchemaArray = cloneDeep(this.state.schemaArray);
    newSchemaArray.splice(index, 1);

    this.setState({
      schemaArray: newSchemaArray,
    }, () => {
      if (uuid) {
        document.getElementById(uuid).scrollIntoView();
      }
    });
  }

  saveSchema() {
    const { updateSchema, usedVariables, reportType } = this.props;

    let schemaArray = this.state.schemaArray;
    const updatedUsedVariables = cloneDeep(usedVariables);

    let readyToSubmit = true;
    const buildErrorMessage = [];
    const questionIssues = {};
    schemaArray.forEach((thisQuestion) => {
      questionIssues[thisQuestion.uuid] = {};
    });

    const rawFields = schemaArray.map((thisQuestion) => { return thisQuestion.variable; });
    const fields = uniq(rawFields);

    // Check for duplicate variable names
    if (fields.length !== rawFields.length) {
      // https://stackoverflow.com/questions/31681732/lodash-get-duplicate-values-from-an-array
      const dups = uniq(flatten(filter(groupBy(rawFields, (n) => { return n; }), (n) => { return n.length > 1; })));

      schemaArray.forEach((thisQuestion) => {
        if (dups.indexOf(thisQuestion.variable) > -1) {
          if (!('variable' in questionIssues[thisQuestion.uuid])) {
            questionIssues[thisQuestion.uuid].variable = true;

            readyToSubmit = false;
            buildErrorMessage.push(<li>Duplicate Field Name: <span className={cx('issue-highlight')}>{thisQuestion.variable}</span></li>);
          }
        }
      });
    }

    // Validate Schema Design
    schemaArray.forEach((thisQuestion) => {
      // Add to list of used_variables
      if (thisQuestion.newQuestion) {
        updatedUsedVariables.push(thisQuestion.variable);
      }

      // Prevent variable reuse
      if (thisQuestion.newQuestion && (usedVariables.indexOf(thisQuestion.variable) > -1)) {
        // Don't have conflicting warnings about the same field for the same question
        // Implemented for future case where there is additional validation
        if (!('variable' in questionIssues[thisQuestion.uuid])) {
          questionIssues[thisQuestion.uuid].variable = true;

          readyToSubmit = false;
          buildErrorMessage.push(<li>Reused Variable Name: <span className={cx('issue-highlight')}>{thisQuestion.variable}</span></li>);
        }
      }

      // Prevent Empty Variable
      if (isEmpty(thisQuestion.variable)) {
        if (!('variable' in questionIssues[thisQuestion.uuid])) {
          questionIssues[thisQuestion.uuid].variable = true;

          readyToSubmit = false;
          buildErrorMessage.push(<li>Empty Field Name: <span className={cx('issue-highlight')}>{thisQuestion.prompt}</span></li>);
        }
      }

      // Prevent Empty Prompt
      if (isEmpty(thisQuestion.prompt)) {
        if (!('prompt' in questionIssues[thisQuestion.uuid])) {
          questionIssues[thisQuestion.uuid].prompt = true;

          readyToSubmit = false;
          buildErrorMessage.push(<li>Empty Prompt: <span className={cx('issue-highlight')}>{thisQuestion.variable}</span></li>);
        }
      }

      // Prevent empty options for questions that require options
      if ((['radio', 'checkbox', 'list'].indexOf(thisQuestion.type) > -1) && isEmpty(thisQuestion.options)) {
        if (!('options' in questionIssues[thisQuestion.uuid])) {
          questionIssues[thisQuestion.uuid].options = true;

          readyToSubmit = false;
          buildErrorMessage.push(<li>Empty Options: <span className={cx('issue-highlight')}>{thisQuestion.variable}</span></li>);
        }
      }
    });

    const outputSchema = {};

    schemaArray.forEach((thisQuestion, thisQuestionIndex) => {
      outputSchema[thisQuestion.variable] = {};
      outputSchema[thisQuestion.variable].order = thisQuestionIndex;
      outputSchema[thisQuestion.variable].type = thisQuestion.type;
      outputSchema[thisQuestion.variable].prompt = thisQuestion.prompt;

      if (['radio', 'checkbox', 'list'].indexOf(thisQuestion.type) > -1) {
        outputSchema[thisQuestion.variable].options = thisQuestion.options;
      }

      if (['startTime', 'endTime'].indexOf(thisQuestion.type) > -1) {
        outputSchema[thisQuestion.variable].choices = thisQuestion.choices;
      }
    });

    if (reportType === types.REPORT_TYPE_METADATA) {
      if (!('startTime' in outputSchema) || !('endTime' in outputSchema)) {
        readyToSubmit = false;
        buildErrorMessage.push(<li>Missing Required Metadata Questions - Please Contact Support</li>);
      }
    }

    if (readyToSubmit) {
      // Prevent new questions from being edited like new questions after saving
      schemaArray = schemaArray.map((thisQuestion) => {
        const question = thisQuestion;
        question.newQuestion = false;
        return question;
      });

      this.setState({
        schemaArray,
        errorMessage: false,
        questionIssues: {},
      });

      updateSchema(outputSchema, updatedUsedVariables);
    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: buildErrorMessage,
        questionIssues,
      }, () => {
        document.getElementById('question-block-container').scrollIntoView();
      });
    }
  }

  render() {
    const { schemaArray } = this.state;

    return (
      <div
        id="question-block-container"
        className={cx('question-block-container')} >
        {(this.state.errorMessage.length > 0) &&
          <div
            className={cx('issues-container')} >
            <h2><span className={cx('issue-highlight')}>Issues</span></h2>
            <ul>
              {this.state.errorMessage}
            </ul>
          </div>
        }
        {(schemaArray.length > 0) &&
          <div
            className={cx('context-help')} >
            <span className={cx('emphasis')}>Create questions using this tool to setup data collection.</span>
            <ul>
              <li><span className={cx('emphasis')}>Add a New Question:</span> Click the green plus button between the questions where you would like to insert the new question.</li>
              <li><span className={cx('emphasis')}>Delete a Question:</span> Click the red X button on the question you would like to delete.</li>
              <li><span className={cx('emphasis')}>Move a Question Up or Down:</span> Press the up and down arrows on the question.</li>
            </ul>
          </div>
        }
        {(schemaArray.length === 0) &&
          <div
            className={cx('schema-manage-button-container')} >
            <div
              className={cx('context-help')} >
              It looks like you haven&apos;t added any questions yet!
              <br />
              Click &quot;Add a Question&quot; to get started.
            </div>
            <button
              onClick={() => {
                this.addQuestion(0);
              }}>
              Add a Question
            </button>
          </div>
        }
        {(schemaArray.length > 0) && schemaArray.map((thisQuestion, thisQuestionIndex) => {
          const typeCategory = (['radio', 'checkbox'].indexOf(thisQuestion.type) > -1) ? 'options' : thisQuestion.type;
          const metadataTimeQuestion = (['startTime', 'endTime'].indexOf(thisQuestion.type) > -1);

          let questionTypePrompt;
          if (thisQuestion.type === 'startTime') {
            questionTypePrompt = 'Start Time';
          } else if (thisQuestion.type === 'endTime') {
            questionTypePrompt = 'End Time';
          } else if (thisQuestion.newQuestion) {
            questionTypePrompt = 'Question Type';
          } else {
            questionTypePrompt = false;
          }
          return (
            <div
              key={thisQuestion.uuid}
              id={thisQuestion.uuid}
              className={cx('question-block', {'question-block-issue': (thisQuestion.uuid in this.state.questionIssues) && (Object.keys(this.state.questionIssues[thisQuestion.uuid]).length > 0)})} >
              <div
                className={cx('question-block-configuration')} >
                <div
                  className={cx('question-block-options')} >
                  <div
                    className={cx('question-block-action-label-container')} >
                    <div
                      className={cx('question-block-action-label')} >
                      Options
                    </div>
                    <div
                      className={cx('context-help')} >
                      Setup the Question
                    </div>
                  </div>
                  {!metadataTimeQuestion &&
                    <div>
                      <div
                        className={cx('question-settings-label')} >
                        Field Name
                      </div>
                      <div
                        className={cx('context-help')} >
                        This variable name will be used to keep track of answers to this question in the data export. This name can&apos;t be changed after saving.
                      </div>
                      {(thisQuestion.newQuestion && this.state.informFieldValidation) &&
                        <div
                          className={cx('inform-field-validation')} >
                          Only lower-cased letters, numbers, and underscores may be used in Field Names.
                        </div>
                      }
                      {thisQuestion.newQuestion ? (
                        <input
                          className={cx({'field-issue': (thisQuestion.uuid in this.state.questionIssues) && ('variable' in this.state.questionIssues[thisQuestion.uuid])})}
                          type="text"
                          value={thisQuestion.variable}
                          onChange={(event) => {
                            this.editVariable(event.target.value, thisQuestionIndex);
                          }} />
                      ) : (
                        <div
                          className={cx('question-settings-fixed')} >
                          {thisQuestion.variable}
                        </div>
                      )}
                    </div>
                  }
                  {!questionTypePrompt &&
                    <div>
                      <div
                        className={cx('question-settings-label')} >
                        Question Type
                      </div>
                      <div
                        className={cx('question-settings-fixed')} >
                        {questionNameLookup[thisQuestion.type]}
                      </div>
                    </div>
                  }
                  {questionTypePrompt &&
                    <div
                      className={cx('question-block-options-settings')} >
                      <div
                        className={cx('question-type-selection-container')} >
                        <div
                          className={cx('question-settings-label')} >
                          {questionTypePrompt}
                        </div>
                        {metadataTimeQuestion &&
                          <div
                            className={cx('context-help')} >
                            Every Metadata has a start and end time.
                            <ul>
                              <li><span className={cx('emphasis')}>Precise:</span> Prompt the user for hours and minutes.</li>
                              <li><span className={cx('emphasis')}>Hour:</span> Metadata may only start and end at the beginning of an hour. Users may quickly select times from buttons.</li>
                              <li><span className={cx('emphasis')}>One Hour Period:</span> When the user selects a start hour, the end hour is automatically selected one hour later.</li>
                            </ul>
                          </div>
                        }
                        {metadataTimeQuestion &&
                          <select
                            name={'type_selector_' + thisQuestion.uuid}
                            id={'type_selector_' + thisQuestion.uuid}
                            defaultValue={thisQuestion.choices}
                            value={thisQuestion.choices}
                            onChange={(event) => {
                              this.editMetadataTimeChoices(event.target.value, thisQuestion.type);
                            }} >
                            <option value="precise">Precise</option>
                            <option value="hour">Hour</option>
                            <option value="oneHour">One Hour Period</option>
                          </select>
                        }
                        {thisQuestion.newQuestion &&
                          <div
                            className={cx('context-help')} >
                            Choose what type of data should be collected. This can&apos;t be changed after saving.
                            <ul>
                              <li><span className={cx('emphasis')}>Information:</span> Display your prompt to the data collector. No response is allowed.</li>
                              <li><span className={cx('emphasis')}>Options:</span> Present the user with a preselected list of choices.</li>
                              <li><span className={cx('emphasis')}>Open Ended List:</span> The user can search through the options you provide or enter a custom response.</li>
                              <li><span className={cx('emphasis')}>Number:</span> Collect numeric values.</li>
                              <li><span className={cx('emphasis')}>Text:</span> Collect text answers.</li>
                            </ul>
                          </div>
                        }
                        {thisQuestion.newQuestion &&
                          <select
                            name={'type_selector_' + thisQuestion.uuid}
                            id={'type_selector_' + thisQuestion.uuid}
                            defaultValue={typeCategory}
                            onChange={(event) => {
                              this.editType(event.target.value, thisQuestionIndex);
                            }} >
                            <option value="info">Information</option>
                            <option value="options">Options</option>
                            <option value="list">Open Ended List</option>
                            <option value="number">Number</option>
                            <option value="text">Text</option>
                          </select>
                        }
                      </div>
                      <div>
                        {(typeCategory === 'options') &&
                          <select
                            name={'subtype_selector_' + thisQuestion.uuid}
                            id={'subtype_selector_' + thisQuestion.uuid}
                            defaultValue={thisQuestion.type}
                            onChange={(event) => {
                              this.editType(event.target.value, thisQuestionIndex);
                            }} >
                            <option value="radio">Single Choice</option>
                            <option value="checkbox">Multiple Choice</option>
                          </select>
                        }
                      </div>
                    </div>
                  }
                  {(['radio', 'list'].indexOf(thisQuestion.type) > -1) &&
                    <div>
                      <div
                        className={cx('question-settings-label')} >
                        Default Selection
                      </div>
                      <div
                        className={cx('context-help')} >
                        This selection is automatically made in the app.
                      </div>
                      <select
                        name={'default_selector_' + thisQuestion.uuid}
                        id={'default_selector_' + thisQuestion.uuid}
                        defaultValue={thisQuestion.singleDefault}
                        onChange={(event) => {
                          this.editDefaults(event.target.value, thisQuestion.type, thisQuestionIndex);
                        }} >
                        <option value="">None</option>
                        {thisQuestion.optionsArray.map((thisOption) => {
                          return (
                            <option
                              key={thisOption}
                              value={thisOption} >
                              {thisOption}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  }
                  {(thisQuestion.type === 'checkbox') &&
                    <div>
                      <div
                        className={cx('question-settings-label')} >
                        Default Selections
                      </div>
                      <div
                        className={cx('context-help')} >
                        These selections are automatically made in the app.
                      </div>
                      {thisQuestion.optionsArray.map((thisOption) => {
                        return (
                          <label
                            key={thisOption}
                            className={cx('default-checkbox')}
                            htmlFor={'default_selector_' + thisQuestion.uuid + '_' + thisOption} >
                            <input
                              id={'default_selector_' + thisQuestion.uuid + '_' + thisOption}
                              type="checkbox"
                              checked={thisQuestion.multipleDefaults.indexOf(thisOption) > -1}
                              value={thisOption}
                              onChange={(event) => {
                                this.editDefaults(event.target.value, thisQuestion.type, thisQuestionIndex);
                              }} />
                            {thisOption}
                          </label>
                        );
                      })}
                    </div>
                  }
                </div>
                <div
                  className={cx('question-block-configure')} >
                  <div
                    className={cx('question-block-action-label-container')} >
                    <div
                      className={cx('question-block-action-label')} >
                      Configure
                    </div>
                    <div
                      className={cx('context-help')} >
                      Add Prompts
                    </div>
                  </div>
                  <div>
                    <div
                      className={cx('question-settings-label')} >
                      Prompt
                    </div>
                    <div
                      className={cx('context-help')} >
                      Write your question here.
                    </div>
                    <Textarea
                      id={'prompt_' + thisQuestion.uuid}
                      name={'prompt_' + thisQuestion.uuid}
                      className={cx({'field-issue': (thisQuestion.uuid in this.state.questionIssues) && ('prompt' in this.state.questionIssues[thisQuestion.uuid])})}
                      onChange={(event) => {
                        this.editPrompt(event.target.value, thisQuestionIndex);
                      }}
                      value={thisQuestion.prompt} />
                  </div>
                  {(['radio', 'checkbox', 'list'].indexOf(thisQuestion.type) > -1) &&
                    <div>
                      <div
                        className={cx('question-settings-label')} >
                        Options
                      </div>
                      <div
                        className={cx('context-help')} >
                        Input all possible answers to the question. Be sure to include all possible options including &quot;None&quot; or &quot;Unknown.&quot; Additional boxes will appear as you type.
                      </div>
                      <div
                        className={cx({'field-issue': (thisQuestion.uuid in this.state.questionIssues) && ('options' in this.state.questionIssues[thisQuestion.uuid])})} >
                        <SchemaTextArray
                          textArray={thisQuestion.optionsArray}
                          editArray={(updatedArray) => {
                            this.editOptions(updatedArray, thisQuestionIndex);
                          }} />
                      </div>
                    </div>
                  }
                </div>
                <div
                  className={cx('question-block-preview')} >
                  <div
                    className={cx('question-block-action-label-container')} >
                    <div
                      className={cx('question-block-action-label')} >
                      Preview
                    </div>
                    <div
                      className={cx('context-help')} >
                      Test the Question
                    </div>
                  </div>
                  <div>
                    <div
                      className={cx('preview-prompt')} >
                      {thisQuestion.prompt}
                    </div>
                  </div>
                  {((thisQuestion.choices === 'hour') || ((thisQuestion.choices === 'oneHour') && (thisQuestion.type === 'startTime'))) &&
                    <div
                      className={cx('preview-hours')} >
                      {range(24).map((thisHour) => {
                        return (
                          <div
                            key={thisHour}
                            className={cx('preview-hour')} >
                            <input
                              id={'preview_selector_' + thisQuestion.uuid + '_' + thisHour}
                              name={'preview_selector_' + thisQuestion.uuid}
                              type={'radio'}
                              value={thisHour} />
                            <label
                              key={thisHour}
                              htmlFor={'preview_selector_' + thisQuestion.uuid + '_' + thisHour} >
                              {padStart(String(thisHour), 2, '0')}:00
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  }
                  {((thisQuestion.choices === 'oneHour') && (thisQuestion.type === 'endTime')) &&
                    <div>
                      When the &quot;One Hour Period&quot; option is selected, the End Time is automatically selected as one hour following the start time.
                    </div>
                  }
                  {thisQuestion.choices === 'precise' &&
                    <div
                      className={cx('preview-time-picker')} >
                      <img src="https://cdn.dunkadoo.org/static/time-picker-preview.png" alt="Mobile Time Pickers" />
                    </div>
                  }
                  {thisQuestion.type === 'text' &&
                    <input type="text" />
                  }
                  {thisQuestion.type === 'number' &&
                    <input type="number" />
                  }
                  {thisQuestion.type === 'list' &&
                    <div>
                      <input
                        type="text"
                        list={'preview_datalist_' + thisQuestion.uuid}
                        defaultValue={thisQuestion.singleDefault} />
                      <datalist
                        id={'preview_datalist_' + thisQuestion.uuid} >
                        {thisQuestion.optionsArray.map((thisOption) => {
                          return (
                            <option
                              key={thisOption}
                              value={thisOption} />
                          );
                        })}
                      </datalist>
                    </div>
                  }
                  {(['radio', 'checkbox'].indexOf(thisQuestion.type) > -1) &&
                    <div>
                      {thisQuestion.optionsArray.map((thisOption) => {
                        return (
                          <div
                            key={thisOption}
                            className={cx('preview-option')} >
                            <input
                              id={'preview_selector_' + thisQuestion.uuid + '_' + thisOption}
                              name={(thisQuestion.type === 'radio') ? ('preview_selector_' + thisQuestion.uuid) : ('preview_selector_' + thisQuestion.uuid + '_' + thisOption)}
                              type={thisQuestion.type}
                              value={thisOption}
                              defaultChecked={(thisQuestion.type === 'radio') ? (thisQuestion.singleDefault === thisOption) : (thisQuestion.multipleDefaults.indexOf(thisOption) > -1)} />
                            <label
                              key={thisOption}
                              className={cx('preview-checkbox')}
                              htmlFor={'preview_selector_' + thisQuestion.uuid + '_' + thisOption} >
                              {thisOption}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>
              <div
                className={cx('question-block-tools')} >
                <div
                  className={cx('tool-container')} >
                  <button
                    className={cx('tool-add-up', {'tool-hidden': metadataTimeQuestion || thisQuestionIndex > 0})}
                    onClick={() => {
                      this.addQuestion(thisQuestionIndex);
                    }} >
                    &#x2b;
                  </button>
                </div>
                <div
                  className={cx('tool-container')} >
                  <button
                    className={cx('tool-move-up', {'tool-hidden': metadataTimeQuestion || thisQuestionIndex === 0})}
                    onClick={() => {
                      this.moveQuestion(thisQuestionIndex, thisQuestionIndex - 1, thisQuestion.uuid);
                    }} >
                    <div className={cx('tool-move-up-icon')} />
                  </button>
                </div>
                <div
                  className={cx('tool-container')} >
                  <button
                    className={cx('tool-remove', {'tool-hidden': metadataTimeQuestion})}
                    onClick={() => {
                      if (schemaArray.length === 1) {
                        this.removeQuestion(thisQuestionIndex, false);
                      } else if (thisQuestionIndex === 0) {
                        this.removeQuestion(thisQuestionIndex, schemaArray[thisQuestionIndex + 1].uuid);
                      } else {
                        this.removeQuestion(thisQuestionIndex, schemaArray[thisQuestionIndex - 1].uuid);
                      }
                    }} >
                    &#x2716;
                  </button>
                </div>
                <div
                  className={cx('tool-container')} >
                  <button
                    className={cx('tool-move-down', {'tool-hidden': metadataTimeQuestion || thisQuestionIndex === schemaArray.length - 1})}
                    onClick={() => {
                      this.moveQuestion(thisQuestionIndex, thisQuestionIndex + 1, thisQuestion.uuid);
                    }} >
                    <div className={cx('tool-move-down-icon')} />
                  </button>
                </div>
                <div
                  className={cx('tool-container')} >
                  <button
                    className={cx('tool-add-down', {'tool-hidden': metadataTimeQuestion && thisQuestionIndex === 0})}
                    onClick={() => {
                      this.addQuestion(thisQuestionIndex + 1);
                    }} >
                    &#x2b;
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <div
          className={cx('schema-manage-button-container')} >
          <button
            onClick={this.saveSchema}>
            Save
          </button>
        </div>
      </div>
    );
  }
}

SchemaEditor.propTypes = {
  updateSchema: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  usedVariables: PropTypes.array.isRequired,
  assetId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired,
};

export default SchemaEditor;
