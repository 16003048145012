/* eslint consistent-return: 0, no-else-return: 0*/
import * as types from '../types';

export function sendUpdateMessages(data) {
  return {
    type: types.UPDATE_MESSAGES,
    data
  };
}

export function updateMessages(messageQueue) {
  return (dispatch) => {
    dispatch(sendUpdateMessages(messageQueue));
  };
}

export function sendReceivedMessages(data) {
  return {
    type: types.RECEIVED_MESSAGES,
    data
  };
}

export function receivedMessages(messageIds) {
  return (dispatch) => {
    dispatch(sendReceivedMessages(messageIds));
  };
}
