import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import update from 'immutability-helper';
import ReactGA from 'react-ga';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import Modal from 'react-modal';
import Loading from 'react-loading';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import Textarea from 'react-textarea-autosize';
import classNames from '../utils/classNames.js';
import { updateProjectInfo, updateWorkingProject, initiateSpeciesButtons, initiateFocusButtons } from '../actions/admin';
import { getTaxonomy } from '../actions/taxonomy';
import styles from 'css/components/schema';
import schemaEditorStyles from 'css/components/schemaeditors';
import * as types from '../types';
import dateStyles from 'css/components/daypicker';
import CoordMap from 'components/CoordMap';
import SpeciesButtons from 'components/SpeciesButtons';
import SpeciesButtonAdder from 'components/SpeciesButtonAdder';

Modal.setAppElement('body');

const cx = classNames.bind(styles);
const cxSchemaEditors = classNames.bind(schemaEditorStyles);

class ProjectManager extends Component {
  constructor(props) {
    super(props);

    this.sendAdminSite = this.sendAdminSite.bind(this);
    this.schemaSorter = this.schemaSorter.bind(this);
    this.injestSiteObject = this.injestSiteObject.bind(this);
    this.egressSiteObject = this.egressSiteObject.bind(this);
    this.updateStartDate = this.updateStartDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.updateProjectLocation = this.updateProjectLocation.bind(this);
    this.duplicateSpeciesList = this.duplicateSpeciesList.bind(this);
    this.siteInfoChange = this.siteInfoChange.bind(this);
    this.generatePublicComponents = this.generatePublicComponents.bind(this);
    this.moveComponent = this.moveComponent.bind(this);

    props.initiateSpeciesButtons(props.adminSchema.species_list);

    props.initiateFocusButtons(props.adminSchema.focus_species_list);

    this.state = {
      schemaId: props.workingProject,
      startDate: new Date(props.adminSchema.start_date),
      endDate: new Date(props.adminSchema.end_date),
      site: this.injestSiteObject(props.adminSchema),
      currentSite: props.workingProject,
      publicPageComponents: [],
      errorMessage: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.currentSite !== nextProps.workingProject) {
      this.setState({
        schemaId: nextProps.workingProject,
        startDate: new Date(nextProps.adminSchema.start_date),
        endDate: new Date(nextProps.adminSchema.end_date),
        site: this.injestSiteObject(nextProps.adminSchema),
        currentSite: nextProps.workingProject,
        errorMessage: '',
      });

      nextProps.initiateSpeciesButtons(nextProps.adminSchema.species_list);
      nextProps.initiateFocusButtons(nextProps.adminSchema.focus_species_list);
    }

    this.generatePublicComponents();
  }

  schemaSorter(schema) {
    // Sort top level questions
    const questions = Object.keys(schema).sort((a, b) => {
      return parseInt(schema[a].order, 10) - parseInt(schema[b].order, 10);
    });

    // Get nested keys
    let nested = [];

    JSON.stringify(schema, (k, v) => {
      if (((k !== '') && !(nested.includes(k))) && !(questions.includes(k))) {
        nested.push(k);
      }

      return v;
    });

    nested = nested.sort((a, b) => {
      const aNum = parseInt(a, 10);
      const bNum = parseInt(b, 10);

      if ((!isNaN(aNum)) && (!isNaN(bNum))) {
        return aNum - bNum;
      } else if (a < b) {
        return -1;
      }

      return 1;
    });

    return [...questions, ...nested];
  }

  injestSiteObject(siteObject) {
    const { getTaxonomy } = this.props;

    const updateSiteObject = cloneDeep(siteObject);
    const fieldNames = Object.keys(updateSiteObject);

    getTaxonomy(siteObject.taxonomy_id);

    for (let fieldIndex = 0; fieldIndex < fieldNames.length; fieldIndex++) {
      // Replace null with empty string
      if (updateSiteObject[fieldNames[fieldIndex]] == null) {
        updateSiteObject[fieldNames[fieldIndex]] = '';
      }
    }

    if (typeof updateSiteObject.schema === 'object') {
      updateSiteObject.schema = JSON.stringify(updateSiteObject.schema, this.schemaSorter(updateSiteObject.schema), '\t');
    }
    if (typeof updateSiteObject.metadata_schema === 'object') {
      updateSiteObject.metadata_schema = JSON.stringify(updateSiteObject.metadata_schema, this.schemaSorter(updateSiteObject.metadata_schema), '\t');
    }
    if (!Array.isArray(updateSiteObject.species_list)) {
      updateSiteObject.species_list = JSON.parse(updateSiteObject.species_list);
    }
    if (!Array.isArray(updateSiteObject.focus_species_list)) {
      updateSiteObject.focus_species_list = JSON.parse(updateSiteObject.focus_species_list);
    }
    if (typeof updateSiteObject.taxonomy_id !== 'string') {
      updateSiteObject.taxonomy_id = String(updateSiteObject.taxonomy_id);
    }
    if (typeof updateSiteObject.organization_id !== 'string') {
      updateSiteObject.organization_id = String(updateSiteObject.organization_id);
    }

    updateSiteObject.newSpeciesButton = '';

    return updateSiteObject;
  }

  egressSiteObject() {
    const { speciesButtonList, focusButtonList } = this.props;

    const updateSiteObject = {};

    let readyToSubmit = true;
    const buildErrorMessage = [];

    try {
      updateSiteObject.id = this.state.site.id;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Save Failed</p>);
    }

    try {
      updateSiteObject.name = this.state.site.name;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Name Failed</p>);
    }

    try {
      updateSiteObject.description = this.state.site.description;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Description Failed</p>);
    }

    try {
      updateSiteObject.directions = this.state.site.directions;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Directions Failed</p>);
    }

    try {
      updateSiteObject.timezone = this.state.site.timezone;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Timezone Failed</p>);
    }

    try {
      updateSiteObject.flag_notable = this.state.site.flag_notable;
      updateSiteObject.flag_review = this.state.site.flag_review;
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Flags Failed</p>);
    }

    try {
      updateSiteObject.latitude = this.state.site.latitude;
      if (isNaN(updateSiteObject.latitude)) {
        throw new Error('Latitude Failed');
      } else if ((updateSiteObject.latitude < -180) || (updateSiteObject.latitude > 180)) {
        throw new Error('Latitude Out of Range');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Latitude Failed - Enter a Latitude Between -180 and 180</p>);
    }

    try {
      updateSiteObject.longitude = this.state.site.longitude;
      if (isNaN(updateSiteObject.longitude)) {
        throw new Error('Longitude Failed');
      } else if ((updateSiteObject.longitude < -180) || (updateSiteObject.longitude > 180)) {
        throw new Error('Longitude Out of Range');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Longitude Failed - Enter a Longitude Between -180 and 180</p>);
    }

    try {
      if (Object.prototype.toString.call(this.state.startDate) === '[object Date]') {
        updateSiteObject.start_date = this.state.startDate;
      } else {
        throw new Error('Start Date Failed');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Start Date Failed</p>);
    }

    try {
      if (Object.prototype.toString.call(this.state.endDate) === '[object Date]') {
        updateSiteObject.end_date = this.state.endDate;
      } else {
        throw new Error('End Date Failed');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>End Date Failed</p>);
    }

    try {
      if (Array.isArray(speciesButtonList)) {
        updateSiteObject.species_list = speciesButtonList;
      } else {
        throw new Error('Species Button List Failed');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Species Button List Failed</p>);
    }

    try {
      if (Array.isArray(focusButtonList)) {
        updateSiteObject.focus_species_list = focusButtonList;
      } else {
        throw new Error('Focus Species Buttons Failed');
      }
    } catch (e) {
      readyToSubmit = false;
      buildErrorMessage.push(<p>Focus Species Buttons Failed</p>);
    }

    updateSiteObject.public = this.state.site.public;

    updateSiteObject.public_show_daytallies = this.state.site.public_show_daytallies;
    updateSiteObject.public_order_daytallies = this.state.site.public_order_daytallies;

    updateSiteObject.public_show_seasontallies = this.state.site.public_show_seasontallies;
    updateSiteObject.public_order_seasontallies = this.state.site.public_order_seasontallies;

    updateSiteObject.public_show_flagged = this.state.site.public_show_flagged;
    updateSiteObject.public_order_flagged = this.state.site.public_order_flagged;
    updateSiteObject.public_notable_hours = this.state.site.public_notable_hours;

    updateSiteObject.public_show_map = this.state.site.public_show_map;
    updateSiteObject.public_order_map = this.state.site.public_order_map;
    updateSiteObject.public_map_hours = this.state.site.public_map_hours;
    updateSiteObject.public_map_notable = this.state.site.public_map_notable;

    updateSiteObject.public_show_livechart = this.state.site.public_show_livechart;
    updateSiteObject.public_order_livechart = this.state.site.public_order_livechart;

    updateSiteObject.public_show_textupdates = this.state.site.public_show_textupdates;
    updateSiteObject.public_order_textupdates = this.state.site.public_order_textupdates;

    updateSiteObject.public_show_composition = this.state.site.public_show_composition;
    updateSiteObject.public_order_composition = this.state.site.public_order_composition;

    updateSiteObject.public_show_hourly = this.state.site.public_show_hourly;
    updateSiteObject.public_order_hourly = this.state.site.public_order_hourly;

    updateSiteObject.public_show_speciescharts = this.state.site.public_show_speciescharts;
    updateSiteObject.public_order_speciescharts = this.state.site.public_order_speciescharts;

    updateSiteObject.public_show_weather = this.state.site.public_show_weather;
    updateSiteObject.public_order_weather = this.state.site.public_order_weather;

    updateSiteObject.schema_lock = this.state.site.schema_lock;
    updateSiteObject.schema_lock_reason = this.state.site.schema_lock_reason;
    updateSiteObject.schema_lock_contact = this.state.site.schema_lock_contact;

    if (readyToSubmit) {
      this.setState({
        errorMessage: '',
      }, () => {
        ReactGA.event({
          category: 'Dashboard: Project',
          action: 'Save: ' + this.props.editing,
          label: this.state.site.name,
        });
      });

      return updateSiteObject;
    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: buildErrorMessage,
      }, () => {
        ReactGA.exception({
          description: 'Project Manager: Failed to Save ' + this.state.site.name,
          fatal: false,
        });
      });
      return false;
    }
  }

  siteInfoChange(event) {
    const { adminSchema, workingProject, getTaxonomy, initiateSpeciesButtons, initiateFocusButtons } = this.props;

    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const updateSite = cloneDeep(this.state.site);
    updateSite[name] = value;

    this.setState({
      site: updateSite
    });

    if (name === 'taxonomy_id') {
      getTaxonomy(value);
      initiateSpeciesButtons([]);
      initiateFocusButtons([]);
    }
  }

  updateStartDate(selectedDate) {
    this.setState({
      startDate: selectedDate,
    });
  }

  updateEndDate(selectedDate) {
    if (selectedDate <= this.state.startDate) {
      return;
    }

    this.setState({
      endDate: selectedDate,
    });
  }

  updateProjectLocation(newPosition) {
    this.setState({
      site: update(this.state.site, {
        latitude: {
          $set: newPosition.latitude,
        },
        longitude: {
          $set: newPosition.longitude,
        }
      })
    });
  }

  duplicateSpeciesList(toList) {
    const { initiateSpeciesButtons, initiateFocusButtons, speciesButtonList, focusButtonList } = this.props;

    if (toList === 'species_list') {
      initiateSpeciesButtons(focusButtonList);
    } else if (toList === 'focus_species_list') {
      initiateFocusButtons(speciesButtonList);
    }
  }

  sendAdminSite(event) {
    const { updateProjectInfo } = this.props;
    event.preventDefault();

    const siteOutput = this.egressSiteObject();

    if (siteOutput) {
      updateProjectInfo(siteOutput);
    }
  }

  generatePublicComponents() {
    // Public page components can be reordered
    let publicPageComponents = [{
      field: 'public_show_daytallies',
      orderField: 'public_order_daytallies',
      image: 'https://cdn.dunkadoo.org/static/public_daytallies.png',
      title: 'Daily Tallies',
      timeControl: false,
      notableControl: false,
      help: 'Display the number of species and total count for the day.',
    }, {
      field: 'public_show_seasontallies',
      orderField: 'public_order_seasontallies',
      image: 'https://cdn.dunkadoo.org/static/public_seasontallies.png',
      title: 'Season Tallies',
      timeControl: false,
      notableControl: false,
      help: 'Display the number of species and total count for the season.',
    }, {
      field: 'public_show_flagged',
      orderField: 'public_order_flagged',
      image: 'https://cdn.dunkadoo.org/static/public_flagged.png',
      title: 'Notable Observations',
      timeControl: true,
      timeField: 'public_notable_hours',
      notableControl: false,
      help: 'Highlight notable sightings.',
    }, {
      field: 'public_show_map',
      orderField: 'public_order_map',
      image: 'https://cdn.dunkadoo.org/static/public_map.png',
      title: 'Map',
      timeControl: true,
      timeField: 'public_map_hours',
      notableControl: true,
      notableField: 'public_map_notable',
      help: 'Display observations on an interactive map.',
    }, {
      field: 'public_show_livechart',
      orderField: 'public_order_livechart',
      image: 'https://cdn.dunkadoo.org/static/public_livechart.png',
      title: 'Real-Time Chart',
      timeControl: false,
      notableControl: false,
      help: 'Display the past two-hours of observations on a real-time animated chart.',
    }, {
      field: 'public_show_textupdates',
      orderField: 'public_order_textupdates',
      image: 'https://cdn.dunkadoo.org/static/public_textupdates.png',
      title: 'Text Updates',
      timeControl: false,
      notableControl: false,
      help: 'Display details from the past two hours of observations.',
    }, {
      field: 'public_show_composition',
      orderField: 'public_order_composition',
      image: 'https://cdn.dunkadoo.org/static/public_composition.png',
      title: 'Species Composition Chart',
      timeControl: false,
      notableControl: false,
      help: 'Represent species composition on an interactive pie chart. Users can choose the time period and species to display.',
    }, {
      field: 'public_show_hourly',
      orderField: 'public_order_hourly',
      image: 'https://cdn.dunkadoo.org/static/public_hourly.png',
      title: 'Hourly Table',
      timeControl: false,
      notableControl: false,
      help: 'Show a table of hourly counts for the whole season.',
    }, {
      field: 'public_show_speciescharts',
      orderField: 'public_order_speciescharts',
      image: 'https://cdn.dunkadoo.org/static/public_speciescharts.png',
      title: 'Daily Species Charts',
      timeControl: false,
      notableControl: false,
      help: 'Display an organized table of all of the speasons seen during the season with a daily graph for each species.',
    }, {
      field: 'public_show_weather',
      orderField: 'public_order_weather',
      image: 'https://cdn.dunkadoo.org/static/public_weather.png',
      title: 'Weather Forecast',
      timeControl: false,
      notableControl: false,
      help: 'Show a weather forecast.',
    }];

    publicPageComponents = publicPageComponents.map((thisComponent) => {
      return update(thisComponent, {
        $merge: {
          order: this.state.site[thisComponent.orderField],
        }
      });
    });

    publicPageComponents = sortBy(publicPageComponents, ['order']);

    this.setState({
      publicPageComponents,
    });
  }

  moveComponent(index, newIndex) {
    let newPublicPageComponents = cloneDeep(this.state.publicPageComponents);
    newPublicPageComponents.splice(newIndex, 0, newPublicPageComponents.splice(index, 1)[0]);

    const updatedPositions = {};

    newPublicPageComponents = newPublicPageComponents.map((thisComponent, thisComponentIndex) => {
      updatedPositions[thisComponent.orderField] = thisComponentIndex;
      return update(thisComponent, {
        $merge: {
          order: thisComponentIndex,
        }
      });
    });

    this.setState({
      publicPageComponents: newPublicPageComponents,
      site: update(this.state.site, {$merge: updatedPositions}),
    });
  }

  render() {
    const { editing, organizationSlug, speciesButtonList, focusButtonList, adminSchema, activeTaxonomyIndex, activeTaxonomyLookup, activeTaxonomyMeta, fetching } = this.props;

    const loadingModal = {
      overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(100, 100, 100, 0.6)'
        },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        border: 'none',
      }
    };

    return (
      <div
        className={cx('container')} >
        <h2>{this.state.site.name}</h2>
        <p
          className={cx('form-errors')} >
          {this.state.errorMessage}
        </p>
        <form
          className={cx('info-form')}
          onSubmit={this.sendAdminSite} >
          {editing === types.ACTIVITY_DETAILS_PROJECT &&
            <div>
              <label
                htmlFor={'name'}
                className={cx('prompt')} >
                Name
              </label>
              <div
                className={cx('context-help')} >
                Enter a name for your project that will help you to easily recognize the project online and in the mobile app.
              </div>
              <input
                id={'name'}
                name="name"
                type="text"
                onChange={this.siteInfoChange}
                value={this.state.site.name} />
              <br />
              <label
                htmlFor={'name'}
                className={cx('prompt')} >
                Taxonomy
              </label>
              <div
                className={cx('context-help')} >
                Every project is connected to a taxonomy. The taxonomy determines what species appear for data collection. You can change the contents of the taxonomy using the Taxonomy Manager, but you can&apos;t switch taxonomies after creating a project.
              </div>
              <div
                className={cx('static-detail')} >
                {activeTaxonomyMeta.name}
              </div>
              <br />
              <label
                htmlFor={'start_date'}
                className={cx('prompt')} >
                Start Date
              </label>
              <div
                className={cx('context-help')} >
                Select the first date that data is collected. This will be the earliest date that appears on data visualizations.
              </div>
              <div
                className={cx('month-selector')} >
                <DayPicker
                  classNames={dateStyles}
                  month={this.state.startDate}
                  selectedDays={this.state.startDate}
                  onDayClick={this.updateStartDate} />
              </div>
              <br />
              <label
                htmlFor={'end_date'}
                className={cx('prompt')} >
                End Date
              </label>
              <div
                className={cx('context-help')} >
                Choose the last date that this project collects data. Before this date, visualizations will end on the current date. After this date, visualizations will stop updating.
              </div>
              <div
                className={cx('month-selector')} >
                <DayPicker
                  classNames={dateStyles}
                  month={this.state.endDate}
                  disabledDays={{
                    before: this.state.startDate
                  }}
                  selectedDays={this.state.endDate}
                  onDayClick={this.updateEndDate} />
              </div>
              <br />
              <label
                htmlFor={'flag_notable'}
                className={cx('prompt')} >
                Allow observer to flag notable observations?
              </label>
              <div
                className={cx('context-help')} >
                Allow observations to be flagged when a notable sighting is entered. This will appear as a column in your data export. You can highlight Notable Observations on the public page.
              </div>
              <input
                id={'flag_notable'}
                className={cx('input-center', 'flag-checkbox')}
                name="flag_notable"
                type="checkbox"
                checked={this.state.site.flag_notable}
                onChange={this.siteInfoChange} />
              <br />
              <label
                htmlFor={'flag_review'}
                className={cx('prompt')} >
                Allow observer to flag observations for review?
              </label>
              <div
                className={cx('context-help')} >
                Allow observations to be flagged when a sighting is entered that needs to be reviewed at a later time. These sightings will appear with a red highlight in the Edit Observations table and in a column in the data export.
              </div>
              <input
                id={'flag_review'}
                className={cx('input-center', 'flag-checkbox')}
                name="flag_review"
                type="checkbox"
                checked={this.state.site.flag_review}
                onChange={this.siteInfoChange} />
              <br />
              <label
                htmlFor={'position'}
                className={cx('prompt')} >
                Project Position
              </label>
              <div
                className={cx('context-help')} >
                Click on the map to select the central location of your project. This location will be the center point on the map to choose the location of observations in Edit Observations when a GPS fix is not obtained. This is also the position where Sunrise is calculated in the mobile app.
              </div>
              <div
                className={cx('coordmap-container')} >
                <CoordMap
                    updateCoords={this.updateProjectLocation}
                    name={this.state.site.name}
                    centerLatitude={this.state.site.latitude}
                    centerLongitude={this.state.site.longitude}
                    baseZoom={3}
                    latitude={this.state.site.latitude}
                    longitude={this.state.site.longitude} />
              </div>
              <br />
              <label
                htmlFor={'timezone'}
                className={cx('prompt')} >
                Timezone
              </label>
              <div
                className={cx('context-help')} >
                Select the timezone where you would like to have data displayed. This timezone is used on mobile and on the web to provide a consistent experience. All data is stored on the server in UTC and is converted prior to display.
              </div>
              <select
                id={'timezone'}
                name="timezone"
                onChange={this.siteInfoChange}
                value={this.state.site.timezone} >
                {moment.tz.names().map((thisZone) => {
                  return (
                    <option
                      key={thisZone}
                      value={thisZone} >
                      {thisZone}
                    </option>
                  );
                })}
              </select>
              <br />
            </div>
          }
          {editing === types.ACTIVITY_DETAILS_PROJECT_PUBLIC &&
            <div>
              <h3
                className={cx('public-components-subheader')} >
                Public Page
              </h3>
              <div
                className={cx('public-show-toggle', 'centered-control')} >
                <input
                  id="public"
                  type="checkbox"
                  name="public"
                  className={cx('public-show-toggle-checkbox')}
                  checked={this.state.site.public}
                  onChange={this.siteInfoChange} />
                <label
                  className={cx('public-show-toggle-label')}
                  htmlFor="public" >
                  <span
                    className={cx('public-show-toggle-inner')} />
                  <span
                    className={cx('public-show-toggle-switch')} />
                </label>
              </div>
            </div>
          }
          {(this.state.site.public && (editing === types.ACTIVITY_DETAILS_PROJECT_PUBLIC)) &&
            <div>
              <h3
                className={cx('public-components-subheader')} >
                Public Page URL
              </h3>
              <div
                className={cx('static-detail')} >
                <Link
                  to={'/explore/' + organizationSlug + '/' + this.state.site.slug} >
                  https://dunkadoo.org/explore/{organizationSlug}/{this.state.site.slug}
                </Link>
              </div>
              <div
                className={cx('public-components')} >
                <div
                  className={cx('public-components-controls')} >
                  <h3
                    className={cx('public-components-subheader')} >
                    Configure
                  </h3>
                  {this.state.publicPageComponents.map((thisComponent, thisComponentIndex) => {
                    return (
                      <div
                        key={thisComponent.field}
                        className={cx('public-component-container')} >
                        <div
                          className={cx('public-component-actions')} >
                          <h3
                            className={cx('public-component-header')} >
                            {thisComponent.title}
                          </h3>
                          <div
                            className={cx('context-help')} >
                            {thisComponent.help}
                          </div>
                          <div
                            className={cx('public-component-options')} >
                            <div
                              className={cx('public-show-toggle')} >
                              <input
                                id={thisComponent.field}
                                type="checkbox"
                                name={thisComponent.field}
                                className={cx('public-show-toggle-checkbox')}
                                checked={this.state.site[thisComponent.field]}
                                onChange={this.siteInfoChange} />
                              <label
                                className={cx('public-show-toggle-label')}
                                htmlFor={thisComponent.field} >
                                <span
                                  className={cx('public-show-toggle-inner')} />
                                <span
                                  className={cx('public-show-toggle-switch')} />
                              </label>
                            </div>
                            {thisComponent.timeControl &&
                              <div>
                                <label
                                  htmlFor={thisComponent.timeField}>
                                  How Long to Display Observations
                                </label>
                                <select
                                  id={thisComponent.timeField}
                                  name={thisComponent.timeField}
                                  onChange={this.siteInfoChange}
                                  value={this.state.site[thisComponent.timeField]} >
                                  <option value="1">1 Hour</option>
                                  <option value="2">2 Hours</option>
                                  <option value="6">6 Hours</option>
                                  <option value="12">12 Hours</option>
                                  <option value="18">18 Hours</option>
                                  <option value="24">1 Day</option>
                                  <option value="36">1.5 Days</option>
                                  <option value="48">2 Days</option>
                                  <option value="72">3 Days</option>
                                  <option value="168">1 Week</option>
                                  <option value="336">2 Weeks</option>
                                  <option value="504">3 Weeks</option>
                                  <option value="672">4 Weeks</option>
                                  <option value="1008">6 Weeks</option>
                                  <option value="1344">8 Weeks</option>
                                  <option value="4032">6 Months</option>
                                  <option value="8760">1 Year</option>
                                  <option value="87600">All Time</option>
                                </select>
                              </div>
                            }
                            {(thisComponent.notableControl && this.state.site.flag_notable) &&
                              <div>
                                <label
                                  htmlFor={thisComponent.notableField} >
                                  <input
                                    id={thisComponent.notableField}
                                    name={thisComponent.notableField}
                                    type="checkbox"
                                    onChange={this.siteInfoChange}
                                    checked={this.state.site[thisComponent.notableField]} />
                                    Only Show Notable Observations
                                </label>
                              </div>
                            }
                          </div>
                        </div>
                        <div
                          className={cx('public-component-tools')} >
                          <div
                            className={cxSchemaEditors('tool-container', 'question-block-tools')} >
                            <button
                              className={cxSchemaEditors('tool-move-up', {'tool-hidden': thisComponentIndex === 0})}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.moveComponent(thisComponentIndex, thisComponentIndex - 1);
                              }} >
                              <div className={cxSchemaEditors('tool-move-up-icon')} />
                            </button>
                          </div>
                          <div
                            className={cxSchemaEditors('tool-container', 'question-block-tools')} >
                            <button
                              className={cxSchemaEditors('tool-move-down', {'tool-hidden': thisComponentIndex === this.state.publicPageComponents.length - 1})}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.moveComponent(thisComponentIndex, thisComponentIndex + 1);
                              }} >
                              <div className={cxSchemaEditors('tool-move-down-icon')} />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={cx('public-components-preview')} >
                  <h3
                    className={cx('public-components-subheader')} >
                    Preview
                  </h3>
                  <div
                    className={cx('public-preview-container')} >
                    {this.state.publicPageComponents.map((thisComponent) => {
                      return (
                        <img
                          className={cx('preview-example-image', {'preview-component-displayed': this.state.site[thisComponent.field], 'preview-component-hidden': !this.state.site[thisComponent.field]})}
                          key={thisComponent.field}
                          src={thisComponent.image}
                          alt={thisComponent.title} />
                      );
                    })}
                  </div>
                </div>
              </div>
              <h3
                className={cx('public-components-subheader')} >
                Project Information
              </h3>
              <label
                htmlFor={'description'}
                className={cx('prompt')} >
                Description
              </label>
              <Textarea
                id={'description'}
                name="description"
                onChange={this.siteInfoChange}
                value={this.state.site.description} />
              <br />
              <label
                htmlFor={'directions'}
                className={cx('prompt')} >
                Directions
              </label>
              <Textarea
                id={'directions'}
                name="directions"
                onChange={this.siteInfoChange}
                value={this.state.site.directions} />
            </div>
          }
          {editing === types.ACTIVITY_SPECIES_BUTTON &&
            <div>
              <div
                className={cx('duplicate-list-button')}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.duplicateSpeciesList('species_list');
                }} >
                Copy from Focus Species
              </div>
              <label
                htmlFor={'speciesList'}
                className={cx('prompt')} >
                Species Buttons
              </label>
              <div
                className={cx('context-help')} >
                <span className={cx('emphasis')}>Configure the Species Buttons in the Mobile App Here</span>
                <ul>
                  <li><span className={cx('emphasis')}>Add a New Species:</span> Type the species common name or code in the box below. Once the right suggestion appears, use the down arrow key to select the entry and press enter. You may also click on the suggestion with your mouse.</li>
                  <li><span className={cx('emphasis')}>Remove a Species:</span> Click the X button on the species you would like to remove.</li>
                  <li><span className={cx('emphasis')}>Move a Species Up or Down:</span> Click on the species with your mouse and drag the position into place.</li>
                </ul>
              </div>
              {activeTaxonomyIndex.length > 0 &&
                <div>
                  <SpeciesButtons
                    buttonGroup="species_list" />
                  <SpeciesButtonAdder
                    buttonGroup="species_list" />
                </div>
              }
            </div>
          }
          {editing === types.ACTIVITY_SPECIES_FOCUS &&
            <div>
              <div
                className={cx('duplicate-list-button')}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.duplicateSpeciesList('focus_species_list');
                }} >
                Copy from Species Buttons
              </div>
              <label
                htmlFor={'focusList'}
                className={cx('prompt')} >
                Focus Species
              </label>
              <div
                className={cx('context-help')} >
                <span className={cx('emphasis')}>Configure the Focus Species for Visualizations Here</span>
                <ul>
                  <li><span className={cx('emphasis')}>Add a New Species:</span> Type the species common name or code in the box below. Once the right suggestion appears, use the down arrow key to select the entry and press enter. You may also click on the suggestion with your mouse.</li>
                  <li><span className={cx('emphasis')}>Remove a Species:</span> Click the X button on the species you would like to remove.</li>
                  <li><span className={cx('emphasis')}>Move a Species Up or Down:</span> Click on the species with your mouse and drag the position into place.</li>
                </ul>
              </div>
              {activeTaxonomyIndex.length > 0 &&
                <div>
                  <SpeciesButtons
                    buttonGroup="focus_species_list" />
                  <SpeciesButtonAdder
                    buttonGroup="focus_species_list" />
                </div>
              }
            </div>
          }
          {editing === types.ACTIVITY_SUPERADMIN_PROJECT &&
            <div>
              <h3
                className={cx('public-components-subheader')} >
                Project Schema Freeze
              </h3>
              <div
                className={cx('public-show-toggle', 'centered-control')} >
                <input
                  id="schema_lock"
                  type="checkbox"
                  name="schema_lock"
                  className={cx('public-show-toggle-checkbox')}
                  checked={this.state.site.schema_lock}
                  onChange={this.siteInfoChange} />
                <label
                  className={cx('public-show-toggle-label')}
                  htmlFor="schema_lock" >
                  <span
                    className={cx('public-show-toggle-inner')} />
                  <span
                    className={cx('public-show-toggle-switch')} />
                </label>
              </div>
            </div>
          }
          {(this.state.site.schema_lock && (editing === types.ACTIVITY_SUPERADMIN_PROJECT)) &&
            <div>
              <label
                htmlFor={'schema_lock_reason'}
                className={cx('prompt')} >
                Schema Lock Explanation
              </label>
              <Textarea
                id={'schema_lock_reason'}
                name="schema_lock_reason"
                placeholder="Editing questions in this project has been disabled to support auto submitting data to HawkCount.org. Please your Dunkadoo project coordinator to make changes."
                onChange={this.siteInfoChange}
                value={this.state.site.schema_lock_reason} />
              <br />
              <label
                htmlFor={'schema_lock_contact'}
                className={cx('prompt')} >
                Project Coordinator Contact Information
              </label>
              <input
                id={'schema_lock_contact'}
                name="schema_lock_contact"
                type="text"
                placeholder="apps@dunkadoo.org"
                onChange={this.siteInfoChange}
                value={this.state.site.schema_lock_contact} />
              <br />
            </div>
          }
          <br />
          <p
            className={cx('form-errors')} >
            {this.state.errorMessage}
          </p>
          <input type="submit" value="Save" />
        </form>
        <Modal
          isOpen={fetching}
          style={loadingModal}
          contentLabel="Loading..."
        >
          <Loading type="spinningBubbles" color="#fff" height={100} width={100} />
        </Modal>
      </div>
    );
  }
}

ProjectManager.propTypes = {
  updateProjectInfo: PropTypes.func.isRequired,
  updateWorkingProject: PropTypes.func.isRequired,
  getTaxonomy: PropTypes.func.isRequired,
  initiateSpeciesButtons: PropTypes.func.isRequired,
  initiateFocusButtons: PropTypes.func.isRequired,
  speciesButtonList: PropTypes.array.isRequired,
  focusButtonList: PropTypes.array.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  editing: PropTypes.string.isRequired,
  adminSchema: PropTypes.object.isRequired,
  workingProject: PropTypes.number.isRequired,
  activeTaxonomyLookup: PropTypes.object.isRequired,
  activeTaxonomyIndex: PropTypes.array.isRequired,
  activeTaxonomyMeta: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    speciesButtonList: state.admin.speciesButtons,
    focusButtonList: state.admin.focusButtons,
    adminSchema: state.admin.adminSchemas[state.admin.workingProject],
    workingProject: state.admin.workingProject,
    organizationSlug: state.admin.orgProjects[state.admin.workingOrg].slug,
    projectId: state.admin.workingProject,
    editing: state.admin.editing,
    activeTaxonomyLookup: state.admin.activeTaxonomy.lookup,
    activeTaxonomyIndex: state.admin.activeTaxonomy.index,
    activeTaxonomyMeta: state.admin.activeTaxonomy.meta,
    fetching: state.isFetching,
  };
}

export default connect(mapStateToProps, { updateProjectInfo, updateWorkingProject, getTaxonomy, initiateSpeciesButtons, initiateFocusButtons })(ProjectManager);
