import { combineReducers } from 'redux';
import moment from 'moment-timezone';
import uuidV1 from 'uuid/v1';
import * as types from '../types';

const displayDuration = 6;

const messages = (
  state = [],
  action
) => {
  switch (action.type) {
    case types.UPDATE_ADMIN_ORG_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Organization Saved', type: 'SUCCESS', uuid: uuidV1()}];
    case types.UPDATE_ADMIN_ORG_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to save organization. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.UPDATE_ADMIN_PROJECT_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Project Saved', type: 'SUCCESS', uuid: uuidV1()}];
    case types.UPDATE_ADMIN_PROJECT_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to save project. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.REQUEST_TAXONOMY_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Request failed. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.UPDATE_TAXONOMY_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Taxonomy Updated', type: 'SUCCESS', uuid: uuidV1()}];
    case types.UPDATE_TAXONOMY_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to save taxonomy. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.UPDATE_USER_PERMISSIONS_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'User Updated', type: 'SUCCESS', uuid: uuidV1()}];
    case types.UPDATE_USER_PERMISSIONS_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to update user. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.UPDATE_ORG_IMAGE_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Image Updated', type: 'SUCCESS', uuid: uuidV1()}];
    case types.UPDATE_ORG_IMAGE_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to update image. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.DELETE_ORG_IMAGE_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Image Deleted', type: 'SUCCESS', uuid: uuidV1()}];
    case types.DELETE_ORG_IMAGE_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to delete image. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.GET_RECORD_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to retrieve record. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.SET_RECORD_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Record Saved', type: 'SUCCESS', uuid: uuidV1()}];
    case types.SET_RECORD_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to save record. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.ADD_ORGANIZATION_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Organization Created', type: 'SUCCESS', uuid: uuidV1()}];
    case types.ADD_ORGANIZATION_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to create organization. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.ADD_PROJECT_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Project Created', type: 'SUCCESS', uuid: uuidV1()}];
    case types.ADD_PROJECT_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to create project. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.ADD_TAXONOMY_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Taxonomy Created', type: 'SUCCESS', uuid: uuidV1()}];
    case types.ADD_TAXONOMY_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Failed to create taxonomy. Please check your internet connection and try again.', type: 'FAILURE', uuid: uuidV1()}];
    case types.ADD_INTEGRATION_HAWKCOUNT_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'HawkCount Integration Created', type: 'SUCCESS', uuid: uuidV1()}];
    case types.ADD_INTEGRATION_HAWKCOUNT_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Hawkcount Integration Failed', type: 'FAILURE', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'HawkCount Crossref Saved', type: 'SUCCESS', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_CROSSREF_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Hawkcount Crossref Failed', type: 'FAILURE', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'HawkCount Condition Fields Saved', type: 'SUCCESS', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_CONDITIONSFIELDS_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Hawkcount Condition Fields Failed', type: 'FAILURE', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'HawkCount Active Status Saved', type: 'SUCCESS', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_ACTIVE_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Hawkcount Active Status Failed', type: 'FAILURE', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_SUCCESS:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'HawkCount Force Sync Started', type: 'SUCCESS', uuid: uuidV1()}];
    case types.INTEGRATION_HAWKCOUNT_FORCESYNC_FAILURE:
      return [...state, {expireAt: moment().add(displayDuration, 'seconds'), text: 'Hawkcount Force Sync Failed', type: 'FAILURE', uuid: uuidV1()}];
    case types.RECEIVED_MESSAGES:
      return state.filter(m => (action.data.indexOf(m.uuid) === -1));
    case types.UPDATE_MESSAGES:
      return action.data;
    default:
      return state;
  }
};

const messageReducer = combineReducers({
  messages,
});

export default messageReducer;
