import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from '../utils/classNames.js';
import Dimensions from '../utils/local_modules/react-dimensions';
import GoogleMapReact from 'google-map-react';
import { fitBounds, ptInBounds, withStateSelector } from 'google-map-react/utils';
import MapMarker from 'components/MapMarker';
import without from 'lodash/without';
import { navigateProject } from '../actions/project';
import styles from 'css/components/projectmap';

const cx = classNames.bind(styles);

class ProjectMap extends Component {
  constructor(props) {
    super(props);

    this.onChildClick = this.onChildClick.bind(this);
    this.orgPosition = this.orgPosition.bind(this);
    this.setBounds = this.setBounds.bind(this);
    this.tightenZoom = this.tightenZoom.bind(this);

    this.state = {
      orgArray: Object.keys(props.organizations).map((i) => { return parseInt(i, 10); }),
      projectArray: Object.keys(props.projects).map((i) => { return parseInt(i, 10); }),
      currentOrgs: Object.keys(props.organizations).map((i) => { return parseInt(i, 10); }),
      currentProjects: [],
      center: null,
      zoom: null,
    };
  }

  onChildClick(markerKey) {
    const { projects, organizations, navigateProject } = this.props;

    if (markerKey.slice(0, 3) === 'org') {
      const thisOrg = parseInt(markerKey.slice(4, markerKey.length), 10);

      this.setBounds(thisOrg);

      this.setState({
        currentOrgs: without(this.state.currentOrgs, thisOrg),
        currentProjects: [...this.state.currentProjects, ...organizations[thisOrg].projects],
      });
    } else {
      navigateProject(organizations[projects[markerKey].organization_id].slug, projects[markerKey].slug);
    }
  }

  setBounds(orgId) {
    const { containerHeight, containerWidth, organizations, projects } = this.props;

    if (organizations[orgId].projects.length > 1) {
      let north = -180;
      let south = 180;
      let west = 180;
      let east = -180;

      organizations[orgId].projects.forEach((thisProject) => {
        const latitude = projects[thisProject].latitude;
        const longitude = projects[thisProject].longitude;

        if (latitude > north) {
          north = latitude;
        }
        if (latitude < south) {
          south = latitude;
        }
        if (longitude > east) {
          east = longitude;
        }
        if (longitude < west) {
          west = longitude;
        }
      });

      const bounds = {
        ne: {
          lat: north,
          lng: east
        },
        sw: {
          lat: south,
          lng: west
        }
      };

      const size = {
        width: containerWidth,
        height: containerHeight,
      };

      let {center, zoom} = fitBounds(bounds, size);

      // Leave room for the map markers
      zoom -= 1;

      this.setState({
        center,
        zoom
      });
    } else {
      // When there's only one project, manually handle
      const center = {
        lat: projects[organizations[orgId].projects[0]].latitude,
        lng: projects[organizations[orgId].projects[0]].longitude,
      };
      const zoom = 14;

      this.setState({
        center,
        zoom
      });
    }
  }

  orgPosition(orgId) {
    const { organizations, projects } = this.props;

    let lat = 0;
    let lng = 0;

    organizations[orgId].projects.forEach((thisProject) => {
      lat += projects[thisProject].latitude;
      lng += projects[thisProject].longitude;
    });

    lat /= organizations[orgId].projects.length;
    lng /= organizations[orgId].projects.length;

    const position = {
      lat,
      lng
    };

    return position;
  }

  tightenZoom(markerKey) {
    const { projects, organizations, navigateProject } = this.props;

    let zoom;

    if (this.state.zoom) {
      zoom = Math.max(this.state.zoom + 2, 14);
    } else {
      zoom = 8;
    }

    if (markerKey.slice(0, 3) === 'org') {
      const thisOrg = parseInt(markerKey.slice(4, markerKey.length), 10);

      const { lat, lng } = this.orgPosition(thisOrg);

      const center = {
        lat,
        lng
      };

      this.setState({
        center,
        zoom
      });
    } else {
      const center = {
        lat: projects[markerKey].latitude,
        lng: projects[markerKey].longitude,
      };

      this.setState({
        center,
        zoom
      });
    }
  }

  render() {
    const { containerHeight, containerWidth, projectData, projects, organizations, googleAPIKey } = this.props;

    const bounds = {
      ne: {
        lat: 49.384358,
        lng: -66.885444
      },
      sw: {
        lat: 5.000000,
        lng: -124.848974
      }
    };

    const size = {
      width: containerWidth,
      height: containerHeight,
    };

    const {center, zoom} = fitBounds(bounds, size);

    const mapSetup = {
      key: googleAPIKey,
      language: 'en'
    };

    const mapOptions = {
      mapTypeId: 'satellite'
    };

    return (
      <GoogleMapReact
        bootstrapURLKeys={mapSetup}
        center={this.state.center || center}
        zoom={this.state.zoom || zoom}
        options={mapOptions} >
        {this.state.orgArray.filter((thisOrg) => {
            return this.state.currentOrgs.indexOf(thisOrg) > -1;
          }).map((thisOrg) => {
          const { lat, lng } = this.orgPosition(thisOrg);
          return (
            <MapMarker
              key={'org_' + String(thisOrg)}
              describes={'org_' + String(thisOrg)}
              lat={lat}
              lng={lng}
              orgLogo={organizations[thisOrg].logo_url}
              name={organizations[thisOrg].name}
              tightenZoom={this.tightenZoom}
              selectedMarker={this.onChildClick} />
          );
        })}
        {this.state.projectArray.filter((thisProject) => {
            return this.state.currentProjects.indexOf(thisProject) > -1;
          }).map((thisProject) => {
          return (
            <MapMarker
              key={thisProject}
              describes={String(thisProject)}
              lat={projects[thisProject].latitude}
              lng={projects[thisProject].longitude}
              orgLogo={projects[thisProject].Organization.logo_url}
              name={projects[thisProject].name}
              tightenZoom={this.tightenZoom}
              selectedMarker={this.onChildClick} />
          );
        })}
      </GoogleMapReact>
    );
  }
}

ProjectMap.propTypes = {
  navigateProject: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  googleAPIKey: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    projects: state.landscape.projects,
    organizations: state.landscape.organizations,
    googleAPIKey: state.environment.GOOGLE_APIKEY,
  };
}

export default connect(mapStateToProps, { navigateProject })(
  Dimensions({
    getHeight: function(element) {
      return Math.min(window.innerHeight, 600);
    },
    getWidth: function(element) {
      return Math.min(window.innerWidth - 50, 800);
    }
})(ProjectMap));
